export const EXPORT_TABLE: any = [
  {
    // key: "holeList",
    key: "hole",
    label: "Hole",
    bgColor: "FFF3F3F3",
    subTypes: [
      {
        subKey: "open_hole",
        subLabel: "Open Hole",
        operations: [
          { operationLabel: "Centering", operationKey: "centering_tools" },
          { operationLabel: "Roughing", operationKey: "roughing_tools" },
          { operationLabel: "Deburring", operationKey: "deburring_tools" },
        ],
      },
      {
        subKey: "finish_hole",
        subLabel: "Finish Hole",
        operations: [
          { operationLabel: "Centering", operationKey: "centering_tools" },
          { operationLabel: "Roughing", operationKey: "roughing_tools" },
          { operationLabel: "Finishing", operationKey: "finishing_tools" },
          { operationLabel: "Deburring", operationKey: "deburring_tools" },
        ],
      },
    ],
  },
  {
    // key: "threadHoleList",
    key: "thread_hole",
    label: "Thread hole",
    bgColor: "FFF3F3F3",
    subTypes: [
      {
        subKey: "thread_hole",
        subLabel: "Thread hole",
        operations: [
          { operationLabel: "Centering", operationKey: "centering_tools" },
          { operationLabel: "Roughing", operationKey: "roughing_tools" },
          { operationLabel: "Threading", operationKey: "threading_tools" },
        ],
      },
    ],
  },
  {
    // key: "faceList",
    key: "face",
    label: "Face",
    bgColor: "FFF3F3F3",
    subTypes: [
      {
        subKey: "top_face",
        subLabel: "Top Face",
        operations: [
          { operationLabel: "Roughing", operationKey: "roughing_tools" },
          { operationLabel: "Finishing", operationKey: "finishing_tools" },
        ],
      },
      {
        subKey: "bottom_face",
        subLabel: "Bottom Face",
        operations: [
          { operationLabel: "Roughing", operationKey: "roughing_tools" },
          { operationLabel: "Finishing", operationKey: "finishing_tools" },
        ],
      },
      {
        subKey: "side_face",
        subLabel: "Side Face",
        operations: [
          { operationLabel: "Roughing", operationKey: "roughing_tools" },
          { operationLabel: "Finishing", operationKey: "finishing_tools" },
        ],
      },
    ],
  },
  {
    // key: "pocketList",
    key: "pocket",
    label: "Pocket",
    bgColor: "FFF3F3F3",
    subTypes: [
      {
        subKey: "pocket_2d",
        subLabel: "2D Pocket",
        operations: [
          { operationLabel: "Roughing", operationKey: "roughing_tools" },
          { operationLabel: "Finishing", operationKey: "finishing_tools" },
          { operationLabel: "Semi Finishing", operationKey: "semi_finishing_tools" },
          { operationLabel: "Cornering", operationKey: "cornering_tools" },
          { operationLabel: "Curling", operationKey: "curling_tools" },
        ],
      },
      {
        subKey: "pocket_3d",
        subLabel: "3D Pocket",
        operations: [
          { operationLabel: "Roughing", operationKey: "roughing_tools" },
          { operationLabel: "Finishing", operationKey: "finishing_tools" },
          { operationLabel: "Semi Finishing", operationKey: "semi_finishing_tools" },
          { operationLabel: "Cornering", operationKey: "cornering_tools" },
          { operationLabel: "Curling", operationKey: "curling_tools" },
        ],
      }
    ],
  },
  {
    // key: "contourList",
    key: "contour",
    label: "Contour",
    bgColor: "FFF3F3F3",
    subTypes: [
      {
        subKey: "fillet",
        subLabel: "Fillet",
        operations: [
          { operationLabel: "Roughing", operationKey: "roughing_tools" },
          { operationLabel: "Finishing", operationKey: "finishing_tools" },
        ],
      },
      {
        subKey: "slot",
        subLabel: "Slot",
        operations: [
          { operationLabel: "Roughing", operationKey: "roughing_tools" },
          { operationLabel: "Finishing", operationKey: "finishing_tools" },
          { operationLabel: "Cornering", operationKey: "cornering_tools" },
          { operationLabel: "Curling", operationKey: "curling_tools" },
        ],
      },
      {
        subKey: "chamfer",
        subLabel: "Chamfer",
        operations: [
          { operationLabel: "Roughing", operationKey: "roughing_tools" },
          { operationLabel: "Finishing", operationKey: "finishing_tools" },
        ],
      },
    ],
  },
  // {
  //   key: "coreCavityList",
  //   label: "3D Core & Cavity",
  //   bgColor: "FFF3F3F3",
  //   subTypes: [
  //     {
  //       subKey: "3d-core",
  //       subLabel: "3d-core",
  //       operations: [
  //         { operationLabel: "Roughing", operationKey: "roughing_tools" },
  //         { operationLabel: "Finishing", operationKey: "finishing_tools" },
  //         { operationLabel: "Semi Finishing", operationKey: "semi_finishing_tools" },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   key: "pencilList",
  //   label: "3D Pencil",
  //   bgColor: "FFF3F3F3",
  //   subTypes: [
  //     {
  //       subKey: "3d-pencil",
  //       subLabel: "3d-pencil",
  //       operations: [
  //         { operationLabel: "Roughing", operationKey: "roughing_tools" },
  //         { operationLabel: "Finishing", operationKey: "finishing_tools" },
  //       ],
  //     },
  //   ],
  // },
];

export const EXPORT_TABLE_KEYS = [
  // { key: "Tool Assembly ID", bgColor: "FFD9EAD3", type: 's' },
  // { key: "Tool specification", bgColor: "FFD9EAD3", type: 's' },
  // { key: "TL_TOOL_TYPE_HOLE_ID", bgColor: "FFD9EAD3", type: 's' },
  // { key: "TL_DIAMETER", bgColor: "FFD9EAD3", type: 'n' },
  // { key: "TL_CORNER_RADIUS", bgColor: "FFD9EAD3", type: 'n' },
  // { key: "TL_FLUTE_LENGTH", bgColor: "FFD9EAD3", type: 'n' },
  // { key: "Total Length (mm)", bgColor: "FFD9EAD3", type: 'n' },
  // { key: "Teeth", bgColor: "FFD9EAD3", type: 'n' },
  // { key: "Tip angle", bgColor: "FFD9EAD3", type: 'n' },

  { key: "Tool ID", bgColor: "FFD9EAD3", type: 's' },
  { key: "Description", bgColor: "FFD9EAD3", type: 's' },
  { key: "Tool type", bgColor: "FFD9EAD3", type: 's' },
  { key: "Cutting diameter", bgColor: "FFD9EAD3", type: 'n' },
  { key: "TL_CORNER_RADIUS", bgColor: "FFD9EAD3", type: 'n' },
  { key: "Flute length", bgColor: "FFD9EAD3", type: 'n' },
  { key: "Body length", bgColor: "FFD9EAD3", type: 'n' },
  { key: "Flute count", bgColor: "FFD9EAD3", type: 'n' },
  { key: "Taper angle", bgColor: "FFD9EAD3", type: 'n' },

  { key: "Optimal Ap (mm)", bgColor: "FFFFF2CC", type: 'n' },
  { key: "Optimal Ae (mm)", bgColor: "FFFFF2CC", type: 'n' },
  { key: "Optimal V (m/min)", bgColor: "FFFFF2CC", type: 'n' },
  { key: "Optimal F (mm/min)", bgColor: "FFFFF2CC", type: 'n' },
  { key: "Optimal MRR (mm^3/min)", bgColor: "FFFFE599", type: 'n' },
  { key: "Optimal T (min)", bgColor: "FFFFE599", type: 'n' },
];
