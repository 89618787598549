import {
  ADD_PROJECT,
  SET_CONTOUR_DATA,
  SET_CONTOUR_LIST,
  SET_CORE_CAVITY_DATA,
  SET_CORE_CAVITY_LIST,
  SET_CUTTING_TOOL,
  SET_FACE_DATA,
  SET_FACE_LIST,
  SET_FORGOT_FORM,
  SET_HOLE_DATA,
  SET_HOLE_LIST,
  SET_LOADING_BAR,
  SET_LOAD_DATA,
  SET_MACHINE_SETUP,
  SET_ORIGIN_MACHINE_SETUP,
  SET_POCKET_DATA,
  SET_POCKET_LIST,
  SET_PROFILE_IMG,
  SET_PROJECT_INFO,
  SET_REGISTER_FORM,
  SET_SETUP_NUMBER,
  SET_STREAM_STEP,
  SET_THREAD_HOLE_DATA,
  SET_THREAD_HOLE_LIST,
  SET_TOOLTIP,
  SET_USER_INFO,
  SET_VISIBLE_IDS,
} from "constant";
import { useReducer, createContext } from "react";

const initialState: any = {
  loadingBar: false,
  projectList: [],
  projectInfo: {},
  setupNumber: 0,
  // Form Data
  holeList: [],
  threadHoleList: [],
  faceList: [],
  pocketList: [],
  contourList: [],
  // coreCavityList: [],
  // pencilList: [],

  registerForm: {},
  forgotForm: null,

  userInfo: null,

  showTooltip: null,
  hideTooltip: null,

  cuttingToolOpen: false,
  cuttingToolContent: null,

  profileImgUrl: "",

  machiningSetups: null,
  originMachiningSetups: null,

  streamStep: null,

  visiblefeature_ids: {},
};

export const GlobalContext = createContext(initialState);

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case SET_TOOLTIP:
      return {
        ...state,
        showTooltip: action?.payload?.showTooltip,
        hideTooltip: action?.payload?.hideTooltip,
      };
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: action?.payload,
      };
    case SET_REGISTER_FORM:
      return {
        ...state,
        registerForm: action?.payload,
      };
    case SET_FORGOT_FORM:
      return {
        ...state,
        forgotForm: action?.payload,
      };
    case ADD_PROJECT:
      return {
        ...state,
        projectList: [...(state?.projectList || []), action?.payload],
      };
    case SET_LOADING_BAR:
      return {
        ...state,
        loadingBar: action?.payload,
      };
    case SET_PROJECT_INFO:
      return {
        ...state,
        projectInfo: action?.payload,
      };
    case SET_HOLE_LIST:
      return {
        ...state,
        holeList: [...action?.payload],
      };
    case SET_HOLE_DATA:
      const newHoleList = [...state?.holeList];
      newHoleList[action?.payload?.index][action?.payload?.key] =
        action?.payload?.value;
      return {
        ...state,
        holeList: newHoleList,
      };
    case SET_THREAD_HOLE_LIST:
      return {
        ...state,
        threadHoleList: action?.payload,
      };
    case SET_THREAD_HOLE_DATA:
      let newThreadHoleList = [...state?.threadHoleList];
      newThreadHoleList[action?.payload?.index][action?.payload?.key] =
        action?.payload?.value;
      return {
        ...state,
        threadHoleList: newThreadHoleList,
      };
    case SET_FACE_LIST:
      return {
        ...state,
        faceList: action?.payload,
      };
    case SET_FACE_DATA:
      let newFaceList = [...state?.faceList];
      newFaceList[action?.payload?.index][action?.payload?.key] =
        action?.payload?.value;
      return {
        ...state,
        faceList: newFaceList,
      };
    case SET_POCKET_LIST:
      return {
        ...state,
        pocketList: action?.payload,
      };
    case SET_POCKET_DATA:
      let newPocketList = [...state?.pocketList];
      newPocketList[action?.payload?.index][action?.payload?.key] =
        action?.payload?.value;
      return {
        ...state,
        pocketList: newPocketList,
      };
    case SET_CONTOUR_LIST:
      return {
        ...state,
        contourList: action?.payload,
      };
    case SET_CONTOUR_DATA:
      let newContourList = [...state?.contourList];
      newContourList[action?.payload?.index][action?.payload?.key] =
        action?.payload?.value;
      return {
        ...state,
        contourList: newContourList,
      };
    // case SET_CORE_CAVITY_LIST:
    //   return {
    //     ...state,
    //     coreCavityList: action?.payload,
    //   };
    // case SET_CORE_CAVITY_DATA:
    //   let newCoreCavityList = [...state?.coreCavityList];
    //   newCoreCavityList[action?.payload?.index][action?.payload?.key] =
    //     action?.payload?.value;
    //   return {
    //     ...state,
    //     coreCavityList: newCoreCavityList,
    //   };
    // case SET_PENCIL_LIST:
    //   return {
    //     ...state,
    //     pencilList: action?.payload,
    //   };
    // case SET_PENCIL_DATA:
    //   let newPencilList = [...state?.pencilList];
    //   newPencilList[action?.payload?.index][action?.payload?.key] =
    //     action?.payload?.value;
    //   return {
    //     ...state,
    //     pencilList: newPencilList,
    //   };

    case SET_CUTTING_TOOL:
      return {
        ...state,
        cuttingToolOpen: action.payload.open,
        cuttingToolContext: action.payload.content,
      };

    case SET_PROFILE_IMG:
      return {
        ...state,
        profileImgUrl: action.payload,
      };

    case SET_SETUP_NUMBER:
      return {
        ...state,
        setupNumber: action.payload,
      };

    case SET_LOAD_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case SET_MACHINE_SETUP:
      return {
        ...state,
        machiningSetups: action.payload,
      };

    case SET_ORIGIN_MACHINE_SETUP:
      return {
        ...state,
        originMachiningSetups: JSON.parse(JSON.stringify(action.payload)),
      };

    case SET_STREAM_STEP:
      return {
        ...state,
        streamStep: action?.payload
      }

    case SET_VISIBLE_IDS:
      return {
        ...state,
        visiblefeature_ids: {
          ...state.visiblefeature_ids,
          [action?.payload?.setupNumber]: action?.payload.ids,
        },
      };

    default:
      return {
        state,
      };
  }
};

export const GlobalContextProvider = (props: any) => {
  const [state, dispatch] = useReducer<any>(reducer, initialState);
  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {props.children}
    </GlobalContext.Provider>
  );
};
