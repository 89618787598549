import { Listbox, Transition } from "@headlessui/react";
import { useState } from "react";
import { FiChevronDown } from "react-icons/fi";

const people = [
  { id: 1, name: "Durward Reynolds", unavailable: false },
  { id: 2, name: "Kenton Towne", unavailable: false },
  { id: 3, name: "Therese Wunsch", unavailable: false },
  { id: 4, name: "Benedict Kessler", unavailable: true },
  { id: 5, name: "Katelyn Rohan", unavailable: false },
];

interface IStrategyMenu {
  isEdit?: boolean;
  value: any;
  options: any;
  onChange?: any;
  isValid?: boolean;
}

const StrategyMenu: React.FC<IStrategyMenu> = ({
  isEdit,
  value,
  options,
  onChange,
  isValid = true
}) => {
  return (
    <div className="z-20">
      <Listbox value={value} onChange={onChange} as="div" className="relative">
        {({ open }) => {
          return (
            <>
              <Listbox.Button className="bg-gray-eac rounded-sm px-1.5 py-px flex flex-row items-center space-x-2">
                <span
                  className={`text-xs font-medium ${
                    isValid ? "text-gray-475" : "text-gray-400"
                  }`}
                >
                  {value?.label}
                </span>
                {isEdit && (
                  <FiChevronDown className="h-[14px] w-[14px] text-gray-475" />
                )}
              </Listbox.Button>
              {isEdit && (
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                  className="absolute left-0 right-0 w-full rounded-md bg-white shadow-lg z-30"
                >
                  <Listbox.Options className="py-2">
                    {options.map((option: any) => (
                      <Listbox.Option
                        key={option.value}
                        value={option}
                        className="rounded-sm text-xs font-medium hover:opacity-80 text-gray-475 px-1.5 py-1 cursor-pointer"
                      >
                        {option?.label}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              )}
            </>
          );
        }}
      </Listbox>
    </div>
  );
};

export default StrategyMenu;
