import React, { useContext, useEffect, useState } from "react";
import DefaultLayout from "Layouts/DefaultLayout";
import SearchInput from "components/SearchInput";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";
// import { useLocalFusionApiCall } from "hooks/useLocalFusionAPI";
import { GlobalContext } from "context/GlobalContext";
import { SET_PROJECT_INFO } from "constant";
import { useApiCall } from "hooks";
import { getProjectsAPI } from "services";
import Table from "components/Table";
import StyledProjectValue from "./StyledProjectValue";
import { FiEdit2, FiEye } from "react-icons/fi";
import LoadingBar from "components/LoadingBar";

const PROJECT_INIT_COLUMNS = [
  { label: "Name", id: "projectName" },
  { label: "Material", id: "material" },
  { label: "CAD File", id: "-" },
  { label: "Machine", id: "machineName" },
  { label: "Created", id: "updated_timestamp" },
];

const ProjectList = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(GlobalContext);
  const { userInfo } = state;
  const [fetchProjects, loading, projects] = useApiCall(getProjectsAPI);
  const [searchKey, setSearchKey] = useState<string>("");

  useEffect(() => {
    if (!userInfo) return;
    const payload = {
      organizationId: userInfo?.["custom:organizationId"],
      userRecordId: userInfo?.["sub"],
    };
    fetchProjects(payload);
  }, [userInfo]);

  const handleEditSelect = (e: any, project: any) => {
    e.stopPropagation();
    dispatch({
      type: SET_PROJECT_INFO,
      payload: project,
    });
    navigate(`/project?tab=project&id=${project?.ncProjectId}&isEdit=true`);
  };

  const handleViewSelect = (e: any, project: any) => {
    e.stopPropagation();
    dispatch({
      type: SET_PROJECT_INFO,
      payload: project,
    });
    navigate(`/project?tab=project&id=${project?.ncProjectId}`);
  };

  const handleSelect = (project: any) => {
    dispatch({
      type: SET_PROJECT_INFO,
      payload: project,
    });
    navigate("/project?tab=feature", { state: { load: true } });
  };

  return (
    <DefaultLayout>
      <div className="flex flex-row justify-between items-center mt-2 mx-4 my-4">
        <span className="font-semibold text-xl">Projects</span>
        <div className="flex flex-row items-center">
          <SearchInput
            value=""
            onChange={(e: any) => setSearchKey(e?.target?.value)}
            placeholder="Search Project"
            className="mr-2"
          />
          <Button onClick={() => navigate("/project?isEdit=true")}>
            Add Project
          </Button>
        </div>
      </div>
      <div className="mx-4 my-4">
        {!!loading && <LoadingBar />}
        <Table>
          <Table.Thead>
            <Table.Tr>
              {PROJECT_INIT_COLUMNS?.map((column: any, idx: number) => (
                <Table.Th key={idx}>{column?.label}</Table.Th>
              ))}
              <Table.Th>Action</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
          {!!projects?.length &&
            projects?.map((project: any, idx: number) => {
              if (project?.projectName?.includes(searchKey)) {
                return (
                  <Table.Tr
                    className="cursor-pointer"
                    onClick={() => handleSelect(project)}
                  >
                    {PROJECT_INIT_COLUMNS?.map((column: any, idx: number) => (
                      <Table.Td key={idx}>
                        <StyledProjectValue
                          project={project}
                          valueKey={column?.id}
                        />
                      </Table.Td>
                    ))}
                    <Table.Td>
                      <div className="flex flex-row items-center space-x-4">
                        <Button
                          variant="ghost"
                          className="px-0 !py-0"
                          onClick={(e: any) => handleEditSelect(e, project)}
                        >
                          <FiEdit2 className="w-5 h-5 text-black" />
                        </Button>
                        <Button
                          variant="ghost"
                          className="px-0 !py-0"
                          onClick={(e: any) => handleViewSelect(e, project)}
                        >
                          <FiEye className="w-5 h-5 text-black" />
                        </Button>
                      </div>
                    </Table.Td>
                  </Table.Tr>
                );
              }
              return null;
            })
          }
          </Table.Tbody>
        </Table>
      </div>
    </DefaultLayout>
  );
};

export default ProjectList;
