import {
  lowerRegExp,
  numberRegExp,
  specialRegExp,
  upperRegExp,
} from "./regExp";
import * as Yup from "yup";

export const emailValidation = Yup.string()
  .required("Email is required")
  .email("The email is invalid");

export const passwordValidation = Yup.string()
  .required("Password is required")
  .min(8, "Password must be at least 8 characters long")
  .matches(numberRegExp, "Password requires at least 1 number")
  .matches(upperRegExp, "Password requires at least 1 uppercase letter")
  .matches(lowerRegExp, "Password requires at least 1 lowercase letter")
  .matches(specialRegExp, "Password requires at least 1 special character");

export const confirmPasswordValidation = Yup.string()
  .required("Password is required")
  .min(8, "Password must be at least 8 characters long")
  .oneOf([Yup.ref("password"), null], "Passwords must match")
