import { useEffect, useState } from "react";
import { Switch as ReactSwitch } from "@headlessui/react";

interface ISwitch {
  label?: string;
  enabled?: boolean;
  onChange?: any;
}
const Switch: React.FC<ISwitch> = ({ label, enabled, onChange }) => {
  const [innerEnabled, setInnerEnabled] = useState(enabled);

  useEffect(() => {
    if (innerEnabled !== enabled) setInnerEnabled(enabled);
  }, [enabled]);

  const handleChange = (flag: boolean) => {
    onChange(flag);
    setInnerEnabled(flag);
  };

  return (
    <ReactSwitch.Group>
      <div className="flex items-center">
        <ReactSwitch
          checked={innerEnabled}
          onChange={handleChange}
          className={`${
            enabled ? "bg-surface-default" : "bg-gray-200"
          } relative inline-flex h-5 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
        >
          <span
            className={`${
              enabled ? "translate-x-6" : "translate-x-1"
            } inline-block h-3.5 w-3.5 transform rounded-full bg-white transition-transform`}
          />
        </ReactSwitch>
        {!!label?.length && (
          <ReactSwitch.Label className="ml-4 text-gray-344 text-sm font-semibold">
            {label}
          </ReactSwitch.Label>
        )}
      </div>
    </ReactSwitch.Group>
  );
};

export default Switch;
