import axios from "axios";
import { BASE_URL, loginInfo } from "config";
import { API } from "aws-amplify";

const API_NAME = process.env.REACT_APP_API_NAME || "";

export const getMachiningStrategy = async (data: any) => {
  const res = await API.post(API_NAME, `/strategy`, { body: data });
  return res;
};

export const getMachineListAPI = async (organizationId: string) => {
  const res = await API.post(API_NAME, `/list-machines/${organizationId}`, {});
  return res;
};

export const getCuttingToolLibraryAPI = async (organizationId: string) => {
  const res = await API.post(
    API_NAME,
    `/list-tool-libraries/${organizationId}`,
    { body: {} }
  );
  return res;
};

export const getCuttingToolAPI = async (data: any) => {
  const res = await API.post(API_NAME, `/tool`, { body: data });
  return res;
};

export const getParametersAPI = async (data: any) => {
  const res = await API.post(API_NAME, `/parameters`, { body: data });
  return res;
  // const res = await axios.post(`${BASE_URL}/parameters`, data, {
  //   headers: getHeader(),
  // });
  // return res.data;
};

// export const getGraphDataAPI = async (data: any) => {
//   const res = await API.post(API_NAME, `/non_optimal_points`, { body: data });
//   return res;
// };

export const postCreateUserSelectionAPI = async (data: any) => {
  const res = await API.post(API_NAME, `/create-user-selections`, {
    body: data,
  });
  return res;
};

// const res = await API.put(
//   API_NAME,
//   `/nc-project-update/${organizationId}/${ncProjectId}`,
//   { body: data }
// );
// return res;

export const putUserSelectionAPI = async (data: any) => {
  const { selectionId, organizationId }: any = { ...data };
  // delete data.organizationId;
  delete data.selectionId;

  const res = await API.put(API_NAME, `/update-user-selection/${organizationId}/${selectionId}`, {
    body: data,
  });
  return res;
};

export const getUserSelectionAPI = async (payload: any) => {
  const res = await API.get(
    API_NAME,
    `/get-user-selection/${payload?.organizationId}/${payload?.selectionId}`,
    {}
  );
  return res;
};

export const postProjectAPI = async (data: any) => {
  const res = await API.post(API_NAME, "/nc-project-create", { body: data });
  return res;
};

export const getProjectsAPI = async ({ organizationId, userRecordId }: any) => {
  const res = await API.get(
    API_NAME,
    `/nc-projects-list/${organizationId}/${userRecordId}`,
    {}
  );
  return res;
};

export const getProjectAPI = async ({ userRecordId, ncProjectId }: any) => {
  const res = await API.get(
    API_NAME,
    `/nc-project-get/${userRecordId}/${ncProjectId}`,
    {}
  );
  return res;
};

export const putProjectAPI = async (data: any) => {
  const { ncProjectId, organizationId }: any = { ...data };
  // delete data.organizationId;
  delete data.ncProjectId;

  const res = await API.put(
    API_NAME,
    `/nc-project-update/${organizationId}/${ncProjectId}`,
    { body: data }
  );
  return res;
};

export const deleteProjectAPI = async (data: any) => {
  const res = await API.put(
    API_NAME,
    `/nc-project-delete`,
    {body: data}
  );
  return res;
};

export const getUserAPI = async ({ userRecordId, organizationId }: any) => {
  const res = await API.get(
    API_NAME,
    `/user/${organizationId}/${userRecordId}`,
    {}
  );
  return res;
};

export const updateUserAPI = async (data: any) => {
  const { userRecordId, organizationId }: any = { ...data };
  delete data.userRecordId;
  delete data.organizationId;
  const res = await API.put(
    API_NAME,
    `/update-user/${organizationId}/${userRecordId}`,
    { body: data }
  );
  return res;
};
