import Button from "components/Button";
import SummaryExport from "components/SummaryExport";
import { keyList, SET_LOADING_BAR } from "constant";
import { EXPORT_TABLE, EXPORT_TABLE_KEYS } from "constant/exportTable";
import { GlobalContext } from "context/GlobalContext";
import { useContext, useEffect, useRef, useState } from "react";
import { FiInfo } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import AWS from "aws-sdk";
import { S3_CONFIG } from "config";
import ShareURLModal from "./ShareURLModal";
import { Storage } from "aws-amplify";
import { useLocalFusionApiCall } from "hooks/useLocalFusionAPI";
import { makeLabels } from "constant";
import DefaultLayout from "Layouts/DefaultLayout";
import { compareFuncForObjectsWithPassNumber, convertKeyToLabel } from "utils";

const TableToExcel = require("@stanlystark/table-to-excel");

const s3 = new AWS.S3({
  accessKeyId: S3_CONFIG.S3_ID,
  secretAccessKey: S3_CONFIG.S3_SECRET,
});

const Summary = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { projectInfo, visiblefeature_ids, machiningSetups, setupNumber } =
    state;
  const navigate = useNavigate();
  const location = useLocation();
  // Use the hook only when callApi prop is true
  const [fetchFunsionData, fushionloading, fushionData, fushionError] =
    useLocalFusionApiCall();
  const [tableData, setTableData] = useState([]);
  const tableRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState<any>(null);
  const [availableList, setAvailableList] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState<any[]>([]);

  useEffect(() => {
    const setupIdx = machiningSetups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    if (setupIdx < 0 || !visiblefeature_ids?.[setupNumber]?.length) return;

    let arr: any = [];
    keyList?.forEach(({ title, type }) => {
      const existing_features = machiningSetups?.[setupIdx]?.features?.filter(
        (feature: any) =>
          feature?.featureInformation?.feature_data?.feature_type === type
      );
      if (!existing_features?.length) return;
      let detailData: any[] = [];
      existing_features?.forEach((feature: any, idx1: number) => {
        const subType = feature?.featureInformation?.feature_data?.feature_name;
        let subData: any[] = [];
        const labels = makeLabels(
          type,
          { ...feature?.featureInformation?.feature_data?.feature_info },
          subType
        );
        labels?.forEach((summary: any, idx2: number) => {
          subData.push({
            key: summary?.label,
            value:
              feature?.featureInformation?.feature_data?.feature_info?.[
                summary?.key
              ],
          });
        });
        if (
          !visiblefeature_ids?.[setupNumber]?.includes(feature?.feature_id) ||
          !feature?.featureInformation?.strategies?.find((strategy: any) => {
            return !!strategy?.machining_strategy?.find(
              (mach: any) =>
                !!mach?.isSelected &&
                !!mach?.passes?.find((pass: any) =>
                  pass?.operations?.find(
                    (operation: any) => !!operation?.["selectedToolId"]
                  )
                )
            );
          })
        )
          return;

        detailData.push({
          title: `${title} ${idx1 + 1}`,
          index: idx1,
          data: subData,
          feature_id: feature?.feature_id,
          featureInformation: feature?.featureInformation,
        });
      });
      arr.push({
        title,
        type,
        data: detailData,
      });
    });
    setAvailableList(arr);
  }, [machiningSetups, visiblefeature_ids, setupNumber]);

  useEffect(() => {
    if (!availableList) return;
    let totalData: any = [];
    EXPORT_TABLE?.filter(({ key }: any) =>
      availableList?.find(
        (list: any) => list?.type === key && !!list?.data?.length
      )
    )?.forEach(({ subTypes, key, label }: any) => {
      // state[key]
      // Sub Type : 'open_hole', 'finish_hole'
      subTypes?.forEach(({ subKey, subLabel, operations }: any) => {
        const typeList: any = availableList?.find(
          (list: any) => list?.type === key
        );
        const subData = typeList?.data?.filter(
          (list1: any) =>
            list1?.featureInformation?.feature_data?.feature_name === subKey
        );
        if (!subData?.length) return;
        subData?.forEach((subDataItem: any, idx1: number) => {
          let idx2 = 0;
          operations?.forEach(({ operationLabel, operationKey }: any) => {
            const selected_strategy =
              subDataItem?.featureInformation?.strategies?.[0]?.machining_strategy?.find(
                (strategy: any) => strategy?.isSelected
              );
            const operations = selected_strategy?.passes?.find(
              (pass: any) => pass?.pass === operationLabel
            );
            operations?.operations?.forEach((operation: any) => {
              idx2++;
              const parameter = operation?.parameters?.find(
                (param: any) =>
                  param?.["Tool ID"] ===
                  operation?.tools?.[
                    operation?.selectedTool
                  ]?.["Tool ID"]
              );
  
              const operationData = {
                ...parameter,
                ...operation?.tools?.[
                  operation?.selectedTool
                ],
              };
              if (!operationData || !Object.keys(operationData)?.length) return;
              let subOpData: any = [];
              EXPORT_TABLE_KEYS?.forEach(({ key, bgColor, type }: any) => {
                subOpData.push({ value: operationData?.[key], bgColor, type });
              });
              totalData.push({
                label: label,
                sub_label: subLabel,
                number_label: idx1 + 1,
                operation_label: operationLabel,
                operation_step: idx2,
                data: subOpData,
                pass_number: operation.pass_number,
              });
            })
          });
        });
      });
    });
    totalData.sort(compareFuncForObjectsWithPassNumber);
    setTableData(totalData);
    extractDataToDisplay();
  }, [availableList]);

  const handleSave = async () => {
    try {
      dispatch({
        type: SET_LOADING_BAR,
        payload: true,
      });
      const wb = TableToExcel.default.tableToBook(tableRef?.current, {
        name: "nc-automation.xlsx",
        autoStyle: false,
        sheet: {
          name: "Output",
        },
      });
      const buffer = await wb.xlsx.writeBuffer();
      const fileName = new Date().getTime();
      const stored = await Storage.put(
        `nc-automation-${fileName}.xlsx`,
        new Blob([buffer], { type: "application/octet-stream" }),
        {
          contentEncoding: "base64",
          contentType: "application/octet-stream",
          level: "protected",
        }
      );
      setIsOpen(true);
      const url = await Storage.get(stored?.key, { level: "protected" });
      setModalData({ url });
      dispatch({
        type: SET_LOADING_BAR,
        payload: false,
      });
    } catch (err) {
      dispatch({
        type: SET_LOADING_BAR,
        payload: false,
      });
    }
  };

  const handleExit = (flag: boolean) => {
    setIsOpen(flag);
    navigate("/projects");
  };

  if (!availableList?.length) {
    navigate("/projects");
  }

  // TODO reduce cognitive complexity of this fucntion
  function extractDataToDisplay() {
    const rowsArray: any[] = [];
    availableList?.forEach(({ data }: any) => {
      data?.forEach((featureData: any) => {
        const item =
          featureData?.featureInformation?.strategies?.[0]?.machining_strategy?.find(
            (strategy: any) => strategy?.isSelected
          );
        item?.passes
          ?.filter((pass: any) =>
            pass?.operations?.find(
              (operation: any) => !!operation?.selectedToolId
            )
          )
          ?.forEach((pass: any) => {
            pass?.operations?.forEach((operation: any) => {
              if (!operation?.parameters?.[operation?.selectedTool]) return;
              rowsArray.push({
                feature_data: featureData.data,
                feature_title: featureData.title,
                pass: pass.pass,
                ...operation,
              });
            });
          });
      });
    });
    rowsArray.sort(compareFuncForObjectsWithPassNumber);
    console.log(rowsArray)
    setDataToDisplay(rowsArray);
  }

  return (
    <DefaultLayout>
      <div className="flex flex-col h-full overflow-hidden">
        <div className="flex flex-row justify-between items-center mt-4 mx-4 my-4">
          <span className="font-semibold text-xl">Summary</span>
          {/* TODO: changing setups currently is not implemented */}
          {/* <div className="flex flex-row items-center">
            <span className="font-semibold text-sm mr-2">Setups</span>
            <Select
              options={SETUP_NAME}
              className="w-8/12 ml-2 setupdropdown"
              value="S1"
            />
          </div> */}
        </div>
        <div className="flex  mt-8 h-full overflow-auto mb-8 mx-4">
          <div className="inline-flex flex-col cgap-4 w-full">
            {dataToDisplay?.map((item: any, idx1: number) => {
              return (
                <div className="flex cgap-4 w-full" key={idx1}>
                  <div className="cgap-4 inline-flex flex-col items-center mr-3">
                    <span className="rounded-full bg-surface-default w-10 text-xl font-semibold text-white h-10 inline-flex items-center justify-center">
                      {idx1 + 1}
                    </span>
                    {idx1 + 1 < dataToDisplay.length && (
                      <div className="w-1 h-100 bg-surface-default rounded"></div>
                    )}
                  </div>
                  <div className="flex flex-row w-full">
                    <div className="orderlistcontent w-full border border-gray-200 rounded-xl">
                      <div className="flex flex-row justify-between w-full items-center border-b border-gray-200 p-3">
                        <div className="inline-flex flex-col w-full">
                          <span className="font-semibold text-lg">
                            {item.feature_title}{" "}
                          </span>
                          <div className="inline-flex flex-row flex-wrap cgap-4">
                            {item.feature_data.map(
                              ({ key, value }: any, idx: number) => {
                                // TODO handle island values req -> Figma design
                                if (key !== "Islands") {
                                  return (
                                    <span
                                      key={idx}
                                      className="inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-600"
                                    >
                                      {key}
                                      <strong className="ml-2 text-gray-700">
                                        {value}
                                      </strong>
                                    </span>
                                  );
                                } else {
                                  return (
                                    <>
                                      {value["islands"]?.length > 1 && (
                                        <span
                                          key={idx}
                                          className="inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-600"
                                        >
                                          {"Minimum distance between islands"}
                                          <strong className="ml-2 text-gray-700">
                                            {value.min_distance_between_islands}
                                          </strong>
                                        </span>
                                      )}
                                      {value["islands"]?.map?.(
                                        (islandData: any, idx2: number) => (
                                          <div
                                            key={`${idx1}-${idx2}`}
                                            className="flex flex-wrap w-full border border-gray-200 rounded-lg px-[4px] pt-[12px] pb-[4px] gap-[2px] items-start mt-[16px] relative"
                                          >
                                            <div className="absolute flex-inline px-[6px] py-[2px] bg-white border border-gray-200 rounded-md top-[-12px] text-[10px] font-semibold text-gray-600">
                                              Island {idx2 + 1}
                                            </div>
                                            {Object.entries(islandData).map(
                                              (
                                                [key, value]: any,
                                                idx3: number
                                              ) => (
                                                <div
                                                  key={`${idx1}-${idx2}-${idx3}`}
                                                  className="text-[10px] text-gray-600 font-semibold flex-inline items-center bg-gray-100 py-[2px] px-[4px] rounded-md"
                                                >
                                                  {convertKeyToLabel(key)}:-{" "}
                                                  <span className="text-gray-700 font-bold flex-inline ml-[8px]">
                                                    {value}
                                                  </span>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        )
                                      )}
                                    </>
                                  );
                                }
                              }
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col p-3">
                        <div className="flex flex-row mb-3 border-b pb-2 border-gray-200">
                          <label className="font-semibold text-sm mb-1">
                            {item.pass}
                          </label>
                          <div className="inline-flex flex-row flex-wrap cgap-4 ml-auto">
                            <div className="inline-flex items-center rounded-md bg-surface-third px-2 py-1 text-xs font-medium text-gray-600">
                              Operation:
                              <strong className="ml-2 text-surface-default">
                                {item?.operation}
                              </strong>
                            </div>
                            <div className="inline-flex items-center rounded-md bg-surface-third px-2 py-1 text-xs font-medium text-gray-600">
                              Tool Path:
                              <strong className="ml-2 text-surface-default">
                                {item?.tool_paths?.[0]?.tool_path}
                              </strong>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row flex-wrap cgap-4">
                          {Object.keys(
                            item.parameters?.[item.selectedTool]
                          )?.map((paramKey: any, paramIdx: number) => {
                            return (
                              paramKey !== "valid_data" &&
                              paramKey !== "non_optimal_points" && (
                                <div
                                  key={paramIdx}
                                  className="inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-600"
                                >
                                  {paramKey}:
                                  <strong className="ml-2 text-gray-700">
                                    {item.parameters?.[item.selectedTool]?.[
                                      paramKey
                                    ]?.toString() ?? "N/A"}
                                  </strong>
                                </div>
                              )
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="hidden">
              <SummaryExport
                ref={tableRef}
                data={tableData}
                projectInfo={projectInfo}
              />
            </div>
          </div>
        </div>
        <ShareURLModal
          isOpen={isOpen}
          setIsOpen={handleExit}
          data={modalData}
        />
      </div>
      <div className="bg-white border-t justify-end mt-auto border-gray-200 px-4 py-3 flex ">
        <div className="flex items-center mr-auto">
          <FiInfo className="h-5 w-5 mr-2 text-gray-302" />
          <span className="text-sm text-gray-302 font-medium">
            Results will be exported to a xlsx file saved locally.
          </span>
        </div>
        <div className="flex items-center">
          <Button
            size="md"
            className="rounded-full py-[6px] w-[160px] bg-gray-f2f text-black-222 mr-6"
            onClick={() => {
              if (location.state?.referrer === "default-recommendation") {
                navigate(-2);
              }
              navigate(-1);
            }}
          >
            Back
          </Button>
          <Button
            size="md"
            className="rounded-full py-[6px] w-[160px]"
            onClick={() => handleSave()}
          >
            Save & Exit
          </Button>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Summary;
