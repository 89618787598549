import { useContext, useEffect, useRef, useState } from "react";
import Button from "components/Button";
import Dropzone from "components/Dropzone";
import Input from "components/Input";
import Select from "components/Select";
import {
  MACHINE_NAME_LIST,
  MATERIAL_LIST,
  NUMBER_OF_AXES_LIST,
  ORIENTATION_LIST,
  SET_LOADING_BAR,
  SUB_MATERIAL_LIST,
  UNIT_LIST,
} from "constant";
import { GlobalContext } from "context/GlobalContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useApiCall } from "hooks";
import {
  deleteProjectAPI,
  getCuttingToolLibraryAPI,
  getMachineListAPI,
  getProjectAPI,
  postProjectAPI,
  putProjectAPI,
} from "services";
import queryString from "query-string";
import clsx from "clsx";
import CuttingToolModal from "components/CuttingToolModal";
import { v4 as uuid } from "uuid";
import { FiDownload } from "react-icons/fi";
import LibraryExport from "./LibraryExport";
import tableData from "./template.json";
const TableToExcel = require("@stanlystark/table-to-excel");

interface IProjectItem {
  label?: string;
  value?: string | number;
  className?: string;
}

const validationSchema = Yup.object().shape({
  projectName: Yup.string().required("This field is required"),
  machineName: Yup.string().required("This field is required"),
  material: Yup.string().required("This field is required"),
  orientation: Yup.string().required("This field is required"),
  numberOfAxis: Yup.number().required("This field is required"),
  unit: Yup.string().required("This field is required"),
  rpm: Yup.string()
    .required("This field is required")
    .matches(/^[1-9]\d*$/, "RPM must be a valid Positive Integer")
    .test("Max-value-test", "Max. value limit 40,000", function (value) {
      return Number(value) <= 40000;
    }),
});

const INIT_PROJECT = {
  projectName: "",
  machineName: "",
  material: "",
  subMaterial: "",
  orientation: "",
  numberOfAxis: "",
  unit: "",
  rpm: "",
  toolLibraryId: [],
  cadFilePath: "",
  userSelectionId: ""
};

const ProjectItem: React.FC<IProjectItem> = ({ label, value, className }) => {
  return (
    <div className={clsx("flex flex-col", className)}>
      <p className="text-gray-696 font-medium text-sm mb-1.5">{label}</p>
      <p className="font-semibold">{value}</p>
    </div>
  );
};

const CreateProject = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { userInfo } = state;
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [getProject] = useApiCall(getProjectAPI);
  const [postProject] = useApiCall(postProjectAPI);
  const [putProject] = useApiCall(putProjectAPI);
  const [getMachineList] = useApiCall(getMachineListAPI);
  const [getCuttingToolLibrary] = useApiCall(getCuttingToolLibraryAPI);
  const [deleteProject] = useApiCall(deleteProjectAPI);
  const location = useLocation();
  const params = queryString.parse(location.search);
  const isEdit = params?.isEdit === "true" ? true : false;
  const projectId = params?.id;
  const isAdd = !params?.id ? true : false;

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    values,
    errors,
    touched,
    isValid,
    dirty,
    setValues,
  } = useFormik({
    initialValues: INIT_PROJECT,
    validationSchema,
    onSubmit: async (values) => {
      try {
        dispatch({
          type: SET_LOADING_BAR,
          payload: true,
        });
        let newValue: any = {
          ...values,
          userRecordId: userInfo?.sub,
          organizationId: userInfo?.["custom:organizationId"],

          updated_timestamp: new Date().toString(),
        };
        if (isAdd) {
          const res = await postProject(newValue);
        } else {
          newValue = {
            ...newValue,
            ncProjectId: projectId,
          };
          const res = await putProject(newValue);
        }
        dispatch({
          type: SET_LOADING_BAR,
          payload: false,
        });
        navigate("/projects");
      } catch (err) {
        dispatch({
          type: SET_LOADING_BAR,
          payload: false,
        });
      }
    },
  });

  useEffect(() => {
    if (!projectId) return;
    dispatch({
      type: SET_LOADING_BAR,
      payload: true,
    });
    getProject({ userRecordId: userInfo?.sub, ncProjectId: projectId })
      .then((res: any) => {
        const data = res?.Items?.[0];
        let formValues: any = {};
        Object.keys(INIT_PROJECT).forEach((key: string) => {
          formValues = {
            ...formValues,
            [key]: data[key],
          };
        });
        setValues({ ...formValues });
      })
      .finally(() => {
        dispatch({
          type: SET_LOADING_BAR,
          payload: false,
        });
      });
  }, [projectId]);

  // useEffect(() => {
  //   if (!userInfo) return;
  //   // getMachineList(userInfo?.["custom:organizationId"]).then((res: any) => {
  //   //   console.log("list", res);
  //   // });
  //   // getCuttingToolLibrary(userInfo?.["custom:organizationId"]).then((res: any) => {
  //   //   console.log("CuttingToolLibrary", res);
  //   // });
  // }, [userInfo]);

  const handleEdit = () => {
    let filters: any = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    filters["isEdit"] = true;
    navigate(
      `${location.pathname}?${queryString.stringify(filters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const handleDelete = async () => {
    try {
      dispatch({
        type: SET_LOADING_BAR,
        payload: true,
      });
      const res = await deleteProject({
        organizationId: userInfo?.["custom:organizationId"],
        ncProjectId: projectId,
      });
      dispatch({
        type: SET_LOADING_BAR,
        payload: false,
      });
      navigate("/projects");
    } catch (err) {
      dispatch({
        type: SET_LOADING_BAR,
        payload: false,
      });
    }
  };

  const handleDownload = async () => {
    try {
      dispatch({
        type: SET_LOADING_BAR,
        payload: true,
      });
      const wb = TableToExcel.default.tableToBook(tableRef?.current, {
        name: "nc-automation.xlsx",
        autoStyle: false,
        sheet: {
          name: "Output",
        },
      });

      const fileName = new Date().getTime();
      const buffer = await wb.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: "application/octet-stream" });

      const url = window.URL.createObjectURL(blob);
      let a: any = document.createElement("a");
      document.body.appendChild(a);
      a["style"] = "display: none";
      a.href = url;
      a.download = `${fileName}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);

      dispatch({
        type: SET_LOADING_BAR,
        payload: false,
      });
    } catch (err) {
      dispatch({
        type: SET_LOADING_BAR,
        payload: false,
      });
    }
  };

  return (
    <div className="h-[calc(100vh-174px)] mt-6 pb-4 px-4 overflow-y-auto overflow-x-hidden pb-[60px]">
      <form>
        <div className="flex mb-4 mt-1">
          {isEdit ? (
            <Input
              placeholder="Project Name"
              className="w-6/12 mr-2"
              name="projectName"
              value={values?.projectName}
              onBlur={handleBlur}
              onChange={handleChange}
              invalid={touched.projectName && errors.projectName}
              helperText={touched.projectName && errors.projectName}
            />
          ) : (
            <ProjectItem
              label="Project Name"
              className="w-4/12 mr-2"
              value={values?.projectName}
            />
          )}
          {isEdit ? (
            <Select
              options={UNIT_LIST}
              placeholder="Preferred Units"
              className="w-6/12 ml-2"
              value={values?.unit}
              onChange={(val: any) => setFieldValue("unit", val)}
              onBlur={handleBlur}
              invalid={touched.unit && errors.unit}
              helperText={touched.unit && errors.unit}
            />
          ) : (
            <ProjectItem
              label="Preferred Units"
              value={values?.unit}
              className="w-4/12 mr-2"
            />
          )}
        </div>
        <div className="flex mb-4">
          {isEdit ? (
            <Select
              options={MACHINE_NAME_LIST}
              placeholder="Machine Name"
              className="w-4/12 mr-2"
              value={values?.machineName}
              onChange={(val: any) => setFieldValue("machineName", val)}
              onBlur={handleBlur}
              invalid={touched.machineName && errors.machineName}
              helperText={touched.machineName && errors.machineName}
            />
          ) : (
            <ProjectItem
              label="Machine Name"
              value={values?.machineName}
              className="w-4/12 mr-2"
            />
          )}
          {isEdit ? (
            <Input
              placeholder="RPM"
              className="w-4/12 mx-2"
              name="rpm"
              type="number"
              value={values?.rpm}
              onBlur={handleBlur}
              onChange={handleChange}
              invalid={touched.rpm && errors.rpm}
              helperText={touched.rpm && errors.rpm}
            />
          ) : (
            <ProjectItem
              label="RPM"
              value={values?.rpm}
              className="w-4/12 mx-2"
            />
          )}
          <div className="w-4/12">
            {isEdit ? (
              <Select
                options={NUMBER_OF_AXES_LIST}
                placeholder="Number Of Axes"
                className="ml-2"
                value={values?.numberOfAxis}
                onChange={(val: any) => setFieldValue("numberOfAxis", val)}
                onBlur={handleBlur}
                invalid={touched.numberOfAxis && errors.numberOfAxis}
                helperText={touched.numberOfAxis && errors.numberOfAxis}
              />
            ) : (
              <ProjectItem
                label="Number Of Axes"
                value={values?.numberOfAxis}
                className="ml-2"
              />
            )}
          </div>
        </div>
        <div className="flex mb-4">
          {isEdit ? (
            <Select
              options={MATERIAL_LIST}
              placeholder="Material"
              className="w-4/12 mr-2"
              value={values?.material}
              onChange={(val: any) => setFieldValue("material", val)}
              onBlur={handleBlur}
              invalid={touched.material && errors.material}
              helperText={touched.material && errors.material}
            />
          ) : (
            <ProjectItem
              label="Material"
              value={values?.material}
              className="w-4/12 mr-2"
            />
          )}
          {isEdit ? (
            <Select
              options={SUB_MATERIAL_LIST?.[values?.material] || []}
              placeholder="Sub Material"
              className="w-4/12 mx-2"
              value={values?.subMaterial}
              onChange={(val: any) => setFieldValue("subMaterial", val)}
              onBlur={handleBlur}
              invalid={touched.subMaterial && errors.subMaterial}
              helperText={touched.subMaterial && errors.subMaterial}
            />
          ) : (
            <ProjectItem
              label="Sub Material"
              value={values?.subMaterial}
              className="w-4/12 mx-2"
            />
          )}
          <div className="w-4/12">
            {isEdit ? (
              <Select
                options={ORIENTATION_LIST}
                placeholder="Orientation"
                className="ml-2"
                value={values?.orientation}
                onChange={(val: any) => setFieldValue("orientation", val)}
                onBlur={handleBlur}
                invalid={touched.orientation && errors.orientation}
                helperText={touched.orientation && errors.orientation}
              />
            ) : (
              <ProjectItem
                label="Orientation"
                value={values?.orientation}
                className="ml-2"
              />
            )}
          </div>
        </div>

        <div className="mb-4">
          <p className="mb-2 font-semibold text-sm">CAD file</p>
          <Dropzone />
        </div>

        <div>
          <div className="mb-2 flex flex-row items-center justify-between w-full">
            <span className="font-semibold text-sm">Cutting Tools Library</span>
            <button
              className="text-surface-default text-sm font-medium flex flex-row items-center"
              onClick={handleDownload}
            >
              <FiDownload className="mr-2" /> Download Tool Library template
            </button>
          </div>
          <Dropzone mode="tool" />

          <div className="hidden">
            <LibraryExport data={tableData.data} ref={tableRef} />
          </div>
        </div>
        <div></div>
        <div className="flex justify-between absolute bottom-0 right-0 left-0 pt-4 border-t border-solid border-gray-e7e px-4 bg-white">
          {isEdit ? (
            <>
              <Button className="px-8" variant="danger" onClick={handleDelete}>
                Delete
              </Button>
              <div className="flex flex-row items-center space-x-2">
                <Button
                  className="px-8"
                  variant="ghost"
                  onClick={() => navigate("/projects")}
                >
                  Back
                </Button>
                <Button
                  className="px-8"
                  onClick={() => handleSubmit()}
                  disabled={!isValid || !dirty}
                >
                  {isAdd ? "Create" : "Update"}
                </Button>
              </div>
            </>
          ) : (
            <>
              <Button className="px-8" variant="danger" onClick={handleDelete}>
                Delete
              </Button>
              <div className="flex flex-row items-center space-x-2">
                <Button
                  className="px-8"
                  variant="ghost"
                  onClick={() => navigate("/projects")}
                >
                  Back
                </Button>
                <Button className="px-8" onClick={handleEdit}>
                  Edit
                </Button>
              </div>
            </>
          )}
        </div>
      </form>
      <CuttingToolModal />
    </div>
  );
};

export default CreateProject;
