export const SET_TOKEN = "SET_TOKEN";
export const SET_PROJECT_INFO = "SET_PROJECT_INFO";
// export const SET_STRATEGY_DATA = "SET_STRATEGY_DATA";

export const SET_HOLE_LIST = "SET_HOLE_LIST";
export const SET_THREAD_HOLE_LIST = "SET_THREAD_HOLE_LIST";
export const SET_FACE_LIST = "SET_FACE_LIST";
export const SET_POCKET_LIST = "SET_POCKET_LIST";
export const SET_CONTOUR_LIST = "SET_CONTOUR_LIST";
export const SET_CORE_CAVITY_LIST = "SET_CORE_CAVITY_LIST";
export const SET_PENCIL_LIST = "SET_PENCIL_LIST";

export const SET_HOLE_DATA = "SET_HOLE_DATA";
export const SET_THREAD_HOLE_DATA = "SET_THREAD_HOLE_DATA";
export const SET_FACE_DATA = "SET_FACE_DATA";
export const SET_POCKET_DATA = "SET_POCKET_DATA";
export const SET_CONTOUR_DATA = "SET_CONTOUR_DATA";
export const SET_CORE_CAVITY_DATA = "SET_CORE_CAVITY_DATA";
export const SET_PENCIL_DATA = "SET_PENCIL_DATA";

export const SET_LOADING_BAR = "SET_LOADING_BAR";

export const ADD_PROJECT = "ADD_PROJECT";

export const SET_REGISTER_FORM = "SET_REGISTER_FORM";
export const SET_FORGOT_FORM = "SET_FORGOT_FORM";

export const SET_USER_INFO = "SET_USER_INFO";

export const SET_TOOLTIP = "SET_TOOLTIP";
export const SET_CUTTING_TOOL = "SET_CUTTING_TOOL";

export const SET_PROFILE_IMG = "SET_PROFILE_IMG";

export const SET_SETUP_NUMBER = "SET_SETUP_NUMBER";
export const SET_LOAD_DATA = "SET_LOAD_DATA";

export const SET_MACHINE_SETUP = "SET_MACHINE_SETUP";
export const SET_ORIGIN_MACHINE_SETUP = "SET_ORIGIN_MACHINE_SETUP";
export const SET_VISIBLE_IDS = "SET_VISIBLE_IDS";
export const SET_STREAM_STEP = "SET_STREAM_STEP";

// export const SET_SELECTED_FEATURE = "SET_SELECTED_FEATURE";
