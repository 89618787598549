import React, { useEffect, useState } from "react";
import clsx from "clsx";
import uniqid from "uniqid";
import DragIcon from "assets/images/icons/drag-icon.svg";
import "./style.css";
import VerifyIcon from "assets/images/icons/verifyIcon";
import TrashIcon from "assets/images/icons/trash-icon.svg";
import { FiCheckCircle } from "react-icons/fi";

export interface IAccordionProps {
  type?: string;
  checked?: boolean;
  onChange?: (e: any) => void;
  tabLabelChild: React.ReactNode;
  labelChildClass?: string;
  verify?: boolean;
  children: React.ReactNode;
  index?: number;
  className?: string;
  removeHole?: any;
  dragIcon?: boolean;
  labelClass?: string;
  subLabel?: React.ReactNode;
  isBackground?: boolean;
  count?: number;
  tabContentClassName?:string;
}

const AccordionItem: React.FC<IAccordionProps> = ({
  type = "checkbox",
  checked,
  dragIcon = true,
  onChange,
  tabLabelChild,
  labelChildClass,
  verify = false,
  children,
  index,
  className,
  removeHole,
  labelClass,
  subLabel,
  isBackground,
  count = -1,
  tabContentClassName
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleChange = (e: any) => {
    if (onChange) onChange(e.target.checked);
    setIsChecked(e.target.checked);
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const _uniqid = uniqid();

  return (
    // overflow-hidden
    <div
      className={clsx(
        "w-full border border-solid border-gray-ede rounded p-1 bg-white",
        className,
        {
          "!bg-surface-third": checked && !!isBackground,
          "!border-gray-eac": !checked
        }
      )}
    >
      <input
        type={type}
        id={_uniqid}
        name={_uniqid}
        className="popup-accordion-input absolute opacity-0 -z-1"
        checked={isChecked}
        onChange={handleChange}
      />
      <label
        className={clsx(
          "tab-label flex justify-between bg-white cursor-pointer relative",
          labelClass,
          {
            "!border-b-0": !isChecked,
            "!bg-surface-third": checked && !!isBackground,
          }
        )}
        htmlFor={_uniqid}
      >
        <div
          className={clsx("flex items-center justify-between w-full mr-10", {
            "mb-1": isChecked,
            "bg-surface-third": isChecked && !!isBackground,
          })}
        >
          <div className="flex items-center w-full">
            {dragIcon && <img src={DragIcon} alt="drag icon" />}
            <div
              className={clsx("flex flex-col", {
                "!flex-row w-full justify-between items-center": count > 0,
              })}
            >
              <div
                className={clsx(
                  "font-semibold text-gray-737 text-sm flex flex-row items-center",
                  labelChildClass
                )}
              >
                {tabLabelChild}
                {count > 0 && (
                  <FiCheckCircle className="text-surface-default font-semibold ml-2" />
                )}
              </div>
              {count > 0 && (
                <div className={clsx("w-5 h-5 flex justify-center items-center text-xs font-semibold text-black rounded-full", {
                  "bg-white": isChecked,
                  "bg-surface-secondary": !isChecked
                })}>
                  {count}
                </div>
              )}
              {subLabel && <div className="ml-4">{subLabel}</div>}
            </div>
          </div>

          <div className="flex items-center">
            {verify && (
              <div className="flex items-center">
                <VerifyIcon verified={true} />
                <span className={`text-[10px] font-bold text-green-00d ml-1`}>
                  Auto-Identified & Verified
                </span>
              </div>
            )}
            {removeHole && (
              <button
                className="bg-transparent ml-2"
                onClick={() => removeHole(Number(index))}
              >
                <img src={TrashIcon} alt="trash icon" />
              </button>
            )}
          </div>
        </div>
      </label>
      {/* truncate */}
      <div
        className={clsx(
          "tab-content max-h-0 bg-white text-ellipsis whitespace-nowrap duration-150 overflow-auto",
          {
            "!bg-surface-third": isChecked && !!isBackground,
          },tabContentClassName
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default AccordionItem;
