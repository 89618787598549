import { useState, useContext, useEffect, useRef } from "react";
import AccordionItem from "components/AccordionItem";
import { Checkbox } from "components/Checkbox";
import Step from "components/Step";
import StepSlider from "components/StepSlider";
import SummaryBox from "components/SummaryBox";
import Toolbox from "components/Toolbox";
import {
  keyList,
  SET_STREAM_STEP,
  SET_LOADING_BAR,
  SET_MACHINE_SETUP,
  SET_ORIGIN_MACHINE_SETUP,
  SET_PROJECT_INFO,
  SUMMARY_VIEW,
} from "constant";
import { GlobalContext } from "context/GlobalContext";
import { useApiCall } from "hooks";
import {
  getCuttingToolAPI,
  getParametersAPI,
  postCreateUserSelectionAPI,
  putProjectAPI,
  putUserSelectionAPI,
} from "services";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";
import { getLowestValue, isObj } from "utils";
import ChangeStrategy from "./ChangeStrategy";
import clsx from "clsx";
import { FiArrowRight } from "react-icons/fi";
import ProjectItemList from "components/ProjectItemList";
import NoFoundImg from "assets/images/NoFound.svg";

const CuttingTool = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const {
    showTooltip,
    hideTooltip,
    projectInfo,
    setupNumber,
    machiningSetups,
    visiblefeature_ids,
    originMachiningSetups,
  } = state;
  const [stepData, setStepData] = useState<any>([]);
  const [selectedStep, setSelectedStep] = useState(stepData[0]);
  const navigate = useNavigate();

  const [putProject] = useApiCall(putProjectAPI);
  const [postCreateUserSelection]: any = useApiCall(postCreateUserSelectionAPI);
  const [putUserSelection]: any = useApiCall(putUserSelectionAPI);

  const [selectedCard, setSelectedCard] = useState(0);
  const [selectedItem, setSelectedItem] = useState<any>({
    type: null,
    index: null,
    key: null,
  });
  const [selectedTitle, setSelectedTitle] = useState<any>(null);
  // const [mrrValue, setMrrValue] = useState(100);
  const [boxData, setBoxData] = useState<any>(null);
  // const [fetchParameter, paramLoading, parameterData, paramError]: any =
  //   useApiCall(getUserSelectionAPI);
  const [getCuttingTool] = useApiCall(getCuttingToolAPI);
  const [toolData, setToolData] = useState<any>(null);
  const [getParameters] = useApiCall(getParametersAPI);
  const [parameterData, setParameterData] = useState<any>(null);

  const [machiningStrategySummaryData, setMachiningStrategySummaryData] =
    useState([]);
  const [showChangeCategory, setShowChangeCategory] = useState(false);

  const [availableList, setAvailableList] = useState([]);
  const [possibleList, setPossibleList] = useState([]);

  const [selectToolID, setSelectToolID] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFirst, setIsFirst] = useState(true);

  // const [duplicateFlag, setDuplicateFlag] = useState(false);

  useEffect(() => {
    const setupIdx = machiningSetups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    if (setupIdx < 0 || !visiblefeature_ids?.[setupNumber]?.length) return;

    let arr: any = [];
    keyList?.forEach(({ title, type }) => {
      const existing_features = machiningSetups?.[setupIdx]?.features?.filter(
        (feature: any) =>
          feature?.featureInformation?.feature_data?.feature_type === type
      );
      if (!existing_features?.length) return;
      let detailData: any[] = [];
      existing_features?.forEach((feature: any, idx1: number) => {
        const subType = feature?.featureInformation?.feature_data?.feature_name;
        let subData: any[] = [];
        SUMMARY_VIEW[type][subType]?.forEach((summary: any, idx2: number) => {
          subData.push({
            key: summary?.label,
            value:
              feature?.featureInformation?.feature_data?.feature_info?.[
                summary?.key
              ],
          });
        });
        if (
          !visiblefeature_ids?.[setupNumber]?.includes(feature?.feature_id) ||
          !feature?.featureInformation?.strategies?.find((strategy: any) => {
            return !!strategy?.machining_strategy?.find(
              (mach: any) => !!mach?.isSelected
            );
          })
        )
          return;
        detailData.push({
          title: `${title} ${idx1 + 1}`,
          index: idx1,
          data: subData,
          feature_id: feature?.feature_id,
          featureInformation: feature?.featureInformation,
        });
      });
      if(!detailData.length)return;
      arr.push({
        title,
        type,
        data: detailData,
      });
    });

    if (isFirst && !!arr?.length) {
      setSelectedItem({
        type: arr?.[0]?.type,
        feature_id: arr?.[0]?.data?.[0]?.feature_id,
      });
      setSelectedTitle(arr?.[0]?.data?.[0]?.title);
      setIsFirst(false);
    }

    setAvailableList(arr);

    let list =
      arr?.find((category: any) => {
        return !!category?.data?.find(({ featureInformation }: any) => {
          return !!featureInformation?.strategies?.find((strategy: any) => {
            return !!strategy?.machining_strategy?.find(
              (mach: any) =>
                !!mach?.isSelected &&
                !!mach?.passes?.find((pass: any) =>
                  pass?.operations?.find(
                    (operation: any) => !!operation?.["selectedToolId"]
                  )
                )
            );
          });
        });
      }) || [];
    setPossibleList(list?.data || []);
  }, [machiningSetups, visiblefeature_ids]);

  const getBoxData = (parameterData: any) => {
    const lowestCost = getLowestValue(
      parameterData || [],
      "Objective function value"
    );
    let newParameterData = [...parameterData];
    newParameterData?.forEach((item, idx) => {
      if (lowestCost === item["Objective function value"])
        newParameterData[idx] = { ...item, label: "Lowest cost" };
      else {
        const percent =
          ((item["Objective function value"] - lowestCost) / lowestCost) * 100;
        newParameterData[idx] = {
          ...item,
          label: `+${percent.toFixed(4)}% cost`,
        };
      }
    });
    return newParameterData;
  };

  useEffect(() => {
    if (!selectedItem?.feature_id) return;

    let feature_information;

    if (!selectedItem?.featureInformation) {
      const setupIdx = machiningSetups?.findIndex(
        (setup: any) => setup?.machiningSetupNumber === setupNumber
      );

      const feature_idx = machiningSetups?.[setupIdx]?.features?.findIndex(
        (feature: any) => feature?.feature_id === selectedItem?.feature_id
      );

      feature_information = {
        ...machiningSetups?.[setupIdx]?.features?.[feature_idx]
          ?.featureInformation,
      };
    } else {
      feature_information = selectedItem?.feature_information;
    }

    // const originaSetupIdx = originMachiningSetups?.findIndex(
    //   (setup: any) => setup?.machiningSetupNumber === setupNumber
    // );

    // const originfeature_idx = originMachiningSetups?.[
    //   originaSetupIdx
    // ]?.features?.findIndex(
    //   (feature: any) => feature?.feature_id === selectedItem?.feature_id
    // );

    // const origin_feature_infomation = {
    //   ...originMachiningSetups?.[originaSetupIdx]?.features?.[originfeature_idx]
    //     ?.featureInformation,
    // };

    // let flag = false;
    // if (
    //   JSON.stringify(origin_feature_infomation) ===
    //   JSON.stringify(feature_information)
    // ) {
    //   flag = true;
    //   setDuplicateFlag(true);
    // }

    const selected_strategy =
      feature_information?.strategies?.[0]?.machining_strategy?.find(
        (item: any) => item?.isSelected
      );

    const new_passes = [...selected_strategy?.passes];
    selected_strategy?.passes?.forEach((pass: any, idx1: number) => {
      pass?.operations?.forEach((operation: any, idx2: number) => {
        new_passes[idx1].operations[idx2] = {
          ...operation,
          tool_path: operation?.tool_paths[0]?.tool_path,
          tool_path_type: operation?.tool_paths[0]?.tool_path_type,
          tool_type: operation?.tool_paths[0]?.tool_type
            ? operation?.tool_paths[0]?.tool_type
            : operation?.tool_paths[0]?.tool_types[0],
        };
      });
    });
    let payload: any = [{ ...feature_information?.feature_data }];
    if (
      !!new_passes?.find(
        (pass: any) =>
          !!pass?.operations?.find((operation: any) => !!operation?.tools)
      )
    ) {
      setToolData({
        data: { passes: new_passes },
        feature_data: payload,
      });
      return;
    }
    payload[0]["machining_strategy"] = {
      passes: new_passes,
    };
    setStepData([]);
    setIsLoading(true);
    getCuttingTool(payload)
      .then((res: any) => {
        if (!res) return;
        res?.cutting_tools?.[0]?.tool_recommendation?.passes?.forEach(
          (pass: any) => {
            pass?.operations?.forEach((operation: any) => {
              operation?.tools?.forEach((tool: any) => {
                tool.isValid = true;
                tool.feedback = "";
              });
            });
          }
        );
        setToolData({
          data: res?.cutting_tools?.[0]?.tool_recommendation,
          feature_data: payload,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedItem]);

  // const loadData = () => {
  //   try {
  //     setBoxData(null);
  //     let selected_weightage = 1;
  //     if (mrrValue === 0) {
  //       selected_weightage = 5;
  //     } else if (mrrValue === 25) {
  //       selected_weightage = 4;
  //     } else if (mrrValue === 50) {
  //       selected_weightage = 3;
  //     } else if (mrrValue === 75) {
  //       selected_weightage = 2;
  //     } else if (mrrValue === 100) {
  //       selected_weightage = 1;
  //     }
  //     let payload: any = {
  //       organizationId: projectInfo?.organizationId,
  //       selectionId: projectInfo?.userSelectionId,
  //     };
  //     // fetchParameter(payload);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  useEffect(() => {
    setBoxData([]);
    if (!toolData) return;
    let steps: any = [];
    toolData?.data?.passes?.forEach((pass: any, idx: number) => {
      steps.push({
        index: idx + 1,
        label: pass?.pass,
        key: pass?.pass,
        isNull: false,
        operations: pass?.operations,
        subIndex: 0,
      });
    });

    setStepData(steps);
    if (!!steps?.length) {
      setSelectedStep(steps[0]);
    }
  }, [toolData]);

  useEffect(() => {
    setParameterData(null);
    setSelectToolID(null);
    setBoxData([]);
    if (!selectedStep) return;
    setSelectToolID(
      selectedStep.operations?.[selectedStep?.subIndex]?.["selectedToolId"]
    );
    if (
      selectedStep.operations?.[selectedStep?.subIndex]?.parameters && 
      selectedStep.operations?.[selectedStep?.subIndex]?.mrr_value >= 0
      // duplicateFlag &&
      // false // Explicitly marking the check as false as it is unstable
    ) {
      // FIXME: Need to re-develop the logic behind caching of tool parameters.
      setParameterData(
        selectedStep.operations?.[selectedStep?.subIndex]?.parameters
      );
      return;
    }
    if (!selectedStep.operations?.[selectedStep?.subIndex]?.tools?.length) {
      return;
    }
    
    const currentMrrValue = selectedStep.operations?.[selectedStep?.subIndex]?.mrr_value ?? 100;

    setIsLoading(true);
    let payloadTools = JSON.parse(
      JSON.stringify(selectedStep.operations?.[selectedStep?.subIndex].tools)
    );
    payloadTools?.forEach((tool: any) => {
      delete tool.isValid;
      delete tool.feedback;
    });
    const paramsRequestPayload = [
      {
        feature_type: toolData?.feature_data?.[0]?.feature_type,
        feature_name: toolData?.feature_data?.[0]?.feature_name,
        feature_id: toolData?.feature_data?.[0]?.feature_id,
        material_info: toolData?.feature_data?.[0]?.material_info,
        machine_info: toolData?.feature_data?.[0]?.machine_info,
        feature_info: toolData?.feature_data?.[0]?.feature_info,
        machining_strategy: {
          cutting_pass: selectedStep.key, // FIXME: Need to rebuild payload for params
          operation:
            selectedStep.operations?.[selectedStep?.subIndex]?.operation, // machining_strategy.passes
          tools: payloadTools,
          weightage: currentMrrValue / 25 + 1,
          tool_path:
            selectedStep.operations?.[selectedStep?.subIndex].tool_path,
          tool_path_type:
            selectedStep.operations?.[selectedStep?.subIndex].tool_path_type,
        },
      },
    ];
    getParameters(paramsRequestPayload)
      .then((res: any) => {
        if (!res) return;
        setParameterData(
          res?.cutting_tools_parameters?.[0]?.cutting_tools_parameters
        );

        let orderedTools: any = [];
        res?.cutting_tools_parameters?.[0]?.cutting_tools_parameters?.forEach(
          (tool: any) => {
            orderedTools.push(
              toolData?.data?.passes?.[selectedStep?.index - 1].operations[
                selectedStep?.subIndex
              ]["tools"]?.find(
                (toolData: any) => toolData["Tool ID"] === tool["Tool ID"]
              )
            );
          }
        );

        let updated_setups = [...machiningSetups];

        let feature_information;

        const setupIdx = updated_setups?.findIndex(
          (setup: any) => setup?.machiningSetupNumber === setupNumber
        );

        const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
          (feature: any) => feature?.feature_id === selectedItem?.feature_id
        );

        feature_information = {
          ...updated_setups?.[setupIdx]?.features?.[feature_idx]
            ?.featureInformation,
        };

        const selected_strategy_idx =
          feature_information?.strategies?.[0]?.machining_strategy?.findIndex(
            (item: any) => item?.isSelected
          );

        feature_information.strategies[0].machining_strategy[
          selected_strategy_idx
        ].passes[selectedStep?.index - 1].operations[selectedStep?.subIndex][
          "parameters"
        ] = res?.cutting_tools_parameters?.[0]?.cutting_tools_parameters || [];
        feature_information.strategies[0].machining_strategy[
          selected_strategy_idx
        ].passes[selectedStep?.index - 1].operations[selectedStep?.subIndex][
          "tools"
        ] = orderedTools || [];

        feature_information.strategies[0].machining_strategy[
          selected_strategy_idx
        ].passes[selectedStep?.index - 1].operations[selectedStep?.subIndex][
          "mrr_value"
        ] = currentMrrValue;
        
        feature_information.strategies[0].machining_strategy[
          selected_strategy_idx
        ].passes[selectedStep?.index - 1].operations[selectedStep?.subIndex][
          "parameters"
        ]?.forEach((paramData: any) => {
          paramData.valid_data = {}
        });

        feature_information.strategies[0].machining_strategy[
          selected_strategy_idx
        ].passes[selectedStep?.index - 1].operations[selectedStep?.subIndex][
          "tool_specification"
        ] = toolData?.data?.passes?.[selectedStep?.index - 1].operations[
          selectedStep?.subIndex
        ]["tool_specification"]

        selectedStep.operations[selectedStep.subIndex].tools = orderedTools;
        delete feature_information?.strategies?.[0]?.machining_strategy?.[
          selected_strategy_idx
        ]?.passes?.[selectedStep?.index - 1]?.operations?.[
          selectedStep?.subIndex
        ]?.selectedTool;
        delete feature_information?.strategies?.[0]?.machining_strategy[
          selected_strategy_idx
        ]?.passes?.[selectedStep?.index - 1]?.operations?.[
          selectedStep?.subIndex
        ]?.selectedToolId;
        delete selectedStep?.operations?.[selectedStep?.subIndex]?.selectedTool;
        delete selectedStep?.operations?.[selectedStep?.subIndex]
          ?.selectedToolId;

        setSelectToolID(null);

        updated_setups[setupIdx].features[feature_idx].featureInformation =
          feature_information;

        selectedStep.operations[selectedStep.subIndex].parameters = res?.cutting_tools_parameters?.[0]?.cutting_tools_parameters || [];
        selectedStep.operations[selectedStep.subIndex].mrr_value = currentMrrValue;
        setSelectedStep(selectedStep);
        dispatch({
          type: SET_MACHINE_SETUP,
          payload: updated_setups,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedStep]);

  useEffect(() => {
    if (!parameterData?.length) return;
    let newParameter: any = [];
    selectedStep.operations?.[selectedStep?.subIndex].tools?.forEach((tool: any, idx: number) => {
      const parameter = parameterData?.find(
        (param: any) => param?.["Tool ID"] === tool?.["Tool ID"]
      );
      newParameter.push({
        ...tool,
        ...parameter,
        "Tool material": tool?.["Body material code"],
      });
    });
    const boxData = getBoxData(newParameter);
    setBoxData(boxData);
  }, [parameterData]);

  useEffect(() => {
    if (isLoading) {
      dispatch({
        type: SET_LOADING_BAR,
        payload: true,
      });
    } else {
      dispatch({
        type: SET_LOADING_BAR,
        payload: false,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    if (!boxData?.length) return;
    if (selectedStep?.operations[selectedStep?.subIndex]?.selectedTool > -1) {
      handleSelectedTool(
        selectedStep?.operations[selectedStep?.subIndex]?.selectedTool,
        true
      );
    } else if (
      selectedStep?.operations[selectedStep?.subIndex]?.tools?.[0]?.isValid !== false
    ) {
      handleSelectedTool(0, true);
    }
  }, [boxData]);

  // const setItemData = (res: any) => {
  //   if (state[res?.key][res?.index]?.tool) {
  //     let steps: any = [];

  //     state[res?.key][res?.index]?.tool?.passes.forEach(
  //       (pass: any, idx: number) => {
  //         steps.push({
  //           index: idx + 1,
  //           label: pass?.pass,
  //           key: pass?.pass,
  //           isNull: false,
  //           operations: pass?.operations,
  //           subIndex: 0,
  //         });
  //       }
  //     );

  //     // Object.keys(state[res?.key][res?.index]?.selected_strategy).forEach(
  //     //   (key, idx) => {
  //     //     if (
  //     //       !state[res?.key][res?.index]?.selected_strategy?.[key]?.[
  //     //         "Operation"
  //     //       ]
  //     //     )
  //     //       return;
  //     //     const toolKey = `${state[res?.key][res?.index]?.selected_strategy?.[
  //     //       key
  //     //     ]?.["Operation"]
  //     //       ?.toString()
  //     //       .toLowerCase()}_tools`;
  //     //     if (
  //     //       !state[res?.key][res?.index]?.tool?.[toolKey] ||
  //     //       !state[res?.key][res?.index]?.tool?.[toolKey]?.length ||
  //     //       !state[res?.key][res?.index]?.tool?.[toolKey]?.[0]?.tools?.length
  //     //     ) {
  //     //       steps.push({
  //     //         index: idx + 1,
  //     //         label:
  //     //           state[res?.key][res?.index]?.selected_strategy?.[key]?.[
  //     //             "Operation"
  //     //           ],
  //     //         key: toolKey,
  //     //         isNull: true,
  //     //       });
  //     //       return;
  //     //     }
  //     //     steps.push({
  //     //       index: idx + 1,
  //     //       label:
  //     //         state[res?.key][res?.index]?.selected_strategy?.[key]?.[
  //     //           "Operation"
  //     //         ],
  //     //       key: toolKey,
  //     //       isNull: false,
  //     //     });
  //     //   }
  //     // );
  //     setStepData(steps);
  //     if (!!steps?.length) setSelectedStep(steps[0]);
  //   }
  // };

  // const handleSelectItem = (res: any) => {
  //   setSelectedItem(res);
  //   setItemData(res);
  // };

  const confirmInValidData = (tool: any) => {
    let feature_information;
    let updated_setups = [...machiningSetups];

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === selectedItem?.feature_id
    );

    feature_information = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]
        ?.featureInformation,
    };
    const selected_strategy_idx =
      feature_information?.strategies?.[0]?.machining_strategy?.findIndex(
        (item: any) => item?.isSelected
      );
    feature_information.strategies[0].machining_strategy[
      selected_strategy_idx
    ].passes[selectedStep?.index - 1].operations[selectedStep?.subIndex][
      "parameters"
    ]?.forEach((param: any) => {
      if (param["Tool ID"] === tool["Tool ID"]) {
        param.valid_data = { ...tool.valid_data };
      }
    });
    feature_information.strategies[0].machining_strategy[
      selected_strategy_idx
    ].passes[selectedStep?.index - 1].operations[selectedStep?.subIndex][
      "tools"
    ]?.forEach((machiningSetUpsTool: any) => {
      if (machiningSetUpsTool["Tool ID"] === tool["Tool ID"]) {
        machiningSetUpsTool.isValid = tool.isValid ?? true;
        machiningSetUpsTool.feedback = tool.feedback ?? "";
      }
    });
    if (selectToolID === tool["Tool ID"]) {
      if (
        tool.isValid === false ||
        tool.valid_data?.["Spindle speed (RPM)"]?.isInValid ||
        tool.valid_data?.["Optimal F (mm/min)"]?.isInValid ||
        tool.valid_data?.["Optimal Ae (mm)"]?.isInValid ||
        tool.valid_data?.["Optimal Ap (mm)"]?.isInValid
      ) {
        feature_information.strategies[0].machining_strategy[
          selected_strategy_idx
        ].passes[selectedStep?.index - 1].operations[selectedStep?.subIndex][
          "selectedToolId"
        ] = "";
        feature_information.strategies[0].machining_strategy[
          selected_strategy_idx
        ].passes[selectedStep?.index - 1].operations[selectedStep?.subIndex][
          "selectedTool"
        ] = -1;
        setSelectToolID(null);
      }
    }
    updated_setups[setupIdx].features[feature_idx].featureInformation =
      feature_information;
    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });
    setBoxData((boxData: any) => {
      boxData?.forEach((box: any) => {
        if (box["Tool ID"] === tool["Tool ID"]) {
          box.valid_data = tool.valid_data;
          box.feedback = tool.feedback ?? "";
          box.isValid = tool.isValid ?? true;
        }
      });
      return boxData;
    });
  };

  const handleRedirect = (tab: string) => {
    try {
      if (tab !== "summary") {
        navigate(`/project?tab=${tab}`, {
          ...(tab === "strategy-summary" && {
            state: { referrer: "tool" },
          }),
        });
        return;
      }
      dispatch({
        type: SET_LOADING_BAR,
        payload: true,
      });
      let payload: any = [];
      payload = {
        organizationId: projectInfo?.organizationId,
        streamStep: "initial-trigger",
        selectionId: projectInfo?.userSelectionId,
        ncProjectId: projectInfo?.ncProjectId,
        userRecordId: projectInfo?.userRecordId,
        machineId: "",
        machineType: "",
        workpieceCADfile: "",
        workpieceId: "",
        workpiecePathToCADfile: "",
        workpieceMaterial: projectInfo?.material,
        workpieceSubMaterial: projectInfo?.subMaterial,
        // workpieceSubMaterial: "high alloy steel",

        workpieceRawDimensions: {
          length: "",
          width: "",
          height: "",
        },
        machiningSetups,
      };

      if (projectInfo?.userSelectionId) {
        if (
          JSON.stringify(machiningSetups) ===
          JSON.stringify(originMachiningSetups)
        ) {
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_BAR,
              payload: false,
            });
            navigate(`/project?tab=${tab}`);
          }, 100);
        } else {
          putUserSelection({
            ...payload,
            selectionId: projectInfo?.userSelectionId,
          })
            .then(() => {
              dispatch({
                type: SET_ORIGIN_MACHINE_SETUP,
                payload: machiningSetups,
              });
            })
            .finally(() => {
              setTimeout(() => {
                dispatch({
                  type: SET_LOADING_BAR,
                  payload: false,
                });
                navigate(`/project?tab=${tab}`);
              }, 100);
            });
        }
      } else
        postCreateUserSelection(payload)
          .then(async (res: any) => {
            let payload1 = {
              organizationId: projectInfo?.organizationId,
              ncProjectId: projectInfo?.ncProjectId,
              userRecordId: projectInfo?.userRecordId,
              userSelectionId: res?.selectionId,
            };
            await putProject(payload1);
            dispatch({
              type: SET_PROJECT_INFO,
              payload: {
                ...projectInfo,
                userSelectionId: res?.selectionId,
              },
            });
            dispatch({
              type:SET_STREAM_STEP,
              payload:"intial-trigger"
            });
            dispatch({
              type: SET_ORIGIN_MACHINE_SETUP,
              payload: machiningSetups,
            });
          })
          .finally(() => {
            setTimeout(() => {
              dispatch({
                type: SET_LOADING_BAR,
                payload: false,
              });
              navigate(`/project?tab=${tab}`);
            }, 100);
          });
    } catch (err) {}
  };

  const handleSelectedTool = (index: number, flag: boolean) => {
    if (flag) {
      if (!selectedItem?.feature_id) return;

      let feature_information;
      let updated_setups = [...machiningSetups];

      const setupIdx = updated_setups?.findIndex(
        (setup: any) => setup?.machiningSetupNumber === setupNumber
      );

      const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
        (feature: any) => feature?.feature_id === selectedItem?.feature_id
      );

      if (!selectedItem?.featureInformation) {
        feature_information = {
          ...updated_setups?.[setupIdx]?.features?.[feature_idx]
            ?.featureInformation,
        };
      } else {
        feature_information = selectedItem?.feature_information;
      }

      const selected_strategy_idx =
        feature_information?.strategies?.[0]?.machining_strategy?.findIndex(
          (item: any) => item?.isSelected
        );
      if (selected_strategy_idx > -1) {
        feature_information.strategies[0].machining_strategy[
          selected_strategy_idx
        ].passes[selectedStep?.index - 1].operations[selectedStep?.subIndex][
          "selectedTool"
        ] = index;
        feature_information.strategies[0].machining_strategy[
          selected_strategy_idx
        ].passes[selectedStep?.index - 1].operations[selectedStep?.subIndex][
          "selectedToolId"
        ] =
          selectedStep?.operations[selectedStep?.subIndex]?.tools?.[index]?.[
            "Tool ID"
          ];
      }

      setSelectToolID(
        selectedStep?.operations[selectedStep?.subIndex]?.tools?.[index]?.[
          "Tool ID"
        ]
      );

      updated_setups[setupIdx].features[feature_idx].featureInformation =
        feature_information;

      dispatch({
        type: SET_MACHINE_SETUP,
        payload: updated_setups,
      });
    }
  };

  const handleStrategy = (selected_strategy: any) => {
    let toolList: any = [];
    const strategy = selected_strategy;
    if (isObj(strategy)) {
      Object.keys(strategy).forEach((key, idx) => {
        if (strategy[key]["Tool type"])
          toolList.push({
            key: strategy[key]?.["Operation"],
            value: strategy[key]["Tool type"],
          });
      });

      if (state[selectedItem?.key][selectedItem?.index]?.tool) {
        let steps: any = [];
        Object.keys(strategy).forEach((key, idx) => {
          if (
            !state[selectedItem?.key][selectedItem?.index]?.selected_strategy?.[
              key
            ]?.["Operation"]
          )
            return;
          const toolKey = `${state[selectedItem?.key][
            selectedItem?.index
          ]?.selected_strategy?.[key]?.["Operation"]
            ?.toString()
            .toLowerCase()}_tools`;
          if (
            !state[selectedItem?.key][selectedItem?.index]?.tool?.[toolKey] ||
            !state[selectedItem?.key][selectedItem?.index]?.tool?.[toolKey]
              ?.length ||
            !state[selectedItem?.key][selectedItem?.index]?.tool?.[toolKey]?.[0]
              ?.tools?.length
          ) {
            steps.push({
              index: idx + 1,
              label:
                state[selectedItem?.key][selectedItem?.index]
                  ?.selected_strategy?.[key]?.["Operation"],
              key: toolKey,
              isNull: true,
            });
            return;
          }
          steps.push({
            index: idx + 1,
            label:
              state[selectedItem?.key][selectedItem?.index]
                ?.selected_strategy?.[key]?.["Operation"],
            key: toolKey,
            isNull: false,
          });
        });
        setStepData(steps);
        if (!!steps?.length) setSelectedStep(steps[0]);
      }
    }
    setMachiningStrategySummaryData(toolList);
  };

  if (!availableList?.length) {
    navigate("/projects");
  }

  return (
    <div className="relative h-[calc(100vh-166px)] flex px-4">
      <div className="w-[25%] h-full">
        {/* <ProjectItemList /> */}
        <div className="w-full overflow-y-auto h-full flex flex-col justify-between bg-gray-f9f p-1 rounded-md border border-gray-eac">
          <div className="w-full">
            <p className="font-medium text-gray-475 text-[10px]">Features</p>
            <div className="pr-1">
              {availableList?.map(({ title, data, type }: any, idx: number) => {
                return (
                  <AccordionItem
                    key={idx}
                    tabLabelChild={`${idx + 1}. ${title}`}
                    className="border-2 border-surface-soft py-2 px-4 my-1"
                    labelChildClass="text-black-222"
                    tabContentClassName="!pb-0"
                    checked={selectedCard === idx}
                    dragIcon={false}
                    onChange={(e) => {
                      if(selectedCard !== idx){
                        setSelectedCard(idx);
                        setSelectedItem({
                          type,
                          feature_id: data?.[0]?.feature_id,
                        });
                        setSelectedTitle(data?.[0]?.title);
                      }
                    }}
                    count={
                      data?.filter(
                        (item: any) =>
                          !!item?.featureInformation?.strategies?.find(
                            (strategy: any) =>
                              !!strategy?.machining_strategy?.find(
                                (item3: any) => item3?.isSelected
                              )
                          )
                      )?.length || 0
                    }
                    isBackground
                  >
                    <div>
                      {data?.map((item: any, idx1: number) => {
                        return (
                          <div
                            className="mb-[10px] flex flex-row items-center cursor-pointer justify-between w-full"
                            onClick={() => {
                              if(selectedItem?.feature_id !== item?.feature_id){
                                setSelectedItem({
                                  type,
                                  feature_id: item?.feature_id,
                                  featureInfomation: item?.featureInformation,
                                });
                                setSelectedTitle(`${item?.title}`);
                              }
                            }}
                          >
                            <div className="flex flex-row items-center">
                              {selectedItem?.type === type &&
                                selectedItem?.index === idx1 && (
                                  <FiArrowRight className="text-surface-default mr-2" />
                                )}
                              <span
                                className={clsx("text-sm", {
                                  "text-surface-default font-semibold":
                                    (selectedItem?.type === type &&
                                      selectedItem?.index === idx1) ||
                                    !!item?.featureInformation?.strategies?.find(
                                      (strategy: any) =>
                                        !!strategy?.machining_strategy?.find(
                                          (item3: any) => item3?.isSelected
                                        )
                                    ),
                                })}
                              >{`${item?.title}`}</span>
                            </div>
                            {!!item?.featureInformation?.strategies?.find(
                              (strategy: any) =>
                                !!strategy?.machining_strategy?.find(
                                  (item3: any) => item3?.isSelected
                                )
                            ) && (
                              <Checkbox checked={true} onChange={() => {}} />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </AccordionItem>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {selectedItem?.feature_id && (
        <div className="w-[75%] flex flex-col relative">
          <div
            className="w-full px-4 h-[calc(100%-75px)] overflow-y-auto"
            onScroll={() => hideTooltip()}
          >
            <div className="w-full mb-4">
              <SummaryBox
                label={`Feature Summary : `}
                selectedItem={selectedItem}
                className="mb-2"
                title={selectedTitle}
              />
            </div>
            <div className="mb-4 border border-gray-eac rounded-lg">
              <div className="w-full flex flex-row items-center justify-between p-2 border-b border-gray-eac bg-gray-f9f p-6">
                <span className="font-semibold text-black">
                  Machining Strategies
                </span>
                <Step
                  data={[...stepData]}
                  current={{
                    index: selectedStep?.index,
                    subIndex: selectedStep?.subIndex,
                  }}
                  setSelectedStep={setSelectedStep}
                  className="mb-2"
                />
              </div>

              <div className="flex flex-col overflow-x-auto p-2">
                <div className="flex flex-row justify-between items-center">
                  <div>
                    <p className="text-gray-475 text-sm font-bold">
                      {selectedStep?.label || ""}
                    </p>
                    <p className="text-gray-475 text-xs font-bold">
                      Tool Path:
                      <span className="font-normal mr-3 ml-1">
                        {
                          selectedStep?.["operations"]?.[selectedStep?.subIndex]
                            ?.tool_path
                        }
                      </span>
                      Operation:
                      <span className="font-normal mr-3 ml-1">
                        {
                          selectedStep?.["operations"]?.[selectedStep?.subIndex]
                            ?.operation
                        }
                      </span>
                      Location:
                      <span className="font-normal mr-3 ml-1">
                        {
                          selectedStep?.["operations"]?.[selectedStep?.subIndex]
                            ?.location
                        }
                      </span>
                    </p>
                  </div>

                  <div className="flex justify-center w-fit">
                    <div className="flex flex-col justify-end mr-2">
                      <span className="text-gray-807 font-medium text-[10px]">
                        Tool Life
                      </span>
                      <span className="text-black text-xs font-medium text-end">
                      {100 - (selectedStep?.operations?.[selectedStep?.subIndex]?.["mrr_value"] ?? 100)}%
                      </span>
                    </div>
                    <StepSlider
                      min={0}
                      max={100}
                      step={25}
                      value={(selectedStep?.operations?.[selectedStep?.subIndex]?.["mrr_value"] ?? 100)}
                      onChange={(value: number) => {
                        let mrr_value = value;
                        if (value < 25) {
                          mrr_value = 0;
                        }
                        if (value > 75) {
                          mrr_value = 100;
                        }
                        let selectedstep = JSON.parse(JSON.stringify(selectedStep));
                        selectedstep.operations[selectedStep?.subIndex]["mrr_value"] = mrr_value;
                        delete selectedstep.operations[selectedStep?.subIndex]["parameters"];
                        setSelectedStep(selectedstep);
                      }}
                      // onChangeComplete={() => loadData()}
                      className="w-[150px]"
                    />
                    <div className="flex flex-col ml-2">
                      <span className="text-gray-807 font-medium text-[10px]">
                        MRR
                      </span>
                      <span className="text-black text-xs font-medium">
                        {(selectedStep?.operations?.[selectedStep?.subIndex]?.["mrr_value"] ?? 100)}%
                      </span>
                    </div>
                  </div>
                </div>

                {!!boxData?.length ? (
                  <div className="flex flex-row overflow-x-auto w-full py-1 px-px mt-4">
                    {boxData?.map((tool: any, idx: number) => (
                      <Toolbox
                        key={idx}
                        tool={{...tool}}
                        className="mr-2"
                        index={idx}
                        setSelectedTool={handleSelectedTool}
                        selectedTool={selectToolID}
                        confirmInValidData={confirmInValidData}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="w-full flex flex-col justify-center items-center py-20 space-y-2">
                    <img src={NoFoundImg} />
                    <p className="text-sm font-semibold text-gray-344">
                      No tools found
                    </p>
                    <p className="text-xs font-medium text-gray-475">
                      Please consider changing the machining strategy from
                      above.
                    </p>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="mb-4">
              <Step
                data={[...stepData]}
                current={{
                  index: selectedStep?.index,
                  subIndex: selectedStep?.subIndex,
                }}
                setSelectedStep={setSelectedStep}
                className="mb-2"
              />
              {selectedStep?.isNull ? (
                <div className="w-full flex flex-col justify-center items-center py-10">
                  <img src={FileNotFoundImg} className="w-6 h-6 mb-2" />
                  <p className="text-black text-sm font-semibold">
                    No tools found.
                  </p>
                  <p className="text-black text-sm">
                    Please consider changing the machining strategy from above.
                  </p>
                </div>
              ) : (
                <>
                  <div className="w-full flex flex-row items-center justify-between">
                    <span className="text-gray-475 font-semibold">
                      Drilling-Rapid out
                    </span>
                    <div className="w-fit">
                      <div className="flex justify-center ">
                        <div className="flex flex-col justify-end mr-2">
                          <span className="text-gray-807 font-medium text-[10px]">
                            MRR
                          </span>
                          <span className="text-black text-xs font-medium text-end">
                            {mrrValue}%
                          </span>
                        </div>

                        <StepSlider
                          min={0}
                          max={100}
                          step={25}
                          value={mrrValue}
                          onChange={(value: number) => {
                            if (value < 25) {
                              setMrrValue(25);
                              return;
                            }
                            if (value > 75) {
                              setMrrValue(75);
                              return;
                            }
                            setMrrValue(value);
                          }}
                          onChangeComplete={() => loadData()}
                          className="w-[250px]"
                        />
                        <div className="flex flex-col ml-2">
                          <span className="text-gray-807 font-medium text-[10px]">
                            Tool Life
                          </span>
                          <span className="text-black text-xs font-medium">
                            {100 - mrrValue}%
                          </span>
                        </div>
                      </div>
                    </div>
                  </div> */}
            {/* <div className="w-full py-2 border border-gray-d9d rounded mb-2">
                    
                    <div className="w-full mb-1">
                      <div className="flex justify-center ">
                        <div className="flex flex-col justify-end mr-2">
                          <span className="text-gray-807 font-medium text-[10px]">
                            MRR
                          </span>
                          <span className="text-black text-xs font-medium text-end">
                            {mrrValue}%
                          </span>
                        </div>

                        <StepSlider
                          min={0}
                          max={100}
                          step={25}
                          value={mrrValue}
                          onChange={(value: number) => {
                            if (value < 25) {
                              setMrrValue(25);
                              return;
                            }
                            if (value > 75) {
                              setMrrValue(75);
                              return;
                            }
                            setMrrValue(value);
                          }}
                          onChangeComplete={() => loadData()}
                          className="w-6/12"
                        />
                        <div className="flex flex-col ml-2">
                          <span className="text-gray-807 font-medium text-[10px]">
                            Tool Life
                          </span>
                          <span className="text-black text-xs font-medium">
                            {100 - mrrValue}%
                          </span>
                        </div>
                      </div>
                    </div>
                  </div> */}
            {/* <div className="flex flex-row overflow-x-auto w-full py-1 px-px mt-4">
                    {boxData?.length > 0 ? (
                      boxData?.map((tool: any, idx: number) => (
                        <Toolbox
                          key={idx}
                          tool={tool}
                          className="mr-2"
                          index={idx}
                          setSelectedTool={handleSelectedTool}
                          selectedTool={
                            state[selectedItem?.key][selectedItem?.index]?.[
                              "parameter"
                            ]?.[selectedStep?.key]?.[idx]
                          }
                        />
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              )}
            </div> */}
          </div>

          <div className="w-full absolute bottom-0 left-0 right-0 bg-white flex justify-end items-center pt-4 border-t border-gray-e7e px-5">
            <div className="flex flex-row">
              <Button
                variant="ghost"
                className="mr-2 px-5"
                onClick={() => {
                  handleRedirect("strategy-summary");
                }}
              >
                Back
              </Button>
              <div
                className={clsx({
                  "cursor-not-allowed": !possibleList?.length,
                })}
                id="tool-summary"
                onMouseOver={() =>
                  !possibleList?.length && showTooltip("tool-summary")
                }
                onMouseLeave={() => hideTooltip()}
                data-tooltip-content="You must select at least one tool to Continue"
              >
                <Button
                  className={clsx("px-5", {
                    "pointer-events-none": !possibleList?.length,
                  })}
                  onClick={() => handleRedirect("summary")}
                  disabled={!possibleList?.length}
                >
                  Next: Summary
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showChangeCategory && (
        <ChangeStrategy
          setShowChangeCategory={setShowChangeCategory}
          selectedItem={selectedItem}
          handleStrategy={handleStrategy}
        />
      )}
    </div>
  );
};

export default CuttingTool;
