import { useState, useEffect } from "react";
import Modal from "react-modal";
import ClossInValidPopUp from "assets/images/icons/charm_cross.svg";
import InvalidSwitch from "components/InvalidSwitch";
import ConfirmPopUp from "components/MachiningStrategySummaryBox/ConfirmPopUp";

interface InValidToolPopUp {
  showPopUp?: any;
  closePopUp?: any;
  tool?: any;
  confirmInValidData?: any;
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "600px",
    padding: 0,
    marginRight: "-50%",
    borderRadius: "16px",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  },
};

const InValidToolPopUp: React.FC<InValidToolPopUp> = ({
  showPopUp,
  closePopUp,
  tool,
  confirmInValidData,
}) => {
  const [localTool, setLocalTool] = useState(JSON.parse(JSON.stringify(tool)));
  const [showConfirmPopUp, setShowConfirmPopUp] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");

  useEffect(() => {
    setLocalTool(JSON.parse(JSON.stringify(tool)));
  }, [showPopUp]);
  const disableParams = localTool?.isValid === false;


  const openConfirmPopUp = () => {
    if (tool?.isValid && localTool?.isValid === false) {
      return 1;
    }
    for (const param in localTool?.valid_data) {
      if (
        localTool.valid_data[param].isInValid &&
        !tool.valid_data[param]?.isInValid
      ) {
        return 2;
      }
    }
    return 0;
  };

  return (
    <Modal
      isOpen={showPopUp}
      style={customStyles as Modal.Styles}
      onRequestClose={closePopUp}
    >
      <div className="modalinvalidandvalid">
        <div className="flex flex-row justify-between border-b border-gray-300 p-[16px] sticky top-0 bg-white">
          <div className="font-[500] text-[20px] leading-[24px]">Invalid</div>
          <button onClick={closePopUp}>
            <img src={ClossInValidPopUp} alt="" />
          </button>
        </div>
        <div className="p-[16px] max-h-[70vh] overflow-y-auto">
          <div className="rounded-[12px] border border-gray-200 w-full mb-4 overflow-hidden">
            <table className="w-full">
              <thead className="rounded-[12px] border-b">
                <tr className="text-[12px] leading-[18px] font-semibold text-gray-600">
                  <th className="px-[24px] py-[12px] text-left w-[100%] font-medium">
                    Tool ID
                  </th>
                  <th className="w-[92px] font-medium  py-[12px] px-[16px]">Validity</th>
                  <th className="w-[272px] font-medium py-[12px] px-[16px]">Reason</th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-gray-50">
                  <td className=" px-[24px] py-[16px]">
                    <div className="font-[600] text-[14px] leading-lg">
                      {tool["Tool ID"]}
                    </div>
                  </td>
                  <td className="text-center w-[92px] p-[16px]">
                    <InvalidSwitch
                      initialChecked={localTool.isValid === false}
                      handleChange={(e: any) => {
                        let lTool = { ...localTool };
                        if (e.target.checked) {
                          lTool.isValid = false;
                          setLocalTool({ ...lTool });
                        } else {
                          lTool.isValid = true;
                          lTool.feedback = "";
                          setLocalTool({ ...lTool });
                        }
                      }}
                    />
                  </td>
                  <td className="w-[272px] p-[16px]">
                    <textarea
                      className="bg-white rounded-[8px] h-[100%] w-[240px] text-[12px]
                            resize-none overflow-hidden focus:outline-none p-[8px]"
                      rows={3}
                      maxLength={150}
                      disabled={localTool.isValid !== false}
                      value={localTool.feedback}
                      placeholder="Tool diameter is insufficient for roughing large surfaces"
                      onChange={(e) => {
                        let lTool = { ...localTool };
                        lTool.feedback = e.target.value;
                        setLocalTool({ ...lTool });
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="rounded-[12px] border border-gray-200 w-full overflow-hidden">
            <table className="w-full">
              <thead className="rounded-[12px] border-b">
                <tr className="text-[12px] leading-[18px] font-semibold text-gray-600">
                  <th className="px-[24px] py-[12px] font-medium text-left">
                    Params
                  </th>
                  <th className="font-medium w-[92px] px-[16px] py-[12px]">Validity</th>
                  <th className="font-medium w-[272px] px-[16px] py-[12px]" >Reason</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b border-gray-200 bg-gray-50">
                  <td className="px-[24px] py-[16px]">
                    <div className="flex flex-col gap-1">
                      <div className="font-[600] text-[14px]">
                        Cutting Velocity
                      </div>
                      <div className="font-[400]  text-[12px]">
                        {localTool["Spindle speed (RPM)"]?.toFixed(2) || "***"}{" "}
                        rpm
                      </div>
                    </div>
                  </td>
                  <td className={`text-center w-[92px] p-[16px] ${disableParams ? "opacity-60" : ""}`}>
                    <InvalidSwitch
                      initialChecked={
                        localTool.valid_data?.["Spindle speed (RPM)"]?.isInValid
                      }
                      handleChange={(e: any) => {
                        let lTool = { ...localTool };
                        if (
                          lTool.valid_data["Spindle speed (RPM)"]?.isInValid ===
                          undefined
                        ) {
                          lTool.valid_data["Spindle speed (RPM)"] = {
                            isInValid: false,
                            feedback: "",
                          };
                        }
                        if (e.target.checked) {
                          lTool.valid_data["Spindle speed (RPM)"].isInValid =
                            true;
                        } else {
                          lTool.valid_data["Spindle speed (RPM)"].isInValid =
                            false;
                          lTool.valid_data["Spindle speed (RPM)"].feedback = "";
                        }
                        setLocalTool({ ...lTool });
                      }}
                      disabled={disableParams}
                    />
                  </td>
                  <td className={`text-center w-[272px] p-[16px] ${disableParams ? "opacity-60" : ""}`}>
                    <textarea
                      className="bg-white rounded-[8px] h-[100%] w-[240px] text-[12px] 
                            resize-none overflow-hidden focus:outline-none p-[8px]"
                      rows={3}
                      maxLength={150}
                      disabled={
                        !localTool.valid_data?.["Spindle speed (RPM)"]
                          ?.isInValid || disableParams
                      }
                      value={
                        localTool.valid_data?.["Spindle speed (RPM)"]?.feedback
                      }
                      onChange={(e) => {
                        let lTool = { ...localTool };
                        lTool.valid_data["Spindle speed (RPM)"].feedback =
                          e.target.value;
                        setLocalTool({ ...lTool });
                      }}
                      placeholder="Radial depth of cut should be reduced"
                    />
                  </td>
                </tr>
                <tr className="border-b border-gray-200 bg-gray-50">
                  <td className="px-[24px] py-[16px]">
                    <div className="flex flex-col gap-1">
                      <div className="font-[600] text-[14px]">Feed Rate</div>
                      <div className="font-[400] text-[12px]">
                        {localTool["Optimal F (mm/min)"]?.toFixed(2) || "***"}{" "}
                        mm/min
                      </div>
                    </div>
                  </td>
                  <td className={`text-center w-[92px] p-[16px] ${disableParams ? "opacity-60" : ""}`}>
                    <InvalidSwitch
                      initialChecked={
                        localTool.valid_data?.["Optimal F (mm/min)"]?.isInValid
                      }
                      handleChange={(e: any) => {
                        let lTool = { ...localTool };
                        if (
                          lTool.valid_data["Optimal F (mm/min)"]?.isInValid ===
                          undefined
                        ) {
                          lTool.valid_data["Optimal F (mm/min)"] = {
                            isInValid: false,
                            feedback: "",
                          };
                        }
                        if (e.target.checked) {
                          lTool.valid_data["Optimal F (mm/min)"].isInValid =
                            true;
                        } else {
                          lTool.valid_data["Optimal F (mm/min)"].isInValid =
                            false;
                          lTool.valid_data["Optimal F (mm/min)"].feedback = "";
                        }
                        setLocalTool({ ...lTool });
                      }}
                      disabled={disableParams}
                    />
                  </td>
                  <td className={`text-center w-[272px] p-[16px] ${disableParams ? "opacity-60" : ""}`}>
                    <textarea
                      className="bg-white rounded-[8px] h-[100%] w-[240px] text-[12px] 
                            resize-none overflow-hidden focus:outline-none p-[8px]"
                      rows={3}
                      maxLength={150}
                      disabled={
                        !localTool.valid_data?.["Optimal F (mm/min)"]?.isInValid
                        || disableParams
                      }
                      value={
                        localTool.valid_data?.["Optimal F (mm/min)"]?.feedback
                      }
                      onChange={(e) => {
                        let lTool = { ...localTool };
                        lTool.valid_data["Optimal F (mm/min)"].feedback =
                          e.target.value;
                        setLocalTool({ ...lTool });
                      }}
                      placeholder="Radial depth of cut should be reduced"
                    />
                  </td>
                </tr>
                <tr className="border-b border-gray-200 bg-gray-50">
                  <td className="px-[24px] py-[16px]">
                    <div className="flex flex-col gap-1">
                      <div className="font-[600] text-[14px]">Radial Depth</div>
                      <div className="font-[400] text-[12px]">
                        {localTool["Optimal Ae (mm)"]?.toFixed(2) || "***"} mm
                      </div>
                    </div>
                  </td>
                  <td className={`text-center w-[92px] p-[16px] ${disableParams ? "opacity-60" : ""}`}>
                    <InvalidSwitch
                      initialChecked={
                        localTool.valid_data?.["Optimal Ae (mm)"]?.isInValid
                      }
                      handleChange={(e: any) => {
                        let lTool = { ...localTool };
                        if (
                          lTool.valid_data["Optimal Ae (mm)"]?.isInValid ===
                          undefined
                        ) {
                          lTool.valid_data["Optimal Ae (mm)"] = {
                            isInValid: false,
                            feedback: "",
                          };
                        }
                        if (e.target.checked) {
                          lTool.valid_data["Optimal Ae (mm)"].isInValid = true;
                        } else {
                          lTool.valid_data["Optimal Ae (mm)"].isInValid = false;
                          lTool.valid_data["Optimal Ae (mm)"].feedback = "";
                        }
                        setLocalTool({ ...lTool });
                      }}
                      disabled={disableParams}
                    />
                  </td>
                  <td className={`text-center w-[272px] p-[16px] ${disableParams ? "opacity-60" : ""}`}>
                    <textarea
                      className="bg-white rounded-[8px] h-[100%] w-[240px] text-[12px] 
                            resize-none overflow-hidden focus:outline-none p-[8px]"
                      rows={3}
                      maxLength={150}
                      disabled={
                        !localTool.valid_data?.["Optimal Ae (mm)"]?.isInValid ||
                        disableParams
                      }
                      value={
                        localTool.valid_data?.["Optimal Ae (mm)"]?.feedback
                      }
                      onChange={(e) => {
                        let lTool = { ...localTool };
                        lTool.valid_data["Optimal Ae (mm)"].feedback =
                          e.target.value;
                        setLocalTool({ ...lTool });
                      }}
                      placeholder="Radial depth of cut should be reduced"
                    />
                  </td>
                </tr>
                <tr className="border-b border-gray-200 bg-gray-50">
                  <td className="px-[24px] py-[16px]">
                    <div className="flex flex-col gap-1">
                      <div className="font-[600] text-[14px]">Axial Depth</div>
                      <div className="font-[400] text-[12px]">
                        {localTool["Optimal Ap (mm)"]?.toFixed(2) || "***"} mm
                      </div>
                    </div>
                  </td>
                  <td className={`text-center w-[92px] p-[16px] ${disableParams ? "opacity-60" : ""}`}>
                    <InvalidSwitch
                      initialChecked={
                        localTool.valid_data?.["Optimal Ap (mm)"]?.isInValid
                      }
                      handleChange={(e: any) => {
                        let lTool = { ...localTool };
                        if (
                          lTool.valid_data["Optimal Ap (mm)"]?.isInValid ===
                          undefined
                        ) {
                          lTool.valid_data["Optimal Ap (mm)"] = {
                            isInValid: false,
                            feedback: "",
                          };
                        }
                        if (e.target.checked) {
                          lTool.valid_data["Optimal Ap (mm)"].isInValid = true;
                        } else {
                          lTool.valid_data["Optimal Ap (mm)"].isInValid = false;
                          lTool.valid_data["Optimal Ap (mm)"].feedback = "";
                        }
                        setLocalTool({ ...lTool });
                      }}
                      disabled={disableParams}
                    />
                  </td>
                  <td className={`text-center w-[272px] p-[16px] ${disableParams ? "opacity-60" : ""}`}>
                    <textarea
                      className="bg-white rounded-[8px] h-[100%] w-[240px] text-[12px] 
                            resize-none overflow-hidden focus:outline-none p-[8px]"
                      rows={3}
                      maxLength={150}
                      disabled={
                        !localTool.valid_data?.["Optimal Ap (mm)"]?.isInValid ||
                        disableParams
                      }
                      value={
                        localTool.valid_data?.["Optimal Ap (mm)"]?.feedback
                      }
                      onChange={(e) => {
                        let lTool = { ...localTool };
                        lTool.valid_data["Optimal Ap (mm)"].feedback =
                          e.target.value;
                        setLocalTool({ ...lTool });
                      }}
                      placeholder="Radial depth of cut should be reduced"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex border-t border-gray-200 p-[16px] justify-center gap-4">
          <button
            onClick={closePopUp}
            className="px-[16px] py-[6px] rounded-[14px] w-[150px] bg-gray-200 text-gray-700 font-bold
            text-[14px]"
          >
            Cancel
          </button>
          <button
            className="px-[16px] py-[6px] rounded-[14px] w-[150px] bg-blue-600 text-white font-bold
            text-[14px]"
            onClick={() => {
              const openConfirm = openConfirmPopUp();
              if (!openConfirm) {
                confirmInValidData({ ...localTool });
                closePopUp();
              } else {
                setConfirmMessage(
                  openConfirm === 1
                    ? "Are you sure you want to mark this tool as Invalid?"
                    : "Are you sure you want to mark the parameters as Invalid?"
                );
                setShowConfirmPopUp(true);
              }
            }}
          >
            Save
          </button>
        </div>
        <ConfirmPopUp
          showConfirmPopUp={showConfirmPopUp}
          closeConfirmPopUp={() => setShowConfirmPopUp(false)}
          confirmFunction={() => {
            confirmInValidData({ ...localTool });
            closePopUp();
          }}
          confirmMessage={confirmMessage}
        />
      </div>
    </Modal>
  );
};

export default InValidToolPopUp;
