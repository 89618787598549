import { useContext, useEffect } from "react";
import DefaultLayout from "Layouts/DefaultLayout";
import queryString from "query-string";
import { TAB_LIST } from "constant";
import { useLocation, useNavigate } from "react-router-dom";
import CreateProject from "./CreateProject";
import FeatureRecognition from "./FeatureRecognition";
import MachineStrategy from "./MachineStrategy";
import CuttingTool from "./CuttingTool";
import Summary from "./Summary";
import clsx from "clsx";
import FeatureSummary from "./FeatureSummary";
import { GlobalContext } from "context/GlobalContext";
import DefaultRecommendations from "./DefaultRecommendations";
import MachiningStrategySummary from "./MachiningStrategySummary";

const Project = () => {
  const { state } = useContext(GlobalContext);

  const location = useLocation();
  const params = queryString.parse(location.search);
  const selectedTab = params?.tab || "project";

  let View = undefined;
  let noLayout = false;
  if (selectedTab === "project") {
    View = CreateProject;
  } else if (selectedTab === "feature") {
    View = FeatureRecognition;
  } else if (selectedTab === "default-recommendations") {
    View = DefaultRecommendations;
  } else if (selectedTab === "machine") {
    View = MachineStrategy;
  } else if (selectedTab === "tool") {
    View = CuttingTool;
  } else if (selectedTab === "summary") {
    View = Summary;
    noLayout = true;
  } else if (selectedTab === "feature-summary") {
    View = FeatureSummary;
    noLayout = true;
  } else if (selectedTab === "strategy-summary") {
    View = MachiningStrategySummary;
    noLayout = true;
  }

  return (
    <DefaultLayout noLayout={noLayout}>
      {/* Tab  */}
      {!noLayout && (
        <div className="rounded-full shadow-tab flex flex-row p-1 mx-4 my-4 space-x-2">
          {TAB_LIST?.map((item, idx) => {
            const Icon = item?.icon;
            return (
              <button
                className={`rounded-full py-1 flex flex-row items-center px-2 w-3/12 justify-center ${
                  selectedTab === item?.type
                    ? "bg-surface-default"
                    : "bg-gray-f1f"
                }`}
                key={idx}
                // onClick={() => navigate(`/project?tab=${item?.type}`)}
              >
                <Icon color={selectedTab === item?.type ? "white" : "black"} />
                <span
                  className={`font-semibold text-sm ml-1 ${
                    selectedTab === item?.type ? "text-white" : "text-black"
                  }`}
                >
                  {item?.label}
                </span>
              </button>
            );
          })}
        </div>
      )}

      <div
        className={clsx("relative", {
          "mt-4": !noLayout,
          "h-full": noLayout,
        })}
      >
        {!!selectedTab && View && <View />}
      </div>
    </DefaultLayout>
  );
};

export default Project;
