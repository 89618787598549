import { GlobalContext } from "context/GlobalContext";
import { useContext, useEffect, useRef, useState } from "react";
import DefaultLayout from "Layouts/DefaultLayout";
import { SET_MACHINE_SETUP, keyList } from "constant";
import "./MachiningStrategySummary.css";
import { BsGripVertical } from "react-icons/bs";
import { useNavigate, useLocation } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CustomOrderModal } from "components/CustomOrderModal";
import {
  createDeepCopy,
  fetchSetupIdx,
  compareFuncForObjectsWithPassNumber,
} from "utils";
import { reorderArrayElement, addPhaseNo, constructTitle } from "./utils";
import OrderDropDownMenu from "components/OrderDropDownMenu";

const MachiningStrategySummary = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { machiningSetups, setupNumber } = state;
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState<any>({});
  const localMachiningSetups = useRef<any[]>([]);
  const [draggableItems, setDraggableItems] = useState<any[]>([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    /* ---------------------------------------------------------------------- */
    // TODO: Remove this code after release is deployed.
    if (location.state?.referrer === "machine") {
      navigate(`/project?tab=tool`);
    } else if (location.state?.referrer === "tool") {
      navigate(`/project?tab=machine`);
    } else {
      navigate("/projects");
    }
    return;
    /* ---------------------------------------------------------------------- */
    if (
      location.state?.referrer !== "machine" &&
      location.state?.referrer !== "tool"
    ) {
      navigate("/projects");
      return;
    }
    const setupIdx = fetchSetupIdx(machiningSetups, setupNumber);
    const draggableRowsData = [];
    const featureTypeToCountMap: any = {};
    const featureTypeToTitleMap: any = {};
    keyList.forEach((element: any) => {
      featureTypeToTitleMap[element.type] = element.title;
    });
    const updatedSetups = createDeepCopy(machiningSetups);

    for (const [idx1, feature] of updatedSetups?.[
      setupIdx
    ]?.features?.entries?.() || []) {
      const type = feature?.featureInformation?.feature_data?.feature_type;
      if (type in featureTypeToCountMap) {
        featureTypeToCountMap[type]++;
      } else {
        featureTypeToCountMap[type] = 1;
      }
      const title = constructTitle(
        type,
        featureTypeToTitleMap,
        featureTypeToCountMap
      );

      const selectedStrategy =
        feature.featureInformation?.strategies?.[0]?.machining_strategy?.find(
          (item: any) => item?.isSelected
        );
      if (!selectedStrategy?.passes) continue;
      for (const [idx2, pass] of selectedStrategy.passes.entries()) {
        if (!pass?.operations) continue;
        for (const [idx3, operation] of pass.operations.entries()) {
          if (!operation) continue;
          let data = {
            id: `${feature.feature_id}-${idx1}-${idx2}-${idx3}`,
            pass: pass.pass,
            location: operation.location,
            operation: operation.operation,
            indexOne: idx1,
            indexTwo: idx2,
            indexThree: idx3,
            tool_path: operation.tool_paths[0]?.tool_path,
            tool_path_type: operation?.tool_paths[0]?.tool_path_type,
            tool_type: operation?.tool_paths[0]?.tool_type
              ? operation?.tool_paths[0]?.tool_type
              : operation?.tool_paths[0]?.tool_types[0],
            operationObj: operation,
            pass_number: operation.pass_number || Number.POSITIVE_INFINITY,
            title: title,
          };
          draggableRowsData.push(data);
        }
      }
    }
    localMachiningSetups.current = updatedSetups;
    draggableRowsData.sort(compareFuncForObjectsWithPassNumber);
    setDraggableItems(draggableRowsData);
  }, []);

  const handleRedirect = (tab: string) => {
    if (tab === "tool") {
      addPhaseNo(draggableItems);
      dispatch({
        type: SET_MACHINE_SETUP,
        payload: JSON.parse(JSON.stringify(localMachiningSetups.current)),
      });
    }
    navigate(`/project?tab=${tab}`);
  };

  const handleDragToIndex = (result: any) => {
    setDraggableItems(
      reorderArrayElement(
        draggableItems,
        result.source.index,
        result.destination.index
      )
    );
  };

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    marginBottom: "4px",
    backgroundColor: "#ffffff",
    "border-radius": "12px",
    ...draggableStyle,
  });

  const handleReordering = (
    selectedId: string,
    type: number,
    position: number = 0
  ) => {
    const currentIndex = draggableItems.findIndex(
      (elem) => elem.id === selectedId
    );
    let destinationIndex;
    switch (type) {
      case 0:
        destinationIndex = 0;
        break;
      case 1:
        destinationIndex = Math.max(0, currentIndex - 1);
        break;
      case 2:
        destinationIndex = Math.min(
          draggableItems.length - 1,
          currentIndex + 1
        );
        break;
      case 3:
        destinationIndex = draggableItems.length - 1;
        break;
      case 4:
        destinationIndex = Math.min(
          Math.max(0, position),
          draggableItems.length - 1
        );
        break;
      default:
        return;
    }
    setDraggableItems(
      reorderArrayElement(draggableItems, currentIndex, destinationIndex)
    );
  };

  return (
    <DefaultLayout>
      <div className="flex flex-col h-full overflow-hidden">
        <div className="flex flex-row justify-between items-center mt-4 mx-4 my-4">
          <span className="font-semibold text-xl">Manufacturing Process</span>
          {/* TODO: changing setups currently is not implemented */}
          {/* <div className="flex flex-row items-center">
            <span className="font-semibold text-sm mr-2">Setups</span>
            <Select
              options={SETUP_NAME}
              className="w-8/12 ml-2 setupdropdown"
              value="S1"
            />
          </div> */}
        </div>
        <div className="flex  mt-8 h-full overflow-auto mb-8 mx-4">
          <div className="inline-flex flex-col cgap-4 mr-4">
            {draggableItems.map((item, index) => (
              <div
                key={index}
                className="orderlistnumber cgap-4 inline-flex flex-col items-center"
              >
                <span className="rounded-full bg-surface-default w-10 text-xl font-semibold text-white h-10 inline-flex items-center justify-center">
                  {index + 1}
                </span>
                {draggableItems.length !== index + 1 && (
                  <div className="w-1 h-100 bg-surface-default rounded"></div>
                )}
              </div>
            ))}
          </div>
          <div className="inline-flex flex-col cgap-4 w-full">
            <DragDropContext onDragEnd={handleDragToIndex}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {draggableItems.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div className="flex flex-row w-full">
                              <div
                                className="orderlistcontent w-full border border-gray-200 rounded-xl"
                                style={{ height: "127px" }}
                              >
                                <div className="flex flex-row justify-between w-full items-center border-b border-gray-200 p-3">
                                  <div className="inline-flex flex-row items-center mr-auto">
                                    <div className="drapbtn inline-flex flex-row w-6 mr-2 h-6 items-center justify-center">
                                      {/* <BsGripVertical className="text-gray-700 text-2xl" /> */}

                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M9 7.5C9.82843 7.5 10.5 6.82843 10.5 6C10.5 5.17157 9.82843 4.5 9 4.5C8.17157 4.5 7.5 5.17157 7.5 6C7.5 6.82843 8.17157 7.5 9 7.5Z"
                                          fill="#344054"
                                        />
                                        <path
                                          d="M15 7.5C15.8284 7.5 16.5 6.82843 16.5 6C16.5 5.17157 15.8284 4.5 15 4.5C14.1716 4.5 13.5 5.17157 13.5 6C13.5 6.82843 14.1716 7.5 15 7.5Z"
                                          fill="#344054"
                                        />
                                        <path
                                          d="M9 13.5C9.82843 13.5 10.5 12.8284 10.5 12C10.5 11.1716 9.82843 10.5 9 10.5C8.17157 10.5 7.5 11.1716 7.5 12C7.5 12.8284 8.17157 13.5 9 13.5Z"
                                          fill="#344054"
                                        />
                                        <path
                                          d="M15 13.5C15.8284 13.5 16.5 12.8284 16.5 12C16.5 11.1716 15.8284 10.5 15 10.5C14.1716 10.5 13.5 11.1716 13.5 12C13.5 12.8284 14.1716 13.5 15 13.5Z"
                                          fill="#344054"
                                        />
                                        <path
                                          d="M9 19.5C9.82843 19.5 10.5 18.8284 10.5 18C10.5 17.1716 9.82843 16.5 9 16.5C8.17157 16.5 7.5 17.1716 7.5 18C7.5 18.8284 8.17157 19.5 9 19.5Z"
                                          fill="#344054"
                                        />
                                        <path
                                          d="M15 19.5C15.8284 19.5 16.5 18.8284 16.5 18C16.5 17.1716 15.8284 16.5 15 16.5C14.1716 16.5 13.5 17.1716 13.5 18C13.5 18.8284 14.1716 19.5 15 19.5Z"
                                          fill="#344054"
                                        />
                                      </svg>
                                    </div>
                                    <span className="font-semibold text-lg">
                                      {item.tool_path_type}-{item.tool_path}{" "}
                                    </span>
                                    <span className="inline-flex items-center rounded-md ml-3 bg-gray-200 px-2 py-1 text-xs font-medium text-gray-700">
                                      Feature:
                                      <strong className="ml-2 text-gray-700">
                                        {item.title}
                                      </strong>
                                    </span>
                                  </div>
                                  {draggableItems.length > 1 && (
                                    <OrderDropDownMenu
                                      item={item}
                                      index={index}
                                      setOpen={setOpen}
                                      setModalData={setModalData}
                                      handleReordering={handleReordering}
                                      lastIndex={draggableItems.length - 1}
                                    ></OrderDropDownMenu>
                                  )}
                                </div>
                                <div className="flex flex-col p-3">
                                  <label className="font-semibold text-sm mb-1">
                                    {item.pass}
                                  </label>
                                  <div className="flex flex-row flex-wrap cgap-4">
                                    <div className="inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-600">
                                      Operation:
                                      <strong className="ml-2 text-gray-700">
                                        {item.operation}
                                      </strong>
                                    </div>
                                    <div className="inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-600">
                                      Location:
                                      <strong className="ml-2 text-gray-700">
                                        {item.location}
                                      </strong>
                                    </div>
                                    <div className="inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-600">
                                      Tool Type:
                                      <strong className="ml-2 text-gray-700">
                                        {item.tool_type}
                                      </strong>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </div>
      <div className="bg-white border-t justify-end mt-auto border-gray-200 px-4 py-3 flex ">
        <button
          type="button"
          className=" inline-flex justify-center rounded-lg bg-gray-200 px-9 py-2 text-md font-semibold text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 mr-3"
          onClick={() => handleRedirect("machine")}
        >
          Edit
        </button>
        <button
          type="button"
          className="inline-flex justify-center rounded-lg bg-surface-default px-9 py-2 text-md font-semibold text-white shadow-sm hover:bg-surface-500 ml-3"
          onClick={() => handleRedirect("tool")}
        >
          Cutting Tools & Parameters
        </button>
      </div>
      <CustomOrderModal
        open={open}
        setOpen={setOpen}
        data={modalData}
        onConfirm={handleReordering}
      ></CustomOrderModal>
    </DefaultLayout>
  );
};

export default MachiningStrategySummary;
