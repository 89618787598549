import { useEffect, useState, useContext } from "react";
import { Checkbox } from "components/Checkbox";
import clsx from "clsx";
import ScatterChart from "components/ScatterChart";
import { GlobalContext } from "context/GlobalContext";
import { renderToString } from "react-dom/server";
// import { useApiCall } from "hooks";
// import { getGraphDataAPI } from "services";
import { capitalize } from "utils";
import LoadingImg from "assets/images/loading-icon.gif";
import ToolPathButtonImg from "assets/images/icons/ph_path-fill.svg";
import PopUpCross from "assets/images/icons/charm_cross.svg";
import Modal from "react-modal";
import InValidCross from "assets/images/icons/uiw_stop-o.svg";
import InValidCrossRed from "assets/images/icons/uiw_stop-o-red.svg";
import InValidToolPopUp from "./InValidToolPopUp";

interface IToolbox {
  className?: string;
  tool?: any;
  index?: number;
  selectedTool?: any;
  setSelectedTool?: any;
  confirmInValidData?: Function;
}

const POPUP_STYLES = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: 0,
    marginRight: "-50%",
    width: "420px",
    borderRadius: "16px",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  },
};

// 1. Tool specification
// 2. Tool material
// 3. Diameter (mm)
// 4. Flute Length (mm)
// 5. Total Length (mm)

const COMMON_LABELS = [
  { label: "Tool ID", value: "Tool ID" },
  { label: "Tool material", value: "Body material code" },
  { label: "Total Length (mm)", value: "Body length" },
  { label: "Cutting Diameter (mm)", value: "Cutting diameter" },
  { label: "Flute Length (mm)", value: "Flute length" },
  { label: "Shaft Diameter (mm)", value:"Shaft diameter" },
  { label:"Flute Count", value:"Flute count" }
];

const ADDITIONAL_DATA_LABELS: any = {
  Tool: {
    "Tool | Spindle Speed": {
      label: "Spindle Speed",
      units: "RPM",
    },
    "Tool | Surface Speed": {
      label: "Surface Speed",
      units: "SFM",
    },
    "Tool | Cutting Feedrate": {
      label: "Cutting Feedrate",
      units: "mm/min",
    },
    "Tool | Feed per Tooth": {
      label: "Feed per Tooth",
      units: "mm/tooth",
    },
    "Tool | Lead-In Feedrate": {
      label: "Lead-In Feedrate",
      units: "mm/min",
    },
    "Tool | Lead-Out Feedrate": {
      label: "Lead-Out Feedrate",
      units: "mm/min",
    },
    "Tool | Ramp Feedrate": {
      label: "Ramp Feedrate",
      units: "mm/min",
    },
    "Tool | Plunge Feedrate": {
      label: "Plunge Feedrate",
      units: "mm/min",
    },
  },
  Geometry: {
    "Geometry | Select same diameter": {
      label: "Select same diameter",
      units: "",
    },
  },
  Heights: {},
  Passes: {
    "Passes | Tolerance": {
      label: "Tolerance",
      units: "mm",
    },
    "Passes | Backoff Distance": {
      label: "Backoff Distance",
      units: "mm",
    },
    "Passes | Repeat Finishing Pass": {
      label: "Repeat Finishing Pass",
      units: "",
    },
    "Passes | Multiple Depths": {
      label: "Multiple Depths",
      units: "",
    },
    "Passes | Multiple Passes": {
      label: "Multiple Passes",
      units: "",
    },
    "Passes | Maximum Roughing Stepdown": {
      label: "Maximum Roughing Stepdown /Maximum Stepdown",
      units: "mm",
    },
    "Passes | Roughing Passes": {
      label: "Roughing Passes",
      units: "",
    },
    "Passes | Stock to Leave": {
      label: "Stock to Leave",
      units: "",
    },
    "Passes | Axial Stock to Leave": {
      label: "Axial Stock to Leave",
      units: "mm",
    },
    "Passes | Radial Stock to Leave": {
      label: "Radial Stock to Leave",
      units: "mm",
    },
    "Passes | Finishing Overlap": {
      label: "Finishing Overlap",
      units: "mm",
    },
    "Passes | Tangential Fragment Extension Distance": {
      label: "Tangential Fragment Extension Distance",
      units: "mm",
    },
    "Passes | Stock Offset": {
      label: "Stock Offset",
      units: "mm",
    },
    "Passes | Compensation Type": {
      label: "Compensation Type",
      units: "",
    },
    "Passes | Number of Stepover": {
      labels: "Number of Stepover",
      units: "",
    },
    "Passes | Stepover": {
      label: "Stepover",
      units: "mm",
    },
    "Passes | Maximum Stepover": {
      label: "Maximum Stepover",
      units: "mm",
    },
    "Passes | Chamfer Clearance": {
      label: "Chamfer Clearance",
      units: "",
    },
    "Passes | Smoothing": {
      label: "Smoothing",
      units: "",
    },
    "Passes | Smoothing Tolerance": {
      label: "Smoothing Tolerance",
      units: "mm",
    },
  },
  Linking: {
    "Linking | Lead-Out (Exit)": {
      label: "Lead-Out (Exit)",
      units: "",
    },
    "Linking | Same as Lead-In": {
      label: "Same as Lead-In",
      units: "",
    },
    "Linking | Linear Lead-In Distance": {
      label: "Linear Lead-In Distance",
      units: "mm",
    },
    "Linking | Perpendicular": {
      label: "Perpendicular",
      units: "",
    },
  },
  Cycle: {
    "Cycle | Cycle": {
      label: "Cycle",
      units: "",
    },
  },
};

const fetchToolTipContent = (toolType: string) =>{
  let labelArray = [...COMMON_LABELS];
  switch (toolType) {
    case "face mill":
    case "slot mill":
    case "t-slot mill":
    case "bull nose end mill":
    case "boring bar":
    case "reamer":
      labelArray.push({label:"Corner Radius", value:"Corner radius"})
      break;
    case "chamfer mill":
    case "taper mill":
    case "tapered ball":
    case "tapered bull nose":
      labelArray.push({label:"Taper Angle", value:"Taper angle"})
      break;
    case "center drill":
    case "spot drill":
    case "drill":
      labelArray.push({label:"Point angle", value:"Point angle"})
      break;
    case "tap":
    case "tap right hand":
    case "thread former":
    case "thread mill":
      labelArray.push({label:"Thread Pitch (mm)", value:"Thread pitch"})
      break;
  }
  return labelArray;
}

const TooltipContent = ({ tool }: any) => {
  const TOOL_TYPE = tool?.["Tool type"];
  const LABEL_ARRAY = fetchToolTipContent(TOOL_TYPE);
  return (
    <div>
      {LABEL_ARRAY.map(({ label, value }, idx) => (
        <p key={idx}>{`${label}: ${tool?.[value] ?? "N/A"}`}</p>
      ))}
    </div>
  );
};

const InvalidTooltipContent = ({ data }: any) => {
  return (
    <div
      className="max-w-[200px] h-auto"
      style={{ overflowWrap: "break-word" }}
    >
      {data}
    </div>
  );
};

const Toolbox: React.FC<IToolbox> = ({
  className,
  tool,
  selectedTool,
  setSelectedTool,
  index,
  confirmInValidData,
}) => {
  const { state } = useContext(GlobalContext);
  const { showTooltip, hideTooltip, projectInfo } = state;
  const [seriesData, setSeriesData] = useState<any>([]);
  const [redPoint, setRedPoint] = useState<any>([]);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [showInvalidPopUp, setShowInvalidPopUp] = useState(false);
  let inValidBorder =
    tool?.isValid === false ||
    tool?.valid_data?.["Spindle speed (RPM)"]?.isInValid ||
    tool?.valid_data?.["Optimal F (mm/min)"]?.isInValid ||
    tool?.valid_data?.["Optimal Ae (mm)"]?.isInValid ||
    tool?.valid_data?.["Optimal Ap (mm)"]?.isInValid;
  const handleClose = () => {
    setPopUpOpen(false);
  };

  const handleOpen = () => {
    setPopUpOpen(true);
  };
  // const [fetchData]: any = useApiCall(getGraphDataAPI);

  useEffect(() => {
    if (!tool) return;
    setSeriesData(tool["non_optimal_points"] || []);
    setRedPoint([
      [
        Number(tool["Optimal T (min)"]?.toFixed(2)) || 0,
        Number(tool["Optimal MRR (mm^3/min)"]?.toFixed(2)) || 0,
      ],
    ]);
  }, [tool]);

  const handleCheckTool = (flag: boolean) => {
    setSelectedTool(index, flag);
  };

  return (
    <div
      className={clsx("shadow-input-2 min-w-[260px] max-w-[300px] flex flex-col flex-1", className, {
        "border-[1px] border-solid border-[#F04438]": inValidBorder,
      })}
    >
      <div className="px-[10px] pt-[10px] mb-1">
        <div className="flex flex-row justify-between items-center mb-2">
          <div className="flex flex-row truncate">
            <Checkbox
              checked={selectedTool === tool?.["Tool ID"]}
              onChange={(val: boolean) => {
                handleCheckTool(val);
              }}
              type="yellow"
              disabled={tool?.isValid === false}
            />
            <span
              className="font-semibold text-sm text-black truncate ml-2"
              id={tool?.["Tool ID"]}
              onMouseOver={() => showTooltip(tool?.["Tool ID"])}
              onMouseLeave={() => hideTooltip()}
              data-tooltip-html={renderToString(<TooltipContent tool={tool} />)}
            >
              {tool?.["Tool ID"] || "N/A"}
            </span>
          </div>
          <div>
            <button
              className="inline-block w-[18px] h-[18px]"
              onClick={() => setShowInvalidPopUp(true)}
              id={`${tool?.["Tool ID"]}-invalidButton`}
              onMouseOver={() => {
                if (!inValidBorder) {
                  showTooltip(`${tool?.["Tool ID"]}-invalidButton`, "gray-344");
                }
              }}
              onMouseLeave={() => hideTooltip()}
              data-tooltip-html={renderToString(
                <p className="font-medium text-base leading-[16.94px]">
                  Mark as Invalid
                </p>
              )}
            >
              <img
                src={!inValidBorder ? InValidCross : InValidCrossRed}
                alt="InValid"
              />
            </button>
          </div>
          <InValidToolPopUp
            showPopUp={showInvalidPopUp}
            closePopUp={() => setShowInvalidPopUp(false)}
            tool={{ ...tool }}
            confirmInValidData={confirmInValidData}
          />
          {/* <span
            className={clsx("text-xs font-semibold whitespace-nowrap", {
              "text-green-1db": tool?.label === "Lowest cost",
              "text-red-ff5": tool?.label !== "Lowest cost",
            })}
          >
            {tool?.label}
          </span> */}
        </div>
        <div className="h-[180px] w-full flex justify-center items-center">
          {!!seriesData?.length ? (
            <ScatterChart seriesData={seriesData} redPoint={redPoint} />
          ) : (
            <img src={LoadingImg} className="w-22 h-20" />
          )}
        </div>
        {/* <div className="w-full mb-1">
          <StepSlider
            min={0}
            max={100}
            step={25}
            value={mrrValue}
            onChange={setMrrValue}
          />
          <div className="flex justify-between">
            <div className="flex flex-col">
              <span className="text-gray-807 font-medium text-[10px]">MRR</span>
              <span className="text-black text-xs font-medium">
                {mrrValue}%
              </span>
            </div>
            <div className="flex flex-col justify-end">
              <span className="text-gray-807 font-medium text-[10px]">
                Tool Life
              </span>
              <span className="text-black text-xs font-medium text-end">
                {100 - mrrValue}%
              </span>
            </div>
          </div>
        </div> */}
        {/* <div className="py-1">
          <SquareCheckbox
            checked={false}
            onChange={() => {}}
            label="Save configuration"
          ></SquareCheckbox>
        </div> */}
      </div>
      <div className={`${tool?.isValid === false ? "opacity-60": ""}`}>
        <div className="w-full flex border-t border-black border-opacity-10">
          <div className="w-6/12 flex flex-col items-center justify-center py-0.5">
            <span className="text-gray-807 font-medium text-[10px]">
              Cutting Velocity
              {tool?.valid_data?.["Spindle speed (RPM)"]?.feedback && (
                <>
                  <span
                    className="text-white h-[11.67px] mx-[4px] w-[11.67px] text-[8px] inline-flex items-center justify-center bg-red-500 rounded-full"
                    id={`${tool["Tool ID"]}cutting-velocity`}
                    onMouseOver={() => {
                      showTooltip(`${tool["Tool ID"]}cutting-velocity`);
                    }}
                    onMouseLeave={() => hideTooltip()}
                    data-tooltip-html={renderToString(
                      <InvalidTooltipContent
                        data={
                          tool?.valid_data?.["Spindle speed (RPM)"]?.feedback ??
                          ""
                        }
                      />
                    )}
                  >
                    i
                  </span>
                </>
              )}
            </span>
            <span
              className={`text-xs font-semibold text-center ${
                tool?.valid_data?.["Spindle speed (RPM)"]?.isInValid
                  ? "text-red-500"
                  : "text-black"
              }`}
            >
              {tool["Spindle speed (RPM)"]?.toFixed(2) || "***"} rpm
            </span>
          </div>
          <div className="w-6/12 flex flex-col items-center justify-center py-0.5 border-l border-black border-opacity-10">
            <span className="text-gray-807 font-medium text-[10px]">
              Feed Rate
              {tool?.valid_data?.["Optimal F (mm/min)"]?.feedback && (
                <span
                  className="text-white h-[11.67px] mx-[4px] w-[11.67px] text-[8px] inline-flex items-center justify-center bg-red-500 rounded-full"
                  id={`${tool["Tool ID"]}feed-rate`}
                  onMouseOver={() => {
                    showTooltip(`${tool["Tool ID"]}feed-rate`);
                  }}
                  onMouseLeave={() => hideTooltip()}
                  data-tooltip-html={renderToString(
                    <InvalidTooltipContent
                      data={
                        tool?.valid_data?.["Optimal F (mm/min)"]?.feedback ?? ""
                      }
                    />
                  )}
                >
                  i
                </span>
              )}
            </span>
            <span
              className={`text-xs font-semibold text-center ${
                tool?.valid_data?.["Optimal F (mm/min)"]?.isInValid
                  ? "text-red-500"
                  : "text-black"
              }`}
            >
              {tool["Optimal F (mm/min)"]?.toFixed(2) || "***"} mm/min
            </span>
          </div>
        </div>
        <div className="w-full flex border-t border-black border-opacity-10">
          <div className="w-6/12 flex flex-col items-center justify-center py-0.5">
            <span className="text-gray-807 font-medium text-[10px]">
              Radial Depth
              {tool?.valid_data?.["Optimal Ae (mm)"]?.feedback && (
                <span
                  className="text-white h-[11.67px] mx-[4px] w-[11.67px] text-[8px] inline-flex items-center justify-center bg-red-500 rounded-full"
                  id={`${tool["Tool ID"]}radial-depth`}
                  onMouseOver={() => {
                    showTooltip(`${tool["Tool ID"]}radial-depth`);
                  }}
                  onMouseLeave={() => hideTooltip()}
                  data-tooltip-html={renderToString(
                    <InvalidTooltipContent
                      data={
                        tool?.valid_data?.["Optimal Ae (mm)"]?.feedback ?? ""
                      }
                    />
                  )}
                >
                  i
                </span>
              )}
            </span>
            <span
              className={`text-xs font-semibold text-center ${
                tool?.valid_data?.["Optimal Ae (mm)"]?.isInValid
                  ? "text-red-500"
                  : "text-black"
              }`}
            >
              {tool["Optimal Ae (mm)"]?.toFixed(2) || "***"} mm
            </span>
          </div>
          <div className="w-6/12 flex flex-col items-center justify-center py-0.5 border-l border-black border-opacity-10">
            <span className="text-gray-807 font-medium text-[10px]">
              Axial Depth
              {tool?.valid_data?.["Optimal Ap (mm)"]?.feedback && (
                <span
                  className="text-white h-[11.67px] mx-[4px] w-[11.67px] text-[8px] inline-flex items-center justify-center bg-red-500 rounded-full"
                  id={`${tool["Tool ID"]}axial-depth`}
                  onMouseOver={() => {
                    showTooltip(`${tool["Tool ID"]}axial-depth`);  
                  }}
                  onMouseLeave={() => hideTooltip()}
                  data-tooltip-html={renderToString(
                    <InvalidTooltipContent
                      data={
                        tool?.valid_data?.["Optimal Ap (mm)"]?.feedback ?? ""
                      }
                    />
                  )}
                >
                  i
                </span>
              )}
            </span>
            <span
              className={`text-xs font-semibold text-center ${
                tool?.valid_data?.["Optimal Ap (mm)"]?.isInValid
                  ? "text-red-500"
                  : "text-black"
              }`}
            >
              {tool["Optimal Ap (mm)"]?.toFixed(2) || "***"} mm
            </span>
          </div>
        </div>
        <div className="w-full flex border-t border-black border-opacity-10">
          <div className="w-6/12 flex flex-col items-center justify-center bg-blue-eef py-0.5">
            <div className="w-full flex flex-col justify-center items-center py-0.5">
              <span className="text-gray-807 font-medium text-[10px]">
                MRR
              </span>
              <span
                className={`text-xs font-semibold text-center`}
              >
                {tool["Optimal MRR (mm^3/min)"]?.toFixed(0) || "***"} mm^3/min
              </span>
            </div>
          </div>
          <div className="w-6/12 flex flex-col items-center justify-center bg-yellow-ffe py-0.5 border-l border-black border-opacity-10">
            <div className="w-full flex flex-col justify-center items-center py-0.5">
              <span className="text-gray-807 font-medium text-[10px]">
                Tool Life
              </span>
              <span
                className={`text-xs font-semibold text-center`}
              >
                {tool["Optimal T (min)"]?.toFixed(2) || "***"} min
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={`w-full flex flex-col justify-center items-center mt-auto ${tool?.isValid === false ? "opacity-60": ""}`}>
          <span className="text-gray-807 font-medium text-[10px] text-center">
            Torque
          </span>
          <span
            className={`text-xs font-semibold text-center`}
          >
            {tool["Torque (Nm)"]?.toFixed(3) || "***"} Nm
          </span>
        </div>
      <div className="w-full flex flex-col justify-center items-center p-[8px] border-t border-black border-opacity-10">
          <button
            className="text-[12px] text-[#1570EF] font-semibold bg-blue-eef 
            w-full py-[6px] px-[12px] flex justify-center gap-[4px] border border-solid border-blue-200 rounded-[4px]"
            onClick={handleOpen}
          >
            <span>
              <img src={ToolPathButtonImg} alt="b" />
            </span>
            <span className="">Tool Path Parameters</span>
          </button>
        </div>
      <Modal
        isOpen={popUpOpen}
        onRequestClose={handleClose}
        style={POPUP_STYLES as Modal.Styles}
      >
        <div>
          <div className="flex flex-row justify-between border-b border-gray-300 p-[16px] sticky top-0 bg-white">
            <span className="font-medium text-[20px] leading-6">
              {tool?.["Tool ID"] || "N/A"}
            </span>
            <div className="flex flex-col justify-center">
              <button
                onClick={handleClose}
                className="inline-block w-[20px] h-[20px]"
              >
                <img src={PopUpCross} alt="cross" />
              </button>
            </div>
          </div>
          <div className="w-full max-h-[70vh] overflow-y-auto">
            <div className="flex p-[16px]">
              <div className="w-full border border-gray-200 rounded-lg overflow-hidden">
                <table className="w-full rounded-lg">
                  {Object.keys(ADDITIONAL_DATA_LABELS).map((key: string) => {
                    let check = false;
                    for (const label in ADDITIONAL_DATA_LABELS[key]) {
                      if (tool?.[label] !== undefined) {
                        check = true;
                        break;
                      }
                    }
                    return (
                      check && (
                        <>
                          <tr>
                            <th
                              colSpan={2}
                              className="text-left bg-gray-200 text-gray-700 px-[8px] py-[2px] text-[16px] font-bold border border-color-100"
                            >
                              {key}
                            </th>
                          </tr>
                          {Object.keys(ADDITIONAL_DATA_LABELS[key]).map(
                            (key1: string) => {
                              return (
                                tool?.[key1] !== undefined && (
                                  <tr>
                                    <td className="text-gray-700 px-[8px] py-[2px] border border-color-100 w-[50%] text-[14px] text-gray-600 font-medium">
                                      {ADDITIONAL_DATA_LABELS[key][key1]?.label}
                                    </td>
                                    <td className="text-gray-700 px-[8px] py-[2px] border border-color-100 w-[50%] text-gray-700 font-bold text-[14px]">
                                      {` ${tool?.[key1]?.toString() ?? "--"} ${
                                        ADDITIONAL_DATA_LABELS[key][key1]?.units
                                      }`}
                                    </td>
                                  </tr>
                                )
                              );
                            }
                          )}
                        </>
                      )
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Toolbox;
