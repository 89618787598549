import Select from 'react-select'
import './index.css';

interface ISelectAutoComplete {
  label?: string
  className?: string;
  onChange?: any;
  onBlur?: any;
  value?: any;
  options?: any[];
}

const SelectAutoComplete: React.FC<ISelectAutoComplete> = ({
  label,
  className,
  options,
  ...props
}) => {
  return (
    <div className={className}>
      {label && (
        <p className="mb-1.5 text-gray-696 font-semibold text-sm">{label}</p>
      )}
      <Select isMulti options={options} {...props}/>
    </div>
  )
}

export default SelectAutoComplete
