import Button from "components/Button";
import ProjectItem from "components/ProjectItem";
import {
  SET_MACHINE_SETUP,
  SET_SETUP_NUMBER,
} from "constant";
import { GlobalContext } from "context";
import queryString from "query-string";
import { useContext, useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";

const ProjectItemList = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { projectInfo, setupNumber, machiningSetups, selectedFeature } = state;
  const navigate = useNavigate();
  const [list, setList] = useState<any>([]);
  const location = useLocation();
  const params = queryString.parse(location.search);
  const selectedTab = params?.tab;

  useEffect(() => {
    if (!projectInfo || !Object.keys(projectInfo)?.length) {
      navigate("/projects");
      return;
    }
    setList(machiningSetups);

    // const data = !!localStorage.getItem("data")?.length
    //   ? JSON.parse(localStorage.getItem("data") || "")
    //   : null;

    // if (
    //   !data?.[projectInfo?.ncProjectId] ||
    //   !Object.keys(data?.[projectInfo?.ncProjectId])?.length
    // ) {
    //   if (selectedTab === "feature") {
    //     const holeList = DEFAULT_HOLE_LIST?.map((obj) => ({
    //       ...obj,
    //       id: `${obj?.type}_${uuid()}`,
    //     }));
    //     dispatch({
    //       type: SET_HOLE_LIST,
    //       payload: [...holeList],
    //     });
    //     const threadHoleList = DEFAULT_THREAD_HOLE_LIST?.map((obj) => ({
    //       ...obj,
    //       id: `thread_hole_${uuid()}`,
    //     }));
    //     dispatch({
    //       type: SET_THREAD_HOLE_LIST,
    //       payload: [...threadHoleList],
    //     });
    //     const faceList = DEFAULT_FACE_LIST?.map((obj) => ({
    //       ...obj,
    //       id: `${obj?.type?.toLowerCase()}_${uuid()}`,
    //     }));
    //     dispatch({
    //       type: SET_FACE_LIST,
    //       payload: [...faceList],
    //     });
    //     const pocketList = DEFAULT_POCKET_LIST?.map((obj) => ({
    //       ...obj,
    //       id: `${obj?.type}_${uuid()}`,
    //     }));
    //     dispatch({
    //       type: SET_POCKET_LIST,
    //       payload: [...pocketList],
    //     });
    //     const contourList = DEFAULT_CONTOUR_LIST?.map((obj) => ({
    //       ...obj,
    //       id: `${obj?.type}_${uuid()}`,
    //     }));
    //     dispatch({
    //       type: SET_CONTOUR_LIST,
    //       payload: [...contourList],
    //     });

    //     let json_data: any = {...data};
    //     json_data[projectInfo?.ncProjectId] = {
    //       selectionId: projectInfo?.userSelectionId || null,
    //     };
    //     const list_data = json_data[projectInfo?.ncProjectId]?.data || [];
    //     list_data[setupNumber] = {
    //       holeList: holeList,
    //       threadHoleList: threadHoleList,
    //       faceList: faceList,
    //       pocketList: pocketList,
    //       contourList: contourList,
    //     };
    //     json_data[projectInfo?.ncProjectId]["data"] = list_data;
    //     localStorage.setItem("data", JSON.stringify(json_data));
    //     setList(list_data);
    //   }
    // } else {
    //   setList(data?.[projectInfo?.ncProjectId]?.data || []);
    //   const list_data = data?.[projectInfo?.ncProjectId]?.data[setupNumber];
    //   dispatch({
    //     type: SET_LOAD_DATA,
    //     payload: list_data,
    //   });
    // }
  }, [machiningSetups]);

  const handleSetup = (setup: any) => {
    // dispatch({
    //   type: SET_SELECTED_FEATURE,
    //   payload: setup,
    // });
    dispatch({
      type: SET_SETUP_NUMBER,
      payload: setup?.machiningSetupNumber,
    });
  };

  const onAddSetup = () => {
    let latestNum = -1;
    machiningSetups?.forEach((setup: any) => {
      if (setup?.machiningSetupNumber >= latestNum)
        latestNum = setup?.machiningSetupNumber;
    });
    const newSetup = {
      machiningSetupNumber: latestNum + 1,
      toolLibraryId: "",
      toolLibraryName: "",
      features: []
    }
    dispatch({
      type: SET_MACHINE_SETUP,
      payload: [
        ...machiningSetups,
        newSetup,
      ],
    });
    dispatch({
      type: SET_SETUP_NUMBER,
      payload: latestNum + 1,
    });
    // let data = !!localStorage.getItem("data")?.length
    //   ? JSON.parse(localStorage.getItem("data") || "")
    //   : null;
    // const holeList = DEFAULT_HOLE_LIST?.map((obj) => ({
    //   ...obj,
    //   id: `${obj?.type}_${uuid()}`,
    // }));
    // dispatch({
    //   type: SET_HOLE_LIST,
    //   payload: [...holeList],
    // });
    // const threadHoleList = DEFAULT_THREAD_HOLE_LIST?.map((obj) => ({
    //   ...obj,
    //   id: `thread_hole_${uuid()}`,
    // }));
    // dispatch({
    //   type: SET_THREAD_HOLE_LIST,
    //   payload: [...threadHoleList],
    // });
    // const faceList = DEFAULT_FACE_LIST?.map((obj) => ({
    //   ...obj,
    //   id: `${obj?.type?.toLowerCase()}_${uuid()}`,
    // }));
    // dispatch({
    //   type: SET_FACE_LIST,
    //   payload: [...faceList],
    // });
    // const pocketList = DEFAULT_POCKET_LIST?.map((obj) => ({
    //   ...obj,
    //   id: `${obj?.type}_${uuid()}`,
    // }));
    // dispatch({
    //   type: SET_POCKET_LIST,
    //   payload: [...pocketList],
    // });
    // const contourList = DEFAULT_CONTOUR_LIST?.map((obj) => ({
    //   ...obj,
    //   id: `${obj?.type}_${uuid()}`,
    // }));
    // dispatch({
    //   type: SET_CONTOUR_LIST,
    //   payload: [...contourList],
    // });
    // const list_data = data[projectInfo?.ncProjectId]?.data || [];
    // list_data.push({
    //   holeList: holeList,
    //   threadHoleList: threadHoleList,
    //   faceList: faceList,
    //   pocketList: pocketList,
    //   contourList: contourList,
    // });
    // data[projectInfo?.ncProjectId]["data"] = list_data;
    // localStorage.setItem("data", JSON.stringify(data));
    // setList(list_data);
    // dispatch({
    //   type: SET_LOAD_DATA,
    //   payload: list_data[list_data?.length],
    // });
    // dispatch({
    //   type: SET_SETUP_NUMBER,
    //   payload: list_data?.length-1,
    // });
  };

  return (
    <div className="h-full min-w-[51px] p-1 border border-gray-eac rounded-md flex flex-col relative bg-gray-f9f">
      <div className="absolute left-[4px] right-[4px] top-[4px] bottom-[40px] flex overflow-y-auto overflow-x-hidden flex-col space-y-1 project-list">
        <p className="text-center text-gray-475 text-[10px] font-medium w-full">
          Setups
        </p>
        <div>
          {!!list?.length &&
            list?.map((item: any, idx: number) => (
              <ProjectItem
                value={item}
                onSelect={handleSetup}
                selected={
                  setupNumber ===
                  item?.machiningSetupNumber
                }
              >{`S${idx + 1}`}</ProjectItem>
            ))}
        </div>
      </div>
      <div className="absolute left-0 right-0 bottom-[4px] w-full flex justify-center items-center">
        <Button
          variant="ghost"
          className="w-[34px] h-[34px] bg-surface-default !rounded-full !p-0"
          onClick={onAddSetup}
        >
          <FiPlus className="text-white w-6 h-6 font-semibold" />
        </Button>
      </div>
    </div>
  );
};

export default ProjectItemList;
