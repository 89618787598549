const ThreadHoleIcon = ({ active = false }) => {
  const strokeClass = active ? "#1570EF" : "black";
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="3.5" y="0.5" width="9" height="14" stroke={strokeClass} />
      <g filter="url(#filter0_d_1730_79)">
        <line
          x1="0.895235"
          y1="5.5111"
          x2="14.8952"
          y2="2.5111"
          stroke={strokeClass}
        />
      </g>
      <g filter="url(#filter1_d_1730_79)">
        <line
          x1="0.895235"
          y1="8.5111"
          x2="14.8952"
          y2="5.5111"
          stroke={strokeClass}
        />
      </g>
      <g filter="url(#filter2_d_1730_79)">
        <line
          x1="0.895235"
          y1="11.5111"
          x2="14.8952"
          y2="8.5111"
          stroke={strokeClass}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1730_79"
          x="0.790527"
          y="2.02222"
          width="14.2095"
          height="4.47778"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1730_79"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1730_79"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_1730_79"
          x="0.790527"
          y="5.02222"
          width="14.2095"
          height="4.47778"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1730_79"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1730_79"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_1730_79"
          x="0.790527"
          y="8.02222"
          width="14.2095"
          height="4.47778"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1730_79"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1730_79"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default ThreadHoleIcon;
