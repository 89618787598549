import { useState, useContext, useEffect } from "react";
import Input from "components/Input";
import { ISLAND_DATA, SET_FACE_DATA, SET_MACHINE_SETUP } from "constant";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GlobalContext } from "context/GlobalContext";

interface IBottomFace {
  feature_info?: any;
  feature_id?: string;
}

const init_bottom_face = {
  length: "",
  breadth: "",
  stock_on_face: "",
  quantity: "",
  islands: [],
  min_distance_between_islands: "",
  island_count: 0,
};

const validationSchema = Yup.object().shape({});

const BottomFace: React.FC<IBottomFace> = ({ feature_info, feature_id }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { setupNumber, projectInfo, machiningSetups } = state;
  // const [isLandCount, setIsLandCount] = useState(0);

  useEffect(() => {
    if (!feature_info) return;
    let formValues: any = {};
    Object.keys(init_bottom_face).forEach((key: string) => {
      switch (key) {
        case "min_distance_between_islands":
          formValues = {
            ...formValues,
            [key]: feature_info?.island_info?.[key] ?? "",
          };
          break;
        case "islands":
          formValues = {
            ...formValues,
            [key]: feature_info?.island_info?.[key] ?? [],
          };
          break;
        case "island_count":
          formValues = {
            ...formValues,
            [key]: feature_info.island_info?.islands?.length || 0,
          };
          break;
        default:
          formValues = {
            ...formValues,
            [key]: feature_info?.[key] ?? "",
          };
          break;
      }
    });
    // setIsLandCount(formValues?.islands?.length);
    setValues(formValues);
  }, [feature_info]);

  const {
    handleChange,
    handleBlur,
    setValues,
    setFieldValue,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: init_bottom_face,
    validationSchema,
    onSubmit: () => {},
  });

  // useEffect(() => {
  //   if (isLandCount > -1) {
  //     const arr: any[] = [];
  //     for (let i = 0; i < Number(isLandCount); i++) {
  //       arr.push({ ...ISLAND_DATA });
  //     }
  //     setFieldValue("islands", arr);
  //   }
  // }, [isLandCount]);

  const handleChangeFunc = (key: string, value: any, flag: boolean = false) => {
    if (flag) setFieldValue(key, value);

    let updated_setups = [...machiningSetups];
    let updatedIslandCount: number;

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );

    let updated_feature = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]?.featureInformation
        ?.feature_data?.feature_info,
    };

    switch (key) {
      case "min_distance_between_islands":
        updated_feature = {
          ...updated_feature,
          island_info: {
            ...updated_feature.island_info,
            [key]: Number(value),
          },
        };
        break;
      case "islands":
        updated_feature = {
          ...updated_feature,
          island_info: {
            ...updated_feature.island_info,
            [key]: value,
          },
        };
        break;
      case "island_count":
        updatedIslandCount = Number(value);
        if (updatedIslandCount === 0) {
          updated_feature.island_info = {
            islands: [],
          };
        } else if (updatedIslandCount > 0) {
          const currentIslandCount =
            updated_feature.island_info.islands?.length ?? 0;
          const countDifference = Math.abs(
            updatedIslandCount - currentIslandCount
          );
          for (let i = 0; i < countDifference; i++) {
            if (updatedIslandCount - currentIslandCount >= 0) {
              updated_feature.island_info.islands.push({ ...ISLAND_DATA });
            } else {
              updated_feature.island_info.islands.pop();
            }
          }
        }
        if (updated_feature.island_info?.islands?.length === 1) {
          delete updated_feature.island_info.min_distance_between_islands;
          setFieldValue("min_distance_between_islands", "");
        }
        break;
      default:
        updated_feature = {
          ...updated_feature,
          [key]: Number(value),
        };
    }

    updated_setups[setupIdx].features[
      feature_idx
    ].featureInformation.feature_data.feature_info = updated_feature;

    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });
    // setFieldValue(key, value);
    // dispatch({
    //   type: SET_FACE_DATA,
    //   payload: {
    //     index,
    //     key,
    //     value,
    //   },
    // });
    // dispatch({
    //   type: SET_FACE_DATA,
    //   payload: {
    //     index,
    //     key: "parameter",
    //     value: null,
    //   },
    // });
    // dispatch({
    //   type: SET_FACE_DATA,
    //   payload: {
    //     index,
    //     key: "selected_strategy",
    //     value: null,
    //   },
    // });
  };

  return (
    <div>
      <div className="mt-4">
        <p className="font-bold text-base text-gray-202">Feature dimensions</p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              placeholder="Face Length"
              className="mr-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="length"
              value={values?.length}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("length", values?.length);
              }}
              invalid={touched.length && errors.length}
              helperText={touched.length && errors.length}
              regex={/^\d*\.?\d*$/}
            />
          </div>

          <div className="w-6/12">
            <Input
              placeholder="Face Breadth"
              className="ml-2"
              type="text"
              name="breadth"
              value={values?.breadth}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("breadth", values?.breadth);
              }}
              invalid={touched.breadth && errors.breadth}
              helperText={touched.breadth && errors.breadth}
              regex={/^\d*\.?\d*$/}
            />
          </div>
        </div>
      </div>

      <div className="mt-4">
        <p className="font-bold text-base text-gray-202">Other details</p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              placeholder="Stock on face"
              className="pr-2"
              type="text"
              name="stock_on_face"
              value={values?.stock_on_face}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("stock_on_face", values?.stock_on_face);
              }}
              invalid={touched.stock_on_face && errors.stock_on_face}
              helperText={touched.stock_on_face && errors.stock_on_face}
              regex={/^\d*\.?\d*$/}
            />
          </div>
          <div className="w-6/12">
            <Input
              placeholder="Quantity"
              className="pl-2"
              type="text"
              name="quantity"
              value={values?.quantity}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("quantity", values?.quantity);
              }}
              invalid={touched.quantity && errors.quantity}
              helperText={touched.quantity && errors.quantity}
              regex={/^[1-9]\d*$/}
            />
          </div>
        </div>
      </div>

      <div className="mt-4">
        <p className="font-bold text-base text-gray-202">Island details</p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              placeholder="No. of islands"
              className="mr-2"
              type="text"
              name="island_count"
              value={values.island_count}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("island_count", values?.island_count);
              }}
              regex={/^\d*$/}
            />
          </div>

          {values.island_count > 1 && (
            <div className="w-6/12">
              <Input
                placeholder="Min distance between islands"
                className="ml-2"
                type="text"
                name="min_distance_between_islands"
                value={values?.min_distance_between_islands}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc(
                    "min_distance_between_islands",
                    values?.min_distance_between_islands
                  );
                }}
                invalid={
                  touched.min_distance_between_islands &&
                  errors.min_distance_between_islands
                }
                helperText={
                  touched.min_distance_between_islands &&
                  errors.min_distance_between_islands
                }
                regex={/^\d*\.?\d*$/}
              />
            </div>
          )}
        </div>

        {!!values?.islands?.length &&
          values?.islands?.map((item: any, index: number) => (
            <div className="flex flex-col" key={index}>
              <div className="py-3 border-b border-black">
                <p className="font-semibold">Island #{index + 1} details</p>
              </div>
              <div className="flex flex-row mt-4">
                <div className="w-6/12">
                  <Input
                    placeholder="Length"
                    className="mr-2"
                    type="text"
                    value={item?.length}
                    onChange={(e: any) => {
                      let data: any = [...values?.islands];
                      data[index]["length"] = Number(e?.target?.value);
                      setFieldValue("islands", data);
                    }}
                    onBlur={(e: any) => {
                      // handleBlur(e);
                      handleChangeFunc("islands", values?.islands);
                    }}
                    regex={/^\d*\.?\d*$/}
                  />
                </div>
                <div className="w-6/12">
                  <Input
                    placeholder="Breadth"
                    className="ml-2"
                    type="text"
                    value={item?.breadth}
                    onChange={(e: any) => {
                      let data: any = [...values?.islands];
                      data[index]["breadth"] = Number(e?.target?.value);
                      setFieldValue("islands", data);
                    }}
                    onBlur={(e: any) => {
                      // handleBlur(e);
                      handleChangeFunc("islands", values?.islands);
                    }}
                    regex={/^\d*\.?\d*$/}
                  />
                </div>
              </div>

              <div className="flex flex-row mt-4">
                <div className="w-6/12">
                  <Input
                    placeholder="Min x distance to face edges"
                    className="mr-2"
                    type="text"
                    value={item?.min_x_distance_to_face_edges}
                    onChange={(e: any) => {
                      let data: any = [...values?.islands];
                      data[index]["min_x_distance_to_face_edges"] = Number(
                        e?.target?.value
                      );
                      setFieldValue("islands", data);
                    }}
                    onBlur={() => {
                      // handleBlur(e);
                      handleChangeFunc("islands", values?.islands);
                    }}
                    regex={/^\d*\.?\d*$/}
                  />
                </div>
                <div className="w-6/12">
                  <Input
                    placeholder="Min y distance to face edges"
                    className="ml-2"
                    type="text"
                    value={item?.min_y_distance_to_face_edges}
                    onChange={(e: any) => {
                      let data: any = [...values?.islands];
                      data[index]["min_y_distance_to_face_edges"] = Number(
                        e?.target?.value
                      );
                      setFieldValue("islands", data);
                    }}
                    onBlur={(e: any) => {
                      // handleBlur(e);
                      handleChangeFunc("islands", values?.islands);
                    }}
                    regex={/^\d*\.?\d*$/}
                  />
                </div>
              </div>

              <div className="flex flex-row mt-4">
                <div className="w-6/12">
                  <Input
                    placeholder="Floor radius"
                    className="mr-2"
                    type="text"
                    value={item?.floor_radius}
                    onChange={(e: any) => {
                      let data: any = [...values?.islands];
                      data[index]["floor_radius"] = Number(e?.target?.value);
                      setFieldValue("islands", data);
                    }}
                    onBlur={(e: any) => {
                      // handleBlur(e);
                      handleChangeFunc("islands", values?.islands);
                    }}
                    regex={/^\d*\.?\d*$/}
                  />
                </div>
                <div className="w-6/12">
                  <Input
                    placeholder="Height"
                    className="ml-2"
                    type="text"
                    value={item?.height}
                    onChange={(e: any) => {
                      let data: any = [...values?.islands];
                      data[index]["height"] = Number(e?.target?.value);
                      handleChangeFunc("islands", data);
                    }}
                    onBlur={(e: any) => {
                      // handleBlur(e);
                      handleChangeFunc("islands", values?.islands);
                    }}
                    regex={/^\d*\.?\d*$/}
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default BottomFace;
