import { useContext, useEffect } from "react";
import RadioComponent from "components/RadioComponent";
import { FACE_OPTIONS, SET_FACE_DATA, SET_MACHINE_SETUP } from "constant";
import { useState } from "react";
import BottomFace from "./BottomFace";
import SideFace from "./SideFace";
import TopFace from "./TopFace";
import { GlobalContext } from "context/GlobalContext";

interface IFaceForm {
  feature_id?: string;
}

const FaceForm: React.FC<IFaceForm> = ({ feature_id }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { setupNumber, machiningSetups } = state;
  const [selectedFeature, setSelectedFeature] = useState<any>(null);

  useEffect(() => {
    if (setupNumber < 0 || !feature_id) return;
    const setupIdx = machiningSetups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );
    const feature_idx = machiningSetups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );
    const feature_data = {
      ...machiningSetups?.[setupIdx]?.features?.[feature_idx]?.featureInformation
        ?.feature_data,
    };
    setSelectedFeature(feature_data);
  }, [setupNumber, feature_id]);

  const handleFaceOption = (val: string) => {
    // dispatch({
    //   type: SET_FACE_DATA,
    //   payload: {
    //     index,
    //     key: 'type',
    //     value: val
    //   }
    // })
    let updated_setups = [...machiningSetups];

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );

    let updated_data = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]?.featureInformation
        ?.feature_data,
    };
    updated_data = {
      ...updated_data,
      feature_name: val,
      // When switching between only the common feature values will presist.
      // Additionally reseting the island_info.
      feature_info: {
        length: updated_data.feature_info?.length ?? 0,
        breadth: updated_data.feature_info?.breadth ?? 0,
        stock_on_face: updated_data.feature_info?.stock_on_face ?? 0,
        quantity: updated_data.feature_info?.quantity ?? 1,
        island_info: { islands: [] },
      },
    };

    setSelectedFeature(updated_data);

    updated_setups[setupIdx].features[
      feature_idx
    ].featureInformation.feature_data = updated_data;
    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });
  };

  return (
    <div>
      <RadioComponent
        options={FACE_OPTIONS}
        setValue={handleFaceOption}
        value={selectedFeature?.feature_name}
        labelClassName="!mr-[16px]"
      />
      {selectedFeature?.feature_name === "top_face" && (
        <TopFace
          feature_info={selectedFeature?.feature_info}
          feature_id={selectedFeature?.feature_id}
        />
      )}
      {selectedFeature?.feature_name === "bottom_face" && (
        <BottomFace
          feature_info={selectedFeature?.feature_info}
          feature_id={selectedFeature?.feature_id}
        />
      )}
      {selectedFeature?.feature_name === "side_face" && (
        <SideFace
          feature_info={selectedFeature?.feature_info}
          feature_id={selectedFeature?.feature_id}
        />
      )}
    </div>
  );
};

export default FaceForm;
