import LoadingImg from "assets/images/loading-icon.gif";

const LoadingBar = () => {
  return (
    <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-20 flex justify-center items-center z-20">
      <img src={LoadingImg} className="w-[150px]" />
    </div>
  );
};

export default LoadingBar;
