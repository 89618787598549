import { useContext, useEffect } from "react";
import Input from "components/Input";
import RadioComponent from "components/RadioComponent";
import {
  SET_CONTOUR_DATA,
  SET_MACHINE_SETUP,
  SLOT_OPEN_SIDES_OPTIONS,
  SLOT_FLOOR_TYPE_OPTIONS,
  SLOT_SURFACE_TYPE_OPTIONS,
  COUNTOUR_SLOT_TYPE_OPTIONS,
} from "constant";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GlobalContext } from "context/GlobalContext";
import Select from "components/Select";

interface ISlotForm {
  feature_info?: any;
  feature_id?: string;
}

const init_slot_contour = {
  open_sides: "",
  length: "",
  breadth: "",
  depth: "",
  floor_radius: "",
  corner_radius: "",
  // undercut: false,
  floor_type: "",
  surface_type: "",
  quantity: "",
  type: "",
};

const validationSchema = Yup.object().shape({});

const SlotForm: React.FC<ISlotForm> = ({ feature_info, feature_id }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { machiningSetups, projectInfo, setupNumber } = state;

  useEffect(() => {
    if (!feature_info) return;
    let formValues: any = {};
    Object.keys(init_slot_contour).forEach((key: string) => {
      formValues = {
        ...formValues,
        [key]: feature_info?.[key] ?? "",
      };
    });
    setValues(formValues);
  }, [feature_info]);

  const { handleChange, handleBlur, setValues, setFieldValue, values, errors, touched } =
    useFormik({
      initialValues: init_slot_contour,
      validationSchema,
      onSubmit: () => {},
    });

  // useEffect(() => {
  //   if (!contourList?.[index]) return;
  //   let formValues: any = {};
  //   Object.keys(init_slot_contour).forEach((key: string) => {
  //     formValues = {
  //       ...formValues,
  //       [key]: contourList[index][key],
  //     };
  //   });
  //   setValues(formValues);
  // }, [contourList, index]);

  const handleChangeFunc = (key: string, value: any, flag: boolean = false) => {
    if (flag) setFieldValue(key, value);

    let updated_setups = [...machiningSetups];

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );

    let updated_feature = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]?.featureInformation
        ?.feature_data?.feature_info,
    };

    switch (key) {
      case "type":
      case "surface_type":
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      case "floor_type":
        if (value === "Through") {
          delete updated_feature.floor_radius;
          setFieldValue("floor_radius", "");
        }
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      case "open_sides":
        if (value === "Both") {
          delete updated_feature.corner_radius;
        }
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      default:
        updated_feature = {
          ...updated_feature,
          [key]: Number(value),
        };
    }

    updated_setups[setupIdx].features[
      feature_idx
    ].featureInformation.feature_data.feature_info = updated_feature;

    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });
    // setFieldValue(key, value);
    // dispatch({
    //   type: SET_CONTOUR_DATA,
    //   payload: {
    //     index,
    //     key,
    //     value,
    //   },
    // });
    // dispatch({
    //   type: SET_CONTOUR_DATA,
    //   payload: {
    //     index,
    //     key: "parameter",
    //     value: null,
    //   },
    // });
    // dispatch({
    //   type: SET_CONTOUR_DATA,
    //   payload: {
    //     index,
    //     key: "selected_strategy",
    //     value: null,
    //   },
    // });
  };

  return (
    <div>
      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">
          Feature dimensions
        </p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              placeholder="Length"
              className="mr-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="length"
              value={values?.length}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("length", values?.length);
              }}
              invalid={touched.length && errors.length}
              helperText={touched.length && errors.length}
              regex={/^\d*\.?\d*$/}
            />
          </div>
          <div className="w-6/12">
            <Input
              placeholder="Breadth"
              className="ml-2"
              type="text"
              name="breadth"
              value={values?.breadth}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("breadth", values?.breadth);
              }}
              invalid={touched.breadth && errors.breadth}
              helperText={touched.breadth && errors.breadth}
              regex={/^\d*\.?\d*$/}
            />
          </div>
        </div>

        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              placeholder="Depth"
              className="mr-2"
              type="text"
              name="depth"
              value={values?.depth}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("depth", values?.depth);
              }}
              invalid={touched.depth && errors.depth}
              helperText={touched.depth && errors.depth}
              regex={/^\d*\.?\d*$/}
            />
          </div>
          <div className="w-6/12">
            <Select
              options={COUNTOUR_SLOT_TYPE_OPTIONS}
              placeholder="Type"
              className="ml-2"
              value={values.type}
              onChange={(val: any) => handleChangeFunc("type", val, true)}
              onBlur={handleBlur}
            />
          </div>
        </div>
      </div>

      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">Other details</p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              placeholder="Quantity"
              className="mr-2"
              type="text"
              name="quantity"
              value={values?.quantity}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("quantity", values?.quantity)
              }}
              invalid={touched.quantity && errors.quantity}
              helperText={touched.quantity && errors.quantity}
              regex={/^[1-9]\d*$/}
            />
          </div>
          <div className="flex justify-between items-center w-6/12 py-[14px]">
            <label className="font-semibold text-gray-475 truncate ml-2">
              Surface type
            </label>
            <RadioComponent
              options={SLOT_SURFACE_TYPE_OPTIONS}
              setValue={(val: any) =>
                handleChangeFunc("surface_type", val, true)
              }
              value={values?.surface_type}
            />
          </div>
        </div>

        <div className="flex flex-row mt-4">
          <div className="flex justify-between items-center w-6/12 py-[14px]">
            <label className="font-semibold text-gray-475 truncate mr-2">
              Open sides
            </label>
            <RadioComponent
              options={SLOT_OPEN_SIDES_OPTIONS}
              setValue={(val: any) => handleChangeFunc("open_sides", val, true)}
              value={values?.open_sides}
            />
          </div>
          {(values.open_sides !== "Both" && values.open_sides) && (
            <div className="w-6/12">
              <Input
                placeholder="Corner Radius"
                className="ml-2"
                type="text"
                name="corner_radius"
                value={values?.corner_radius}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc("corner_radius", values?.corner_radius)
                }}
                invalid={touched.corner_radius && errors.corner_radius}
                helperText={touched.corner_radius && errors.corner_radius}
                regex={/^\d*\.?\d*$/}
              />
            </div>
          )}
        </div>

        <div className="flex flex-row mt-4">
          <div className="flex justify-between items-center w-6/12 py-[14px]">
            <label className="font-semibold text-gray-475 truncate mr-2">
              Floor type
            </label>
            <RadioComponent
              options={SLOT_FLOOR_TYPE_OPTIONS}
              setValue={(val: any) => handleChangeFunc("floor_type", val, true)}
              value={values?.floor_type}
            />
          </div>
          {(values.floor_type === "Solid" && values.floor_type) && (
            <div className="w-6/12">
              <Input
                placeholder="Floor Radius"
                className="ml-2"
                type="text"
                name="floor_radius"
                value={values?.floor_radius}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc("floor_radius", values?.floor_radius);
                }}
                invalid={touched.floor_radius && errors.floor_radius}
                helperText={touched.floor_radius && errors.floor_radius}
                regex={/^\d*\.?\d*$/}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SlotForm;
