import Button from "components/Button";
import Input from "components/Input";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import LogoImg from "assets/images/logo.svg";
import SelectAutoComplete from "components/SelectAutoComplete";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "context/GlobalContext";
import { SET_REGISTER_FORM } from "constant";
import Geosuggest from "react-geosuggest";
import { useSelectBillToSuggestion } from "hooks";
import clsx from "clsx";

const validationSchema = Yup.object().shape({
  streetAddress: Yup.string().required("This field is required"),
  city: Yup.string().required("This field is required"),
  stateOf: Yup.string().required("This field is required"),
  zipCode: Yup.string().required("This field is required"),
});

const industryOptions = [
  { label: "Aerospace", value: "Aerospace" },
  { label: "Automotive", value: "Automotive" },
  { label: "Defense", value: "Defense" },
  { label: "Energy", value: "Energy" },
  { label: "Semiconductor", value: "Semiconductor" },
  { label: "Other Manufacturing", value: "Other Manufacturing" },
];

const Register2 = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(GlobalContext);
  const { registerForm } = state;
  const [suggestAddress, setSuggestAddress] = useState("");

  useEffect(() => {
    if (!registerForm || !Object.keys(registerForm)?.length)
      navigate("/signup");
  }, [registerForm]);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    values,
    errors,
    touched,
    isValid,
    dirty,
    validateField,
  } = useFormik({
    initialValues: {
      industry: [],
      streetAddress: "",
      streetAddressLine2: "",
      city: "",
      stateOf: "",
      zipCode: "",
      country: "US",
    },
    validationSchema,
    onSubmit: () => {
      dispatch({
        type: SET_REGISTER_FORM,
        payload: { ...registerForm, ...values },
      });
      navigate("/set-password");
    },
  });

  const userBillingAddress = useSelectBillToSuggestion(suggestAddress);

  useEffect(() => {
    if (!!userBillingAddress?.city?.length) setFieldTouched("city", true);
    setFieldValue("city", userBillingAddress?.city || "");

    if (!!userBillingAddress?.zipCode?.length) setFieldTouched("zipCode", true);
    setFieldValue("zipCode", userBillingAddress?.zipCode || "");

    if (!!userBillingAddress?.stateOf?.length) setFieldTouched("stateOf", true);
    setFieldValue("stateOf", userBillingAddress?.stateOf || "");

    if (!!userBillingAddress?.streetAddress?.length)
      setFieldTouched("streetAddress", true);
    setFieldValue("streetAddress", userBillingAddress?.streetAddress || "");

    if (!!userBillingAddress?.streetAddressLine2?.length)
      setFieldTouched("streetAddressLine2", true);
    setFieldValue(
      "streetAddressLine2",
      userBillingAddress?.streetAddressLine2 || ""
    );

    if (!!userBillingAddress)
      setTimeout(() => {
        validateField("city");
        validateField("zipCode");
        validateField("stateOf");
        validateField("streetAddress");
        validateField("streetAddressLine2");
      }, 1);
  }, [userBillingAddress]);

  return (
    <div className="flex items-center justify-center w-full h-full relative">
      <div className="fixed top-0 right-0 left-0 bottom-0 bg-auth-background w-full h-full bg-cover "></div>
      <div className="fixed top-0 right-0 left-0 bottom-0 splash-background"></div>
      <div className="w-6/12 h-full flex flex-col justify-between px-5 py-10 z-[1]">
        <img src={LogoImg} alt="logo" className="max-w-[180px] h-fit" />
        <div className="flex flex-col">
          <p className="text-surface-default font-semibold text-lg">
            Welcome to
          </p>
          <p className="text-black font-bold text-[40px] mb-6">
            Generative Programming
            <span className="text-[10px] text-black-222">(Beta v-1.0.0)</span>
          </p>
          <p className="text-gray-494 font-medium">
            A tool that brings the power of artificial intelligence to CNC
            programmers by recommending machining strategies, optimal cutting
            tools & machining parameters, and more based on the specific
            features in your CAD files.
          </p>
        </div>
        <div className="flex flex-row justify-between items-center">
          <span className="text-gray-424 text-xs font-semibold">
            2024 © Lambda Function, Inc.
          </span>
          <span className="text-gray-424 text-xs font-semibold">
            Beta v-1.0.0
          </span>
        </div>
      </div>
      <div className="w-6/12 h-full flex justify-center relative pt-10 pb-16">
        <div className="w-full h-full overflow-auto px-5 flex justify-center items-center">
          <div className="flex flex-col max-w-[50vw] p-px my-auto h-fit w-8/12 min-w-[350px]">
            <div className="mb-[30px]">
              <p className="font-semibold text-[27px] text-black mb-1">
                Almost ready to get started.
              </p>
              <div className="rounded bg-surface-default w-[48px] h-1"></div>
            </div>
            <form onSubmit={handleSubmit}>
              <SelectAutoComplete
                label="Industry Type"
                options={industryOptions}
                className="mb-2"
                onChange={(value: any) => {
                  setFieldValue("industry", value);
                }}
                onBlur={handleBlur}
                value={values?.industry}
              />
              <div className="mb-2">
                <p className="mb-1.5 text-gray-696 font-semibold text-sm">
                  Street Address
                </p>
                <Geosuggest
                  placeholder="Street Address"
                  value={values?.streetAddress}
                  initialValue={values?.streetAddress}
                  inputClassName={clsx(
                    "font-medium text-sm w-full outline-none !shadow-form-input !bg-white !rounded-xl relative px-5 py-[14px] input-field h-[52px]"
                  )}
                  onSuggestSelect={(suggestion: any) => {
                    setSuggestAddress(suggestion?.label);
                  }}
                  name="streetAddress"
                  id="streetAddress"
                />
              </div>
              <Input
                label="Address Line 2"
                name="streetAddressLine2"
                className="min-w-[350px] mb-2"
                subClassName="!shadow-form-input !bg-white !rounded-xl"
                inputClassName="bg-transparent"
                value={values?.streetAddressLine2}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={
                  touched.streetAddressLine2 && errors.streetAddressLine2
                }
                helperText={
                  touched.streetAddressLine2 && errors.streetAddressLine2
                }
              />
              <Input
                label="City"
                name="city"
                className="min-w-[350px] mb-2"
                subClassName="!shadow-form-input !bg-white !rounded-xl"
                inputClassName="bg-transparent"
                value={values?.city}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={touched.city && errors.city}
                helperText={touched.city && errors.city}
              />
              <div className="flex flex-row space-x-3 mb-4">
                <Input
                  label="State"
                  name="stateOf"
                  className="w-6/12 h-full mb-2"
                  subClassName="!shadow-form-input !bg-white !rounded-xl"
                  inputClassName="bg-transparent"
                  value={values?.stateOf}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.stateOf && errors.stateOf}
                  helperText={touched.stateOf && errors.stateOf}
                />
                <Input
                  label="Zip code"
                  name="zipCode"
                  className="w-6/12 h-full mb-2"
                  subClassName="!shadow-form-input !bg-white !rounded-xl"
                  inputClassName="bg-transparent"
                  value={values?.zipCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.zipCode && errors.zipCode}
                  helperText={touched.zipCode && errors.zipCode}
                />
              </div>
              <Button
                className="w-full rounded-xl text-lg py-4 font-semibold mb-[10px]"
                type="submit"
                disabled={!isValid}
              >
                Get started
              </Button>
              <p className="font-semibold text-gray-696 text-sm text-center">
                Already have an account?{" "}
                <Link to="/login" className="text-blue-033">
                  Login
                </Link>
              </p>
            </form>
          </div>
        </div>
        <p className="text-gray-696 font-semibold text-xs fixed w-6/12 text-center bottom-[40px]">
          Issues with logging in or signing up?{" "}
          <a
            href="mailto:info@lambdafunction.ai"
            className="text-surface-default underline"
          >
            We're here to help!
          </a>
        </p>
      </div>
    </div>
  );
};

export default Register2;
