import React, { ReactNode } from "react";
import Footer from "components/Footer";
import TopNavigation from "components/TopNavigation/TopNavigation";

interface IDefaultLayout {
  children: ReactNode;
  noLayout?: boolean;
}

const DefaultLayout: React.FC<IDefaultLayout> = ({
  children,
  noLayout = false,
}) => {
  return (
    <div className="w-screen h-screen relative">
      {noLayout ? (
        <div className="h-full">{children}</div>
      ) : (
        <div className="w-full h-full fixed top-0 left-0 right-0">
          <TopNavigation />
          <div className="h-[calc(100%-136px)]">{children}</div>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default DefaultLayout;
