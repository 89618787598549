import { Auth } from "aws-amplify";
import LoadingBar from "components/LoadingBar";
import { SET_USER_INFO } from "constant";
import { GlobalContext } from "context/GlobalContext";
import { Login } from "pages/auth";
import ProjectList from "pages/projectList";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

export const RequiredAuth: React.FC = () => {
  const { dispatch } = useContext(GlobalContext);
  const [isAuth, setIsAuth] = useState(false);
  const loadingRef = useRef(true);
  const navigate = useNavigate();

  const checkAuth = async () => {
    try {
      loadingRef.current = true;
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      
      if (authenticatedUser) {
        loadingRef.current = false;
        setIsAuth(true);
        dispatch({
          type: SET_USER_INFO,
          payload: authenticatedUser?.attributes,
        });
      } else {
        loadingRef.current = false;
        setIsAuth(false);
        navigate("/login");
      }
    } catch (err) {
      loadingRef.current = false;
      setIsAuth(false);
      navigate("/login");
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  if (loadingRef?.current) {
    return <LoadingBar />;
  }

  if (isAuth) {
    return (
      <>
        <Outlet />
      </>
    );
  }

  return <Login />;
};

export const UnRequiredAuth: React.FC = () => {
  const { dispatch } = useContext(GlobalContext);
  const [isAuth, setIsAuth] = useState(false);
  const navigate = useNavigate();

  const checkAuth = async () => {
    try {
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      if (authenticatedUser) {
        navigate("/projects");
        setIsAuth(true);
        dispatch({
          type: SET_USER_INFO,
          payload: authenticatedUser?.attributes,
        });
      } else {
        setIsAuth(false);
      }
    } catch (err) {
      setIsAuth(false);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  if (!isAuth) {
    return (
      <>
        <Outlet />
      </>
    );
  }

  return <ProjectList />;
};
