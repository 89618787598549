import React from 'react'
import YellowLogoSmall from 'assets/images/logo/blue-logo-small.svg'

const Footer = () => {
  return (
    <div className="flex flex-row justify-between items-center px-4 py-[6px] fixed bottom-0 left-0 right-0">
      <div className="flex flex-row">
        <span className="text-xs">Powered By</span>
        <img src={YellowLogoSmall} alt="logo" className="mx-1" />
        <span className="text-xs text-surface-default font-semibold">Lambda Function, Inc.</span>
      </div>
      <div className="flex flex-row">
        <span className="text-xs mr-1">Any feedback or suggestions? </span>
        <span className="text-xs text-surface-default font-semibold">Please share</span>
      </div>
    </div>
  )
}

export default Footer
