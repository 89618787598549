import clsx from "clsx";
import Chart from "react-apexcharts";

interface IScatterChart {
  className?: string;
  seriesData?: any;
  redPoint?: any;
}

const initOptions = {
  chart: {
    type: "scatter",
    zoom: {
      enabled: true,
      type: "xy",
    },
    toolbar: {
      show: true,
      tools: {
        zoomin: false,
        zoomout: false,
        pan: false,
        reset: true,
        download: false,
        selection: false,
      },
    },
    width: "100%",
    animations: {
      enabled: false,
    },
  },
  markers: {
    strokeWidth: [0, 1],
    size: [2, 4],
    colors: ["#1570EF", "#FF0000"],
  },
  xaxis: {
    tickAmount: 3,
    labels: {
      formatter: function (val: string) {
        return parseFloat(val).toFixed(1);
      },
      style: {
        marginTop: "9px",
        fontSize: "8px",
      },
    },
    title: {
      text: "Tool Life (min)",
    },
    tooltip: {
      enabled: false,
    },
    tickPlacement: "on",
    type: "number",
  },
  yaxis: {
    tickAmount: 3,
    labels: {
      formatter: function (val: string) {
        return parseFloat(val).toFixed(1);
      },
      style: {
        fontSize: "8px",
      },
    },
    title: {
      text: "MRR (mm^3/min)",
    },
    tickPlacement: "between"
  },
  tooltip: {
    enabled: true,
    custom({ series, seriesIndex, dataPointIndex, w }: any) {
      return (
        `<div style="width: 100%; height: 100%; border-radius: 4px; background: #1570EF; padding: 10px; display: flex; justify-content: center; align-items: center; flex-direction: column">
        ${seriesIndex === 1 ? "<p style='color: white;font-size: 12px;font-weight: 600;margin-bottom: 5px;'>Optimal Point</p>":""}
        <p style='color: white;font-size: 10px'>Tool Life : ${Number(w?.globals?.seriesX?.[seriesIndex]?.[dataPointIndex])?.toFixed(2) || "***"}</p>
        <p style='color: white;font-size: 10px'>MRR : ${Number(series[seriesIndex]?.[dataPointIndex])?.toFixed(2) || "***"}</p>
        </div>`
      );
    },
  },
  legend: {
    show: false,
  },
};

const ScatterChart: React.FC<IScatterChart> = ({
  className,
  seriesData,
  redPoint,
}) => {
  let options = {
    ...initOptions,
    series: [
      {
        name: "Tool Life",
        data: [...seriesData] || [],
      },
      {
        name: "Tool Life",
        data: [...redPoint] || [],
      },
    ],
  };

  return (
    <div className={clsx("w-full h-full", className)}>
      <Chart
        options={options as any}
        series={options?.series as any}
        type="scatter"
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default ScatterChart;
