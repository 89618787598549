import { useState, useEffect } from "react";
import Modal from "react-modal";
import ClossInValidPopUp from "assets/images/icons/charm_cross.svg";
import ConfirmPopUp from "./ConfirmPopUp";
import InvalidSwitch from "components/InvalidSwitch";

interface InvalidOperationsPopUp {
  strategyInd?: any;
  showPopUp?: any;
  closePopUp?: any;
  setInValid?: any;
  strategy?: any;
}

const InvalidOperationsPopUp: React.FC<InvalidOperationsPopUp> = ({
  strategyInd,
  closePopUp,
  showPopUp,
  setInValid,
  strategy,
}) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      padding: 0,
      marginRight: "-50%",
      borderRadius: "16px",
      transform: "translate(-50%, -50%)",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 999,
    },
  };
  const [showConfirmPopUp, setShowConfirmPopUp] = useState(false);
  const [localStrategy, setLocalStrategy] = useState(
    JSON.parse(JSON.stringify(strategy))
  );

  const openConfirmPopUp = () => {
    let hasInvalidOperation = false;
    localStrategy?.passes?.forEach((pass: any, idx: number) => {
      pass?.operations?.forEach((operation: any, idx1: number) => {
        if (
          strategy?.passes?.[idx]?.operations?.[idx1]?.isValid &&
          operation?.isValid === false
        ) {
          hasInvalidOperation = true;
        }
      });
    });

    return hasInvalidOperation;
  };

  const confirmInvalidStatus = () => {
    let localStrategyCopy = { ...localStrategy };
    let check = true;
    localStrategyCopy?.passes?.forEach((pass: any) => {
      pass?.operations?.forEach((operation: any) => {
        if (!operation?.isValid) {
          check = false;
        }
      });
    });
    localStrategyCopy.isValid = check;
    setInValid({ ...localStrategyCopy }, strategyInd);
    closePopUp();
  }; 

  useEffect(() => {
    setLocalStrategy(JSON.parse(JSON.stringify(strategy)));
  }, [showPopUp]);
  return (
    <Modal
      isOpen={showPopUp}
      style={customStyles as Modal.Styles}
      onRequestClose={closePopUp}
    >
      <div>
        <div className="flex flex-row justify-between border-b border-gray-300 p-[16px] sticky top-0 bg-white">
          <div className="font-[500] text-[20px] leading-[24px]">Invalid</div>
          <button onClick={closePopUp}>
            <img src={ClossInValidPopUp} alt="cross" />
          </button>
        </div>
        <div className="p-[16px] max-h-[70vh] overflow-y-auto">
          <div className="rounded-[12px] border border-gray-200 w-full overflow-hidden">
          <table>
            <thead>
              <tr className="text-[12px] leading-[18px] text-gray-600 border-b">
                <th colSpan={2} className="px-[24px] py-[12px] text-left font-medium">
                  Operations
                </th>
                <th className="font-medium">Validity</th>
                <th className="font-medium" colSpan={2}>Reason</th>
              </tr>
            </thead>
            <tbody>
              {localStrategy?.passes?.map((element: any, idx: number) =>
                element?.operations?.map((operation: any, idx1: number) => {
                  return (
                    <tr className="border-b border-gray-200 bg-gray-50">
                      <td colSpan={2}>
                        <div className="flex flex-col py-[12px] px-[24px]">
                          <div className="font-[600] text-[14px] leading-lg">{`${
                            operation?.tool_paths?.[0]?.tool_path_type ||
                            operation?.tool_paths?.[0]?.type ||
                            ""
                          } - ${
                            operation?.tool_paths?.[0]?.tool_path ||
                            operation?.tool_paths?.[0]?.name ||
                            ""
                          }`}</div>
                          <div className="font-[400] text-[12px] leading-lg">
                            {operation?.operation} ({operation?.location})
                          </div>
                        </div>
                      </td>
                      <td className="text-center">
                        <InvalidSwitch
                          initialChecked={
                            !localStrategy?.passes[idx]?.operations[idx1]
                              ?.isValid
                          }
                          handleChange={(e: any) => {
                            let lStrat = { ...localStrategy };
                            if (e.target.checked) {
                              lStrat.passes[idx].operations[idx1].isValid =
                                false;
                              lStrat.isValid = false;
                              setLocalStrategy({ ...lStrat });
                            } else {
                              lStrat.passes[idx].operations[idx1].isValid =
                                true;
                              lStrat.passes[idx].operations[idx1].feedback = "";
                              setLocalStrategy({ ...lStrat });
                            }
                          }}
                        />
                      </td>
                      <td className="p-[16px]">
                        <textarea
                          className="bg-white rounded-[8px] h-[100%] w-[240px] text-[12px] 
                            resize-none overflow-hidden p-[8px] focus:outline-none"
                          rows={3}
                          maxLength={150}
                          disabled={
                            localStrategy?.passes[idx]?.operations[idx1]
                              ?.isValid
                          }
                          value={
                            localStrategy?.passes[idx]?.operations[idx1]
                              ?.feedback
                          }
                          placeholder="Prefer boring operation for deeper holes"
                          onChange={(e) => {
                            let lStrat = { ...localStrategy };
                            lStrat.passes[idx].operations[idx1].feedback =
                              e.target.value;
                            setLocalStrategy({ ...lStrat });
                          }}
                        />
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          </div>
        </div>
        <div className="flex border-t border-gray-200 p-[16px] justify-center gap-4">
          <button
            className="px-[16px] py-[6px] rounded-[14px] w-[150px] bg-gray-200 text-gray-700 font-bold
            text-[14px]"
            onClick={() => {
              closePopUp();
            }}
          >
            Cancel
          </button>
          <button
            className="px-[16px] py-[6px] rounded-[14px] w-[150px] bg-blue-600 text-white font-bold
            text-[14px]"
            onClick={() => {
              if (openConfirmPopUp()) {
                setShowConfirmPopUp(true);
              } else {
                confirmInvalidStatus();
              }
            }}
          >
            Save
          </button>
        </div>
        <ConfirmPopUp
          showConfirmPopUp={showConfirmPopUp}
          closeConfirmPopUp={() => setShowConfirmPopUp(false)}
          confirmFunction={confirmInvalidStatus}
        />
      </div>
    </Modal>
  );
};

export default InvalidOperationsPopUp;
