import LogoImg from "assets/images/logo.svg";
import { useContext, useState } from "react";
import OtpInput from "react-otp-input";
import "../input.css";
import Button from "components/Button";
import { GlobalContext } from "context/GlobalContext";
import { Auth } from "aws-amplify";
import Input from "components/Input";
import { useNavigate } from "react-router-dom";
import { SET_LOADING_BAR, SET_USER_INFO } from "constant";

const VerifyCode = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { registerForm } = state;
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [verifyEmail, setVerifyEmail] = useState("");

  const handleConfirm = async () => {
    try {
      dispatch({
        type: SET_LOADING_BAR,
        payload: true,
      });
      const response = await Auth.confirmSignUp(
        registerForm?.adminEmail?.toLowerCase() || verifyEmail?.toLowerCase(),
        otp
      );
      if (response?.toLowerCase() !== "success") {
        dispatch({
          type: SET_LOADING_BAR,
          payload: false,
        });
        return;
      }
      if (registerForm?.password) {
        const signInRes = await Auth.signIn(
          registerForm?.adminEmail?.toLowerCase() || verifyEmail?.toLowerCase(),
          registerForm?.password
        );
        dispatch({
          type: SET_USER_INFO,
          payload: signInRes?.attributes,
        });
        dispatch({
          type: SET_LOADING_BAR,
          payload: false,
        });
        navigate("/projects");
      } else {
        dispatch({
          type: SET_LOADING_BAR,
          payload: false,
        });
        navigate("/signup-success");
      }
    } catch (err) {
      // console.log(err);
      dispatch({
        type: SET_LOADING_BAR,
        payload: false,
      });
    }
  };

  return (
    <div className="flex items-center justify-center w-full h-full relative">
      <div className="fixed top-0 right-0 left-0 bottom-0 bg-auth-background w-full h-full bg-cover "></div>
      <div className="fixed top-0 right-0 left-0 bottom-0 splash-background"></div>
      <div className="w-6/12 h-full flex flex-col justify-between px-5 py-10 z-[1]">
        <img src={LogoImg} alt="logo" className="max-w-[180px] h-fit" />
        <div className="flex flex-col">
          <p className="text-surface-default font-semibold text-lg">
            Welcome to
          </p>
          <p className="text-black font-bold text-[40px] mb-6">
            Generative Programming
            <span className="text-[10px] text-black-222">(Beta v-1.0.0)</span>
          </p>
          <p className="text-gray-494 font-medium">
            A tool that brings the power of artificial intelligence to CNC
            programmers by recommending machining strategies, optimal cutting
            tools & machining parameters, and more based on the specific
            features in your CAD files.
          </p>
        </div>
        <div className="flex flex-row justify-between items-center">
          <span className="text-gray-424 text-xs font-semibold">
            2024 © Lambda Function, Inc.
          </span>
          <span className="text-gray-424 text-xs font-semibold">
            Beta v-1.0.0
          </span>
        </div>
      </div>
      <div className="w-6/12 h-full flex justify-center relative pt-10 pb-16">
        <div className="w-full h-full overflow-auto px-5 flex justify-center items-center">
          <div className="flex flex-col max-w-[50vw] p-px my-auto h-fit w-8/12 min-w-[350px]">
            <div className="mb-4">
              <p className="font-semibold text-[27px] text-black mb-1">
                Verify email
              </p>
              <div className="rounded bg-surface-default w-[48px] h-1"></div>
            </div>
            <p className="font-semibold text-gray-696 text-sm mb-4 max-w-[420px]">
              A verification code has been sent to you. Enter code below
            </p>
            {!registerForm?.adminEmail && (
              <Input
                label="Verify Email"
                type="email"
                className="min-w-[350px] mb-4"
                subClassName="!shadow-form-input !bg-white !rounded-xl"
                inputClassName="bg-transparent"
                value={verifyEmail}
                onChange={(e: any) => setVerifyEmail(e.target.value)}
              />
            )}
            <div className="flex flex-row space-x-[10px] mb-4 otp">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            <Button
              className="w-full rounded-xl text-lg py-4 font-semibold mb-[10px]"
              disabled={otp?.length < 6}
              onClick={handleConfirm}
            >
              Verify Email
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyCode;
