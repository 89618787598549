import { convertKeyToLabel } from "utils";
export const SUMMARY_VIEW: any = {
  hole: {
    open_hole: [
      { key: "diameter", label: "Diameter" },
      { key: "depth", label: "Depth" },
      { key: "quantity", label: "Quantity" },
      { key: "bottom_type", label: "Bottom Type" },
      { key: "bottom", label: "Bottom" },
      { key: "bottom_radius", label: "Bottom Radius" },
      { key: "bottom_angle", label: "Bottom Angle" },
      { key: "deburring", label: "Deburring" },
      { key: "additional_length", label: "Top Distance" },
    ],
    finish_hole: [
      { key: "diameter", label: "Diameter" },
      { key: "depth", label: "Depth" },
      { key: "quantity", label: "Quantity" },
      { key: "bottom_type", label: "Bottom Type" },
      { key: "bottom", label: "Bottom" },
      { key: "bottom_radius", label: "Bottom Radius" },
      { key: "bottom_angle", label: "Bottom Angle" },
      { key: "deburring", label: "Deburring" },
      { key: "additional_length", label: "Top Distance" },
      { key: "finish_type", label: "Finish Type" },
    ],
  },

  thread_hole: {
    thread_hole: [
      { key: "thread_type", label: "Thread type" },
      { key: "thread_size", label: "Thread size" },
      { key: "thread_pitch", label: "Thread pitch" },
      { key: "thread_depth", label: "Thread depth" },
      { key: "depth", label: "Depth" },
      { key: "bottom_type", label: "Bottom Type" },
      { key: "bottom", label: "Bottom" },
      { key: "bottom_radius", label: "Bottom Radius" },
      { key: "bottom_angle", label: "Bottom Angle" },
      { key: "deburring", label: "Deburring" },
      { key: "additional_length", label: "Additional Lenght" },
    ],
  },

  face: {
    top_face: [
      { key: "length", label: "Length" },
      { key: "breadth", label: "Breadth" },
      { key: "stock_on_face", label: "Stock on Face" },
    ],
    bottom_face: [
      { key: "length", label: "Length" },
      { key: "breadth", label: "Breadth" },
      { key: "stock_on_face", label: "Stock on Face" },
      {
        key: "min_distance_between_islands",
        label: "Min Distance between Islands",
      },
    ],
    side_face: [
      { key: "length", label: "Length" },
      { key: "breadth", label: "Breadth" },
      { key: "stock_on_face", label: "Stock on Face" },
      { key: "depth", label: "Depth" },
      { key: "floor_type", label: "Floor Type" },
      { key: "floor_radius", label: "Floor Radius" },
    ],
  },

  pocket: {
    pocket_2d: [
      { key: "length", label: "Length" },
      { key: "breadth", label: "Breadth" },
      { key: "depth", label: "Depth" },
      { key: "pocket_type", label: "Pocket Type" },
      { key: "walls", label: "Walls" },
      { key: "corner_radius", label: "Corner Radius" },
      { key: "min_distance", label: "Min Distance" },
      { key: "floor_type", label: "Floor Type" },
      { key: "floor_radius", label: "Floor Radius" },
      {
        key: "min_distance_between_islands",
        label: "Min Distance between Islands",
      },
    ],
    pocket_3d: [
      // { key: "pocketLength", label: "Length" },
      // { key: "pocketBreadth", label: "Breadth" },
      // { key: "wallHeight", label: "Height" },
      // { key: "wallThickness", label: "Wall Thickness" },
      // { key: "wallAngleType", label: "Wall Angle Type" },
      // { key: "bottomType", label: "Bottom Type" },
      // { key: "groundRadius", label: "Ground Radius" },
      // { key: "sideRadius", label: "Side Radius" },
      // { key: "reliefHoleDiameter", label: "Relief Hole Diameter" },
      // { key: "reliefHoleDepth", label: "Relief Hole Depth" },
      // { key: "isLandLength", label: "Land Length" },
      // { key: "isLandBreadth", label: "Land Breadth" },
      // { key: "isLandHeight", label: "Land Height" },
      // { key: "minXDistanceToIsLand", label: "Min X Distance To IsLand" },
      // { key: "minYDistanceToIsLand", label: "Min Y Distance To IsLand" },
      // { key: "groundRadiusWithIsLand", label: "Ground Radius With IsLand" },
      // { key: "corneringOperation", label: "Cornering Operation" },
      // { key: "curlingOperation", label: "Curling Operation" },
    ]
  },

  contour: {
    Fillet: [
      { key: "length", label: "Length" },
      { key: "depth", label: "Depth" },
      { key: "radius", label: "Radius" },
      { key: "location", label: "Location" },
      { key: "fillet_type", label: "Type" },
      { key: "floor_type", label: "Floor Type" },
      { key: "floor_radius", label: "Floor Radius" },
    ],
    Slot: [
      { key: "length", label: "Length" },
      { key: "breadth", label: "Breadth" },
      { key: "depth", label: "Depth" },
      { key: "floor_radius", label: "Floor Radius" },
      { key: "open_sides", label: "Open Sides" },
      { key: "corner_radius", label: "Corner Radius" }
    ],
    Chamfer: [
      { key: "length", label: "Length" },
      { key: "breadth", label: "Breadth" },
      { key: "angle", label: "Angle" },
      { key: "chamfer_type", label: "Type" },
      { key: "location", label: "Location" },
      { key: "corner_radius", label: "Corner Radius" },
      { key: "vertical_edges", label: "Vertical Edges" },
      { key: "distance", label: "Distance" },
    ]
  },

  core_cavity: {
    core_cavity: [{ key: "count", label: "Count" }],
    data: [
      { key: "bottomLength", label: "Bottom Length" },
      { key: "bottomBreadth", label: "Bottom Breadth" },
      { key: "bottomDepth", label: "Bottom Depth" },
      { key: "bottomType", label: "Bottom Type" },
      { key: "groundRadius", label: "Ground Radius" },
      { key: "sideRadius", label: "Side Radius" },
    ]
  },

  pencil: {
    pencil: [
      { key: "pencilDiameter", label: "Diameter" },
      { key: "cornerRadius", label: "Corner Radius" },
      { key: "stockLeft", label: "Stock Left" },
      { key: "groundRadius", label: "Ground Radius" },
      { key: "sideRadius", label: "Side Radius" },
      { key: "distance", label: "Distance" },
      { key: "wallTangent", label: "Wall Tangent" },
      { key: "wallAngleType", label: "wall Angle Type" },
    ]
  }
};


const HOLE_LABELS = [
  { key: "diameter", label: "Diameter" },
  { key: "depth", label: "Depth" },{ key: "quantity", label: "Quantity" },
  { key: "bottom_type", label: "Bottom Type" },{ key: "deburring", label: "Deburring" },
  { key: "additional_length", label: "Top Distance" }
]


const THREADHOLE_LABELS = [
  { key: "thread_type", label: "Thread type" },
  { key: "thread_size", label: "Thread size" },
  { key: "thread_pitch", label: "Thread pitch" },
  { key: "thread_depth", label: "Thread depth" },
  { key: "depth", label: "Depth" },
  { key: "additional_length", label: "Top Distance" },
  { key: "deburring", label: "Deburring" },
  { key: "bottom_type", label: "Bottom Type" }
];

const FACE_LABELS = [
  { key: "length", label: "Length" },
  { key: "breadth", label: "Breadth" },
  { key: "stock_on_face", label: "Stock on Face" }
];

const POCKET_LABELS = [
  { key: "length", label: "Length" },
  { key: "breadth", label: "Breadth" },
  { key: "depth", label: "Depth" },
  { key: "pocket_type", label: "Pocket Type" },
  { key: "walls", label: "Walls" },
  { key: "floor_type", label: "Floor Type" },
]

const CONTOUR_LABELS = {
  fillet: [
    { key: "length", label: "Length" },
    { key: "depth", label: "Depth" },
    { key: "radius", label: "Radius" },
    { key: "location", label: "Location" },
    { key: "fillet_type", label: "Type" },
    
  ],
  slot: [
    { key: "length", label: "Length" },
    { key: "breadth", label: "Breadth" },
    { key: "depth", label: "Depth" },
    { key: "floor_radius", label: "Floor Radius" },
    { key: "open_sides", label: "Open Sides" },
    { key: "corner_radius", label: "Corner Radius" }
  ],
  chamfer: [
    { key: "length", label: "Length" },
    { key: "breadth", label: "Breadth" },
    { key: "angle", label: "Angle" },
    { key: "chamfer_type", label: "Type" },
    { key: "vertical_edges", label: "Vertical Edges" },
    { key: "location", label: "Location" },
  ]
}

export const ISLAND_LABELS: any = {
  length: "Length",
  breadth: "Breadth",
  floor_radius: "Floor Radius",
  height: "Height",
  min_x_distance_to_face_edges: "Min x distance to face edges",
  min_y_distance_to_face_edges: "Min y distance to face edges",
};


export function makeLabels(type: string, featureObject: any, subType: string) {
  let labels: any = [];

  switch (type) {
    case "hole":
    case "thread_hole":
    case "contour":
      Object.keys(featureObject).forEach((key) => {
        labels.push({ key: key, label: convertKeyToLabel(key) });
      });
      break;
    case "face":
    case "pocket":
      Object.keys(featureObject).forEach((key) => {
        if (key !== "island_info")
          labels.push({ key: key, label: convertKeyToLabel(key) });
      });
      if (subType === "bottom_face" || type === "pocket") {
        labels.push({ key: "island_info", label: "Islands" });
      }
      break;
    default:
      break;
  }
  return labels;
}