import { useState, useContext, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "components/Input";
import RadioComponent from "components/RadioComponent";
import {
  HOLE_BOTTOM_TYPE_OPTIONS,
  HOLE_BOTTOM_OPTIONS,
  CONFIRM_OPTIONS,
  THREAD_PICTH_TYPE_OPTIONS,
  SET_THREAD_HOLE_DATA,
  THREAD_SIZE_OPTIONS,
  SET_MACHINE_SETUP,
} from "constant";
import { GlobalContext } from "context/GlobalContext";
import Select from "components/Select";

interface IThreadHoleForm {
  feature_id?: string;
}

const init_thread_hole = {
  thread_type: "coarse",
  thread_size: "",
  thread_pitch: "",
  thread_depth: "",
  depth: "",

  bottom_type: "",
  bottom: "",
  bottom_radius: "",
  bottom_angle: "",

  deburring: true,
  additional_length: 0,
  quantity: 1,
};

const validationSchema = Yup.object().shape({
  diameter: Yup.number()
    .required("This field is required")
    .min(0, "This needs over 0"),
  depth: Yup.number()
    .required("This field is required")
    .min(0, "This needs over 0"),
});

const ThreadHoleForm: React.FC<IThreadHoleForm> = ({ feature_id }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { threadHoleList, projectInfo, setupNumber, machiningSetups } = state;
  const [nearestWallDistance, setNearestWallDistance] = useState(false);

  useEffect(() => {
    if (setupNumber < 0 || !feature_id) return;
    const setupIdx = machiningSetups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );
    const feature_idx = machiningSetups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );

    const feature_info = {
      ...machiningSetups?.[setupIdx]?.features?.[feature_idx]?.featureInformation
        ?.feature_data?.feature_info,
    };

    let formValues: any = {};
    Object.keys(init_thread_hole).forEach((key: string) => {
      if (key === "deburring") {
        formValues = {
          ...formValues,
          [key]: feature_info?.deburring === "yes",
        };
        return;
      }
      formValues = {
        ...formValues,
        [key]: feature_info?.[key] ?? "",
      };
    });
    setValues(formValues);
  }, [setupNumber, feature_id]);

  const {
    handleChange,
    handleBlur,
    setValues,
    setFieldValue,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: init_thread_hole,
    validationSchema,
    onSubmit: () => {},
  });

  // useEffect(() => {
  //   if (!threadHoleList?.[index]) return;
  //   let formValues: any = {};
  //   Object.keys(init_thread_hole).forEach((key: string) => {
  //     formValues = {
  //       ...formValues,
  //       [key]: threadHoleList[index][key],
  //     };
  //   });
  //   setValues(formValues);
  // }, [threadHoleList, index]);

  // useEffect(() => {
  //   if (!!values?.thread_size && values?.thread_type === "coarse") {
  //     const item = THREAD_PITCH_LIST.find(
  //       (pitch) =>
  //         pitch.threadSize?.toString() === values?.thread_size?.toString()
  //     );
  //     setFieldValue("thread_pitch", item?.coarse);
  //   } else if (!!values?.thread_size && values?.thread_type === "fine") {
  //     const item = THREAD_PITCH_LIST.find(
  //       (pitch) =>
  //         pitch.threadSize?.toString() === values?.thread_size?.toString()
  //     );
  //     setFieldValue("thread_pitch", item?.fine);
  //   }
  //   // if (
  //   //   values?.drillAngle &&
  //   //   (Number(values?.drillAngle) === 180 || Number(values?.drillAngle) === 0)
  //   // ) {
  //   //   handleChangeFunc("holeBottom", "Flat");
  //   // } else if (
  //   //   values?.drillAngle &&
  //   //   Number(values?.drillAngle) < 180 &&
  //   //   Number(values?.drillAngle) > 0
  //   // ) {
  //   //   handleChangeFunc("holeBottom", "V Shape");
  //   // }
  //   if (Number(values?.additional_length) >= 50) {
  //     setNearestWallDistance(true);
  //   }
  // }, [values?.thread_size, values?.thread_pitch, values?.additional_length]);

  const handleChangeFunc = (key: string, value: any, flag: boolean = false) => {
    if (flag) setFieldValue(key, value);

    let updated_setups = [...machiningSetups];

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );

    let updated_feature = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]?.featureInformation
        ?.feature_data?.feature_info,
    };

    switch (key) {
      case "thread_type":
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      case "bottom_type":
        if (value === "Through") {
          delete updated_feature["bottom"];
          setFieldValue("bottom", "");
          delete updated_feature["bottom_angle"];
          setFieldValue("bottom_angle", "");
          delete updated_feature["bottom_radius"];
          setFieldValue("bottom_radius", "");
        }
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      case "bottom":
        if (value === "Flat") {
          delete updated_feature["bottom_angle"];
          setFieldValue("bottom_angle", "");
        } else if (value === "v Shape") {
          delete updated_feature["bottom_radius"];
          setFieldValue("bottom_radius", "");
        }
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      case "bottom_radius":
        delete updated_feature["bottom_angle"];
        setFieldValue("bottom_angle", "");
        updated_feature = {
          ...updated_feature,
          [key]: Number(value),
        };
        break;
      case "bottom_angle":
        delete updated_feature["bottom_radius"];
        setFieldValue("bottom_radius", "");
        updated_feature = {
          ...updated_feature,
          [key]: Number(value),
        };
        break;
      case "deburring":
        updated_feature = {
          ...updated_feature,
          [key]: value ? "yes" : "no",
        };
        break;
      default:
        updated_feature = {
          ...updated_feature,
          [key]: Number(value),
        };
    }

    updated_setups[setupIdx].features[
      feature_idx
    ].featureInformation.feature_data.feature_info = updated_feature;

    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });

    // setFieldValue(key, value);
    // dispatch({
    //   type: SET_THREAD_HOLE_DATA,
    //   payload: {
    //     index,
    //     key,
    //     value,
    //   },
    // });
    // dispatch({
    //   type: SET_THREAD_HOLE_DATA,
    //   payload: {
    //     index,
    //     key: "parameter",
    //     value: null,
    //   },
    // });
    // dispatch({
    //   type: SET_THREAD_HOLE_DATA,
    //   payload: {
    //     index,
    //     key: "selected_strategy",
    //     value: null,
    //   },
    // });
  };

  return (
    <div className="">
      <div>
        <p className="font-semibold text-base text-gray-202">
          Feature dimensions
        </p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Select
              options={THREAD_PICTH_TYPE_OPTIONS}
              placeholder="Thread Type"
              className="mr-2"
              value={values.thread_type}
              onChange={(val: any) =>
                handleChangeFunc("thread_type", val, true)
              }
              onBlur={handleBlur}
            />
          </div>
          <div className="w-6/12">
            <Input
              placeholder="Thread Size"
              className="ml-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="thread_size"
              value={values?.thread_size}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("thread_size", values?.thread_size);
              }}
              invalid={touched.thread_size && errors.thread_size}
              helperText={touched.thread_size && errors.thread_size}
              regex={/^\d*\.?\d*$/}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row mt-4">
        <div className="w-6/12">
          <Input
            placeholder="Thread Pitch"
            className="mr-2"
            type="text"
            unit={projectInfo?.unit || "mm"}
            name="thread_pitch"
            value={values?.thread_pitch}
            onChange={handleChange}
            onBlur={(e: any) => {
              handleBlur(e);
              handleChangeFunc("thread_pitch", values?.thread_pitch);
            }}
            invalid={touched.thread_pitch && errors.thread_pitch}
            helperText={touched.thread_pitch && errors.thread_pitch}
            regex={/^\d*\.?\d*$/}
          />
        </div>
        <div className="w-6/12">
          <Input
            placeholder="Thread Depth"
            className="ml-2"
            type="text"
            name="thread_depth"
            value={values?.thread_depth}
            onChange={handleChange}
            onBlur={(e: any) => {
              handleBlur(e);
              handleChangeFunc("thread_depth", values?.thread_depth);
            }}
            invalid={touched.thread_depth && errors.thread_depth}
            helperText={touched.thread_depth && errors.thread_depth}
            regex={/^\d*\.?\d*$/}
          />
        </div>
      </div>

      <div className="flex flex-row mt-4">
        <div className="w-6/12">
          <Input
            placeholder="Depth"
            className="mr-2"
            type="text"
            unit={projectInfo?.unit || "mm"}
            name="depth"
            value={values?.depth}
            onChange={handleChange}
            onBlur={(e: any) => {
              handleBlur(e);
              handleChangeFunc("depth", values?.depth);
            }}
            invalid={touched.depth && errors.depth}
            helperText={touched.depth && errors.depth}
            regex={/^\d*\.?\d*$/}
          />
        </div>
      </div>

      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">Bottom details</p>
        <div className="flex flex-row mt-4">
          <div className="flex justify-between items-center w-6/12 py-[14px]">
            <label className="font-semibold text-gray-475 truncate mr-2">
              Bottom Type
            </label>
            <RadioComponent
              options={HOLE_BOTTOM_TYPE_OPTIONS}
              setValue={(val: any) =>
                handleChangeFunc("bottom_type", val, true)
              }
              value={values?.bottom_type}
            />
          </div>
          {values?.bottom_type === "Blind" && (
            <div className="flex justify-between items-center w-6/12 py-[14px]">
              <label className="font-semibold text-gray-475 truncate ml-2">
                Bottom
              </label>
              <RadioComponent
                options={HOLE_BOTTOM_OPTIONS}
                setValue={(val: any) => handleChangeFunc("bottom", val, true)}
                value={values?.bottom}
              />
            </div>
          )}
        </div>

        {values?.bottom_type === "Blind" && (
          <div className="flex flex-row mt-4">
            <div className="w-6/12">
              {values?.bottom === "Flat" && (
                <Input
                  placeholder="Bottom Radius"
                  type="text"
                  className="mr-2"
                  name="bottom_radius"
                  value={values?.bottom_radius}
                  onChange={handleChange}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    handleChangeFunc("bottom_radius", values?.bottom_radius);
                  }}
                  invalid={touched.bottom_radius && errors.bottom_radius}
                  helperText={touched.bottom_radius && errors.bottom_radius}
                  regex={/^\d*\.?\d*$/}
                />
              )}
              {values?.bottom === "v Shape" && (
                <Input
                  placeholder="Bottom Angle"
                  type="text"
                  className="mr-2"
                  name="bottom_angle"
                  value={values?.bottom_angle}
                  onChange={handleChange}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    handleChangeFunc("bottom_angle", values?.bottom_angle);
                  }}
                  invalid={touched.bottom_angle && errors.bottom_angle}
                  helperText={touched.bottom_angle && errors.bottom_angle}
                  regex={/^\d*$/}
                />
              )}
            </div>
          </div>
        )}
      </div>

      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">Other details</p>
        <div className="flex flex-row mt-4">
          <div className="flex justify-between items-center w-6/12 py-[14px]">
            <label className="font-semibold text-gray-475 truncate mr-2">
              Deburring
            </label>
            <RadioComponent
              options={CONFIRM_OPTIONS}
              setValue={(val: any) => handleChangeFunc("deburring", val, true)}
              value={values?.deburring}
            />
          </div>
          <div className="w-6/12">
            <Input
              placeholder="Quantity"
              className="ml-2"
              type="text"
              name="quantity"
              value={values?.quantity}
              onChange={(e: any) =>
                handleChangeFunc("quantity", e?.target?.value)
              }
              onBlur={handleBlur}
              invalid={touched.quantity && errors.quantity}
              helperText={touched.quantity && errors.quantity}
              regex={/^[1-9]\d*$/}
            />
          </div>
        </div>
      </div>

      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">
          Additional details
        </p>
        <div className="flex flex-row mt-4">
          <div className="flex w-6/12">
            <Input
              placeholder="Additional Length"
              className="w-full mr-2"
              type="text"
              name="additional_length"
              value={values?.additional_length}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc(
                  "additional_length",
                  values?.additional_length
                );
              }}
              invalid={touched.additional_length && errors.additional_length}
              helperText={touched.additional_length && errors.additional_length}
              regex={/^\d*\.?\d*$/}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreadHoleForm;
