import React, { forwardRef, useRef } from "react";
import { v4 as uuid } from "uuid";

const SummaryExport = forwardRef<HTMLTableElement, any>(
  ({ data, projectInfo }, ref) => {
    return (
      <div>
        <table ref={ref}>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td
              data-f-bold={true}
              data-fill-color="FFF3F3F3"
              data-b-t-s="thick"
              data-b-l-s="thick"
            >
              Project
            </td>
            <td data-b-t-s="thick" data-b-r-s="thick">
              {projectInfo?.projectName || ""}
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td
              data-f-bold={true}
              data-fill-color="FFF3F3F3"
              data-b-l-s="thick"
            >
              CAD File
            </td>
            <td data-b-r-s="thick">{projectInfo?.fileName || ""}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td
              data-f-bold={true}
              data-fill-color="FFF3F3F3"
              data-b-l-s="thick"
            >
              Workpiece Material
            </td>
            <td data-b-r-s="thick">{projectInfo?.material || ""}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td
              data-f-bold={true}
              data-fill-color="FFF3F3F3"
              data-b-l-s="thick"
              data-b-b-s="thick"
            >
              Machine
            </td>
            <td data-b-r-s="thick" data-b-b-s="thick">
              {projectInfo?.machineName || ""}
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td
              colSpan={3}
              data-a-h="center"
              data-f-sz={13}
              data-f-bold={true}
              data-fill-color="FFD9D9D9"
            >
              Machining Feature
            </td>
            <td
              colSpan={2}
              data-a-h="center"
              data-f-sz={13}
              data-f-bold={true}
              data-fill-color="FFF9CB9C"
            >
              Machining Strategy
            </td>
            <td
              colSpan={9}
              data-a-h="center"
              data-f-sz={13}
              data-f-bold={true}
              data-fill-color="FFB6D7A8"
            >
              Cutting Tool Recommendations
            </td>
            <td
              colSpan={6}
              data-a-h="center"
              data-f-sz={13}
              data-f-bold={true}
              data-fill-color="FFFFE599"
            >
              Optimal Machining Parameters Recommendations
            </td>
          </tr>
          <tr>
            <td></td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFD9D9D9">
              Type
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFD9D9D9">
              Name
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFD9D9D9">
              Number
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFF9CB9C">
              Step
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFF9CB9C">
              Operation
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFB6D7A8">
              ID
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFB6D7A8">
              Description
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFB6D7A8">
              Type
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFB6D7A8">
              Diameter (mm)
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFB6D7A8">
              Corner radius (mm)
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFB6D7A8">
              Flute Length (mm)
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFB6D7A8">
              Total Length (mm)
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFB6D7A8">
              Teeth
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFB6D7A8">
              Tip angle
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFFFE599">
              Axial depth of cut (mm)
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFFFE599">
              Radial depth of cut (mm)
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFFFE599">
              Spindle Speed (m/min)
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFFFE599">
              Feed rate (mm/min)
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFFFE599">
              Material Removal Rate (mm^3/min)
            </td>
            <td data-a-h="center" data-f-bold={true} data-fill-color="FFFFE599">
              Tool Life (min)
            </td>
          </tr>
          {data?.map((item: any, idx1: number) => {
            return (
              <tr key={`${idx1}_${uuid()}`}>
                <td></td>
                <td data-fill-color="FFF3F3F3" data-a-h="center">
                  {item?.label}
                </td>
                <td data-fill-color="FFF3F3F3" data-a-h="center">
                  {item?.sub_label}
                </td>
                <td data-fill-color="FFF3F3F3" data-t="n" data-a-h="center">
                  {item?.number_label}
                </td>
                <td data-fill-color="FFFCE5CD">{`Step ${item?.operation_step}`}</td>
                <td data-fill-color="FFFCE5CD">{item?.operation_label}</td>
                {item?.data?.map((col: any, idx2: number) => (
                  <td
                    key={`${idx1}_${idx2}_${uuid()}`}
                    data-fill-color={col?.bgColor}
                    data-t={col?.type}
                  >
                    {col?.value}
                  </td>
                ))}
              </tr>
            );
          })}
        </table>
      </div>
    );
  }
);

export default SummaryExport;
