import { useContext, useEffect } from "react";
import Input from "components/Input";
import MultiRange from "components/MultiRange";
import RadioComponent from "components/RadioComponent";
import Select from "components/Select";
import {
  BOTTOM_TYPE_OPTIONS,
  CHAMFER_LOCATION_OPTIONS,
  CHAMFER_TYPE_OPTIONS,
  CHAMFER_SURFACE_TYPE_OPTIONS,
  CHAMFER_POSITION_OPTIONS,
  CONFIRM_OPTIONS,
  FILLET_TYPE_OPTIONS,
  SET_CONTOUR_DATA,
  SET_MACHINE_SETUP,
} from "constant";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GlobalContext } from "context";

interface IChamferForm {
  feature_info?: any;
  feature_id?: string;
}

const init_chamfer_contour = {
  type: "",
  length: "",
  breadth: "",
  angle: "",
  location: "",
  corner_radius: "",
  vertical_edges: "",
  distance: "",
  quantity: "",
  surface_type: "",
  position: ""
};

const validationSchema = Yup.object().shape({});

const ChamferForm: React.FC<IChamferForm> = ({ feature_info, feature_id }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { machiningSetups, projectInfo, setupNumber } = state;

  useEffect(() => {
    if (!feature_info) return;
    let formValues: any = {};
    Object.keys(init_chamfer_contour).forEach((key: string) => {
      if (key === "vertical_edges") {
        formValues = {
          ...formValues,
          [key]: key in feature_info ? feature_info?.[key] === "yes" : "",
        };
        return;
      }
      formValues = {
        ...formValues,
        [key]: feature_info?.[key] ?? "",
      };
    });
    setValues(formValues);
  }, [feature_info]);

  const {
    handleChange,
    handleBlur,
    setValues,
    setFieldValue,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: init_chamfer_contour,
    validationSchema,
    onSubmit: () => {},
  });

  // useEffect(() => {
  //   if (!contourList?.[index]) return;
  //   let formValues: any = {};
  //   Object.keys(init_chamfer_contour).forEach((key: string) => {
  //     formValues = {
  //       ...formValues,
  //       [key]: contourList[index][key],
  //     };
  //   });
  //   setValues(formValues);
  // }, [contourList, index]);

  const handleChangeFunc = (key: string, value: any, flag: boolean = false) => {
    if (flag) setFieldValue(key, value);

    let updated_setups = [...machiningSetups];

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );

    let updated_feature = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]?.featureInformation
        ?.feature_data?.feature_info,
    };

    switch (key) {
      case "type":
      case "position":
      case "surface_type":
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      case "location":
        if (value === "Outside") {
          delete updated_feature.vertical_edges;
          setFieldValue("vertical_edges", "");
          delete updated_feature.corner_radius;
          setFieldValue("corner_radius", "");
          delete updated_feature.distance;
          setFieldValue("distance", "");
        }
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      case "vertical_edges":
        if (!value) {
          delete updated_feature.distance;
          setFieldValue("distance", "");
        }
        updated_feature = {
          ...updated_feature,
          [key]: value ? "yes" : "no",
        };
        break;
      default:
        updated_feature = {
          ...updated_feature,
          [key]: Number(value),
        };
    }

    updated_setups[setupIdx].features[
      feature_idx
    ].featureInformation.feature_data.feature_info = updated_feature;

    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });
    // setFieldValue(key, value);
    // dispatch({
    //   type: SET_CONTOUR_DATA,
    //   payload: {
    //     index,
    //     key,
    //     value,
    //   },
    // });
    // dispatch({
    //   type: SET_CONTOUR_DATA,
    //   payload: {
    //     index,
    //     key: "parameter",
    //     value: null,
    //   },
    // });
    // dispatch({
    //   type: SET_CONTOUR_DATA,
    //   payload: {
    //     index,
    //     key: "selected_strategy",
    //     value: null,
    //   },
    // });
  };

  return (
    <div>
      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">
          Feature dimensions
        </p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              placeholder="Length"
              className="mr-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="length"
              value={values?.length}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("length", values?.length);
              }}
              invalid={touched.length && errors.length}
              helperText={touched.length && errors.length}
              regex={/^\d*\.?\d*$/}
            />
          </div>
          <div className="w-6/12">
            <Input
              placeholder="Breadth"
              className="ml-2"
              type="text"
              name="breadth"
              value={values?.breadth}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("breadth", values?.breadth);
              }}
              invalid={touched.breadth && errors.breadth}
              helperText={touched.breadth && errors.breadth}
              regex={/^\d*\.?\d*$/}
            />
          </div>
        </div>

        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              placeholder="Angle"
              className="mr-2"
              type="text"
              name="angle"
              value={values?.angle}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("angle", values?.angle);
              }}
              invalid={touched.angle && errors.angle}
              helperText={touched.angle && errors.angle}
              regex={/^\d*$/}
            />
          </div>

          <div className="flex justify-between items-center w-6/12 py-[14px]">
            <label className="font-semibold text-gray-475 truncate ml-2">
              Type
            </label>
            <RadioComponent
              options={CHAMFER_TYPE_OPTIONS}
              setValue={(val: any) =>
                handleChangeFunc("type", val, true)
              }
              value={values?.type}
            />
          </div>
        </div>
      </div>

      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">Other details</p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              placeholder="Quantity"
              className="mr-2"
              type="text"
              name="quantity"
              value={values?.quantity}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("quantity", values?.quantity);
              }}
              invalid={touched.quantity && errors.quantity}
              helperText={touched.quantity && errors.quantity}
              regex={/^[1-9]\d*$/}
            />
          </div>
          <div className="flex justify-between items-center w-6/12 py-[14px]">
            <label className="font-semibold text-gray-475 truncate ml-2">
              Surface type
            </label>
            <RadioComponent
              options={CHAMFER_SURFACE_TYPE_OPTIONS}
              setValue={(val: any) =>
                handleChangeFunc("surface_type", val, true)
              }
              value={values?.surface_type}
            />
          </div>
        </div>
        <div className="flex flex-row mt-4">
          <div className="flex justify-between items-center w-6/12 py-[14px]">
            <label className="font-semibold text-gray-475 truncate">
              Position
            </label>
            <RadioComponent
              options={CHAMFER_POSITION_OPTIONS}
              setValue={(val: any) => handleChangeFunc("position", val, true)}
              value={values?.position}
            />
          </div>
          <div className="flex justify-between items-center w-6/12 py-[14px]">
            <label className="font-semibold text-gray-475 truncate ml-2">
              Location
            </label>
            <RadioComponent
              options={CHAMFER_LOCATION_OPTIONS}
              setValue={(val: any) => handleChangeFunc("location", val, true)}
              value={values?.location}
            />
          </div>
        </div>
        {values?.location === "Inside" && (
          <div>
            <div className="flex flex-row mt-4">
              <div className="flex justify-between items-center w-6/12 py-[14px]">
                <label className="font-semibold text-gray-475 truncate mr-2">
                  Vertical edges
                </label>
                <RadioComponent
                  options={CONFIRM_OPTIONS}
                  setValue={(val: any) =>
                    handleChangeFunc("vertical_edges", val, true)
                  }
                  value={values?.vertical_edges}
                />
              </div>
              <div className="w-6/12">
                <Input
                  placeholder="Corner Radius"
                  className="ml-2"
                  type="text"
                  name="corner_radius"
                  value={values?.corner_radius}
                  onChange={handleChange}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    handleChangeFunc("corner_radius", values?.corner_radius);
                  }}
                  invalid={touched.corner_radius && errors.corner_radius}
                  helperText={touched.corner_radius && errors.corner_radius}
                  regex={/^\d*\.?\d*$/}
                />
              </div>
            </div>

            <div className="flex flex-row mt-4">
              {!!values?.vertical_edges && (
                <div className="w-6/12">
                  <Input
                    placeholder="Distance"
                    className="mr-2"
                    type="text"
                    name="distance"
                    value={values?.distance}
                    onChange={handleChange}
                    onBlur={(e: any) => {
                      handleBlur(e);
                      handleChangeFunc("distance", values?.distance);
                    }}
                    invalid={touched.distance && errors.distance}
                    helperText={touched.distance && errors.distance}
                    regex={/^\d*\.?\d*$/}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChamferForm;
