import clsx from "clsx";
import { IOption } from "./Select";
import ArrowDown from "assets/images/icons/arrow-down.svg";
import ArrowUp from "assets/images/icons/arrow-up.svg";
import { MATERIAL_LIST } from "constant";

interface IDefaultToggler {
  selected: any;
  toggleSelect: any;
  isOpen: boolean;
  className?: any;
  placeholder?: string;
  disabled?: boolean;
  options: IOption[];
  isTag?: boolean;
}

export const DefaultToggler: React.FC<IDefaultToggler> = ({
  selected,
  toggleSelect,
  isOpen,
  className,
  placeholder,
  disabled = false,
  options,
  isTag,
}) => {
  const togglerClsx = clsx({
    "select-toggler": true,
    "justify-between": selected,
    "justify-end": !selected,
    [className]: className,
  });

  return (
    <button
      type="button"
      className={togglerClsx}
      onClick={toggleSelect}
      disabled={disabled}
    >
      <label
        className={`left-[20px] font-medium absolute pointer-events-none z-10 transition-all ${
          selected || isOpen
            ? "text-[10px] top-[2px]"
            : "top-[16px] text-gray-b9b text-sm"
        }`}
        htmlFor="name"
      >
        {placeholder}
      </label>
      <div className={clsx("flex flex-row items-center", {
        "mt-1": isTag
      })}>
        {isTag && (
          <>
            {(() => {
              const option = MATERIAL_LIST.find(
                (material) => material.value === selected
              );
              if (!option) return;
              return (
                <div
                  className="w-6 h-6 rounded-sm font-medium flex justify-center items-center mr-2"
                  style={{
                    color: option?.textColor,
                    background: option?.bgColor,
                  }}
                >
                  {option?.tag}
                </div>
              );
            })()}
          </>
        )}
        {selected &&
          options?.find((option) => option?.value === selected)?.label}
      </div>
      <img src={isOpen ? ArrowUp : ArrowDown} alt="arrow" />
    </button>
  );
};
