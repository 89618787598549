import {
  FeatureIcon,
  MachineIcon,
  ProjectIcon,
  ToolIcon,
} from "assets/images/icons/tab";
import {
  SET_CONTOUR_DATA,
  SET_CORE_CAVITY_DATA,
  SET_FACE_DATA,
  SET_HOLE_DATA,
  SET_PENCIL_DATA,
  SET_POCKET_DATA,
  SET_THREAD_HOLE_DATA,
} from "./action";

export * from "./action";
export * from "./features";
export * from "./machining";
export * from "./property";
export * from "./project";
export * from "./regExp";
export * from "./validation";
export * from "./default";
export * from "./cuttingTool";

export const TAB_LIST = [
  { type: "project", label: "Project", icon: ProjectIcon },
  { type: "feature", label: "Feature Recognition", icon: FeatureIcon }, // Feature
  { type: "machine", label: "Machining Strategies", icon: MachineIcon }, // Machine
  { type: "tool", label: "Tools & Parameters", icon: ToolIcon },
];

export const keyList = [
  { key: "holeList", title: "Hole", type: "hole", set_type: SET_HOLE_DATA },
  {
    key: "threadHoleList",
    title: "Thread Hole",
    type: "thread_hole",
    set_type: SET_THREAD_HOLE_DATA,
  },
  { key: "faceList", title: "Face", type: "face", set_type: SET_FACE_DATA },
  {
    key: "pocketList",
    title: "Pocket",
    type: "pocket",
    set_type: SET_POCKET_DATA,
  },
  {
    key: "contourList",
    title: "Contour",
    type: "contour",
    set_type: SET_CONTOUR_DATA,
  },
  // {
  //   key: "coreCavityList",
  //   title: "3D Core & Cavity",
  //   type: "core_cavity",
  //   set_type: SET_CORE_CAVITY_DATA,
  // },
  // {
  //   key: "pencilList",
  //   title: "3D Pencil",
  //   type: "pencil",
  //   set_type: SET_PENCIL_DATA,
  // },
];

export const ACTION_BY_KEY: any = {
  hole: SET_HOLE_DATA,
  thread_hole: SET_THREAD_HOLE_DATA,
  face: SET_FACE_DATA,
  pocket: SET_POCKET_DATA,
  contour: SET_CONTOUR_DATA,
  core_cavity: SET_CORE_CAVITY_DATA,
  pencil: SET_PENCIL_DATA,
};
