import { useState, useCallback } from "react";
import { toast } from "react-toastify";

export const useApiCall = (service: any) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const fetchData: any = useCallback(async (param: any) => {
    setLoading(true);
    try {
      const result = await service(param);
      setLoading(false);
      if (result?.status || result?.data) {
        setData(result?.data || result?.result);
        return result?.data || result?.result;
      } else {
        toast(result?.message);
        return;
      }
    } catch (error: any) {
      setLoading(false);
      setError(error?.message);
      toast(error?.message);
      return;
    }
  }, []);

  return [fetchData, loading, data, error];
};
