import { useContext, useEffect } from "react";
import Input from "components/Input";
import { SET_FACE_DATA, SET_MACHINE_SETUP } from "constant";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GlobalContext } from "context/GlobalContext";

interface ITopFace {
  feature_info?: any;
  feature_id?: string;
}

const init_top_face = {
  length: "",
  breadth: "",
  stock_on_face: "",
  quantity: "",
};

const validationSchema = Yup.object().shape({});

const TopFace: React.FC<ITopFace> = ({ feature_info, feature_id }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { machiningSetups, projectInfo, setupNumber } = state;

  useEffect(() => {
    if (!feature_info) return;
    let formValues: any = {};
    Object.keys(init_top_face).forEach((key: string) => {
      formValues = {
        ...formValues,
        [key]: feature_info?.[key] ?? "",
      };
    });
    setValues(formValues);
  }, [feature_info]);

  const {
    handleChange,
    handleBlur,
    setValues,
    setFieldValue,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: init_top_face,
    validationSchema,
    onSubmit: () => {},
  });

  // useEffect(() => {
  //   if (!faceList?.[index]) return;
  //   let formValues: any = {};
  //   Object.keys(init_top_face).forEach((key: string) => {
  //     formValues = {
  //       ...formValues,
  //       [key]: faceList[index][key],
  //     };
  //   });
  //   setValues(formValues);
  // }, [faceList, index]);

  const handleChangeFunc = (key: string, value: any, flag: boolean = false) => {
    if (flag) setFieldValue(key, value);

    let updated_setups = [...machiningSetups];

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );

    let updated_feature = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]?.featureInformation
        ?.feature_data?.feature_info,
    };

    switch (key) {
      default:
        updated_feature = {
          ...updated_feature,
          [key]: Number(value),
        };
    }

    updated_setups[setupIdx].features[
      feature_idx
    ].featureInformation.feature_data.feature_info = updated_feature;

    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });

    // setFieldValue(key, value);
    // dispatch({
    //   type: SET_FACE_DATA,
    //   payload: {
    //     index,
    //     key,
    //     value,
    //   },
    // });
    // dispatch({
    //   type: SET_FACE_DATA,
    //   payload: {
    //     index,
    //     key: "parameter",
    //     value: null,
    //   },
    // });
    // dispatch({
    //   type: SET_FACE_DATA,
    //   payload: {
    //     index,
    //     key: "selected_strategy",
    //     value: null,
    //   },
    // });
  };

  return (
    <div>
      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">
          Feature dimensions
        </p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              placeholder="Face Length"
              className="mr-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="length"
              value={values?.length}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("length", values?.length);
              }}
              invalid={touched.length && errors.length}
              helperText={touched.length && errors.length}
              regex={/^\d*\.?\d*$/}
            />
          </div>

          <div className="w-6/12">
            <Input
              placeholder="Face Breadth"
              className="ml-2"
              type="text"
              name="breadth"
              value={values?.breadth}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("breadth", values?.breadth);
              }}
              invalid={touched.breadth && errors.breadth}
              helperText={touched.breadth && errors.breadth}
              regex={/^\d*\.?\d*$/}
            />
          </div>
        </div>
      </div>

      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">Other details</p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              placeholder="Stock on face"
              className="pr-2"
              type="text"
              name="stock_on_face"
              value={values?.stock_on_face}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("stock_on_face", values?.stock_on_face);
              }}
              invalid={touched.stock_on_face && errors.stock_on_face}
              helperText={touched.stock_on_face && errors.stock_on_face}
              regex={/^\d*\.?\d*$/}
            />
          </div>
          <div className="w-6/12">
            <Input
              placeholder="Quantity"
              className="pl-2"
              type="text"
              name="quantity"
              value={values?.quantity}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("quantity", values?.quantity);
              }}
              invalid={touched.quantity && errors.quantity}
              helperText={touched.quantity && errors.quantity}
              regex={/^[1-9]\d*$/}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopFace;
