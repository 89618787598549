import clsx from 'clsx'
import SquareCheckbox from 'components/SquareCheckbox'
import { ISLAND_LABELS } from 'constant';
import { capitalize } from 'utils';

const FeatureSummaryItem = ({ data, handleSubChecked, index }: any) => {
  return (
    <div>
      {data?.map((item: any, idx: number) => (
        <div key={idx} className={clsx({ "mb-4": idx < data?.length - 1 })}>
          <div className="border-b border-gray-e3e pb-2 mb-2">
            <SquareCheckbox
              labelClassName="left-[25px] top-[-4px] font-semibold text-base"
              label={item?.title}
              checked={item.checked}
              pending={false}
              onChange={(checked: boolean) => handleSubChecked(index, idx, checked)}
            />
          </div>
          <div className="grid grid-cols-4">
            {item?.data?.map((item1: any, idx1: number) =>
              item1?.key !== "Islands" ? (
                <div key={idx1} className="col-span-1 flex flex-row mb-1">
                  <span className="text-sm font-semibold text-gray-b9b mr-1">
                    {item1?.key}:
                  </span>
                  <span className="text-sm font-medium text-black">
                    {item1?.value !== undefined ? capitalize(item1?.value) : ""}
                    {/* {item1?.value} */}
                  </span>
                </div>
              ) : (
                <>
                  <div key={idx1} className="col-span-1 flex flex-row mb-1">
                    <span className="text-sm font-semibold text-gray-b9b mr-1">
                      Islands :
                    </span>
                    <span className="text-sm font-medium text-black">
                      {item1?.value?.islands?.length}
                    </span>
                  </div>
                  {item1?.value?.islands?.length > 1 && (
                    <div
                      key={idx1 + 1}
                      className="col-span-1 flex flex-row mb-1"
                    >
                      <span className="text-sm font-semibold text-gray-b9b mr-1">
                        Min Distance between Islands :
                      </span>
                      <span className="text-sm font-medium text-black">
                        {item1?.value?.min_distance_between_islands
                          ? item1?.value?.min_distance_between_islands
                          : ""}
                      </span>
                    </div>
                  )}
                  <div className="col-span-4 text-xs flex flex-wrap w-full gap-4">
                    {item1?.value?.islands?.length > 0 &&
                      item1?.value?.islands?.map(
                        (item3: any, index: number) => (
                          <div
                            key={index}
                            className="bg-white p-2 w-[248px] h-[135px] 
                      border border-gray-300 overflow-y-auto"
                          >
                            <span className="font-semibold text-sm text-black mr-[10px]">
                              Island {index + 1}
                            </span>
                            {Object.entries(ISLAND_LABELS).map(
                              ([iKey, iValue]: [string, any], i: number) => (
                                <div key={i} className="block">
                                  <span className="font-semibold text-gray-b9b mr-1">
                                    {iValue} :{" "}
                                  </span>
                                  <span className="font-semibold text-gray-b9b">
                                    {item3[iKey]}
                                  </span>
                                </div>
                              )
                            )}
                          </div>
                        )
                      )}
                  </div>
                </>
              )
            )}
          </div>
          {
            !!item?.deepData?.length && item?.deepData?.map((deepData: any, idx2: number) => (
              <div className='grid grid-cols-4 border-t py-2 mt-2' key={idx2}>
                {deepData?.map((item2: any, idx3: number) => (
                  <div key={idx3} className="col-span-1 flex flex-row mb-1">
                    <span className="text-sm font-semibold text-gray-b9b mr-1">
                      {item2?.key}:
                    </span>
                    <span className="text-sm font-medium text-black">
                      {item2?.value}
                    </span>
                  </div>
                ))}
              </div>
            ))
          }
        </div>
      ))}
    </div>
  )
}

export default FeatureSummaryItem
