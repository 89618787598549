import { useState, useCallback, useContext } from "react";
import { useDropzone } from "react-dropzone";
import FileUpload from "assets/images/icons/file-upload.svg";
import CadIcon from "assets/images/icons/cad-icon.svg";
import TrashIcon from "assets/images/icons/trash-icon.svg";
import CircleCheckbox from "components/CircleCheckbox";
import { FiEye } from "react-icons/fi";
import { GlobalContext } from "context/GlobalContext";
import { SET_CUTTING_TOOL } from "constant";

interface IDropzone {
  acceptType?: any;
  dropFiles?: any[];
  setDropFiles?: any;
  mode?: "cad" | "tool";
  content?: any;
}

const Dropzone: React.FC<IDropzone> = ({
  acceptType,
  setDropFiles,
  mode = "cad",
  content,
}) => {
  const { dispatch } = useContext(GlobalContext);
  const [myFiles, setMyFiles] = useState<any>([]);
  const onDrop = useCallback(
    (acceptedFiles: any[]) => {
      setMyFiles([...myFiles, ...acceptedFiles]);
      setDropFiles([...myFiles, ...acceptedFiles]);
    },
    [myFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDrop,
    ...acceptType,
  });

  const removeFile = (file: any) => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const handleModal = (content: any) => {
    dispatch({
      type: SET_CUTTING_TOOL,
      payload: {
        open: true,
        content: content,
      },
    });
  };

  const files = myFiles.map((file: any) => {
    if (mode === "cad") {
      return (
        <li
          className="w-full flex justify-between bg-white border border-solid border-gray-ede p-4 rounded"
          key={file?.path}
        >
          <div className="flex flex-row items-center">
            <img src={CadIcon} alt="cad icon" className="mr-6" />
            <span className="font-bold text-base">{file?.name}</span>
          </div>
          <button
            className="rounded bg-red-ffe p-2"
            onClick={() => removeFile(file)}
          >
            <img src={TrashIcon} alt="trash icon" />
          </button>
        </li>
      );
    } else {
      return (
        <li
          className="w-full flex justify-between bg-white border border-solid border-gray-ede p-4 rounded mb-2"
          key={file?.path}
        >
          <div className="flex flex-row items-center">
            <CircleCheckbox
              checked={false}
              onChange={() => {}}
              label={file?.name}
            />
          </div>
          <div className="flex flex-row items-center space-x-3">
            <button
              className="rounded bg-surface-secondary p-2"
              onClick={() => handleModal(content)}
            >
              <FiEye className="w-6 h-6 text-surface-default" />
            </button>
            <button
              className="rounded bg-red-ffe p-2"
              onClick={() => removeFile(file)}
            >
              <img src={TrashIcon} alt="trash icon" />
            </button>
          </div>
        </li>
      );
    }
  });

  return (
    <div>
      {myFiles?.length > 0 && (
        <aside className="mb-2">
          <ul>{files}</ul>
        </aside>
      )}
      {((myFiles?.length === 0 && mode === "cad") || mode === "tool") && (
        <div
          {...getRootProps({
            className:
              "w-full flex py-[22px] bg-surface-secondary rounded shadow-dropzone justify-center items-center",
          })}
        >
          <input {...getInputProps()} />
          <div className="flex items-center flex-col">
            <img src={FileUpload} alt="upload icon" className="mb-2" />
            <p className="font-bold text-base text-gray-898">
              Choose a file <span className="font-normal">or drag it here</span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropzone;
