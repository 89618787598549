export const fetchSetupIdx = (machiningSetups: any, setupNumber: any) => {
  return machiningSetups?.findIndex(
    (setup: any) => setup?.machiningSetupNumber === setupNumber
  );
};

export const compareFuncForObjectsWithPassNumber = (obj1: any, obj2: any) => {
  if (obj1.pass_number < obj2.pass_number) return -1;
  else if (obj1.pass_number > obj2.pass_number) return 1;
  else return 0;
};

export function createDeepCopy(data: any) {
  return JSON.parse(JSON.stringify(data));
}
