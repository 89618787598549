import { API } from 'aws-amplify';

const API_NAME = process.env.REACT_APP_API_NAME || '';

export const postSignatureAPI = async (userRecordId: string) => {
  const response = await API.post(API_NAME, `/user-security?sign=true&userRecordId=${userRecordId}`, {
    body: {}
  });
  return response;
};

export const postCorporateAPI = async (payload: any) => {
  const response = await API.post(API_NAME, `/corporate-record`, {
    body: payload.body,
    headers: {
      'X-ECOM-REQUEST': payload.signature
    }
  });
  return response;
};

export const postUserAPI = async (payload: any) => {
  const response = await API.post(API_NAME, `/users/add`, {
    body: payload.body,
    headers: {
      'X-ECOM-REQUEST': payload.signature
    }
  });
  return response;
};

export const postCheckCorporateAPI = async (payload: any) => {
  const response = await API.post(API_NAME, `/corporate-record-check`, {
    body: payload,
  });
  return response;
};
