import RadioComponent from "components/RadioComponent";
import { POCKET_OPTIONS, SET_POCKET_DATA } from "constant";
import { GlobalContext } from "context/GlobalContext";
import { useState, useContext } from "react";
import ThreeDForm from "./ThreeDForm";
import TwoDForm from "./TwoDForm";

const PocketForm = ({ feature_id }: any) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { pocketList } = state;

  return (
    <div>
      {/* <RadioComponent
        options={POCKET_OPTIONS}
        setValue={handlePocketOption}
        value={pocketList[index]?.type}
        labelClassName="!mr-4"
      /> */}
      <TwoDForm feature_id={feature_id} />
      {/* {pocketList[index]?.type === "pocket_2d" && <TwoDForm index={index} />}
      {pocketList[index]?.type === "pocket_3d" && <ThreeDForm index={index} /> } */}
    </div>
  );
}

export default PocketForm;
