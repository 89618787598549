import SearchIcon from 'assets/images/icons/search.svg'
import { useState, useEffect } from 'react'

interface ISearchInput {
  value: string
  onChange: any
  placeholder?: string
  className?: string
}
const SearchInput: React.FC<ISearchInput> = ({
  value,
  onChange,
  placeholder,
  className,
}) => {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (e: any) => {
    setInputValue(e?.target?.value);
    if (onChange) onChange(e);
  };

  return (
    <div className={`shadow-input rounded-[4px] relative ${className}`}>
      <div className="absolute top-0 bottom-0 left-0 w-[37px] flex justify-center items-center">
        <img src={SearchIcon} alt="search icon" />
      </div>
      <input
        data-testid="search-input-field"
        value={inputValue}
        onChange={handleChange}
        placeholder={placeholder}
        className="w-full py-[7px] pl-[37px] pr-2 text-sm text-gray-a7a"
      />
    </div>
  )
}

export default SearchInput
