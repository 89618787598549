import RadioComponent from "components/RadioComponent";
import { SET_HOLE_DATA, SET_MACHINE_SETUP } from "constant";
import { GlobalContext } from "context/GlobalContext";
import { useState, useContext, useEffect } from "react";
import FinishHole from "./FinishHole";
import OpenHole from "./OpenHole";

const HoleOptions = [
  { label: "Open Hole", value: "open_hole" },
  { label: "Finish Hole", value: "finish_hole" },
];

interface IHoleForm {
  feature_id?: string;
}

const HoleForm: React.FC<IHoleForm> = ({ feature_id }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { setupNumber, machiningSetups } = state;
  const [selectedFeature, setSelectedFeature] = useState<any>(null);

  useEffect(() => {
    if (setupNumber < 0 || !feature_id) return;
    const setupIdx = machiningSetups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );
    const feature_idx = machiningSetups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );
    const feature_data = {
      ...machiningSetups?.[setupIdx]?.features?.[feature_idx]?.featureInformation
        ?.feature_data,
    };
    setSelectedFeature(feature_data);
  }, [setupNumber, feature_id]);

  const handleHoleOption = (val: string) => {
    let updated_setups = [...machiningSetups];

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );

    let updated_data = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]?.featureInformation
        ?.feature_data,
    };
    updated_data = {
      ...updated_data,
      feature_name: val,
    };
    updated_setups[setupIdx].features[
      feature_idx
    ].featureInformation.feature_data = updated_data;
    
    if (val === "open_hole") {
      delete updated_data?.feature_info["finish_type"];
    }
    
    setSelectedFeature(updated_data);
    
    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });
  };
  return (
    <div>
      <RadioComponent
        options={HoleOptions}
        setValue={handleHoleOption}
        value={selectedFeature?.feature_name}
        labelClassName="!mr-4"
      />
      {selectedFeature?.feature_name === "open_hole" && (
        <OpenHole
          feature_info={selectedFeature?.feature_info}
          feature_id={selectedFeature?.feature_id}
        />
      )}
      {selectedFeature?.feature_name === "finish_hole" && (
        <FinishHole
          feature_info={selectedFeature?.feature_info}
          feature_id={selectedFeature?.feature_id}
        />
      )}
    </div>
  );
};

export default HoleForm;
