import Button from "components/Button";
import Input from "components/Input";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import LogoImg from "assets/images/logo.svg";
import { FormCheckbox } from "components/Checkbox";
import { GoKey } from "react-icons/go";
import { Auth } from "aws-amplify";
import { GlobalContext } from "context/GlobalContext";
import { useContext } from "react";
import {
  SET_LOADING_BAR,
  SET_USER_INFO,
  emailValidation,
  passwordValidation,
} from "constant";

const validationSchema = Yup.object().shape({
  email: emailValidation,
  password: passwordValidation,
});

const Login = () => {
  const { dispatch } = useContext(GlobalContext);
  const navigate = useNavigate();

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
    isValid,
    dirty,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        dispatch({
          type: SET_LOADING_BAR,
          payload: true,
        });
        const signInRes = await Auth.signIn(
          values?.email?.toLowerCase(),
          values?.password
        );
        dispatch({
          type: SET_LOADING_BAR,
          payload: false,
        });
        if (!signInRes) return;
        dispatch({
          type: SET_USER_INFO,
          payload: signInRes?.attributes,
        });
        navigate("/projects");
      } catch (err: any) {
        console.log("err", err?.message);
        err?.message && toast.error(err?.message);
        dispatch({
          type: SET_LOADING_BAR,
          payload: false,
        });
      }
    },
  });

  return (
    <div className="flex items-center justify-center w-full h-full relative">
      <div className="fixed top-0 right-0 left-0 bottom-0 bg-auth-background w-full h-full bg-cover "></div>
      <div className="fixed top-0 right-0 left-0 bottom-0 splash-background"></div>
      <div className="w-6/12 h-full flex flex-col justify-between px-5 py-10 z-[1]">
        <img src={LogoImg} alt="logo" className="max-w-[180px] h-fit" />
        <div className="flex flex-col">
          <p className="text-surface-default font-semibold text-lg">
            Welcome to
          </p>
          <p className="text-black font-bold text-[40px] mb-6">
            Generative Programming
            <span className="text-[10px] text-black-222">(Beta v-1.0.0)</span>
          </p>
          <p className="text-gray-494 font-medium">
            A tool that brings the power of artificial intelligence to CNC
            programmers by recommending machining strategies, optimal cutting
            tools & machining parameters, and more based on the specific
            features in your CAD files.
          </p>
        </div>
        <div className="flex flex-row justify-between items-center">
          <span className="text-gray-424 text-xs font-semibold">
            2024 © Lambda Function, Inc.
          </span>
          <span className="text-gray-424 text-xs font-semibold">
            Beta v-1.0.0
          </span>
        </div>
      </div>
      <div className="w-6/12 h-full flex justify-center relative pt-10 pb-16">
        <div className="w-full h-full overflow-auto px-5 flex justify-center items-center">
          <div className="flex flex-col max-w-[50vw] p-px my-auto h-fit w-8/12 min-w-[350px]">
            <div className="mb-[30px]">
              <p className="font-semibold text-[27px] text-black mb-1">Login</p>
              <div className="rounded bg-surface-default w-[48px] h-1"></div>
            </div>

            <form onSubmit={handleSubmit}>
              <Input
                label="User Name"
                type="string"
                name="email"
                className="min-w-[350px] mb-2"
                subClassName="!shadow-form-input !bg-white !rounded-xl"
                inputClassName="bg-transparent"
                value={values?.email}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={touched.email && errors.email}
                helperText={touched.email && errors.email}
              />
              <Input
                label="Password"
                type="password"
                name="password"
                className="min-w-[350px] mb-4"
                subClassName="!shadow-form-input !bg-white !rounded-xl"
                inputClassName="bg-transparent"
                value={values?.password}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={touched.password && errors.password}
                helperText={touched.password && errors.password}
              />
              <div className="flex flex-row items-center justify-between text-xs mb-4 text-black-222 font-semibold">
                <div>
                  <FormCheckbox
                    label="Remember me"
                    checked={values.remember}
                    onChange={(flag: boolean) =>{
                      setFieldValue("remember", flag)
                    }}
                    id={"remember"}
                    name="remember"
                  />
                </div>
                <Link
                  to="/forgot-password"
                  className="cursor-pointer text-surface-default font-semibold text-sm flex "
                >
                  <GoKey className="w-5 h-5 mr-2 underline" /> Forgot Password
                </Link>
              </div>
              <Button
                className="w-full rounded-xl text-lg py-4 font-semibold mb-[10px]"
                type="submit"
                disabled={!isValid || !dirty}
              >
                Login
              </Button>
              <p className="font-semibold text-gray-696 text-sm text-center">
                Don't have Account?{" "}
                <Link to="/signup" className="text-blue-033">
                  Sign Up
                </Link>
              </p>
            </form>
          </div>
        </div>
        <p className="text-gray-696 font-semibold text-xs fixed w-6/12 text-center bottom-[40px]">
          Issues with logging in or signing up?{" "}
          <a href="mailto:info@lambdafunction.ai" className="text-surface-default underline">
            We're here to help!
          </a>
        </p>
      </div>
    </div>
  );
};

export default Login;
