const ContourIcon = ({ active = false }) => {
  const fillClass = active ? "#FFF2C3" : "#D9D9D9";
  const strokeClass = active ? "#1570EF" : "black";
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.13574 9.13469V5.8766L7.58069 2.67544L13.952 4.99544V8.19376L9.4485 11.368L3.13574 9.13469Z"
        fill={fillClass}
        stroke={strokeClass}
        strokeWidth="0.5"
      />
      <path
        d="M14.0555 4.85034L9.42879 8.14375M9.42879 8.14375L2.91602 5.74855M9.42879 8.14375L9.45826 11.5869"
        stroke={strokeClass}
        strokeWidth="0.5"
      />
      <path
        d="M9.75248 14.9102V12.6387M14 7L16 8.5L15.9411 10.5988L9.75248 15L1.05896 11.8264L1 9.5L3 8"
        stroke={strokeClass}
        strokeWidth="0.5"
      />
      <path
        d="M1 9.5L9.72299 12.6646L16 8.5"
        stroke={strokeClass}
        strokeWidth="0.5"
      />
      <path
        d="M6.38093 2.21545C6.38093 2.27396 6.34048 2.35022 6.21738 2.4366C6.09706 2.52103 5.91646 2.60112 5.68428 2.67003C5.22122 2.80747 4.57556 2.8939 3.85814 2.8939C3.14073 2.8939 2.49506 2.80747 2.032 2.67003C1.79983 2.60112 1.61922 2.52103 1.4989 2.4366C1.37581 2.35022 1.33535 2.27396 1.33535 2.21545C1.33535 2.15695 1.37581 2.08069 1.4989 1.99431C1.61922 1.90988 1.79983 1.82979 2.032 1.76088C2.49506 1.62344 3.14073 1.53701 3.85814 1.53701C4.57556 1.53701 5.22122 1.62344 5.68428 1.76088C5.91646 1.82979 6.09706 1.90988 6.21738 1.99431C6.34048 2.08069 6.38093 2.15695 6.38093 2.21545Z"
        fill={fillClass}
        stroke={strokeClass}
        strokeWidth="0.2"
      />
      <path
        d="M6.55781 1.61689C6.55781 1.68407 6.51189 1.76822 6.38002 1.86158C6.25077 1.95309 6.05726 2.03958 5.80914 2.11387C5.31416 2.26209 4.62438 2.35521 3.8582 2.35521C3.09203 2.35521 2.40224 2.26209 1.90727 2.11387C1.65914 2.03958 1.46563 1.95309 1.33638 1.86158C1.20451 1.76822 1.15859 1.68407 1.15859 1.61689C1.15859 1.54971 1.20451 1.46555 1.33638 1.37219C1.46563 1.28069 1.65914 1.1942 1.90727 1.1199C2.40224 0.971684 3.09203 0.878564 3.8582 0.878564C4.62438 0.878564 5.31416 0.971684 5.80914 1.1199C6.05726 1.1942 6.25077 1.28069 6.38002 1.37219C6.51189 1.46555 6.55781 1.54971 6.55781 1.61689Z"
        fill={fillClass}
        stroke={strokeClass}
        strokeWidth="0.2"
      />
      <path
        d="M6.55781 1.13764C6.55781 1.20482 6.51189 1.28897 6.38002 1.38234C6.25077 1.47384 6.05726 1.56033 5.80914 1.63463C5.31416 1.78284 4.62438 1.87596 3.8582 1.87596C3.09203 1.87596 2.40224 1.78284 1.90727 1.63463C1.65914 1.56033 1.46563 1.47384 1.33638 1.38234C1.20451 1.28897 1.15859 1.20482 1.15859 1.13764C1.15859 1.07046 1.20451 0.986306 1.33638 0.892943C1.46563 0.801442 1.65914 0.714952 1.90727 0.640653C2.40224 0.492436 3.09203 0.399316 3.8582 0.399316C4.62438 0.399316 5.31416 0.492436 5.80914 0.640653C6.05726 0.714952 6.25077 0.801442 6.38002 0.892943C6.51189 0.986306 6.55781 1.07046 6.55781 1.13764Z"
        fill={fillClass}
        stroke={strokeClass}
        strokeWidth="0.2"
      />
      <path
        d="M3.04531 0.519189H4.67242V1.35668C4.13583 1.46857 3.58191 1.46857 3.04531 1.35668V0.519189Z"
        fill={fillClass}
        stroke={strokeClass}
        strokeWidth="0.2"
      />
      <path
        d="M4.8493 0.389222C4.8493 0.40209 4.84227 0.427882 4.79912 0.464303C4.75643 0.500328 4.68873 0.537276 4.59632 0.570263C4.41237 0.635926 4.15167 0.678443 3.85893 0.678443C3.56618 0.678443 3.30549 0.635926 3.12154 0.570263C3.02913 0.537276 2.96142 0.500328 2.91874 0.464303C2.87558 0.427882 2.86855 0.40209 2.86855 0.389222C2.86855 0.376353 2.87558 0.350561 2.91874 0.31414C2.96142 0.278115 3.02913 0.241167 3.12154 0.20818C3.30549 0.142517 3.56618 0.1 3.85893 0.1C4.15167 0.1 4.41237 0.142517 4.59632 0.20818C4.68873 0.241167 4.75643 0.278115 4.79912 0.31414C4.84227 0.350561 4.8493 0.376353 4.8493 0.389222Z"
        fill={fillClass}
        stroke={strokeClass}
        strokeWidth="0.2"
      />
      <path
        d="M3.18084 3.47314L3.15137 5.59889L4.4775 4.67075V3.47314"
        stroke={strokeClass}
        strokeWidth="0.5"
      />
      <path
        d="M2.47363 2.84424L2.58344 2.92736C3.39653 3.54287 4.52873 3.50826 5.30271 2.84424V2.84424"
        stroke={strokeClass}
        strokeWidth="0.2"
      />
      <path
        d="M2.47363 2.87427L2.50439 2.91572C3.19896 3.8518 4.607 3.83072 5.27324 2.87427V2.87427"
        stroke={strokeClass}
        strokeWidth="0.2"
      />
    </svg>
  );
};

export default ContourIcon;
