import {
  ContourIcon,
  CoreIcon,
  FaceIcon,
  HoleIcon,
  PocketIcon,
  ThreadHoleIcon,
} from "assets/images/icons/features";

export const FEATURE_LIST = [
  { type: "hole", label: "Hole", icon: HoleIcon },
  { type: "thread_hole", label: "Thread Hole", icon: ThreadHoleIcon },
  { type: "face", label: "Face", icon: FaceIcon },
  { type: "pocket", label: "Pocket", icon: PocketIcon },
  { type: "contour", label: "Contour", icon: ContourIcon },
  // { type: "3d-core", label: "3D Core & Cavity", icon: CoreIcon },
  // { type: "3d-pencil", label: "3D Pencil", icon: ContourIcon },
];

export const HOLE_BOTTOM_TYPE_OPTIONS = [
  { label: "Blind", value: "Blind" },
  { label: "Through", value: "Through" },
];

export const HOLE_TYPE_OPTIONS = [
  { label: "Solid", value: "Solid" },
  { label: "Hollow", value: "Hollow" },
];

export const HOLE_BOTTOM_OPTIONS = [
  { label: "Flat", value: "Flat" },
  { label: "V Shape", value: "v Shape" },
];

export const OPEN_HOLE_TOOL_TYPE_OPTIONS = [{ label: "Drill", value: "Drill" }];

export const TOOL_TYPE_OPTIONS = [
  { label: "Drill", value: "Drill" },
  { label: "Endmill", value: "Endmill" },
  { label: "Tap", value: "Tap" },
];

export const CONFIRM_OPTIONS = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const FINISH_TYPE_OPTIONS = [
  { label: "G5", value: "g5" },
  { label: "E6", value: "e6" },
  { label: "F6", value: "f6" },
  { label: "G6", value: "g6" },
  { label: "H6", value: "h6" },
  { label: "J6", value: "j6" },
  { label: "K6", value: "k6" },
  { label: "M6", value: "m6" },
  { label: "G7", value: "g7" },
  { label: "H7", value: "h7" },
  { label: "D8", value: "d8" },
  { label: "F8", value: "f8" },
  { label: "H8", value: "h8" },
  { label: "P8", value: "p8" },
  { label: "S8", value: "s8" },
  { label: "E9", value: "e9" },
  { label: "F9", value: "f9" },
  { label: "G9", value: "g9" },
  { label: "H9", value: "h9" },
  { label: "P9", value: "p9" },
  { label: "H11", value: "h11" },
  { label: "Custom", value: "custom" },
];

export const THREAD_PICTH_TYPE_OPTIONS = [
  { label: "Coarse", value: "coarse" },
  { label: "Fine", value: "fine" },
];

export const THREAD_SIZE_OPTIONS = [
  { label: 1, value: 1 },
  { label: 1.2, value: 1.2 },
  { label: 1.4, value: 1.4 },
  { label: 1.6, value: 1.6 },
  { label: 2, value: 2 },
  { label: 2.5, value: 2.5 },
  { label: 3, value: 3 },
  { label: 3.5, value: 3.5 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 8, value: 8 },
  { label: 10, value: 10 },
  { label: 12, value: 12 },
  { label: 14, value: 14 },
  { label: 16, value: 16 },
  { label: 20, value: 20 },
  { label: 22, value: 22 },
  { label: 24, value: 24 },
  { label: 27, value: 27 },
  { label: 30, value: 30 },
  { label: 36, value: 36 },
  { label: 42, value: 42 },
  { label: 48, value: 48 },
  { label: 56, value: 56 },
  { label: 64, value: 64 },
  { label: 68, value: 68 },
];

export const THREAD_PITCH_LIST = [
  { threadSize: 1.6, coarse: 0.35, fine: "" },
  { threadSize: 2, coarse: 0.4, fine: "" },
  { threadSize: 2.5, coarse: 0.45, fine: "" },
  { threadSize: 3, coarse: 0.5, fine: "" },
  { threadSize: 3.5, coarse: 0.6, fine: "" },
  { threadSize: 4, coarse: 0.7, fine: 0.5 },
  { threadSize: 5, coarse: 0.8, fine: 0.5 },
  { threadSize: 6, coarse: 1.0, fine: 0.75 },
  { threadSize: 8, coarse: 1.25, fine: 1.0 },
  { threadSize: 10, coarse: 1.5, fine: 1.25 },
  { threadSize: 12, coarse: 1.75, fine: 1.25 },
  { threadSize: 14, coarse: 2.0, fine: 1.5 },
  { threadSize: 16, coarse: 2.0, fine: 1.5 },
  { threadSize: 20, coarse: 2.5, fine: 1.5 },
  { threadSize: 22, coarse: 2.5, fine: 1.5 },
  { threadSize: 24, coarse: 3.0, fine: 2.0 },
  { threadSize: 27, coarse: 3.0, fine: 2.0 },
  { threadSize: 30, coarse: 3.5, fine: 2.0 },
  { threadSize: 36, coarse: 4.0, fine: 3.0 },
  { threadSize: 42, coarse: 4.5, fine: 3.0 },
  { threadSize: 48, coarse: 5.0, fine: 3.0 },
  { threadSize: 56, coarse: 5.5, fine: "" },
  { threadSize: 64, coarse: 6.0, fine: "" },
  { threadSize: 68, coarse: 6.0, fine: "" },
];

export const COUNTERSINK_ANGLE_OPTIONS = [
  { label: "120 deg", value: 120 },
  { label: "90 deg", value: 90 },
  { label: "0 deg", value: 0 },
];

export const DRILL_TIP_ANGLE_OPTIONS = [
  { label: "180 deg", value: 180 },
  { label: "118 deg", value: 118 },
  { label: "0 deg", value: 0 },
];

export const FACE_OPTIONS = [
  { label: "Top Face", value: "top_face" },
  { label: "Bottom Face", value: "bottom_face" },
  { label: "Side Face", value: "side_face" },
];

export const SIDE_FACE_PROFILE_OPTIONS = [
  { label: "Flat", value: "flat" },
  { label: "Tapered", value: "tapered" },
  { label: "Spline", value: "spline" },
  { label: "Curved", value: "curved" },
];

export const SIDE_FACE_POSITION_OPTIONS = [
  { label: "Inner", value: "inner" },
  { label: "Outer", value: "outer" },
  { label: "Hole", value: "hole" },
];

export const SIDE_FACE_SURFACE_TYPE_OPTIONS = [
  { label: "Flat", value: "Flat" },
  { label: "Curved", value: "Curved" },
]

export const POCKET_OPTIONS = [
  { label: "2D Pocket", value: "pocket_2d" },
  { label: "3D Pocket", value: "pocket_3d" },
];

export const CONTOUR_OPTIONS = [
  { label: "Fillet", value: "fillet" },
  { label: "Slot", value: "slot" },
  { label: "Chamfer", value: "chamfer" },
];

export const BOTTOM_TYPE_OPTIONS = [
  { label: "Solid", value: "Solid" },
  { label: "Through", value: "Through" },
];

export const POCKET_TYPE_OPTIONS = [
  { label: "Open", value: "Open" },
  { label: "Closed", value: "Closed" },
];

export const POCKET_WALL_OPTIONS = [
  { label: "Single", value: "Single" },
  { label: "Multiple", value: "Multiple" },
];

export const POCKET_WALLS_PROFILE_TYPE_OPTIONS = [
  { label: "Flat", value: "Flat" },
  { label: "Tapered", value: "Tapered" },
  { label: "Spline", value: "Spline" },
  { label: "Curved", value: "Curved" },
]

export const FACE_SIDE_FACE_TYPE_OPTIONS = [
  { label: "Open profile", value: "Open profile" },
  { label: "Close profile", value: "Close profile" },
];

export const WALL_ANGLE_TYPE_OPTIONS = [
  { label: "Positive", value: "Positive" },
  { label: "Negative", value: "Negative" },
];

export const FILLET_LOCATION_OPTIONS = [
  { label: "Inside", value: "Inside" },
  { label: "Outside", value: "Outside" },
];

export const FILLET_SURFACE_TYPE_OPTIONS = [
  { label: "Flat", value: "Flat" },
  { label: "Curved", value: "Curved" },
]

export const FILLET_TYPE_OPTIONS = [
  { label: "Concave", value: "Concave" },
  { label: "Convex", value: "Convex" },
];

export const SLOT_OPEN_SIDES_OPTIONS = [
  { label: "Both", value: "Both" },
  { label: "One", value: "One" },
  { label: "None", value: "None" },
];

export const SLOT_SURFACE_TYPE_OPTIONS = [
  { label: "Flat", value: "Flat" },
  { label: "Curved", value: "Curved" },
]

export const COUNTOUR_SLOT_TYPE_OPTIONS = [
  { label: "Simple", value: "Simple" },
  { label: "Internal Groove", value: "Internal Groove" },
  { label: "External Groove", value: "External Groove" },
]

export const SLOT_FLOOR_TYPE_OPTIONS = [
  { label: "Solid", value: "Solid" },
  { label: "Through", value: "Through" },
]

export const CHAMFER_TYPE_OPTIONS = [
  { label: "Bevel edge", value: "Bevel edge" },
  { label: "Taper", value: "Taper" },
];

export const CHAMFER_LOCATION_OPTIONS = [
  { label: "Inside", value: "Inside" },
  { label: "Outside", value: "Outside" },
];

export const CHAMFER_SURFACE_TYPE_OPTIONS = [
  { label: "Flat", value: "flat" },
  { label: "Curved", value: "curved" },
]

export const CHAMFER_POSITION_OPTIONS = [
  { label: "Inner", value: "inner" },
  { label: "External", value: "external" }
];

export const CORE_BOTTOM_NUMBER_OPTIONS = [
  { label: "Single", value: "single" },
  { label: "Multiple", value: "multiple" },
];

export const CORE_BOTTOM_TYPE_OPTIONS = [
  { label: "Flat", value: "Flat" },
  { label: "Tapered", value: "Tapered" },
  { label: "Curvature profile", value: "Curvature profile" },
];

export const HOLE_TOOL_TYPE_OPTIONS = [];

export const INIT_HOLE_DATA = {
  diameter: 0,
  depth: 0,
  quantity: 0,
  bottom_type: "Blind",
  bottom: "v Shape",
  bottom_angle: 0,
  deburring: "no",
  additional_length: 0,
};

export const HOLE_DATA = {
  type: "open_hole", // open or finish
  diameter: "",
  depth: "",
  quantity: "",
  bottom_type: "Blind",
  bottom: "v Shape",
  bottom_radius: "",
  bottom_angle: "",
  deburring: "no",
  additional_length: 0,
  finish_type: "",
  visible: false,
};

export const INIT_THREAD_HOLE_DATA = {
  thread_type: "coarse",
  thread_size: 0,
  thread_pitch: 0,
  thread_depth: 0,
  depth: 0,

  bottom_type: "Blind",
  bottom: "v Shape",
  bottom_angle: 0,

  deburring: "yes",
  additional_length: 0,
  quantity: 1,
};

export const THREAD_HOLE_DATA = {
  type: "thread_hole",
  thread_type: "coarse",
  thread_size: "",
  thread_pitch: "",
  thread_depth: "",
  depth: "",

  bottom_type: "Blind",
  bottom: "v Shape",
  bottom_radius: "",
  bottom_angle: "",

  deburring: "yes",
  additional_length: 0,
  visible: false,
  quantity: 1,
};

export const INIT_FACE_DATA = {
  length: 0,
  breadth: 0,
  stock_on_face: 0,
  quantity: 1,
  island_info: { islands: [] },
};

export const FACE_DATA = {
  type: "top_face", // top, bottom, side
  length: 100,
  breadth: 100,
  stock_on_face: 2,
  quantity: 1,

  // Bottom
  islands: [],
  min_distance_between_islands: "",

  // Side
  depth: "",
  floor_type: "Solid",
  floor_radius: "",
  profile: "flat",
  position: "inner",
  use_corner_radius: false,
  corner_radius: "",

  // faceLength: 218.44,
  // faceBreadth: 247.65,
  // faceHeight: 10.16,
  // faceDepth: 10,
  // bottomType: "Solid",
  // faceType: "Open profile",
  // stockToRemove: 1.98,
  // groundRadius: 2.54,
  // island: false,
  // isLandLength: "",
  // isLandBreadth: "",
  // isLandHeight: "",
  // minXDistanceToIsLand: "",
  // minYDistanceToIsLand: "",
  // groundRadiusWithIsLand: "",
  // operation: "",

  // countersinkAngle: 120,
  // drillAngle: 118,
  // drillDiameter: "",
  visible: false,
};

export const ISLAND_DATA = {
  length: "",
  breadth: "",
  min_x_distance_to_face_edges: "",
  min_y_distance_to_face_edges: "",
  floor_radius: "",
  height: "",
};

export const INIT_POCKET_DATA = {
  breadth: 0,
  corner_radius: 0,
  depth: 0,
  floor_radius: 0,
  floor_type: "Solid",
  island_info: { islands: [] },
  length: 0,
  min_distance: 0,
  pocket_type: "Closed",
  quantity: 1,
  walls: "Multiple"
};

export const POCKET_DATA = {
  type: "pocket_2d", // 2d or 3d

  length: "",
  breadth: "",
  depth: "",
  pocket_type: "Closed",
  walls: "Multiple",
  corner_radius: "",
  min_distance: "",
  floor_type: "Solid",
  floor_radius: "",
  islands: [],
  min_distance_between_islands: "",
  walls_profile: "Flat",
  quantity: 1,
  // pocketLength: 241.3,
  // pocketBreadth: 187.96,
  // wallHeight: 30.48,

  // // 3d
  // wallThickness: 5,
  // wallAngleType: "Negative",
  // wallAngle: { min: 10, max: 80 },
  // //

  // stockToRemove: 1.98,
  // bottomType: "Solid",
  // groundRadius: 2.54,
  // sideRadius: 20.32,
  // reliefHoleDiameter: "",
  // reliefHoleDepth: "",

  // island: false,
  // isLandLength: 127,
  // isLandBreadth: 73.66,
  // isLandHeight: 7.62,
  // minXDistanceToIsLand: 55.88,
  // minYDistanceToIsLand: 53.34,
  // groundRadiusWithIsLand: 2.54,

  // corneringOperation: false,
  // curlingOperation: false,
  visible: false,
};

export const INIT_CONTOUR_DATA = {
  length: 0,
  depth: 0,
  type: "Convex", // type
  radius: 0,
  floor_type: "Solid",
  floor_radius: 0,
  location: "Outside",
}

export const CONTOUR_DATA = {
  type: "fillet", // slot, chamfer

  qunatity: 1,

  length: "",
  depth: "",
  fillet_type: "Convex", // type
  radius: "",
  floor_type: "Solid",
  floor_radius: "",
  location: "Outside",

  // Slot
  breadth: 20,
  corner_radius: "",
  open_sides: "",
  undercut: false,

  // Chamfer
  angle: "",
  chamfer_type: "Bevel edge",
  vertical_edges: false,
  distance: "",
  surface_type: "Flat",

  // filletType: "Inside",
  // filletLength: "",
  // filletBreadth: "",
  // filletHeight: "",
  // bottomType: "",

  // groundRadius: 0,
  // sideRadius: 15,

  // // slot
  // slotType: "Without undercut",
  // openSide: "None",
  // slotBreadth: 30,
  // slotLength: "",
  // slotDepth: 30,
  // undercutBreadth: "",
  // undercutDepth: "",
  // undercutGroundRadius: "",
  // undercutSideRadius: "",
  // reliefHoleDiameter: "",
  // raliefHoleDepth: "",

  // // Chamfer
  // chamferType: "Taper",
  // chamferAngle: { min: 30, max: 80 },
  // chamferLocation: "Outside",
  // chamferLength: 69.3,
  // verticalEdge: false,
  // distanceFromVerticalEdge: "",
  visible: false,
};

export const CORE_CAVITY_DATA = {
  count: 1,
  data: [],
  visible: false,
};

export const CORE_CAVITY_DATA_ITEM = {
  bottomLength: 120,
  bottomBreadth: 150,
  bottomDepth: 36.45,
  bottomType: "Tapered",
  groundRadius: 8,
  sideRadius: 0,
};

export const PENCIL_DATA = {
  pencilDiameter: 16,
  cornerRadius: 8,
  stockLeft: 3,
  groundRadius: 5,
  sideRadius: 10,
  distance: 45,
  wallTangent: false,
  wallAngleType: "Positive",
  wallAngle: { min: 100, max: 100 },
  visible: false,
};
