import { useContext, useEffect, useState } from "react";
import Input from "components/Input";
import Select from "components/Select";
import RadioComponent from "components/RadioComponent";
import {
  BOTTOM_TYPE_OPTIONS,
  CONFIRM_OPTIONS,
  ISLAND_DATA,
  POCKET_TYPE_OPTIONS,
  POCKET_WALL_OPTIONS,
  SET_MACHINE_SETUP,
  SET_POCKET_DATA,
  POCKET_WALLS_PROFILE_TYPE_OPTIONS,
} from "constant";
import { GlobalContext } from "context/GlobalContext";
import { useFormik } from "formik";
import * as Yup from "yup";

const init_2d_pocket = {
  length: "",
  breadth: "",
  depth: "",
  pocket_type: "",
  walls: "",
  corner_radius: "",
  min_distance: "",
  floor_type: "",
  floor_radius: "",
  islands: [],
  min_distance_between_islands: "",
  walls_profile: "",
  quantity: "",
  island_count: 0,
};

const validationSchema = Yup.object().shape({});

const TwoDForm = ({ feature_id }: any) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { projectInfo, setupNumber, machiningSetups } = state;
  // const [isLandCount, setIsLandCount] = useState(0);

  const {
    handleBlur,
    handleChange,
    setValues,
    setFieldValue,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: init_2d_pocket,
    validationSchema,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (setupNumber < 0 || !feature_id) return;
    const setupIdx = machiningSetups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );
    const feature_idx = machiningSetups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );

    const feature_info = {
      ...machiningSetups?.[setupIdx]?.features?.[feature_idx]?.featureInformation?.feature_data?.feature_info,
    };

    let formValues: any = {};
    Object.keys(init_2d_pocket).forEach((key: string) => {
      switch (key) {
        case "min_distance_between_islands":
          formValues = {
            ...formValues,
            [key]: feature_info?.island_info?.[key] ?? "",
          };
          break;
        case "islands":
          formValues = {
            ...formValues,
            [key]: feature_info?.island_info?.[key] ?? [],
          };
          break;
        case "island_count":
          formValues = {
            ...formValues,
            [key]: feature_info.island_info?.islands?.length || 0,
          };
          break;
        default:
          formValues = {
            ...formValues,
            [key]: feature_info?.[key] ?? "",
          };
          break;
      }
    });

    // if (!pocketList?.[index]) return;
    // let formValues: any = {};
    // Object.keys(init_2d_pocket).forEach((key: string) => {
    //   formValues = {
    //     ...formValues,
    //     [key]: pocketList[index][key],
    //   };
    // });
    // setIsLandCount(formValues?.islands?.length);
    setValues(formValues);
  }, [setupNumber, feature_id]);

  // useEffect(() => {
  //   if (isLandCount > -1) {
  //     let arr: any[] = [];
  //     [...Array(Number(isLandCount))].forEach(() => {
  //       arr.push({...ISLAND_DATA});
  //     });
  //     setFieldValue("islands", arr);
  //   }
  // }, [isLandCount]);

  const handleChangeFunc = (key: string, value: any, flag: boolean = false) => {
    if (flag) setFieldValue(key, value);

    let updated_setups = [...machiningSetups];
    let updatedIslandCount: number;

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );

    let updated_feature = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]?.featureInformation
        ?.feature_data?.feature_info,
    };

    switch (key) {
      case "min_distance_between_islands":
        updated_feature = {
          ...updated_feature,
          island_info: {
            ...updated_feature.island_info,
            [key]: Number(value),
          },
        };
        break;
      case "islands":
        updated_feature = {
          ...updated_feature,
          island_info: {
            ...updated_feature.island_info,
            [key]: value,
          },
        };
        break;
      case "island_count":
        updatedIslandCount = Number(value);
        if (updatedIslandCount === 0) {
          const sameRefEmptyArray: any = [];
          updated_feature.island_info = {
            islands: sameRefEmptyArray,
          };
          setFieldValue("islands", sameRefEmptyArray);
          setFieldValue("island_count", 0);
          setFieldValue("min_distance_between_islands", "");
        } else if (updatedIslandCount > 0) {
          const currentIslandCount =
            updated_feature.island_info.islands?.length ?? 0;
          const countDifference = Math.abs(
            updatedIslandCount - currentIslandCount
          );
          for (let i = 0; i < countDifference; i++) {
            if (updatedIslandCount - currentIslandCount >= 0) {
              updated_feature.island_info.islands.push({ ...ISLAND_DATA });
            } else {
              updated_feature.island_info.islands.pop();
            }
          }
        }
        if (updated_feature.island_info?.islands?.length === 1) {
          delete updated_feature.island_info.min_distance_between_islands;
          setFieldValue("min_distance_between_islands", "");
        }
        break;
      case "pocket_type":
        updated_feature = {
          ...updated_feature,
          [key]: value,
          ...(value === "Closed" && { walls: "Multiple" }),
        };
        if (value === "Closed") {
          setFieldValue("walls", "Multiple");
        } else if (value === "Open") {
          delete updated_feature.min_distance;
          setFieldValue("min_distance", "");
        }
        break;
      case "floor_type":
        const sameRefEmptyArray: any = [];
        if (value === "Through") {
          delete updated_feature.floor_radius;
          setFieldValue("islands", sameRefEmptyArray);
          setFieldValue("island_count", 0);
          setFieldValue("min_distance_between_islands", "");
        }
        updated_feature = {
          ...updated_feature,
          ...(value === "Through" && {
            island_info: { islands: sameRefEmptyArray },
          }),
          [key]: value,
        };
        break;
      case "walls":
        if (value === "Single") {
          delete updated_feature.corner_radius;
          setFieldValue("corner_radius", "");
        }
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      case "walls_profile":
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      default:
        updated_feature = {
          ...updated_feature,
          [key]: Number(value),
        };
    }

    updated_setups[setupIdx].features[
      feature_idx
    ].featureInformation.feature_data.feature_info = updated_feature;

    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });

    // dispatch({
    //   type: SET_POCKET_DATA,
    //   payload: {
    //     index,
    //     key,
    //     value,
    //   },
    // });
    // dispatch({
    //   type: SET_POCKET_DATA,
    //   payload: {
    //     index,
    //     key: "parameter",
    //     value: null,
    //   },
    // });
    // dispatch({
    //   type: SET_POCKET_DATA,
    //   payload: {
    //     index,
    //     key: "selected_strategy",
    //     value: null,
    //   },
    // });
  };

  return (
    <div>
      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">
          Feature dimensions
        </p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              placeholder="Length"
              className="mr-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="length"
              value={values?.length}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("length", values?.length);
              }}
              invalid={touched.length && errors.length}
              helperText={touched.length && errors.length}
              regex={/^\d*\.?\d*$/}
            />
          </div>
          <div className="w-6/12">
            <Input
              placeholder="Breadth"
              className="ml-2"
              type="text"
              name="breadth"
              value={values?.breadth}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("breadth", values?.breadth);
              }}
              invalid={touched.breadth && errors.breadth}
              helperText={touched.breadth && errors.breadth}
              regex={/^\d*\.?\d*$/}
            />
          </div>
        </div>

        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              placeholder="Depth"
              className="mr-2"
              type="text"
              name="depth"
              value={values?.depth}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("depth", values?.depth);
              }}
              invalid={touched.depth && errors.depth}
              helperText={touched.depth && errors.depth}
              regex={/^\d*\.?\d*$/}
            />
          </div>
          <div className="w-6/12">
            <Select
              options={POCKET_WALLS_PROFILE_TYPE_OPTIONS}
              placeholder="Walls profile"
              className="ml-2"
              value={values.walls_profile}
              onChange={(val: any) =>
                handleChangeFunc("walls_profile", val, true)
              }
              onBlur={handleBlur}
            />
          </div>
        </div>
      </div>

      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">Other Details</p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              placeholder="Quantity"
              className="mr-2"
              type="text"
              name="quantity"
              value={values?.quantity}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleChangeFunc("quantity", values?.quantity);
              }}
              invalid={touched.quantity && errors.quantity}
              helperText={touched.quantity && errors.quantity}
              regex={/^[1-9]\d*$/}
            />
          </div>
        </div>
      </div>

      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">Pocket details</p>
        <div className="flex flex-row mt-4">
          <div className="flex justify-between items-center w-6/12 py-[14px] pr-2">
            <label className="font-semibold text-gray-475 truncate mr-2">
              Pocket Type
            </label>
            <RadioComponent
              options={POCKET_TYPE_OPTIONS}
              setValue={(val: any) =>
                handleChangeFunc("pocket_type", val, true)
              }
              value={values?.pocket_type}
            />
          </div>
          {values?.pocket_type === "Open" && (
            <div className="flex justify-between items-center w-6/12 py-[14px] pl-2">
              <label className="font-semibold text-gray-475 truncate mr-2">
                Walls
              </label>
              <RadioComponent
                options={POCKET_WALL_OPTIONS}
                setValue={(val: any) => handleChangeFunc("walls", val, true)}
                value={values?.walls}
              />
            </div>
          )}
        </div>
        {(values?.walls === "Multiple" || values?.pocket_type === "Closed") && (
          <div className="flex flex-row mt-4">
            {values?.walls === "Multiple" && (
              <div className="w-6/12">
                <Input
                  placeholder="Corner radius"
                  className="mr-2"
                  type="text"
                  name="corner_radius"
                  value={values?.corner_radius}
                  onChange={handleChange}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    handleChangeFunc("corner_radius", values?.corner_radius);
                  }}
                  invalid={touched.corner_radius && errors.corner_radius}
                  helperText={touched.corner_radius && errors.corner_radius}
                  regex={/^\d*\.?\d*$/}
                  />
              </div>
            )}
            {values?.pocket_type === "Closed" && (
              <div className="w-6/12">
                <Input
                  placeholder="Min distance"
                  className="ml-2"
                  type="text"
                  name="min_distance"
                  value={values?.min_distance}
                  onChange={handleChange}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    handleChangeFunc("min_distance", values?.min_distance);
                  }}
                  invalid={touched.min_distance && errors.min_distance}
                  helperText={touched.min_distance && errors.min_distance}
                  regex={/^\d*\.?\d*$/}
                />
              </div>
            )}
          </div>
        )}
      </div>

      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">Floor details</p>
        <div className="flex flex-row mt-4">
          <div className="flex justify-between items-center w-6/12 py-[14px]">
            <label className="font-semibold text-gray-475 truncate mr-2">
              Floor Type
            </label>
            <RadioComponent
              options={BOTTOM_TYPE_OPTIONS}
              setValue={(val: any) => handleChangeFunc("floor_type", val, true)}
              value={values?.floor_type}
            />
          </div>

          {values?.floor_type === "Solid" && (
            <div className="w-6/12">
              <Input
                placeholder="Floor Radius"
                className="ml-2"
                type="text"
                name="floor_radius"
                value={values?.floor_radius}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc("floor_radius", values?.floor_radius);
                }}
                invalid={touched.floor_radius && errors.floor_radius}
                helperText={touched.floor_radius && errors.floor_radius}
                regex={/^\d*\.?\d*$/}
              />
            </div>
          )}
        </div>
      </div>

      {values?.floor_type === "Solid" && (
        <div className="mt-4">
          <p className="font-bold text-base text-gray-202">Island details</p>
          <div className="flex flex-row mt-4">
            <div className="w-6/12">
              <Input
                placeholder="No. of islands"
                className="mr-2"
                type="text"
                name="island_count"
                value={values.island_count}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc("island_count", values?.island_count);
                }}
                regex={/^\d*$/}
              />
            </div>

            {values.island_count > 1 && (
              <div className="w-6/12">
                <Input
                  placeholder="Min distance between islands"
                  className="ml-2"
                  type="text"
                  name="min_distance_between_islands"
                  value={values?.min_distance_between_islands}
                  onChange={handleChange}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    handleChangeFunc(
                      "min_distance_between_islands",
                      values?.min_distance_between_islands
                    );
                  }}
                  invalid={
                    touched.min_distance_between_islands &&
                    errors.min_distance_between_islands
                  }
                  helperText={
                    touched.min_distance_between_islands &&
                    errors.min_distance_between_islands
                  }
                  regex={/^\d*\.?\d*$/}
                />
              </div>
            )}
          </div>

          {!!values?.islands?.length &&
            values?.islands?.map((item: any, index: number) => (
              <div className="flex flex-col" key={index}>
                <div className="py-3 border-b border-black">
                  <p className="font-semibold">Island #{index + 1} details</p>
                </div>
                <div className="flex flex-row mt-4">
                  <div className="w-6/12">
                    <Input
                      placeholder="Length"
                      className="mr-2"
                      type="text"
                      value={item?.length}
                      onChange={(e: any) => {
                        let data: any = [...values?.islands];
                        data[index]["length"] = Number(e?.target?.value);
                        setFieldValue("islands", data);
                      }}
                      onBlur={(e: any) => {
                        // handleBlur(e);
                        handleChangeFunc("islands", values?.islands);
                      }}
                      regex={/^\d*\.?\d*$/}
                    />
                  </div>
                  <div className="w-6/12">
                    <Input
                      placeholder="Breadth"
                      className="ml-2"
                      type="text"
                      value={item?.breadth}
                      onChange={(e: any) => {
                        let data: any = [...values?.islands];
                        data[index]["breadth"] = Number(e?.target?.value);
                        setFieldValue("islands", data);
                      }}
                      onBlur={(e: any) => {
                        // handleBlur(e);
                        handleChangeFunc("islands", values?.islands);
                      }}
                      regex={/^\d*\.?\d*$/}
                    />
                  </div>
                </div>

                <div className="flex flex-row mt-4">
                  <div className="w-6/12">
                    <Input
                      placeholder="Min x distance to face edges"
                      className="mr-2"
                      type="text"
                      value={item?.min_x_distance_to_face_edges}
                      onChange={(e: any) => {
                        let data: any = [...values?.islands];
                        data[index]["min_x_distance_to_face_edges"] = Number(
                          e?.target?.value
                        );
                        setFieldValue("islands", data);
                      }}
                      onBlur={(e: any) => {
                        // handleBlur(e);
                        handleChangeFunc("islands", values?.islands);
                      }}
                      regex={/^\d*\.?\d*$/}
                    />
                  </div>
                  <div className="w-6/12">
                    <Input
                      placeholder="Min y distance to face edges"
                      className="ml-2"
                      type="text"
                      value={item?.min_y_distance_to_face_edges}
                      onChange={(e: any) => {
                        let data: any = [...values?.islands];
                        data[index]["min_y_distance_to_face_edges"] = Number(
                          e?.target?.value
                        );
                        setFieldValue("islands", data);
                      }}
                      onBlur={(e: any) => {
                        // handleBlur(e);
                        handleChangeFunc("islands", values?.islands);
                      }}
                      regex={/^\d*\.?\d*$/}
                    />
                  </div>
                </div>

                <div className="flex flex-row mt-4">
                  <div className="w-6/12">
                    <Input
                      placeholder="Floor radius"
                      className="mr-2"
                      type="text"
                      value={item?.floor_radius}
                      onChange={(e: any) => {
                        let data: any = [...values?.islands];
                        data[index]["floor_radius"] = Number(e?.target?.value);
                        setFieldValue("islands", data);
                      }}
                      onBlur={(e: any) => {
                        // handleBlur(e);
                        handleChangeFunc("islands", values?.islands);
                      }}
                      regex={/^\d*\.?\d*$/}
                    />
                  </div>
                  <div className="w-6/12">
                    <Input
                      placeholder="Height"
                      className="ml-2"
                      type="text"
                      value={item?.height}
                      onChange={(e: any) => {
                        let data: any = [...values?.islands];
                        data[index]["height"] = Number(e?.target?.value);
                        setFieldValue("islands", data);
                      }}
                      onBlur={(e: any) => {
                        // handleBlur(e);
                        handleChangeFunc("islands", values?.islands);
                      }}
                      regex={/^\d*\.?\d*$/}
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default TwoDForm;
