import { useState, useEffect, useContext } from "react";
import clsx from "clsx";
import { Checkbox } from "components/Checkbox";
import { FiEdit2 } from "react-icons/fi";
import { GlobalContext } from "context/GlobalContext";
import StrategyMenu from "./StrategyMenu";
import Button from "components/Button/Button";
import { ACTION_BY_KEY, SET_MACHINE_SETUP } from "constant";
import "./style.css";
import InvalidOperationsPopUp from "./InvalidOperationsPopUp";
import InValidCross from "assets/images/icons/uiw_stop-o.svg";
import InValidCrossRed from "assets/images/icons/uiw_stop-o-red.svg";
import { renderToString } from "react-dom/server";

interface IMachiningStrategySummaryBox {
  isAdd?: boolean;
  type?: "recommend" | "alternate" | "custom";
  setSelectedSummary?: any;
  kind?: string;
  checked?: boolean;
  selectedItem?: any;
  index: number;
  setStrategyData?: any;
  handleSelectedStrategyData?: any;
  strategy?: any;
  setInValid?: Function;
}

const MachiningStrategySummaryBox: React.FC<IMachiningStrategySummaryBox> = ({
  strategy,
  type,
  setSelectedSummary,
  selectedItem,
  index,
  setInValid
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { machiningSetups, setupNumber, showTooltip, hideTooltip } = state;
  const [isEdit, setIsEdit] = useState(false);

  const [tempData, setTempData] = useState<any>(null);

  const [showPopUp, setShowPopUp] = useState(false);

  const handleCheck = (checked: boolean) => {
    if (!checked) return;
    let updated_setups = [...machiningSetups]

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === selectedItem?.feature_id
    );

    const feature_information = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]
        ?.featureInformation,
    };

    let selected = null;
    let data = [...feature_information?.strategies];
    const new_machining_strategy =
      data?.[0]?.machining_strategy?.map(
        (item: any, idx: number) => {
          if (idx === index) {
            selected = { ...item, isSelected: true };
            return { ...item, isSelected: true };
          }
          return { ...item, isSelected: false };
        }
      );

    data[0].machining_strategy = new_machining_strategy;

    updated_setups[setupIdx].features[
      feature_idx
    ].featureInformation.strategies = data;

    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });

    setSelectedSummary(selected);
  };

  const handleToolType = (
    passIdx: number,
    operationIdx: number,
    value: string
  ) => {
    const new_data: any = JSON.parse(JSON.stringify(tempData));
    new_data.passes[passIdx].operations[operationIdx].tool_paths[0][
      "tool_type"
    ] = value;
    setTempData(new_data);
  };

  const handleSave = () => {
    let updated_setups = [...machiningSetups]

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === selectedItem?.feature_id
    );

    const feature_information = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]
        ?.featureInformation,
    };
    
    tempData?.passes?.forEach((pass: any, ind1: number) => {
      pass?.operations?.forEach((operation: any, ind2: number) => {
        if (
          operation?.tool_paths?.[0]?.tool_type !==
          strategy?.passes?.[ind1]?.operations[ind2]?.tool_paths?.[0]?.tool_type
        ) {
          delete operation.parameters;
          delete operation.tools;
        }
      });
    });

    let data = [...feature_information?.strategies];

    data[0].machining_strategy[index] = tempData;
    updated_setups[setupIdx].features[
      feature_idx
    ].featureInformation.strategies = data;

    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });
    
    setIsEdit(false);
  };

  return (
    <div
      className={clsx(
        "shadow-box-1 bg-white rounded-lg min-w-[322px] min-h-[212px] relative mr-2",
        {
          "bg-[#FEF3F2] border-[1px] border-solid border-[#F04438]":
            !strategy?.isValid,
        }
      )}
    >
      {/* Title */}
      <div className="py-2 mx-2 border-b border-b-gray-eac flex flex-row items-center justify-between">
        <div className="flex flex-row items-center">
          <Checkbox
            checked={(isEdit ? tempData : strategy)?.isSelected}
            onChange={(val: boolean) => {
              handleCheck(val);
            }}
            disabled={!strategy?.isValid}
            type="yellow"
          />
          <span
            className={`font-bold text-sm ${
              strategy?.isValid ? "text-gray-475" : "text-gray-400"
            } ml-3`}
          >
            Strategy {Number(index) + 1}
          </span>
        </div>
        <div className="flex flex-row space-x-[10px] items-center">
          {!isEdit ? (
            <>
              {type === "recommend" && (
                <span className="bg-grean-039 rounded py-1 px-1.5 text-white text-[10px]">
                  Recommended
                </span>
              )}
              {type === "custom" && (
                <span className="bg-grean-039 rounded py-1 px-1.5 text-white text-[10px]">
                  Custom
                </span>
              )}
              <button
                onClick={() => {
                  setShowPopUp(true);
                }}
                id={`strategy${index}-invalidButton`}
                onMouseOver={() => {
                  if (strategy?.isValid) {
                    showTooltip(`strategy${index}-invalidButton`, "gray-344");
                  }
                }}
                onMouseLeave={() => hideTooltip()}
                data-tooltip-html={renderToString(
                  <p className="font-medium text-base leading-[16.94px]">
                    Mark as Invalid
                  </p>
                )}
              >
                <img
                  src={strategy?.isValid ? InValidCross : InValidCrossRed}
                  alt="InValid"
                />
              </button>
              <InvalidOperationsPopUp
                strategyInd={index}
                showPopUp={showPopUp}
                closePopUp={() => setShowPopUp(false)}
                strategy={strategy}
                setInValid={setInValid}
              />
              {strategy?.isValid && (
                <FiEdit2
                  className="text-[14px] text-gray-475 cursor-pointer"
                  onClick={() => {
                    setTempData(strategy);
                    setIsEdit(true);
                  }}
                />
              )}
            </>
          ) : (
            <div className="flex flex-row items-center space-x-2">
              <Button
                size="sm"
                variant="gray"
                className="rounded"
                onClick={() => {
                  // setData(strategy);
                  setIsEdit(false);
                }}
              >
                Cancel
              </Button>
              <Button size="sm" className="rounded" onClick={handleSave}>
                Save
              </Button>
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col pl-2 pr-2 overflow-y-auto">
        {(!!isEdit ? tempData : strategy)?.passes?.length &&
          (isEdit ? tempData : strategy)?.passes?.map(
            (item: any, idx: number) => (
              <div
                className={clsx("py-1.5 w-full", {
                  "border-t border-t-gray-eac": idx > 0,
                })}
                key={idx}
              >
                <p
                  className={`text-xs font-semibold ${
                    strategy?.isValid ? "text-gray-344" : "text-gray-400"
                  } mb-1`}
                >
                  {item?.pass}
                </p>
                {!!item?.operations?.length &&
                  item?.operations?.map((operation: any, idx1: number) => {
                    return (
                      <div
                        className="flex flex-row items-center justify-between w-full py-1"
                        key={idx1}
                      >
                        <div className="flex flex-row space-x-1">
                          <div className="flex flex-col text-center overflow-hidden">
                            <div className="w-2 h-2 round-label rounded-full mt-1 bg-gray-b9b"></div>
                            <div className="w-[2px] h-[100%] bg-gray-200 mt-0 m-auto"></div>
                          </div>
                          <div>
                            <p
                              className={`text-xs font-medium ${
                                strategy?.isValid
                                  ? "text-gray-475"
                                  : "text-gray-400"
                              }`}
                            >
                              {`${
                                operation?.tool_paths?.[0]?.tool_path_type ||
                                operation?.tool_paths?.[0]?.type ||
                                ""
                              } - ${
                                operation?.tool_paths?.[0]?.tool_path ||
                                operation?.tool_paths?.[0]?.name ||
                                ""
                              }`}
                            </p>
                            <p className="text-[10px] text-gray-b9b font-medium">
                              <span className="font-bold">Operation: </span>
                              {operation?.operation ?? "N/A"}
                            </p>
                            <p className="text-[10px] text-gray-b9b font-medium">
                              <span className="font-bold">Location: </span>
                              {operation?.location ?? "N/A"}
                            </p>
                          </div>
                        </div>
                        <div>
                          <StrategyMenu
                            value={
                              operation?.tool_paths?.[0]?.tool_type
                                ? {
                                    label:
                                      operation?.tool_paths?.[0]?.tool_type,
                                    value:
                                      operation?.tool_paths?.[0]?.tool_type,
                                  }
                                : {
                                    label:
                                      operation?.tool_paths?.[0]
                                        ?.tool_types?.[0],
                                    value:
                                      operation?.tool_paths?.[0]
                                        ?.tool_types?.[0],
                                  }
                            }
                            options={operation?.tool_paths?.[0]?.tool_types?.map(
                              (type: string) => ({ label: type, value: type })
                            )}
                            onChange={(option: any) => {
                              handleToolType(idx, idx1, option?.value);
                            }}
                            isEdit={
                              isEdit &&
                              operation?.tool_paths?.[0]?.tool_types?.length > 1
                            }
                            isValid={strategy?.isValid ?? true}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            )
          )}
      </div>
    </div>
  );
};

export default MachiningStrategySummaryBox;
