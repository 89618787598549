const FeatureIcon = ({ color = "black" }) => {
  return <svg
    width="30"
    height="26"
    viewBox="0 0 30 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9025 7.30165L13.9025 5.07351L22.4408 9.99023C22.7509 10.1688 22.942 10.4994 22.942 10.8571L22.942 18.4913C22.942 18.6372 22.9101 18.7813 22.8485 18.9135L21.7821 21.2014C21.7205 21.3336 21.6885 21.4777 21.6885 21.6237L21.6885 24.3135L16.5415 21.4021"
      stroke={color}
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <path
      d="M15.2135 9.25616L15.2135 7.5774L21.6227 11.1355L21.6227 17.5927L19.5678 16.3723"
      stroke={color}
      strokeWidth="0.5"
    />
    <path
      d="M15.3523 11.3798L15.3523 14.0346L16.2759 14.4108"
      stroke={color}
      strokeWidth="0.5"
    />
    <path
      d="M14.0357 5.20533L16.1468 3.68986L25.3172 9.02696L25.3172 22.0732L21.6227 24.1817"
      stroke={color}
      strokeWidth="0.5"
    />
    <path
      d="M22.9138 10.2852L25.3171 9.02697"
      stroke={color}
      strokeWidth="0.5"
    />
    <path
      d="M13.3819 20.4918L13.3819 24.2476L8.76371 24.2476L8.7637 16.9338L8.7637 16.2273C8.7637 15.6278 9.01547 15.056 9.45767 14.6511V14.6511C9.47405 14.6361 9.48337 14.615 9.48337 14.5928L9.48337 13.5734V13.5734C9.13723 13.1844 8.89589 12.7139 8.78213 12.2061L8.7637 12.1238L8.7637 9.55407V9.55407C9.51424 6.83058 13.3804 6.83995 14.1675 9.55407V9.55407V9.55407C14.4548 10.0131 14.1249 10.6083 13.5833 10.6083L8.7637 10.6083"
      stroke={color}
      strokeWidth="0.5"
    />
    <path
      d="M10.603 15.0728V15.0728C11.312 14.9997 12.0152 15.2576 12.509 15.7718L14.8273 18.1856L17.3343 14.957L17.4662 12.7168L18.9836 11.0695V11.0695C19.5062 10.9597 19.9335 11.4882 19.7165 11.9759L19.7093 11.992L18.7857 13.0462L19.0911 13.0462C19.651 13.0462 20.1052 13.5002 20.1052 14.0601L20.1052 14.2154C20.1052 14.689 19.7213 15.0728 19.2475 15.0728V15.0728L16.9493 18.8831C15.9605 20.5224 13.709 20.8277 12.3177 19.5112L11.5266 18.7626"
      stroke={color}
      strokeWidth="0.5"
    />
    <path
      d="M14.164 10.6296L14.0015 12.0629C13.8997 12.9611 13.3406 13.7433 12.5234 14.1309V14.1309C12.4778 14.1526 12.4487 14.1986 12.4487 14.249L12.4487 15.6819"
      stroke={color}
      strokeWidth="0.5"
    />
    <path
      d="M12.0135 10.5382L12.0135 10.8401C12.0135 11.0739 11.9339 11.3007 11.7877 11.4832V11.4832C11.6656 11.6356 11.4808 11.7242 11.2855 11.7242L8.75766 11.7242"
      stroke={color}
      strokeWidth="0.5"
    />
  </svg>;
};

export default FeatureIcon;
