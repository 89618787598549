import { useEffect, useState, useContext } from "react";
import clsx from "clsx";
import {
  CONTOUR_DATA,
  FACE_DATA,
  FEATURE_LIST,
  HOLE_DATA,
  INIT_CONTOUR_DATA,
  INIT_FACE_DATA,
  INIT_HOLE_DATA,
  INIT_POCKET_DATA,
  INIT_THREAD_HOLE_DATA,
  POCKET_DATA,
  SET_LOADING_BAR,
  SET_MACHINE_SETUP,
  SET_ORIGIN_MACHINE_SETUP,
  SET_SETUP_NUMBER,
  SET_STREAM_STEP,
  THREAD_HOLE_DATA,
  keyList,
} from "constant";
import AddYellowCircleIcon from "assets/images/icons/add-yellow-circle-icon.svg";
import AccordionItem from "components/AccordionItem";
import HoleForm from "./HoleForm";
import AddIcon from "assets/images/icons/add-md-icon.svg";
import ThreadHoleForm from "./ThreadHoleForm";
import FaceForm from "./FaceForm";
import PocketForm from "./PocketForm";
import ContourForm from "./ContourForm";
import Button from "components/Button";
import { GlobalContext } from "context/GlobalContext";
import { useLocation, useNavigate } from "react-router-dom";
import ProjectItemList from "components/ProjectItemList";
import { v4 as uuid } from "uuid";
import { useApiCall } from "hooks";
import { getUserSelectionAPI } from "services";

interface IFeatureItem {
  item?: any;
  selected?: string;
  onSelect?: any;
}

const FeatureItem: React.FC<IFeatureItem> = ({ item, selected, onSelect }) => {
  const classes = clsx({
    "rounded border-solid border-2 px-4 py-3 flex flex-row items-center my-2 cursor-pointer":
      true,
    "bg-surface-secondary border-surface-default": selected === item?.type,
    "bg-white border-surface-secondary": selected !== item?.type,
  });

  const textClass = clsx({
    "font-semibold text-xs": true,
    "text-black": selected !== item?.type,
    "text-surface-default": selected === item?.type,
  });
  const IconView = item?.icon;

  return (
    <div className={classes} onClick={() => onSelect(item?.type)}>
      <div className="mr-2">
        {<IconView active={selected === item?.type} />}
      </div>
      <span className={textClass}>{item?.label}</span>
    </div>
  );
};

const FeatureRecognition = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state, dispatch } = useContext(GlobalContext);
  const {
    showTooltip,
    hideTooltip,
    projectInfo,
    setupNumber,
    machiningSetups,
  } = state;
  const [getSelection, isLoading] = useApiCall(getUserSelectionAPI);
  const [selectedItem, setSelectedItem] = useState("hole");
  const [selectedFeature, setSelectedFeature] = useState<any>(null);

  useEffect(() => {
    if (!projectInfo || !Object.keys(projectInfo)?.length) {
      navigate("/projects");
      return;
    }
    if (!location?.state?.load) return;

    if (!projectInfo?.userSelectionId) {
      const newSetup = {
        machiningSetupNumber: 0,
        toolLibraryId: "",
        toolLibraryName: "",
        features: [],
      };
      dispatch({
        type: SET_MACHINE_SETUP,
        payload: [newSetup],
      });
      dispatch({
        type: SET_SETUP_NUMBER,
        payload: 0,
      });
      return;
    }

    getSelection({
      organizationId: projectInfo?.organizationId,
      selectionId: projectInfo?.userSelectionId,
    })
      .then((res: any) => {
        if (!res) {
          dispatch({
            type: SET_MACHINE_SETUP,
            payload: [],
          });
          dispatch({
            type: SET_ORIGIN_MACHINE_SETUP,
            payload: [],
          });
          dispatch({
            type: SET_STREAM_STEP,
            payload: null,
          });
          return;
        }
        dispatch({
          type: SET_MACHINE_SETUP,
          payload: [...res?.machiningSetups],
        });
        dispatch({
          type: SET_ORIGIN_MACHINE_SETUP,
          payload: [...res?.machiningSetups],
        });
        dispatch({
          type: SET_STREAM_STEP,
          payload: res?.streamStep,
        });
        if (res?.machiningSetups?.length) {
          dispatch({
            type: SET_SETUP_NUMBER,
            payload: res?.machiningSetups?.[0]?.machiningSetupNumber,
          });
        }
      })
      .catch(() => {
        dispatch({
          type: SET_MACHINE_SETUP,
          payload: [],
        });
        dispatch({
          type: SET_ORIGIN_MACHINE_SETUP,
          payload: [],
        });
        dispatch({
          type: SET_STREAM_STEP,
          payload: null,
        });
      });
  }, [projectInfo]);

  useEffect(() => {
    if (!machiningSetups?.length || setupNumber < 0) {
      setSelectedFeature({});
      return;
    }
    const selected = machiningSetups?.find(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );
    setSelectedFeature(selected);
    if (selectedItem === "hole") {
      for (const { type } of keyList) {
        if (
          selected?.features?.find(
            (feature: any) =>
              feature?.featureInformation?.feature_data?.feature_type === type
          )
        ) {
          setSelectedItem(type);
          break;
        }
      }
    }
  }, [machiningSetups, setupNumber]);

  useEffect(() => {
    if (isLoading) {
      dispatch({
        type: SET_LOADING_BAR,
        payload: true,
      });
    } else {
      dispatch({
        type: SET_LOADING_BAR,
        payload: false,
      });
    }
  }, [isLoading]);

  const addHole = () => {
    let updated_setups = [...machiningSetups];

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    let updated_features = updated_setups?.[setupIdx]?.features;

    let new_feature = {};
    let feature_id;

    const material_info = {
      material: projectInfo?.material || "aluminium",
      sub_material: projectInfo?.subMaterial || "any",
    };
    const machine_info = {
      name: projectInfo?.machineName || "Hermle c800",
      rpm: projectInfo?.rpm || 1000,
      axes: projectInfo?.numberOfAxis?.toString() || "3",
    };

    switch (selectedItem) {
      case "hole":
        feature_id = `${HOLE_DATA?.type}_${uuid()}`;
        new_feature = {
          feature_id,
          featureInformation: {
            feature_data: {
              feature_id,
              feature_name: HOLE_DATA?.type,
              feature_type: "hole",
              material_info,
              machine_info,
              feature_info: {
                ...INIT_HOLE_DATA,
              },
            },
            strategies: [],
          },
        };
        // dispatch({
        //   type: SET_HOLE_LIST,
        //   payload: [
        //     ...holeList,
        //     { ...HOLE_DATA, id: `${HOLE_DATA?.type}_${uuid()}` },
        //   ],
        // });
        break;
      case "thread_hole":
        feature_id = `thread_hole_${uuid()}`;
        new_feature = {
          feature_id,
          featureInformation: {
            feature_data: {
              feature_id,
              feature_name: THREAD_HOLE_DATA?.type,
              feature_type: "thread_hole",
              material_info,
              machine_info,
              feature_info: {
                ...INIT_THREAD_HOLE_DATA,
              },
            },
            strategies: [],
          },
        };
        // dispatch({
        //   type: SET_THREAD_HOLE_LIST,
        //   payload: [
        //     ...threadHoleList,
        //     { ...THREAD_HOLE_DATA, id: `thread_hole_${uuid()}` },
        //   ],
        // });
        break;
      case "face":
        feature_id = `${FACE_DATA?.type?.toLowerCase()}_${uuid()}`;
        new_feature = {
          feature_id,
          featureInformation: {
            feature_data: {
              feature_id,
              feature_name: FACE_DATA?.type,
              feature_type: "face",
              material_info,
              machine_info,
              feature_info: JSON.parse(JSON.stringify(INIT_FACE_DATA)),
            },
            strategies: [],
          },
        };
        // dispatch({
        //   type: SET_FACE_LIST,
        //   payload: [
        //     ...faceList,
        //     { ...FACE_DATA, id: `${FACE_DATA?.type?.toLowerCase()}_${uuid()}` },
        //   ],
        // });
        break;
      case "pocket":
        feature_id = `${POCKET_DATA?.type}_${uuid()}`;
        new_feature = {
          feature_id,
          featureInformation: {
            feature_data: {
              feature_id,
              feature_name: POCKET_DATA?.type,
              feature_type: "pocket",
              material_info,
              machine_info,
              feature_info: JSON.parse(JSON.stringify(INIT_POCKET_DATA)),
            },
            strategies: [],
          },
        };
        // dispatch({
        //   type: SET_POCKET_LIST,
        //   payload: [
        //     ...pocketList,
        //     { ...POCKET_DATA, id: `${POCKET_DATA?.type}_${uuid()}` },
        //   ],
        // });
        break;
      case "contour":
        feature_id = `${CONTOUR_DATA?.type?.toLowerCase()}_${uuid()}`;
        new_feature = {
          feature_id,
          featureInformation: {
            feature_data: {
              feature_id,
              feature_name: CONTOUR_DATA?.type,
              feature_type: "contour",
              material_info,
              machine_info,
              feature_info: {
                ...INIT_CONTOUR_DATA,
              },
            },
            strategies: [],
          },
        };
        // dispatch({
        //   type: SET_CONTOUR_LIST,
        //   payload: [
        //     ...contourList,
        //     {
        //       ...CONTOUR_DATA,
        //       id: `${CONTOUR_DATA?.type?.toLowerCase()}_${uuid()}`,
        //     },
        //   ],
        // });
        break;
      // case "3d-core":
      //   dispatch({
      //     type: SET_CORE_CAVITY_LIST,
      //     payload: [
      //       ...coreCavityList,
      //       { ...CORE_CAVITY_DATA, id: `core_${uuid()}` },
      //     ],
      //   });
      //   break;
      // case "3d-pencil":
      //   dispatch({
      //     type: SET_PENCIL_LIST,
      //     payload: [...pencilList, { ...PENCIL_DATA, id: `pencil_${uuid()}` }],
      //   });
      //   break;
    }

    updated_features.push(new_feature);
    updated_setups[setupIdx].features = updated_features;

    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });
  };

  const handleRemove = (feature_id: string) => {
    let updated_setups = [...machiningSetups];

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );
    if (feature_idx < 0) return;
    updated_setups?.[setupIdx]?.features?.splice(feature_idx, 1);
    // updated_setups[setupIdx].features = updated_features;

    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });
  };

  const handleRedirect = (tab: string) => {
    navigate(`/project?tab=${tab}`);
  };

  const handleBackToProjectList = () => {
    navigate(`/projects`);
  };
  const featuresAvailable: boolean = machiningSetups?.[setupNumber]?.features?.length > 0;

  return (
    <div className="relative h-[calc(100vh-166px)] flex px-4 ">
      <div className="w-[30%] flex h-full flex-row space-x-2">
        <ProjectItemList />
        <div className="w-full overflow-y-auto h-full flex flex-col justify-between bg-gray-f9f p-1 rounded-md border border-gray-eac">
          <div className="w-full">
            <p className="font-medium text-gray-475 text-[10px]">
              Features Identified
            </p>
            <div className="pr-1">
              {FEATURE_LIST.map((item: any, idx) => (
                <FeatureItem
                  item={item}
                  key={idx}
                  selected={selectedItem}
                  onSelect={setSelectedItem}
                />
              ))}
            </div>
          </div>
          <div className="mr-2">
            <button
              className="border-2 border-dashed rounded border-surface-default text-surface-default text-sm font-semibold py-3 w-full flex items-center justify-center"
              onClick={() => addHole()}
            >
              <img src={AddYellowCircleIcon} alt="add icon" className="mr-2" />
              Add Feature
            </button>
          </div>
        </div>
      </div>
      <div className="w-[70%] px-4 flex flex-row relative pt-0.5">
        <div className="w-full mr-2 pr-2 mt-px overflow-y-auto h-[calc(100%-75px)]">
          {selectedItem === "hole" &&
            selectedFeature?.features
              ?.filter(
                (feature: any) =>
                  feature?.featureInformation?.feature_data?.feature_type ===
                  "hole"
              )
              ?.map((hole: any, idx: number) => (
                <AccordionItem
                  index={idx}
                  key={idx}
                  tabLabelChild={`${idx + 1}. Hole`}
                  className="mb-2"
                  removeHole={() => handleRemove(hole?.feature_id)}
                >
                  <HoleForm key={idx} feature_id={hole?.feature_id} />
                </AccordionItem>
              ))}
          {selectedItem === "thread_hole" &&
            selectedFeature?.features
              ?.filter(
                (feature: any) =>
                  feature?.featureInformation?.feature_data?.feature_type ===
                  "thread_hole"
              )
              ?.map((hole: any, idx: number) => (
                <AccordionItem
                  index={idx}
                  key={idx}
                  tabLabelChild={`${idx + 1}. Thread Hole`}
                  className="mb-2"
                  removeHole={() => handleRemove(hole?.feature_id)}
                >
                  <ThreadHoleForm key={idx} feature_id={hole?.feature_id} />
                </AccordionItem>
              ))}
          {selectedItem === "face" &&
            selectedFeature?.features
              ?.filter(
                (feature: any) =>
                  feature?.featureInformation?.feature_data?.feature_type ===
                  "face"
              )
              ?.map((face: any, idx: number) => (
                <AccordionItem
                  index={idx}
                  key={idx}
                  tabLabelChild={`${idx + 1}. Face`}
                  className="mb-2"
                  removeHole={() => handleRemove(face?.feature_id)}
                >
                  <FaceForm key={idx} feature_id={face?.feature_id} />
                </AccordionItem>
              ))}

          {selectedItem === "pocket" &&
            selectedFeature?.features
              ?.filter(
                (feature: any) =>
                  feature?.featureInformation?.feature_data?.feature_type ===
                  "pocket"
              )
              ?.map((pocket: any, idx: number) => (
                <AccordionItem
                  index={idx}
                  key={idx}
                  tabLabelChild={`${idx + 1}. Pocket`}
                  className="mb-2"
                  removeHole={() => handleRemove(pocket?.feature_id)}
                >
                  <PocketForm key={idx} feature_id={pocket?.feature_id} />
                </AccordionItem>
              ))}

          {selectedItem === "contour" &&
            selectedFeature?.features
              ?.filter(
                (feature: any) =>
                  feature?.featureInformation?.feature_data?.feature_type ===
                  "contour"
              )
              ?.map((contour: any, idx: number) => (
                <AccordionItem
                  index={idx}
                  key={idx}
                  tabLabelChild={`${idx + 1}. Contour`}
                  className="mb-2"
                  removeHole={() => handleRemove(contour?.feature_id)}
                >
                  <ContourForm key={idx} feature_id={contour?.feature_id} />
                </AccordionItem>
              ))}

          {/* {selectedItem === "3d-core" &&
            coreCavityList?.map((coreCavity: any, idx: number) => (
              <AccordionItem
                index={idx}
                key={idx}
                tabLabelChild={`${idx + 1}. 3D Core & Cavity`}
                className="mb-2"
                removeHole={handleRemove}
              >
                <CoreCavity index={idx} />
              </AccordionItem>
            ))}

          {selectedItem === "3d-pencil" &&
            pencilList?.map((pencil: any, idx: number) => (
              <AccordionItem
                index={idx}
                key={idx}
                tabLabelChild={`${idx + 1}. 3D Pencil`}
                className="mb-2"
                removeHole={handleRemove}
              >
                <PencilForm index={idx} />
              </AccordionItem>
            ))} */}
        </div>
        <button
          className="rounded shadow-add-btn bg-white p-2 h-fit mt-px"
          onClick={() => addHole()}
        >
          <img src={AddIcon} alt="add icon" />
        </button>

        <div className="w-full absolute bottom-0 left-0 right-0 bg-white flex justify-end items-center pt-4 border-t border-gray-e7e px-5">
          <div className="flex flex-row">
            <Button
              variant="ghost"
              className="mr-2 px-5"
              onClick={() => handleBackToProjectList()}
            >
              Back
            </Button>
            <div
              className={clsx({
                "cursor-not-allowed": !featuresAvailable,
              })}
              id="feature-btn"
              onMouseOver={() =>
                !featuresAvailable && showTooltip("feature-btn")
              }
              onMouseLeave={() => hideTooltip()}
              data-tooltip-content="You must enter at least one feature to Continue"
            >
              <Button
                className={clsx("px-5", {
                  "pointer-events-none": !featuresAvailable,
                })}
                onClick={() => handleRedirect("feature-summary")}
                disabled={!featuresAvailable}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureRecognition;
