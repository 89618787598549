import DarkLogo from "assets/images/logo/dark-logo.svg";
import CharmCirleIcon from "assets/images/icons/charm-circle.svg";
import { Link, useNavigate } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { Fragment, useContext, useEffect, useState } from "react";
import clsx from "clsx";
import UserImg from "assets/images/user.png";
import { Auth } from "aws-amplify";
import { GlobalContext } from "context/GlobalContext";
import { useApiCall } from "hooks";
import { getUserAPI } from "services";
import { Storage } from "aws-amplify";
import React from "react";
import { SET_PROFILE_IMG } from "constant";

const MENU_LIST = [
  {
    label: "Setting",
    url: "/setting",
  },
];

const TopNavigation = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { userInfo, profileImgUrl } = state;
  const navigate = useNavigate();
  const [getUser, loading, userData] = useApiCall(getUserAPI);

  useEffect(() => {
    if (!userInfo || !profileImgUrl) return;
    getUser({
      userRecordId: userInfo?.["sub"],
      organizationId: userInfo?.["custom:organizationId"],
    });
  }, [userInfo]);

  useEffect(() => {
    if (!userData?.profileImgPath || !!profileImgUrl) return;
    getImageUrl(userData.profileImgPath)
  }, [userData?.profileImgPath]);

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      navigate("/login");
    } catch (err) {
      console.log("Sign Out Err", err);
    }
  };

  const getImageUrl = async (key: string) => {
    try {
      const url = await Storage.get(key);
      dispatch({
        type: SET_PROFILE_IMG,
        payload: url
      })
    } catch (error) {
      dispatch({
        type: SET_PROFILE_IMG,
        payload: ""
      })
    }
  };

  const handleLogo = () => {
    if (!!userInfo) {
      navigate("/projects");
    } else {
      navigate("/");
    }
  };

  return (
    <div className="w-full h-[48px] px-4 py-2 flex flex-row shadow-nav justify-between">
      <div
        className="flex flex-row items-center cursor-pointer"
        onClick={handleLogo}
      >
        <img src={DarkLogo} alt="logo" />
        <p className="text-black font-semibold text-xl ml-[14px]">
          Generative Programming
        </p>
      </div>
      <div className="flex flx-row items-center space-x-2">
        <Menu as="div" className="relative w-8 h-8">
          {({ open }) => (
            <>
              <Menu.Button>
                <div className={clsx("w-8 h-8 rounded-lg drop-shadow-avatar")}>
                  <img
                    src={profileImgUrl}
                    alt="avatar"
                    className="w-full h-full object-cover rounded-lg"
                    onError={(
                      event: React.SyntheticEvent<HTMLImageElement, Event>
                    ) => {
                      event.currentTarget.onerror = null;
                      event.currentTarget.src = UserImg;
                    }}
                  />
                </div>
              </Menu.Button>

              <Transition
                show={open}
                as={Fragment}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right divide-y rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {MENU_LIST.map((menu, idx) => (
                    <Menu.Item key={idx}>
                      <Link to={menu.url}>
                        <p className="py-2 px-3 cursor-pointer font-medium text-black-222 hover:bg-surface-secondary hover:text-surface-default">
                          {menu.label}
                        </p>
                      </Link>
                    </Menu.Item>
                  ))}
                  <Menu.Item>
                    <p
                      className="py-2 px-3 cursor-pointer font-medium text-black-222 hover:bg-surface-secondary hover:text-surface-default"
                      onClick={handleSignOut}
                    >
                      Sign Out
                    </p>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
        <div>
          <img src={CharmCirleIcon} alt="charm-circle" />
        </div>
      </div>
    </div>
  );
};

export default React.memo(TopNavigation);
