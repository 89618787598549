import { ISLAND_LABELS } from "constant";

interface ITooltipIslandInfo {
  island: any;
  index: any;
}

const TooltipIslandInfo: React.FC<ITooltipIslandInfo> = ({ island, index }) => {
  return (
    <div>
      <div className="border border-gray-200 p-[8px] rounded-md text-start">
        <h4 className="text-[12px] font-semibold text-gray-700 m-0 text-start">
          Island {index + 1}
        </h4>
        {Object.entries(ISLAND_LABELS).map(
          ([key, value]: [string, any], idx: number) => (
            <p key={idx} className="text-[10px] font-semibold text-gray-600">
              {`${value}: ${island[key]}`}
            </p>
          )
        )}
      </div>
    </div>
  );
};

export default TooltipIslandInfo;
