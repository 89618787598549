import clsx from "clsx";
import { useState, useRef, useEffect, useCallback } from "react";
import { useClickOutside } from "hooks";
import { DefaultToggler } from "./DefaultToggler";
import "./select.css";
import ReactDOM from "react-dom";

export interface IOption {
  label: string | number;
  value: string | boolean | number;
  tag?: string;
  textColor?: string;
  bgColor?: string;
}

interface ISelect {
  value?: string | number | boolean;
  onChange?: any;
  onBlur?: any;
  options: IOption[];
  className?: string;
  placeholder?: string;
  invalid?: any;
  helperText?: any;
  disabled?: boolean;
  isTag?: boolean;
}

const Select: React.FC<ISelect> = ({
  value,
  onChange = () => {},
  onBlur = () => {},
  options,
  placeholder,
  className,
  invalid,
  helperText,
  disabled = false,
  isTag
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<any>(value);
  const selectRef = useRef(null);

  const toggleSelect = useCallback(
    () => setIsOpen((isOpen) => !isOpen),
    [isOpen]
  );

  useEffect(() => {
    setSelected(value);
  }, [value]);

  useClickOutside(selectRef, (event: any) => {
    isOpen && onBlur(event);
    setIsOpen(false);
  });

  const handleOptionClick = useCallback((value: string | boolean | number) => {
    setSelected(value);
    toggleSelect();
    onChange(value);
  }, []);

  const classes = clsx({
    select: true,
    // States
    "select-invalid": invalid,
  });

  const optionsClsx = clsx({
    "select-options": true,
  });

  return (
    <div className={className}>
      <div ref={selectRef} className={clsx("relative", classes)}>
        <DefaultToggler
          isOpen={isOpen}
          toggleSelect={toggleSelect}
          selected={selected}
          placeholder={placeholder}
          disabled={disabled}
          options={options}
          className={
            isOpen
              ? "border-surface-default border border-solid"
              : "shadow-input-2"
          }
          isTag
        />
        {(() => {
          if (!isOpen) return;
          const child = (
            <ul className={clsx("top-0 absolute left-0 right-0", optionsClsx)}>
              {options?.map((option, idx) => {
                const { label, value } = option;
                const optionClsx = clsx({
                  "select-option": true,
                  "select-option-border": idx !== options.length - 1,
                  "select-option-active": selected === value,
                });
                return (
                  <li
                    key={idx}
                    className={optionClsx}
                    onClick={() => handleOptionClick(value)}
                  >
                    {option?.tag && (
                      <div
                        className="w-6 h-6 rounded-sm font-medium flex justify-center items-center mr-2"
                        style={{
                          color: option?.textColor,
                          background: option?.bgColor,
                        }}
                      >
                        {option?.tag}
                      </div>
                    )}
                    {label}
                  </li>
                );
              })}
            </ul>
          );

          return child;
        })()}
      </div>
      {helperText && <span className="select-helper-text">{helperText}</span>}
    </div>
  );
};

export default Select;
