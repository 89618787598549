import { Link, useNavigate } from "react-router-dom";
import LogoImg from "assets/images/logo.svg";
import RegisterSuccessImg from "assets/images/register-success.svg";

const RegisterSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center w-full h-full relative">
      <div className="fixed top-0 right-0 left-0 bottom-0 bg-auth-background w-full h-full bg-cover "></div>
      <div className="fixed top-0 right-0 left-0 bottom-0 splash-background"></div>
      <div className="w-6/12 h-full flex flex-col justify-between px-5 py-10 z-[1]">
        <img src={LogoImg} alt="logo" className="max-w-[180px] h-fit" />
        <div className="flex flex-col">
          <p className="text-surface-default font-semibold text-lg">
            Welcome to
          </p>
          <p className="text-black font-bold text-[40px] mb-6">
            Generative Programming
            <span className="text-[10px] text-black-222">(Beta v-1.0.0)</span>
          </p>
          <p className="text-gray-494 font-medium">
            A tool that brings the power of artificial intelligence to CNC
            programmers by recommending machining strategies, optimal cutting
            tools & machining parameters, and more based on the specific
            features in your CAD files.
          </p>
        </div>
        <div className="flex flex-row justify-between items-center">
          <span className="text-gray-424 text-xs font-semibold">
            2024 © Lambda Function, Inc.
          </span>
          <span className="text-gray-424 text-xs font-semibold">
            Beta v-1.0.0
          </span>
        </div>
      </div>
      <div className="w-6/12 h-full flex justify-center relative pt-10 pb-16">
        <div className="w-full h-full overflow-auto px-5 flex justify-center items-center">
          <div className="flex flex-col max-w-[50vw] p-px my-auto h-fit w-8/12 min-w-[350px]">
            <div className="flex flex-col items-center text-black font-semibold text-[27px] mb-5">
              <img src={RegisterSuccessImg} className="mb-5 w-15 h-15" />
              <p>Registration </p>
              <p>successfully</p>
              <div className="rounded bg-surface-default w-[48px] h-1"></div>
            </div>
            <p className="text-gray-696 font-medium text-sm max-w-[360px] text-center mb-5">
              An email has been sent to the site administrator. The
              administrator will review the information that has been submitted
              and either approve or deny your request. you will receive an email
              with instructions on what you will need to do next. <br />
              <br />
              Thanks for your Patience.
            </p>
            <Link
              to="/login"
              className="text-blue-033 text-sm font-semibold text-center"
            >
              Back to Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterSuccess;
