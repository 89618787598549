import React, { ReactNode } from "react";
import clsx from "clsx";

interface IButton {
  variant?: 'primary' | 'secondary' | 'ghost' | 'danger' | "gray";
  size?: "sm" | "md" | "lg";
  children?: ReactNode | string;
  className?: string;
  disabled?: boolean;
  onClick?: any;
  type?: "button" | "reset" | "submit";
  id?: string;
  onMouseOver?: any;
  onMouseLeave?: any;
}

const Button: React.FC<IButton> = ({
  variant = 'primary',
  children,
  size = "md",
  className = "",
  disabled = false,
  type = "button",
  onClick,
  ...props
}) => {
  let variantClassName = '';
  if (variant === 'primary') {
    variantClassName = 'bg-surface-default text-white'
  } else if (variant === 'secondary') {
    variantClassName = 'border-surface-default text-surface-default border'
  } else if (variant === 'ghost') {
    variantClassName = 'text-surface-default'
  } else if (variant === 'danger') {
    variantClassName = ' bg-red-ff0 text-white'
  } else if (variant === 'gray') {
    variantClassName = ' bg-gray-fff text-gray-475'
  } 
  const classes = clsx({
    "flex justify-center items-center":
      true,
    "px-2 py-0.5 text-xs": size === "sm",
    "px-4 py-2 text-sm rounded": size === "md",
    "px-6 py-4 text-2xl font-semibold rounded-xl": size === "lg",
    [className]: className,
    [variantClassName]: variantClassName,
    "hover:opacity-80 active:opacity-50": !disabled,
    // bg-gray-d9d 
    "cursor-not-allowed opacity-50": disabled,
  });
  return (
    <button
      className={classes}
      disabled={disabled}
      type={type}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
