import clsx from "clsx";

interface IProjectItem {
  value?: any;
  selected?: boolean;
  onSelect?: any;
  children: any;
}

const ProjectItem: React.FC<IProjectItem> = ({
  value,
  selected,
  onSelect,
  children,
}) => {
  const classes = clsx({
    "rounded border-solid border-2 flex flex-row items-center justify-center my-2 cursor-pointer h-10 w-10":
      true,
    "bg-surface-secondary border-surface-default": selected,
    "bg-white border-gray-eac": !selected,
  });

  const textClass = clsx({
    "font-semibold text-sm": true,
    "text-gray-475": !selected,
    "text-surface-default": selected,
  });
  return (
    <div className={classes} onClick={() => onSelect(value)}>
      <span className={textClass}>{children}</span>
    </div>
  );
};

export default ProjectItem;
