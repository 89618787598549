export const MATERIAL_LIST = [
  {
    label: "Steel",
    value: "p | steel",
    tag: "P",
    bgColor: "#00B9F1",
    textColor: "#FFFFFF",
  },
  {
    label: "Stainless Steel",
    value: "m | stainless steel",
    tag: "M",
    bgColor: "#FFEB3B",
    textColor: "#344054",
  },
  {
    label: "Cast Iron",
    value: "k | cast iron",
    tag: "K",
    bgColor: "#EF4130",
    textColor: "#FFFFFF",
  },
  {
    label: "Nonferrous",
    value: "n | nonferrous",
    tag: "N",
    bgColor: "#00B26B",
    textColor: "#FFFFFF",
  },
  {
    label: "Superalloys",
    value: "s | superalloys",
    tag: "S",
    bgColor: "#F58345",
    textColor: "#FFFFFF",
  },
  {
    label: "Hardened Steel",
    value: "h | hardened steel",
    tag: "H",
    bgColor: "#BBBDBF",
    textColor: "#344054",
  },
];

export const SUB_MATERIAL_LIST: any = {
  "p | steel": [
    { label: "Carbon Steel", value: "carbon steel" },
    { label: "Free-Cut Steel", value: "free-cut steel" },
    { label: "Low Alloy Steel", value: "low alloy steel" },
    {
      label: "High Alloy Steel",
      value: "high alloy steel",
    },
  ],
  "m | stainless steel": [
    {
      label: "Austenitic Stainless Steel",
      value: "austenitic stainless steel",
    },
    {
      label: "Martensitic / Ferritic Stainless Steel",
      value: "martensitic / ferritic stainless steel",
    },
    { label: "Duplex Stainless Steel", value: "duplex stainless steel" },
  ],
  "k | cast iron": [
    { label: "Grey Cast Iron", value: "grey cast iron" },
    { label: "Nodular cast iron", value: "nodular cast iron" },
    { label: "Malleable cast iron", value: "malleable cast iron" },
  ],
  "n | nonferrous": [
    { label: "Copper / Brass", value: "copper / brass" },
    { label: "Aluminum", value: "aluminum" },
    { label: "Plastics", value: "plastics" },
  ],
  "s | superalloys": [
    { label: "Nickel (Ni) based", value: "nickel (ni) based" },
    { label: "Iron-based", value: "iron-based" },
    { label: "Cobalt-based superalloys", value: "cobalt-based superalloys" },
    {
      label: "Titanium-based superalloys",
      value: "titanium-based superalloys",
    },
  ],
  "h | hardened steel": [
    { label: "Heat Treated to 45-65 HRC", value: "heat treated to 45-65 hrc" },
  ],
};

export const UNIT_LIST = [
  { label: "millimeters (mm)", value: "mm" },
  { label: "centimeters (cm)", value: "cm" },
  { label: "inches (in)", value: "in" },
];

export const MACHINE_NAME_LIST = [
  { label: "Hermle c800", value: "Hermle c800" },
  { label: "dmg_evo50_5ax", value: "dmg_evo50_5ax" },
  {
    label: "dmg_evo60_5ax dmg_evo70_5ax",
    value: "dmg_evo60_5ax dmg_evo70_5ax",
  },
  {
    label: "dmg_evo80_5ax hermle c800 5ax",
    value: "dmg_evo80_5ax hermle c800 5ax",
  },
  { label: "dmg_dmu80_3ax Dmg_Dmu80", value: "dmg_dmu80_3ax Dmg_Dmu80" },
  { label: "makino d500 Grob_g350", value: "makino d500 Grob_g350" },
  {
    label: "Grob_g550 unisign_unipro5_4ax",
    value: "Grob_g550 unisign_unipro5_4ax",
  },
  {
    label: "unisign_unipro5_5ax unisign_unipro5000_5ax",
    value: "unisign_unipro5_5ax unisign_unipro5000_5ax",
  },
  {
    label: "dmg_hsc105_5ax Chiron mill3000",
    value: "dmg_hsc105_5ax Chiron mill3000",
  },
  { label: "Grob_g550T Makino A81M", value: "Grob_g550T Makino A81M" },
  {
    label: "Makino A100 Chevalier qp2033",
    value: "Makino A100 Chevalier qp2033",
  },
];

export const ORIENTATION_LIST = [
  { label: "Horizontal", value: "horizontal" },
  { label: "Vertical", value: "vertical" },
];

export const NUMBER_OF_AXES_LIST = [
  { label: 3, value: 3 },
  { label: 5, value: 5 },
];
