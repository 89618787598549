import clsx from "clsx";
import { useState } from "react";
import "./style.css";
import uniqid from "uniqid";

interface ICircleCheckbox {
  checked: boolean;
  onChange: any;
  label?: string;
  type?: 'black' | 'yellow';
  className?: string;
  labelClassName?: string;
}

const CircleCheckbox: React.FC<ICircleCheckbox> = ({ checked, onChange, label, type = 'black', className, labelClassName }) => {
  const [isCheck, setIsCheck] = useState(checked);

  const handleChange = (e: any) => {
    setIsCheck(e.target.checked);
    onChange(e.target.checked);
  }

  const name = `check-${uniqid()}`;

  return (
    <div className="flex flex-row items-center">
      <div className="round-circle">
        <input type="checkbox" id={name} checked={isCheck} onChange={handleChange}/>
        <label htmlFor={name} className={clsx('h-5 w-5 border-2 border-surface-default', className)}>
          <span className={clsx('absolute font-semibold text-base left-[27px] top-[-3px] text-black whitespace-nowrap', labelClassName)}>{label}</span>
        </label>
      </div>
    </div>
  );
};

export default CircleCheckbox;
