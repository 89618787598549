import { useState, useEffect, useContext } from "react";
import Modal from "components/Modal";
import copy from "copy-to-clipboard";
import { FiCopy, FiX } from "react-icons/fi";
import Button from "components/Button";
import {
  useLocalFusionApiCall,
  useLocalSiemensNxApiCall,
  useLocalMasterCamApiCall,
} from "hooks";
import { GlobalContext } from "context";

interface IShareURLModal {
  data?: any;
  isOpen: boolean;
  setIsOpen?: any;
}

const ShareURLModal: React.FC<IShareURLModal> = ({
  setIsOpen,
  isOpen,
  data,
}) => {
  const { state } = useContext(GlobalContext);
  const { machiningSetups, streamStep } = state;
  const [copied, setCopied] = useState(false);
  const [fetchFunsionData, fushionloading, fushionData, fushionError] =
    useLocalFusionApiCall();
  const [fetchSiemensNxData, siemensNxLoading, siemensNxData, siemensNxError] =
    useLocalSiemensNxApiCall();
  const [fetchMasterCamData, MasterCamLoading, MasterCamData, MasterCamError] =
    useLocalMasterCamApiCall();
  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  const handleOpen = (e: boolean) => {
    if (!e) {
      setIsOpen(false);
    }
  };

  const handleFusion = () => {
    fetchFunsionData("stream", machiningSetups);
  };

  const handleSiemensNx = () => {
    fetchSiemensNxData("generate_tool_path", machiningSetups);
  };

  const handlemasterCam = () => {
    fetchMasterCamData("generate_tool_path", machiningSetups);
  }

  return (
    <Modal isOpen={isOpen} setIsOpen={handleOpen} size="default">
      <Modal.Header>
        <div className="flex flex-row items-center justify-between pb-4">
          <h3 className="text-lg font-medium leading-6 text-black-222">
            Excel File Download
          </h3>
          <FiX
            className="w-6 h-6 cursor-pointer text-black-222"
            onClick={() => setIsOpen(false)}
          />
        </div>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div>
          <div className="p-4 border-surface-default border border-solid rounded flex flex-nowrap flex-row justify-between items-center">
            <span className="truncate w-[calc(100%-24px)] mr-1">
              {data?.url}
            </span>
            <FiCopy
              className="h-5 w-5 cursor-pointer text-black-222"
              onClick={() => {
                copy(data?.url);
                setCopied(true);
              }}
            />
          </div>
          {copied && (
            <div className="flex w-full justify-end">
              <span className="text-surface-default text-sm ">Copied!</span>
            </div>
          )}

          {streamStep &&
            streamStep?.toString()?.toLowerCase() !== "" && ( // FIXME: "params-complete" && (
              <>
                <h3 className="text-lg font-medium leading-6 text-black-222 my-4">
                  Generate Toolpath
                </h3>
                <div className="flex justify-center space-x-4">
                  <Button
                    className="!rounded-full py-1.5 w-[120px]"
                    onClick={handleSiemensNx}
                  >
                    SiemensNx
                  </Button>
                  <Button
                    className="!rounded-full py-1.5 w-[120px]"
                    onClick={handlemasterCam}
                  >
                    MasterCAM
                  </Button>
                  <Button
                    className="!rounded-full py-1.5 w-[120px]"
                    onClick={handleFusion}
                  >
                    Fusion360
                  </Button>
                </div>
              </>
            )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShareURLModal;
