import { forwardRef } from "react";
import { fetchByDotOperator } from "utils";

const COLUMN_DATA = [
  { label: "Tool Index (tool_index)", id: ""},
  { label: "Preset Name (preset_name)", id: "name"},
  { label: "Type (tool_type)", id: "type", isRoot: true},
  { label: "Description (tool_description)", id: "description"},
  { label: "Diameter (tool_diameter)", id: ""},
  { label: "Number (tool_number)", id: ""},
  {label: "Unit (tool_unit)", id: "unit", isRoot: true},
  { label: "Holder Description (holder_description)", id: "holder.description", isRoot: true},
  { label: "Holder Product ID (holder_productId)", id: "holder.product-id", isRoot: true},
  { label: "Holder Product Link (holder_productLink)", id: "holder.product-link", isRoot: true },
  { label: "Holder Vendor (holder_vendor)", id: "holder.vendor", isRoot: true},
  { label: "Abrasive Flow Rate (tool_abrasiveFlowRate)", id: ""},
  { label: "Orientation (tool_angle)", id: ""},
  { label: "Gauge Length (tool_assemblyGaugeLength)", id: "geometry.assemblyGaugeLength", isRoot: true},
  { label: "Assist Gas (tool_assistGas)", id: ""},
  { label: "Axial Distance (tool_axialDistance)", id: ""},
  { label: "Bead Width (tool_beadWidth)", id: ""},
  { label: "Body Length (tool_bodyLength)", id: ""},
  { label: "Break Control (tool_breakControl)", id: "post-process.break-control", isRoot: true},
  { label: "Clamping (tool_clamping)", id: ""},
  { label: "Clockwise Spindle Rotation (tool_clockwise)", id: ""},
  { label: "Comment (tool_comment)", id: "post-process.comment", isRoot: true},
  { label: "Compensation (tool_compensation)", id: ""},
  { label: "Compensation Offset (tool_compensationOffset)", id: ""},
  { label: "Coolant (tool_coolant)", id: "tool-coolant"},
  { label: "Coolant Support (tool_coolantSupport)", id: ""},
  { label: "Corner Radius (tool_cornerRadius)", id: ""},
  { label: "Cross Section (tool_crossSection)", id: ""},
  { label: "Cut Height (tool_cutHeight)", id: ""},
  { label: "Cut Power (tool_cutPower)", id: ""},
  { label: "Cutting Width (tool_cuttingWidth)", id: ""},
  { label: "Auxiliary Gas Flow Rate (tool_depositingAuxiliaryGasFlowRate)", id: ""},
  { label: "Carrier Gas Flow Rate (tool_depositingCarrierGasFlowRate)", id: ""},
  { label: "Current (tool_depositingCurrent)", id: ""},
  { label: "Power (tool_depositingPower)", id: ""},
  { label: "Shield Gas Flow Rate (tool_depositingShieldGasFlowRate)", id: ""},
  { label: "Voltage (tool_depositingVoltage)", id: ""},
  { label: "Depth of Cut (tool_depthOfCut)", id: ""},
  { label: "Diameter Offset (tool_diameterOffset)", id: "post-process.diameter-offset"},
  { label: "End Angle (tool_endAngle)", id: ""},
  { label: "End Cutting (tool_endCutting)", id: ""},
  { label: "Cutting Feedrate (tool_feedCutting)", id: ""},
  { label: "Cutting Feed per Revolution (tool_feedCuttingRel)", id: ""},
  { label: "Depositing Feedrate (tool_feedDepositing)", id: ""},
  { label: "Lead-In Feedrate (tool_feedEntry)", id: "v_f_leadIn"},
  { label: "Lead-In Feed per Revolution (tool_feedEntryRel)", id: ""},
  { label: "Lead-Out Feedrate (tool_feedExit)", id: "v_f_leadOut"},
  { label: "Lead-Out Feed per Revolution (tool_feedExitRel)", id: ""},
  { label: "Plunge Feed per Revolution (tool_feedPerRevolution)", id: "v_f_plunge"},
  { label: "Feed per Tooth (tool_feedPerTooth)", id: ""},
  { label: "Plunge Feedrate (tool_feedPlunge)", id: ""},
  { label: "Link Feedrate (tool_feedProbeLink)", id: ""},
  { label: "Measure Feedrate (tool_feedProbeMeasure)", id: ""},
  { label: "Ramp Feedrate (tool_feedRamp)", id: "n_ramp"},
  { label: "Retract Feedrate (tool_feedRetract)", id: ""},
  { label: "Retract Feed per Revolution (tool_feedRetractPerRevolution)", id: ""},
  { label: "Transition Feedrate (tool_feedTransition)", id: "v_f_transition"},
  { label: "Wire Feedrate (tool_feedWire)", id: ""},
  { label: "Flute Length (tool_fluteLength)", id: ""},
  { label: "Use Opposite Edge (tool_grooveCompOppositeEdge)", id: ""},
  { label: "Groove Width (tool_grooveWidth)", id: ""},
  { label: "Hand (tool_hand)", id: ""},
  { label: "Head Clearance (tool_headClearance)", id: ""},
  { label: "Head Length (tool_headLength)", id: ""},
  { label: "Gauge Length (tool_holderGaugeLength)", id: "holder.gaugeLength", isRoot: true},
  { label: "Head Length (tool_holderHeadLength)", id: ""},
  { label: "Overall Length (tool_holderOverallLength)", id: ""},
  { label: "Style (tool_holderType)", id: "holder.type"},
  { label: "Angle (tool_insertAngle)", id: ""},
  { label: "Insert size (tool_insertSize)", id: ""},
  { label: "Size specified by (tool_insertSizeSpecificationMode)", id: ""},
  { label: "Shape (tool_insertType)", id: ""},
  { label: "Width (tool_insertWidth)", id: ""},
  { label: "Internal Thread (tool_internalThread)", id: ""},
  { label: "Kerf Width (tool_kerfWidth)", id: ""},
  { label: "Layer Thickness (tool_layerThickness)", id: ""},
  { label: "Leading Angle (tool_leadingAngle)", id: ""},
  { label: "Trailing edge length (tool_lengthNonCuttingEdge)", id: ""},
  { label: "Length Offset (tool_lengthOffset)", id: "post-process.length-offset", isRoot: true},
  { label: "Live Tool (tool_live)", id: "post-process.live"},
  { label: "Lower Radius (tool_lowerRadius)", id: ""},
  { label: "Quality Control (tool_machineQualityControl)", id: ""},
  { label: "Manual Tool Change (tool_manualToolChange)", id: "post-process.manual-tool-change", isRoot: true},
  { label: "Material (tool_material)", id: ""},
  { label: "Maximum Thread Pitch (tool_maximumThreadPitch)", id: ""},
  { label: "Minimum Thread Pitch (tool_minimumThreadPitch)", id: ""},
  { label: "Nozzle Diameter (tool_nozzleDiameter)", id: ""},
  { label: "Number of Flutes (tool_numberOfFlutes)", id: ""},
  { label: "Number of Teeth (tool_numberOfTeeth)", id: ""},
  { label: "Overall Length (tool_overallLength)", id: ""},
  { label: "Pierce Height (tool_pierceHeight)", id: ""},
  { label: "Pierce Power (tool_piercePower)", id: ""},
  { label: "Pierce Time (tool_pierceTime)", id: ""},
  { label: "Powder Flow Rate (tool_powderFlowRate)", id: ""},
  { label: "Category (tool_presetMaterialCategory)", id: "material.category"},
  { label: "Materials match (tool_presetMaterialQuery)", id: ""},
  { label: "Preset Program Number (tool_presetProgram)", id: ""},
  { label: "Pressure (tool_pressure)", id: ""},
  { label: "Product ID (tool_productId)", id: "holder.product-id", isRoot: true},
  { label: "Product Link (tool_productLink)", id: "holder.product-link", isRoot: true},
  { label: "Profile Radius (tool_profileRadius)", id: ""},
  { label: "Ramp Spindle Speed (tool_rampSpindleSpeed)", id: "n_ramp"},
  { label: "Relief Angle (tool_reliefAngle)", id: ""},
  { label: "Round Shank (tool_roundShank)", id: ""},
  { label: "Shaft Diameter (tool_shaftDiameter)", id: ""},
  { label: "Shank Height (tool_shankHeight)", id: ""},
  { label: "Shank Width (tool_shankWidth)", id: ""},
  { label: "Shoulder Length (tool_shoulderLength)", id: "geometry.shoulder-length", isRoot: true},
  { label: "Side Angle (tool_sideAngle)", id: ""},
  { label: "Side Cutting (tool_sideCutting)", id: ""},
  { label: "Spindle Speed (tool_spindleSpeed)", id: ""},
  { label: "Stand-off Distance (tool_standoffDistance)", id: ""},
  { label: "Stepdown (tool_stepdown)", id: "use-stepdown"},
  { label: "Stepover (tool_stepover)", id: "use-stepover"},
  { label: "Surface Speed (tool_surfaceSpeed)", id: ""},
  { label: "Taper Angle (tool_taperAngle)", id: ""},
  { label: "Tapered Type (tool_taperedType)", id: ""},
  { label: "Thickness (tool_thickness)", id: ""},
  { label: "Thread Pitch (tool_threadPitch)", id: ""},
  { label: "Thread Profile Angle (tool_threadProfileAngle)", id: ""},
  { label: "Thread Tip Radius (tool_threadTipRadius)", id: ""},
  { label: "Thread Tip Type (tool_threadTipType)", id: ""},
  { label: "Thread Tip Width (tool_threadTipWidth)", id: ""},
  { label: "Tip Angle (tool_tipAngle)", id: ""},
  { label: "Tip Diameter (tool_tipDiameter)", id: ""},
  { label: "Tip Length (tool_tipLength)", id: ""},
  { label: "Tip Offset (tool_tipOffset)", id: ""},
  { label: "Tolerance (tool_tolerance)", id: ""},
  { label: "Trailing Angle (tool_trailingAngle)", id: ""},
  { label: "Turret (tool_turret)", id: "post-process.turret", isRoot: true},
  { label: "Upper Radius (tool_upperRadius)", id: ""},
  { label: "Use Constant Surface Speed (tool_useConstantSurfaceSpeed)", id: ""},
  { label: "Use Feed per Revolution (tool_useFeedPerRevolution)", id: ""},
  { label: "Vendor (tool_vendor)", id: "holder.vendor", isRoot: true},
  { label: "Use Depth of Cut (use_tool_depthOfCut)", id: ""},
  { label: "Use Preset Program Number (use_tool_presetProgram)", id: ""},
  { label: "Use Stepdown (use_tool_stepdown)", id: "use-stepdown"},
  { label: "Use Stepover (use_tool_stepover)", id: "use-stepover"},
  { label: "Shaft Segments (shaft_segments)", id: ""},
  { label: "Holder Segments (holder_segments)", id: ""},
  { label: "Tool Library Version (tool_library_version)", id: ""},
  { label: "CSV_TOOLS_VERSION_1", id: ""},
];

const LibraryExport = forwardRef<HTMLTableElement, any>(
  ({ data }, ref) => {
    return (
        <table ref={ref}>
          <tr>
            {COLUMN_DATA.map((column, idx) => (
              <td key={idx}>{column.label}</td>
            ))}
          </tr>
          {
            data?.map((item: any, idx: number) => <>
              {
                item?.['start-values']?.['presets'].map((present: any, idx1: number) => <tr>
                  {
                    COLUMN_DATA.map((column, idx3) => <td>{
                      column.isRoot? fetchByDotOperator(item, column.id): fetchByDotOperator(present, column.id)
                    }</td>)
                  }
                </tr>)
              }
            </>)
          }
        </table>
    );
  }
);

export default LibraryExport;
