import clsx from "clsx";
import { useEffect, useState } from "react";
import uniqid from "uniqid";
import "./input.css";

interface IInput {
  placeholder?: string;
  value?: any;
  onChange?: any;
  className?: string;
  onBlur?: any;
  unit?: string;
  subClassName?: string;
  inputClassName?: string;
  placeholderClassName?: string;
  label?: string;
  size?: "default" | "small";
  inputType?: "input" | "textarea";
  [name: string]: any;
  regex?: RegExp;
}
const Input: React.FC<IInput> = ({
  placeholder = "",
  value,
  onChange,
  className = "",
  helperText,
  invalid,
  success,
  warning,
  disabled,
  onBlur,
  unit,
  inputClassName,
  placeholderClassName,
  subClassName,
  label,
  inputType = "input",
  size = "default",
  regex,
  ...rest
}) => {
  const [focus, setFocus] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const classes = clsx(subClassName, "relative", {
    "px-5 py-[14px] rounded-[8px] input-field h-[52px]": size === "default",
    "px-2.5 py-1 rounded-[8px] input-field h-[38px]": size === "small",
    "!h-fit": inputType === "textarea",
    "border-surface-default border border-solid": focus,
    "!pt-[16px] !pb-[12px]": size === "default" && placeholder,
    // "!pt-[16px] !pb-[12px]": size === 'default' && placeholder,
    "shadow-input-2": !focus,
    "input-invalid": invalid,
    "input-success": success,
    "input-warning": warning,
    "input-disabled": disabled,
  });

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handle = (e: any) => {
    // Not making changes to the inputValue if the input's target value fails to
    // match with the regular expression.
    if (regex && e?.target?.value && !regex.test(e.target.value)) {
      return;
    }
    setInputValue(e?.target?.value);
    if (onChange) onChange(e);
  };

  const handleBlue = (e: any) => {
    setFocus(false);
    onBlur(e);
  };

  const name = uniqid();

  return (
    <div className={className}>
      {label && (
        <p className="mb-1.5 text-gray-696 font-semibold text-sm">{label}</p>
      )}
      <div
        className={clsx(classes, "flex", {
          "items-center":
            size === "small" &&
            !(!!inputValue?.toString()?.trim()?.length || focus),
          "items-end":
            size === "small" &&
            (!!inputValue?.toString()?.trim()?.length || focus),
        })}
      >
        <label
          className={clsx(
            placeholderClassName,
            "absolute pointer-events-none z-10 transition-all",
            {
              "left-[20px] font-medium": size === "default",
              "left-[10px] font-normal text-sm": size === "small",
              "text-[10px] top-[2px]":
                size === "default" &&
                (!!inputValue?.toString()?.trim()?.length || focus),
              "text-[8px] top-[0px]":
                size === "small" &&
                (!!inputValue?.toString()?.trim()?.length || focus),
              "top-[16px] text-gray-b9b text-sm":
                size === "default" &&
                !(!!inputValue?.toString()?.trim()?.length || focus),
              "top-[9px] text-gray-b9b text-sm":
                size === "small" &&
                !(!!inputValue?.toString()?.trim()?.length || focus),
            }
          )}
          htmlFor={name}
        >
          {placeholder}
        </label>
        {inputType === "input" && (
          <input
            id={name}
            className={clsx(" w-full outline-none", inputClassName, {
              "pr-6": !!unit,
              "font-medium text-sm": size === "default",
              "font-normal text-sm": size === "small",
            })}
            value={inputValue}
            onChange={handle}
            onFocus={() => setFocus(true)}
            onBlur={handleBlue}
            {...rest}
          />
        )}
        {inputType === "textarea" && (
          <textarea
            id={name}
            className={clsx("w-full outline-none mt-3", inputClassName, {
              "pr-6": !!unit,
              "font-medium text-sm": size === "default",
              "font-normal text-sm": size === "small",
            })}
            value={inputValue}
            onChange={handle}
            onFocus={() => setFocus(true)}
            onBlur={handleBlue}
            {...rest}
          />
        )}
        {!!unit && (
          <span className="text-sm absolute right-[10px]">{unit}</span>
        )}
      </div>
      {helperText && <p className="input-helper-text">{helperText}</p>}
    </div>
  );
};

export default Input;
