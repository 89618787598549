import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SummaryImg from "assets/images/summary.svg";
import SquareCheckbox from "components/SquareCheckbox";
import AccordionItem from "components/AccordionItem";
import FeatureSummaryItem from "./FeatureSummaryItem";
import Button from "components/Button";
import { GlobalContext } from "context/GlobalContext";
import {
  keyList,
  makeLabels,
  SET_LOADING_BAR,
  SET_ORIGIN_MACHINE_SETUP,
  SET_PROJECT_INFO,
  SET_STREAM_STEP,
  SET_VISIBLE_IDS,
  SUMMARY_VIEW,
} from "constant";
import { useApiCall } from "hooks";
import { postCreateUserSelectionAPI, putProjectAPI, putUserSelectionAPI } from "services";
import { json } from "stream/consumers";

const initData = [
  {
    category: "Hole",
    checked: false,
    pending: false,
    data: [
      {
        title: "Hole 1",
        checked: false,
        pending: false,
        data: [
          { key: "Diameter", value: "****" },
          { key: "Depth", value: "****" },
          { key: "Type", value: "****" },
          { key: "Bottom", value: "****" },
          { key: "Deburring", value: "Yes" },
          { key: "No. of Holes", value: "****" },
          { key: "Distance from Top", value: "****" },
          { key: "Use Extension rod", value: "****" },
          { key: "Add Nearest wall distances", value: "****" },
          { key: "Nearest distance X-direction", value: "****" },
          { key: "Nearest distance Y-direction", value: "****" },
          { key: "Extension rod length", value: "****" },
          { key: "Roughing tool", value: "****" },
          { key: "Corner radius", value: "****" },
          { key: "Countersink angle", value: "****" },
          { key: "Drill/Tip angle", value: "****" },
        ],
      },
      {
        title: "Hole 2",
        checked: false,
        pending: false,
        data: [
          { key: "Diameter", value: "****" },
          { key: "Depth", value: "****" },
          { key: "Type", value: "****" },
          { key: "Bottom", value: "****" },
          { key: "Deburring", value: "Yes" },
          { key: "No. of Holes", value: "****" },
          { key: "Distance from Top", value: "****" },
          { key: "Use Extension rod", value: "****" },
          { key: "Add Nearest wall distances", value: "****" },
          { key: "Nearest distance X-direction", value: "****" },
          { key: "Nearest distance Y-direction", value: "****" },
          { key: "Extension rod length", value: "****" },
          { key: "Roughing tool", value: "****" },
          { key: "Corner radius", value: "****" },
          { key: "Countersink angle", value: "****" },
          { key: "Drill/Tip angle", value: "****" },
        ],
      },
    ],
  },
  {
    category: "Thread Hole",
    checked: false,
    pending: false,
    data: [
      {
        title: "Thread Hole 1",
        checked: false,
        pending: false,
        data: [
          { key: "Diameter", value: "****" },
          { key: "Depth", value: "****" },
          { key: "Type", value: "****" },
          { key: "Bottom", value: "****" },
          { key: "Deburring", value: "Yes" },
          { key: "No. of Holes", value: "****" },
          { key: "Distance from Top", value: "****" },
          { key: "Use Extension rod", value: "****" },
          { key: "Add Nearest wall distances", value: "****" },
          { key: "Nearest distance X-direction", value: "****" },
          { key: "Nearest distance Y-direction", value: "****" },
          { key: "Extension rod length", value: "****" },
          { key: "Roughing tool", value: "****" },
          { key: "Corner radius", value: "****" },
          { key: "Countersink angle", value: "****" },
          { key: "Drill/Tip angle", value: "****" },
        ],
      },
      {
        title: "Thread Hole 2",
        checked: false,
        pending: false,
        data: [
          { key: "Diameter", value: "****" },
          { key: "Depth", value: "****" },
          { key: "Type", value: "****" },
          { key: "Bottom", value: "****" },
          { key: "Deburring", value: "Yes" },
          { key: "No. of Holes", value: "****" },
          { key: "Distance from Top", value: "****" },
          { key: "Use Extension rod", value: "****" },
          { key: "Add Nearest wall distances", value: "****" },
          { key: "Nearest distance X-direction", value: "****" },
          { key: "Nearest distance Y-direction", value: "****" },
          { key: "Extension rod length", value: "****" },
          { key: "Roughing tool", value: "****" },
          { key: "Corner radius", value: "****" },
          { key: "Countersink angle", value: "****" },
          { key: "Drill/Tip angle", value: "****" },
        ],
      },
    ],
  },
];

const FeatureSummary = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(GlobalContext);
  const { projectInfo, setupNumber, machiningSetups, originMachiningSetups } =
    state;
  const [data, setData] = useState<any>([]);
  const [checkedAll, setCheckedAll] = useState(true);
  const [pendingAll, setPendingAll] = useState(false);
  const [putUserSelection]: any = useApiCall(putUserSelectionAPI);
  const [postCreateUserSelection]: any = useApiCall(postCreateUserSelectionAPI);
  const [putProject]: any = useApiCall(putProjectAPI);
  const [defaultRecommendationCheck, setDefaultRecommendationCheck] =
    useState(false);

  useEffect(() => {
    if (!projectInfo || !Object.keys(projectInfo)?.length) {
      navigate("/projects");
      return;
    }
    let arr: any[] = [];

    const setupIdx = machiningSetups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );
    // machiningSetups?.[setupIdx]?.features
    // const availableData = keyList.filter(({ key }) => state[key].length > 0);
    keyList?.forEach(({ title, type }) => {
      const existing_features = machiningSetups?.[setupIdx]?.features?.filter(
        (feature: any) =>
          feature?.featureInformation?.feature_data?.feature_type === type
      );
      if (!existing_features?.length) return;
      let detailData: any[] = [];
      existing_features?.forEach((feature: any, idx1: number) => {
        const subType = feature?.featureInformation?.feature_data?.feature_name;
        let subData: any[] = [];
        let deepData: any[] = [];

        const labelsArray = makeLabels(
          type,
          { ...feature?.featureInformation?.feature_data?.feature_info },
          subType
        );
        
        labelsArray?.forEach((summary: any, idx2: number) => {
          subData.push({
            key: summary?.label,
            value:
              feature?.featureInformation?.feature_data?.feature_info?.[
                summary?.key
              ],
          });
        });
        detailData.push({
          title: `${title} ${idx1 + 1}`,
          checked: true,
          pending: false,
          index: idx1,
          data: subData,
          deepData: deepData,
          feature_id: feature?.feature_id,
        });
      });
      arr.push({
        category: title,
        checked: true,
        pending: false,
        // set_type: set_type,
        data: detailData,
      });
    });
    setData(arr);

    // let arr: any[] = [];
    // availableData?.forEach(({ key, title, type, set_type }, idx) => {
    //   let detailData: any[] = [];
    //   state[key]?.forEach((item: any, idx1: number) => {
    //     const subType = item?.type ? item?.type : type;
    //     let subData: any[] = [];
    //     let deepData: any[] = [];
    //     if (type === "core_cavity") {
    //       item?.data?.forEach((deep: any, idx3: number) => {
    //         let deepSubData: any[] = [];
    //         SUMMARY_VIEW[type]?.["data"]?.forEach(
    //           (summary: any, idx2: number) => {
    //             deepSubData.push({
    //               key: summary?.label,
    //               value: deep[summary?.key],
    //             });
    //           }
    //         );
    //         deepData.push(deepSubData);
    //       });
    //     }
    //     SUMMARY_VIEW[type][subType]?.forEach((summary: any, idx2: number) => {
    //       subData.push({ key: summary?.label, value: item[summary?.key] });
    //     });
    //     detailData.push({
    //       title: `${title} ${idx1 + 1}`,
    //       checked: true,
    //       pending: false,
    //       index: idx1,
    //       data: subData,
    //       deepData: deepData,
    //     });
    //   });

    //   arr.push({
    //     category: title,
    //     checked: true,
    //     pending: false,
    //     set_type: set_type,
    //     data: detailData,
    //   });
    // });
    // setData(arr);
  }, [setupNumber, machiningSetups]);

  const handleSubChecked = (idx: number, subIdx: number, checkVal: boolean) => {
    const newData = [...data];
    newData[idx].data[subIdx].checked = checkVal;
    const [checked, pending] = isChecked(newData[idx].data);
    newData[idx].checked = checked;
    newData[idx].pending = pending;
    const [rootChecked, rootPending] = isChecked(newData, true);
    setCheckedAll(rootChecked);
    setPendingAll(rootPending);
    setData(newData);
  };

  const isChecked = (checkingData: any[], rootFlag = false): any => {
    let pendingData = [];
    if (!rootFlag) pendingData = checkingData.filter((item) => !item.checked);
    else
      pendingData = checkingData.filter(
        (item) => !item.checked || item.pending
      );
    if (pendingData.length === 0) return [true, false];
    else if (pendingData && pendingData.length < checkingData.length)
      return [true, true];
    else if (pendingData && pendingData.length === checkingData.length)
      return [false, false];
  };

  const handleChecked = (idx: number, checkVal: boolean) => {
    if (idx > -1) {
      let newData = [...data];
      newData[idx].checked = checkVal;
      newData[idx].pending = false;
      newData[idx].data?.forEach((item: any) => {
        item.checked = checkVal;
      });
      setData(newData);
      const [rootChecked, rootPending] = isChecked(newData, true);
      setCheckedAll(rootChecked);
      setPendingAll(rootPending);
    } else {
      let newData = [...data];
      newData.forEach((rootItem: any, idx) => {
        rootItem.checked = checkVal;
        rootItem.pending = false;
        rootItem.data?.forEach((item: any) => {
          item.checked = checkVal;
        });
      });
      setData(newData);
      setCheckedAll(checkVal);
      setPendingAll(false);
    }
  };

  const handleNext = (tab: string) => {
    let visibleFeatures: any = [];
    data?.forEach((item: any, idx: number) => {
      item?.data?.forEach((item1: any, idx1: number) => {
        visibleFeatures.push(item1?.feature_id);
      });
    });

    dispatch({
      type: SET_VISIBLE_IDS,
      payload: {
        setupNumber,
        ids: visibleFeatures,
      },
    });

    const payload: any = {
      organizationId: projectInfo?.organizationId,
      streamStep: "initial-trigger",
      selectionId: projectInfo?.userSelectionId,
      ncProjectId: projectInfo?.ncProjectId,
      userRecordId: projectInfo?.userRecordId,
      machineId: "",
      machineType: "",
      workpieceCADfile: "",
      workpieceId: "",
      workpiecePathToCADfile: "",
      workpieceMaterial: projectInfo?.material,
      workpieceSubMaterial: projectInfo?.subMaterial,
      workpieceRawDimensions: {
        length: "",
        width: "",
        height: "",
      },
      machiningSetups,
    };
    if (projectInfo?.userSelectionId) {
      if (
        JSON.stringify(machiningSetups) ===
        JSON.stringify(originMachiningSetups)
      ) {
        setTimeout(() => {
          navigate(`/project?tab=${tab}`, {
            ...(tab === "default-recommendations" && {
              state: { referrer: "feature-summary" },
            }),
          });
        }, 500);
      } else {
        // Identifying the features where feature_data is changed and removing
        // strategy data from them.
        machiningSetups?.forEach((setupInfo: any, index: number) => {
          const originalSetupInfo = originMachiningSetups?.[index];
          for (const feature of setupInfo?.features ?? []) {
            for (const originalFeature of originalSetupInfo?.features ?? []) {
              if (
                feature?.feature_id === originalFeature?.feature_id &&
                JSON.stringify(feature?.featureInformation?.feature_data) !==
                  JSON.stringify(
                    originalFeature?.featureInformation?.feature_data
                  )
              ) {
                delete feature?.featureInformation?.strategies;
              }
            }
          }
        });
        let payload: any = {
          organizationId: projectInfo?.organizationId,
          selectionId: projectInfo?.userSelectionId,
          machiningSetups,
        };
        dispatch({
          type: SET_LOADING_BAR,
          payload: true,
        });
        putUserSelection(payload)
          .then(() => {
            dispatch({
              type: SET_ORIGIN_MACHINE_SETUP,
              payload: machiningSetups,
            });
          })
          .finally(() => {
            setTimeout(() => {
              dispatch({
                type: SET_LOADING_BAR,
                payload: false,
              });
              navigate(`/project?tab=${tab}`, {
                ...(tab === "default-recommendations" && {
                  state: { referrer: "feature-summary" },
                }),
              });
            }, 500);
          });
      }
    } else {
      dispatch({
        type: SET_LOADING_BAR,
        payload: true,
      });
      postCreateUserSelection(payload)
        .then(async (res: any) => {
          const putProjectPayload = {
            organizationId: projectInfo?.organizationId,
            ncProjectId: projectInfo?.ncProjectId,
            userRecordId: projectInfo?.userRecordId,
            userSelectionId: res?.selectionId,
          };
          await putProject(putProjectPayload);
          dispatch({
            type: SET_PROJECT_INFO,
            payload: {
              ...projectInfo,
              userSelectionId: res?.selectionId,
            },
          });
          dispatch({
            type: SET_STREAM_STEP,
            payload: "intial-trigger",
          });
          dispatch({
            type: SET_ORIGIN_MACHINE_SETUP,
            payload: [...machiningSetups],
          });
        })
        .finally(() => {
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_BAR,
              payload: false,
            });
            navigate(`/project?tab=${tab}`, {
              ...(tab === "default-recommendations" && {
                state: { referrer: "feature-summary" },
              }),
            });
          }, 500);
        });
    }
  };

  return (
    <div className="relative">
      <div className="w-full flex flex-col items-center justify-center py-7">
        <img src={SummaryImg} alt="summary img" className="w-10 h-10 mb-2" />
        <p className="text-black text-lg font-semibold text-center mb-1">
          Features Summary
        </p>
        <p className="text-sm text-black">
          Please verify the information below and select at least one feature to
          continue
        </p>
      </div>
      <div className="px-5 h-[calc(100vh-240px)] overflow-y-auto absolute left-0 right-0">
        <SquareCheckbox
          labelClassName="left-[25px] top-[-4px] font-semibold"
          label="Select All"
          checked={checkedAll}
          pending={pendingAll}
          onChange={(val: boolean) => handleChecked(-1, val)}
        />
        <div className="mt-4">
          {data?.map((item: any, idx: number) => (
            <AccordionItem
              key={idx}
              tabLabelChild={
                <div>
                  <SquareCheckbox
                    labelClassName="left-[25px] top-[-7px] font-semibold text-xl"
                    label={item?.category}
                    checked={item?.checked}
                    pending={item?.pending}
                    onChange={(val: boolean) => handleChecked(idx, val)}
                  />
                </div>
              }
              className="border-1 border-gray-c1c rounded-lg mb-4"
              labelChildClass="text-xl text-black ml-4"
              labelClass="py-2 border-b border-gray-c1c mx-[-2px]"
              dragIcon={false}
            >
              <FeatureSummaryItem
                data={item?.data}
                handleSubChecked={handleSubChecked}
                index={idx}
              />
            </AccordionItem>
          ))}
        </div>
      </div>

      <div className="fixed bottom-[54px] left-0 right-0 h-[30px] px-5 flex items-center border-t border-gray-efe bg-white">
        <SquareCheckbox
          labelClassName="left-[25px] top-[-4px] font-semibold"
          label="Select default recommendations"
          checked={defaultRecommendationCheck}
          onChange={() => {
            setDefaultRecommendationCheck((value) => !value);
          }}
        />
      </div>

      <div className="fixed bottom-0 left-0 right-0 h-[54px] px-5 flex justify-center items-center border-t border-gray-efe bg-white">
        <Button
          size="md"
          className="rounded-full py-[6px] w-[160px] bg-gray-f2f text-black-222 mr-6"
          onClick={() => navigate("/project?tab=feature")}
        >
          Edit
        </Button>
        <Button
          size="md"
          className="rounded-full py-[6px] whitespace-nowrap"
          disabled={!checkedAll && !pendingAll}
          onClick={() => {
            if (defaultRecommendationCheck) {
              handleNext("default-recommendations");
            } else {
              handleNext("machine");
            }
          }}
        >
          {defaultRecommendationCheck
            ? "Next: Summary"
            : "Next: Machining Strategy"}
        </Button>
      </div>
    </div>
  );
};

export default FeatureSummary;
