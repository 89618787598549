import Modal from "react-modal";
import { useEffect } from "react";
import WarningSign from "assets/images/icons/uil_exclamation-triangle.svg";

interface ConfirmPopUp {
  showConfirmPopUp: boolean;
  closeConfirmPopUp: any;
  confirmFunction: any;
  confirmMessage?: string;
}
const customStyles = {
  content: {
    top: "55%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: 0,
    width: "348px",
    marginRight: "-50%",
    borderRadius: "16px",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  },
};
const ConfirmPopUp: React.FC<ConfirmPopUp> = ({
  showConfirmPopUp,
  closeConfirmPopUp,
  confirmFunction,
  confirmMessage = "Are you sure you want to mark this strategy as Invalid?",
}) => {
  
  useEffect(() => {
    const warningTriangleImage = new Image();
    warningTriangleImage.src = WarningSign;
  }, []);

  return (
    <Modal
      isOpen={showConfirmPopUp}
      style={customStyles as Modal.Styles}
      onRequestClose={closeConfirmPopUp}
    >
      <div className="p-[16px] flex flex-col justify-center text-center gap-[16px]">
        <div>
          <img src={WarningSign} alt="Err" className="mx-auto" />
          <div className="text-[14px] font-normal">{confirmMessage}</div>
        </div>
        <div className="flex justify-between">
          <button
            className="w-[150px] px-[16px] py-[6px] bg-gray-200 rounded-[14px] text-[14px] font-semibold"
            onClick={closeConfirmPopUp}
          >
            Cancel
          </button>
          <button
            className="w-[150px] px-[16px] py-[6px] bg-blue-600 text-white rounded-[14px] text-[14px] font-semibold"
            onClick={() => {
              closeConfirmPopUp();
              confirmFunction();
            }}
          >
            Yes, Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmPopUp;
