import Input from "components/Input";
import RadioComponent from "components/RadioComponent";
import {
  CONFIRM_OPTIONS,
  HOLE_BOTTOM_OPTIONS,
  HOLE_BOTTOM_TYPE_OPTIONS,
  HOLE_TYPE_OPTIONS,
  SET_MACHINE_SETUP,
} from "constant";
import { useContext, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GlobalContext } from "context/GlobalContext";

interface IOpenHole {
  feature_info?: any;
  feature_id?: string;
}

const init_open_hole = {
  diameter: "",
  depth: "",
  quantity: "",
  hole_type: "",
  bottom_type: "",
  bottom: "",
  bottom_radius: "",
  bottom_angle: "",
  stock_to_remove: "",
  deburring: false,
  additional_length: "",
};

const validationSchema = Yup.object().shape({
  diameter: Yup.number()
    .required("This field is required")
    .min(0, "This needs over 0"),
  depth: Yup.number()
    .required("This field is required")
    .min(0, "This needs over 0"),
  quantity: Yup.number()
    .required("This field is required")
    .min(0, "This needs over 0"),
});

const OpenHole: React.FC<IOpenHole> = ({ feature_info, feature_id }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { projectInfo, machiningSetups, setupNumber } = state;

  useEffect(() => {
    if (!feature_info) return;
    let formValues: any = {};
    Object.keys(init_open_hole).forEach((key: string) => {
      if (key === "deburring") {
        formValues = {
          ...formValues,
          [key]: feature_info?.deburring === "yes",
        };
        return;
      }
      formValues = {
        ...formValues,
        [key]: feature_info?.[key] ?? "",
      };
    });
    setValues(formValues);
  }, [feature_info]);

  const {
    handleBlur,
    setFieldValue,
    handleChange,
    values,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: init_open_hole,
    validationSchema,
    onSubmit: () => {},
  });

  const handleChangeFunc = (key: string, value: any, flag: boolean = false) => {
    if (flag) setFieldValue(key, value);

    let updated_setups = [...machiningSetups];

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );

    let updated_feature = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]?.featureInformation
        ?.feature_data?.feature_info,
    };

    switch (key) {
      case "bottom_type":
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        if (value === "Through") {
          delete updated_feature["bottom"];
          setFieldValue("bottom", "");
          delete updated_feature["bottom_angle"];
          setFieldValue("bottom_angle", "");
          delete updated_feature["bottom_radius"];
          setFieldValue("bottom_radius", "");
        }
        break;
      case "bottom":
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        if (value === "Flat") {
          delete updated_feature["bottom_angle"];
          setFieldValue("bottom_angle", "");
        } else if (value === "v Shape") {
          delete updated_feature["bottom_radius"];
          setFieldValue("bottom_radius", "");
        }
        break;
      case "deburring":
        updated_feature = {
          ...updated_feature,
          [key]: value ? "yes" : "no",
        };
        break;
      case "bottom_radius":
        delete updated_feature["bottom_angle"];
        setFieldValue("bottom_angle", "");
        updated_feature = {
          ...updated_feature,
          [key]: Number(value),
        };
        break;
      case "bottom_angle":
        delete updated_feature["bottom_radius"];
        setFieldValue("bottom_radius", "");
        updated_feature = {
          ...updated_feature,
          [key]: Number(value),
        };
        break;
      case "hole_type":
        if (value === "Solid") {
          delete updated_feature.stock_to_remove;
          setFieldValue("stock_to_remove", "");
        }
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      default:
        updated_feature = {
          ...updated_feature,
          [key]: Number(value),
        };
    }

    updated_setups[setupIdx].features[
      feature_idx
    ].featureInformation.feature_data.feature_info = updated_feature;

    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });
  };

  return (
    <div>
      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">
          Feature dimensions
        </p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              placeholder="Diameter"
              className="mr-2"
              type="text"
              name="diameter"
              value={values?.diameter}
              unit={projectInfo?.unit || "mm"}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("diameter", values?.diameter);
              }}
              invalid={touched.diameter && errors.diameter}
              helperText={touched.diameter && errors.diameter}
              regex={/^\d*\.?\d*$/}
            />
          </div>
          <div className="w-6/12">
            <Input
              placeholder="Depth"
              className="ml-2"
              type="text"
              name="depth"
              unit={projectInfo?.unit || "mm"}
              value={values?.depth}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("depth", values?.depth);
              }}
              invalid={touched.depth && errors.depth}
              helperText={touched.depth && errors.depth}
              regex={/^\d*\.?\d*$/}
            />
          </div>
        </div>
      </div>

      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">Bottom details</p>
        <div className="flex flex-row mt-4">
          <div className="flex justify-between items-center w-6/12 py-[14px]">
            <label className="font-semibold text-gray-475 truncate mr-2">
              Bottom Type
            </label>
            <RadioComponent
              options={HOLE_BOTTOM_TYPE_OPTIONS}
              setValue={(val: any) =>
                handleChangeFunc("bottom_type", val, true)
              }
              value={values?.bottom_type}
            />
          </div>
          {values?.bottom_type === "Blind" && (
            <div className="flex justify-between items-center w-6/12 py-[14px]">
              <label className="font-semibold text-gray-475 truncate ml-2">
                Bottom
              </label>
              <RadioComponent
                options={HOLE_BOTTOM_OPTIONS}
                setValue={(val: any) => handleChangeFunc("bottom", val, true)}
                value={values?.bottom}
              />
            </div>
          )}
        </div>

        {values?.bottom_type === "Blind" && (
          <div className="flex flex-row mt-4">
            <div className="w-6/12">
              {values?.bottom === "Flat" && (
                <Input
                  placeholder="Bottom Radius"
                  type="text"
                  className="mr-2"
                  name="bottom_radius"
                  value={values?.bottom_radius}
                  onChange={handleChange}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    handleChangeFunc("bottom_radius", values?.bottom_radius);
                  }}
                  invalid={touched.bottom_radius && errors.bottom_radius}
                  helperText={touched.bottom_radius && errors.bottom_radius}
                  regex={/^\d*\.?\d*$/}
                />
              )}
              {values?.bottom === "v Shape" && (
                <Input
                  placeholder="Bottom Angle"
                  type="text"
                  className="mr-2"
                  name="bottom_angle"
                  value={values?.bottom_angle}
                  onChange={handleChange}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    handleChangeFunc("bottom_angle", values?.bottom_angle);
                  }}
                  invalid={touched.bottom_angle && errors.bottom_angle}
                  helperText={touched.bottom_angle && errors.bottom_angle}
                  regex={/^\d*$/}
                />
              )}
            </div>
          </div>
        )}
      </div>

      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">Other details</p>
        <div className="flex flex-row mt-4">
          <div className="flex justify-between items-center w-6/12 py-[14px]">
            <label className="font-semibold text-gray-475 truncate mr-2">
              Deburring
            </label>
            <RadioComponent
              options={CONFIRM_OPTIONS}
              setValue={(val: any) => handleChangeFunc("deburring", val, true)}
              value={values?.deburring}
            />
          </div>
          <div className="w-6/12">
            <Input
              placeholder="Quantity"
              className="ml-2"
              type="text"
              name="quantity"
              value={values?.quantity}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("quantity", values?.quantity);
              }}
              invalid={touched.quantity && errors.quantity}
              helperText={touched.quantity && errors.quantity}
              regex={/^[1-9]\d*$/}
            />
          </div>
        </div>
        <div className="flex flex-row mt-4">
          <div className="flex justify-between items-center w-6/12 py-[14px]">
            <label className="font-semibold text-gray-475 truncate mr-2">
              Hole Type
            </label>
            <RadioComponent
              options={HOLE_TYPE_OPTIONS}
              setValue={(val: any) => handleChangeFunc("hole_type", val, true)}
              value={values?.hole_type}
            />
          </div>
          {values?.hole_type === "Hollow" && (
            <div className="w-6/12">
              <Input
                placeholder="Stock to Remove"
                className="ml-2"
                type="text"
                name="stock_to_remove"
                value={values?.stock_to_remove}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc("stock_to_remove", values?.stock_to_remove);
                }}
                invalid={touched.stock_to_remove && errors.stock_to_remove}
                helperText={touched.stock_to_remove && errors.stock_to_remove}
                regex={/^\d*\.?\d*$/}
              />
            </div>
          )}
        </div>
      </div>

      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">
          Additional details
        </p>
        <div className="flex flex-row mt-4">
          <div className="flex w-6/12">
            <Input
              placeholder="Additional length"
              className="w-full mr-2"
              type="text"
              name="additional_length"
              value={values?.additional_length}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc(
                  "additional_length",
                  values?.additional_length
                );
              }}
              invalid={touched.additional_length && errors.additional_length}
              helperText={touched.additional_length && errors.additional_length}
              regex={/^\d*\.?\d*$/}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenHole;
