import { GlobalContext } from "context/GlobalContext";
import { useContext, useEffect, useState } from "react";
import {
  SET_LOADING_BAR,
  SET_MACHINE_SETUP,
  SET_ORIGIN_MACHINE_SETUP,
  SET_PROJECT_INFO,
  SET_STREAM_STEP,
  SET_VISIBLE_IDS,
} from "constant";
import "./DefaultRecommendation.css";
import { useApiCall } from "hooks";
import {
  getCuttingToolAPI,
  getMachiningStrategy,
  getParametersAPI,
  postCreateUserSelectionAPI,
  putProjectAPI,
  putUserSelectionAPI,
} from "services";
import { useNavigate, useLocation } from "react-router-dom";

const DefaultRecommendations = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { projectInfo, setupNumber, machiningSetups, originMachiningSetups } =
    state;
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [fetchStrategy] = useApiCall(getMachiningStrategy);
  const [fetchTool] = useApiCall(getCuttingToolAPI);
  const [fetchParameter] = useApiCall(getParametersAPI);
  const [putUserSelection] = useApiCall(putUserSelectionAPI);
  const [putProject] = useApiCall(putProjectAPI);
  const [postCreateUserSelection] = useApiCall(postCreateUserSelectionAPI);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // If user is not coming from the feature summary screen the request will be
    // redirected back to the project list screen.
    if (location.state?.referrer !== "feature-summary") {
      navigate("/projects");
      return;
    }

    const fetchDefaultRecommendations = async () => {
      // Step 1 -> Extract feature data from the global state
      const setupIdx = machiningSetups?.findIndex(
        (setup: any) => setup?.machiningSetupNumber === setupNumber
      );
      const updatedSetups = [...machiningSetups];
      const visibleFeatures: (string | number)[] = [];
      const featuresArray = JSON.parse(
        JSON.stringify(updatedSetups?.[setupIdx]?.features)
      );
      if (!featuresArray?.length) return;
      const progressInterval = 100 / (featuresArray.length * 3);
      const featuresBatchSize = 10;

      // Step 2 -> Running the code in batches with each item in batch running asynchronously
      for (let i = 0; i < featuresArray?.length; i += featuresBatchSize) {
        const batch = featuresArray
          .slice(i, i + featuresBatchSize)
          .map((batchItem: any) =>
            fetchFeatureRecomendations(
              batchItem,
              progressInterval,
              visibleFeatures
            )
          );
        await Promise.allSettled(batch);
      }

      // Once batch processing is complete the global state is updated
      updatedSetups[setupIdx].features = featuresArray;
      dispatch({
        type: SET_MACHINE_SETUP,
        payload: updatedSetups,
      });

      // Updated the visibleFeatures in the global state.
      dispatch({
        type: SET_VISIBLE_IDS,
        payload: {
          setupNumber,
          ids: visibleFeatures,
        },
      });

      // Redirecting to the summary page.
      handleRedirect("summary");
    };

    fetchDefaultRecommendations();
  }, []); // Only running the main logic once.

  const fetchFeatureRecomendations = async (
    feature: any,
    progressInterval: number,
    visibleFeatures: (string | number)[]
  ) => {
    try {
      const strategies = await fetchStrategyData(feature.featureInformation);
      if (!strategies?.[0]?.machining_strategy) {
        setProgress((progress) => progress + progressInterval * 3);
        return;
      }

      // Once startegies have been successfully fetched for a feature that
      // feature's id can be pushed into the visibleFeatures array.
      feature.featureInformation.strategies = strategies;
      visibleFeatures.push(feature?.feature_id);
      // Incrementing the progress bar.
      setProgress((progress) => progress + progressInterval);

      const selectedStrategy =
        feature.featureInformation?.strategies?.[0]?.machining_strategy?.find(
          (item: any) => item?.isSelected
        );
      if (!selectedStrategy?.passes) {
        // Incrementing the remaining progress value to complete iteration.
        setProgress((progress) => progress + progressInterval * 2);
        return;
      }

      // Step 2.2 -> Fetch tool recommendations for the feature using the
      // selected strategy
      const recommendedTools = await fetchToolData(
        feature.featureInformation,
        selectedStrategy
      );
      if (!recommendedTools) {
        // Complete the progress for that iteration.
        setProgress((progress) => progress + progressInterval * 2);
        return;
      }
      setProgress((progress) => progress + progressInterval);

      // Step 2.3 -> Fetching parameters using the selected strategy and the
      // fetched tools.
      let operationsBatch: any = [];
      for (const [idx1, pass] of selectedStrategy.passes.entries()) {
        if (!pass?.operations) continue;
        for (const [idx2, operation] of pass.operations.entries()) {
          if (!operation) continue;
          const operationTools =
            recommendedTools.passes?.[idx1]?.operations?.[idx2]?.tools;
          const toolSpecification =
            recommendedTools.passes?.[idx1]?.operations?.[idx2]
              ?.tool_specification;
          operationsBatch.push(
            fetchOperationParameters(
              feature,
              pass,
              operation,
              operationTools,
              toolSpecification
            )
          );
          if (operationsBatch.length >= 5) {
            await Promise.allSettled(operationsBatch);
            operationsBatch = [];
          }
        }
      }
      if (operationsBatch) {
        await Promise.allSettled(operationsBatch);
      }
      setProgress((progress) => progress + progressInterval);
    } catch (error) {}
  };

  const fetchOperationParameters = async (
    feature: any,
    pass: any,
    operation: any,
    operationTools: any,
    toolSpecification: any
  ) => {
    const parameters = await fetchParameterData(
      feature.featureInformation,
      pass,
      operation,
      operationTools
    );
    if (!parameters?.length) return;
    operation.tools = operationTools;
    operation.tool_specification = toolSpecification;
    operation.parameters = parameters;
    operation.selectedTool = 0;
    operation.selectedToolId = parameters[0]?.["Tool ID"];
  };

  const handleRedirect = (tab: string) => {
    try {
      if (tab !== "summary") {
        navigate(`/project?tab=${tab}`);
        return;
      }
      setIsLoading(true);
      let payload = {
        organizationId: projectInfo?.organizationId,
        streamStep: "initial-trigger",
        selectionId: projectInfo?.userSelectionId,
        ncProjectId: projectInfo?.ncProjectId,
        userRecordId: projectInfo?.userRecordId,
        machineId: "",
        machineType: "",
        workpieceCADfile: "",
        workpieceId: "",
        workpiecePathToCADfile: "",
        workpieceMaterial: projectInfo?.material,
        workpieceSubMaterial: projectInfo?.subMaterial,
        workpieceRawDimensions: {
          length: "",
          width: "",
          height: "",
        },
        machiningSetups,
      };

      if (projectInfo?.userSelectionId) {
        if (
          JSON.stringify(machiningSetups) ===
          JSON.stringify(originMachiningSetups)
        ) {
          setIsLoading(false);
          setTimeout(() => {
            navigate(`/project?tab=${tab}`, {
              state: { referrer: "default-recommendation" },
            });
          }, 400);
        } else {
          putUserSelection({
            ...payload,
            selectionId: projectInfo?.userSelectionId,
          })
            .then(() => {
              dispatch({
                type: SET_ORIGIN_MACHINE_SETUP,
                payload: machiningSetups,
              });
            })
            .finally(() => {
              setIsLoading(false);
              setTimeout(() => {
                navigate(`/project?tab=${tab}`, {
                  state: { referrer: "default-recommendation" },
                });
              }, 400);
            });
        }
      } else
        postCreateUserSelection(payload)
          .then(async (res: any) => {
            let payload1 = {
              organizationId: projectInfo?.organizationId,
              ncProjectId: projectInfo?.ncProjectId,
              userRecordId: projectInfo?.userRecordId,
              userSelectionId: res?.selectionId,
            };
            await putProject(payload1);
            dispatch({
              type: SET_PROJECT_INFO,
              payload: {
                ...projectInfo,
                userSelectionId: res?.selectionId,
              },
            });
            dispatch({
              type: SET_STREAM_STEP,
              payload: "intial-trigger",
            });
            dispatch({
              type: SET_ORIGIN_MACHINE_SETUP,
              payload: machiningSetups,
            });
          })
          .finally(() => {
            setIsLoading(false);
            setTimeout(() => {
              navigate(`/project?tab=${tab}`, {
                state: { referrer: "default-recommendation" },
              });
            }, 400);
          });
    } catch (err) {}
  };

  const fetchStrategyData = async (featureInformation: any) => {
    let result = [];
    try {
      const res = await fetchStrategy([featureInformation?.feature_data]);
      if (!res) return;
      const new_strategy = res?.strategies?.[0]?.machining_strategy?.map(
        (item: any, idx: number) => {
          if (idx === 0) {
            return { ...item, isSelected: true };
          }
          return { ...item, isSelected: false };
        }
      );
      res.strategies[0].machining_strategy = new_strategy;
      result = res.strategies;
    } catch (error) {}
    return result;
  };

  const fetchToolData = async (
    featureInformation: any,
    selectedStrategy: any
  ) => {
    let result;
    try {
      const new_passes = [...(selectedStrategy?.passes ?? [])];
      selectedStrategy?.passes?.forEach((pass: any, idx1: number) => {
        pass?.operations?.forEach((operation: any, idx2: number) => {
          new_passes[idx1].operations[idx2] = {
            ...operation,
            tool_path: operation?.tool_paths[0]?.tool_path,
            tool_path_type: operation?.tool_paths[0]?.tool_path_type,
            tool_type: operation?.tool_paths[0]?.tool_type
              ? operation?.tool_paths[0]?.tool_type
              : operation?.tool_paths[0]?.tool_types[0],
          };
        });
      });
      let payload: any = [{ ...featureInformation?.feature_data }];
      payload[0]["machining_strategy"] = {
        passes: new_passes,
      };
      const res = await fetchTool(payload);
      if (res) {
        result = res.cutting_tools?.[0]?.tool_recommendation;
      }
    } catch (error) {}
    return result;
  };

  const fetchParameterData = async (
    featureInformation: any,
    passInfo: any,
    operationInfo: any,
    tools: any
  ) => {
    let result;
    try {
      const paramsRequestPayload = [
        {
          feature_type: featureInformation?.feature_data?.feature_type,
          feature_name: featureInformation?.feature_data?.feature_name,
          feature_id: featureInformation?.feature_data?.feature_id,
          material_info: featureInformation?.feature_data?.material_info,
          machine_info: featureInformation?.feature_data?.machine_info,
          feature_info: featureInformation?.feature_data?.feature_info,
          machining_strategy: {
            cutting_pass: passInfo?.pass,
            operation: operationInfo?.operation,
            tools: tools,
            weightage: 5,
            tool_path: operationInfo?.tool_path,
            tool_path_type: operationInfo?.tool_path_type,
          },
        },
      ];
      const res = await fetchParameter(paramsRequestPayload);
      if (res) {
        result =
          res?.cutting_tools_parameters?.[0]?.cutting_tools_parameters || [];
      }
    } catch (error) {}
    return result;
  };

  return (
    <div className="loaderbg fixed top-[0px] left-[0px] flex-col h-full w-full index-99 flex items-center justify-center">
      <div className="inline-flex flex-col">
        <div className={`loaderinner flex gap-4 items-center completespin`}>
          <div className="inline-flex w-[24px] h-[24px] relative spinner">
            <div className="inline-flex w-[24px] h-[24px] items-top pt-[5px] justify-center absolute top-[0px] left-[0px] rotateanimation-x">
              <svg
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 6.5C2 5.84339 2.12933 5.19321 2.3806 4.58658C2.63188 3.97996 3.00017 3.42876 3.46447 2.96447C3.92876 2.50017 4.47995 2.13188 5.08658 1.8806C5.69321 1.62933 6.34339 1.5 7 1.5C7.65661 1.5 8.30679 1.62933 8.91342 1.8806C9.52004 2.13188 10.0712 2.50017 10.5355 2.96447C10.9998 3.42876 11.3681 3.97996 11.6194 4.58658C11.8707 5.19321 12 5.84339 12 6.5"
                  stroke="#98A2B3"
                  stroke-width="3"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <div className="inline-flex w-[24px] h-[24px] items-end justify-center absolute top-[0px] left-[0px] rotateanimation-y">
              <svg
                width="24"
                height="14"
                viewBox="0 0 24 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.5 1.5C22.5 2.87888 22.2284 4.24426 21.7007 5.51818C21.1731 6.79209 20.3996 7.94961 19.4246 8.92462C18.4496 9.89964 17.2921 10.6731 16.0182 11.2007C14.7443 11.7284 13.3789 12 12 12C10.6211 12 9.25574 11.7284 7.98182 11.2007C6.7079 10.6731 5.55039 9.89964 4.57538 8.92462C3.60036 7.94961 2.82694 6.79209 2.29926 5.51818C1.77159 4.24426 1.5 2.87888 1.5 1.5"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                />
              </svg>
            </div>
          </div>
          <div className="checkspin w-[24px] h-[24px] inline-flex items-center justify-center">
            <svg
              width="22"
              height="21"
              viewBox="0 0 22 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.3761 11.6251C19.6261 15.3751 16.7986 18.9061 12.8311 19.6951C10.8961 20.0804 8.88879 19.8455 7.09507 19.0237C5.30136 18.2018 3.81265 16.8351 2.84091 15.118C1.86917 13.4008 1.46395 11.4209 1.68293 9.46008C1.90192 7.49926 2.73396 5.65751 4.06057 4.19707C6.78157 1.20007 11.3761 0.375069 15.1261 1.87507"
                stroke="#30F599"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.625 10.125L11.375 13.875L20.375 4.125"
                stroke="#30F599"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <span className="">Feature Recognition</span>
        </div>
        <div className="linecopleted">
          <div
            className="lineprogressloader"
            style={{ height: `${(Math.min(33, progress) / 33) * 100}%` }}
          ></div>
        </div>
        <div
          className={`loaderinner flex gap-4 items-center ${
            progress < 33 ? "activespin" : "completespin"
          }`}
        >
          <div className="inline-flex w-[24px] h-[24px] relative spinner">
            <div className="inline-flex w-[24px] h-[24px] items-top pt-[5px] justify-center absolute top-[0px] left-[0px] rotateanimation-x">
              <svg
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 6.5C2 5.84339 2.12933 5.19321 2.3806 4.58658C2.63188 3.97996 3.00017 3.42876 3.46447 2.96447C3.92876 2.50017 4.47995 2.13188 5.08658 1.8806C5.69321 1.62933 6.34339 1.5 7 1.5C7.65661 1.5 8.30679 1.62933 8.91342 1.8806C9.52004 2.13188 10.0712 2.50017 10.5355 2.96447C10.9998 3.42876 11.3681 3.97996 11.6194 4.58658C11.8707 5.19321 12 5.84339 12 6.5"
                  stroke="#98A2B3"
                  stroke-width="3"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <div className="inline-flex w-[24px] h-[24px] items-end justify-center absolute top-[0px] left-[0px] rotateanimation-y">
              <svg
                width="24"
                height="14"
                viewBox="0 0 24 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.5 1.5C22.5 2.87888 22.2284 4.24426 21.7007 5.51818C21.1731 6.79209 20.3996 7.94961 19.4246 8.92462C18.4496 9.89964 17.2921 10.6731 16.0182 11.2007C14.7443 11.7284 13.3789 12 12 12C10.6211 12 9.25574 11.7284 7.98182 11.2007C6.7079 10.6731 5.55039 9.89964 4.57538 8.92462C3.60036 7.94961 2.82694 6.79209 2.29926 5.51818C1.77159 4.24426 1.5 2.87888 1.5 1.5"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                />
              </svg>
            </div>
          </div>
          <div className="checkspin w-[24px] h-[24px] inline-flex items-center justify-center">
            <svg
              width="22"
              height="21"
              viewBox="0 0 22 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.3761 11.6251C19.6261 15.3751 16.7986 18.9061 12.8311 19.6951C10.8961 20.0804 8.88879 19.8455 7.09507 19.0237C5.30136 18.2018 3.81265 16.8351 2.84091 15.118C1.86917 13.4008 1.46395 11.4209 1.68293 9.46008C1.90192 7.49926 2.73396 5.65751 4.06057 4.19707C6.78157 1.20007 11.3761 0.375069 15.1261 1.87507"
                stroke="#30F599"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.625 10.125L11.375 13.875L20.375 4.125"
                stroke="#30F599"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <span className="">
            {progress < 33 ? "Fetching " : "Fetched "}Strategies
          </span>
        </div>
        <div className="linecopleted">
          <div
            className="lineprogressloader"
            style={
              progress > 33
                ? { height: `${(Math.min(33, progress - 33) / 33) * 100}%` }
                : { height: "0%" }
            }
          ></div>
        </div>
        <div
          className={`loaderinner flex gap-4 items-center relative ${
            progress >= 33 ? "activespin" : ""
          } ${progress >= 66 ? "completespin" : ""}`}
        >
          <div className="inline-flex w-[24px] h-[24px] relative spinner">
            <div className="inline-flex w-[24px] h-[24px] items-top pt-[5px] justify-center absolute top-[0px] left-[0px] rotateanimation-x">
              <svg
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 6.5C2 5.84339 2.12933 5.19321 2.3806 4.58658C2.63188 3.97996 3.00017 3.42876 3.46447 2.96447C3.92876 2.50017 4.47995 2.13188 5.08658 1.8806C5.69321 1.62933 6.34339 1.5 7 1.5C7.65661 1.5 8.30679 1.62933 8.91342 1.8806C9.52004 2.13188 10.0712 2.50017 10.5355 2.96447C10.9998 3.42876 11.3681 3.97996 11.6194 4.58658C11.8707 5.19321 12 5.84339 12 6.5"
                  stroke="#98A2B3"
                  stroke-width="3"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <div className="inline-flex w-[24px] h-[24px] items-end justify-center absolute top-[0px] left-[0px] rotateanimation-y">
              <svg
                width="24"
                height="14"
                viewBox="0 0 24 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.5 1.5C22.5 2.87888 22.2284 4.24426 21.7007 5.51818C21.1731 6.79209 20.3996 7.94961 19.4246 8.92462C18.4496 9.89964 17.2921 10.6731 16.0182 11.2007C14.7443 11.7284 13.3789 12 12 12C10.6211 12 9.25574 11.7284 7.98182 11.2007C6.7079 10.6731 5.55039 9.89964 4.57538 8.92462C3.60036 7.94961 2.82694 6.79209 2.29926 5.51818C1.77159 4.24426 1.5 2.87888 1.5 1.5"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                />
              </svg>
            </div>
          </div>
          <div className="checkspin w-[24px] h-[24px] inline-flex items-center justify-center">
            <svg
              width="22"
              height="21"
              viewBox="0 0 22 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.3761 11.6251C19.6261 15.3751 16.7986 18.9061 12.8311 19.6951C10.8961 20.0804 8.88879 19.8455 7.09507 19.0237C5.30136 18.2018 3.81265 16.8351 2.84091 15.118C1.86917 13.4008 1.46395 11.4209 1.68293 9.46008C1.90192 7.49926 2.73396 5.65751 4.06057 4.19707C6.78157 1.20007 11.3761 0.375069 15.1261 1.87507"
                stroke="#30F599"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.625 10.125L11.375 13.875L20.375 4.125"
                stroke="#30F599"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <span>{progress < 66 ? "Fetching " : "Fetched "}Tools</span>
        </div>
        <div className="linecopleted">
          <div
            className="lineprogressloader"
            style={
              progress > 66
                ? { height: `${(Math.min(33, progress - 66) / 33) * 100}%` }
                : { height: "0%" }
            }
          ></div>
        </div>
        <div
          className={`loaderinner flex gap-4 items-center relative ${
            progress >= 66 || isLoading ? "activespin" : ""
          } ${progress >= 99 && !isLoading ? "completespin" : ""}`}
        >
          <div className="inline-flex w-[24px] h-[24px] relative spinner">
            <div className="inline-flex w-[24px] h-[24px] items-top pt-[5px] justify-center absolute top-[0px] left-[0px] rotateanimation-x">
              <svg
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 6.5C2 5.84339 2.12933 5.19321 2.3806 4.58658C2.63188 3.97996 3.00017 3.42876 3.46447 2.96447C3.92876 2.50017 4.47995 2.13188 5.08658 1.8806C5.69321 1.62933 6.34339 1.5 7 1.5C7.65661 1.5 8.30679 1.62933 8.91342 1.8806C9.52004 2.13188 10.0712 2.50017 10.5355 2.96447C10.9998 3.42876 11.3681 3.97996 11.6194 4.58658C11.8707 5.19321 12 5.84339 12 6.5"
                  stroke="#98A2B3"
                  stroke-width="3"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <div className="inline-flex w-[24px] h-[24px] items-end justify-center absolute top-[0px] left-[0px] rotateanimation-y">
              <svg
                width="24"
                height="14"
                viewBox="0 0 24 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.5 1.5C22.5 2.87888 22.2284 4.24426 21.7007 5.51818C21.1731 6.79209 20.3996 7.94961 19.4246 8.92462C18.4496 9.89964 17.2921 10.6731 16.0182 11.2007C14.7443 11.7284 13.3789 12 12 12C10.6211 12 9.25574 11.7284 7.98182 11.2007C6.7079 10.6731 5.55039 9.89964 4.57538 8.92462C3.60036 7.94961 2.82694 6.79209 2.29926 5.51818C1.77159 4.24426 1.5 2.87888 1.5 1.5"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                />
              </svg>
            </div>
          </div>
          <div className="checkspin w-[24px] h-[24px] inline-flex items-center justify-center">
            <svg
              width="22"
              height="21"
              viewBox="0 0 22 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.3761 11.6251C19.6261 15.3751 16.7986 18.9061 12.8311 19.6951C10.8961 20.0804 8.88879 19.8455 7.09507 19.0237C5.30136 18.2018 3.81265 16.8351 2.84091 15.118C1.86917 13.4008 1.46395 11.4209 1.68293 9.46008C1.90192 7.49926 2.73396 5.65751 4.06057 4.19707C6.78157 1.20007 11.3761 0.375069 15.1261 1.87507"
                stroke="#30F599"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.625 10.125L11.375 13.875L20.375 4.125"
                stroke="#30F599"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <span>
            {progress < 99 || isLoading ? "Fetching " : "Fetched "}Cutting
            Params
          </span>
        </div>
      </div>
    </div>
  );
};
//FIX ME : Progress bar checked when no visible features
export default DefaultRecommendations;
