import { useCallback, useState } from "react";
import { v4 as uuid } from "uuid";
declare const window: any;

export const useLocalMasterCamApiCall = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState<any>(null);

  const fetchData = useCallback((type: string, args: any) => {
    const callbackId = "lfReactPluginEvents_" + uuid();
    let timeoutId: NodeJS.Timeout;
    let isResolved = false;
    return new Promise<void>((resolve) => {
      function handleReturnEvent(event: any) {
        if (!isResolved) {
          try {
            const response = JSON.parse(event.data);
            setData(response);
          } catch (e) {
            setError("Error occured while trying to parse JSON");
          } finally {
            window.removeEventListener(callbackId, handleReturnEvent);
            clearTimeout(timeoutId);
            setLoading(false);
            isResolved = true;
            resolve();
          }
        }
      }

      function timeoutEventListner() {
        if (!isResolved) {
          window.removeEventListener(callbackId, handleReturnEvent);
          setError("Event timeout error");
          setLoading(false);
          isResolved = true;
          resolve();
        }
      }

      setLoading(true);
      if (window.chrome?.webview) {
        window.addEventListener(callbackId, handleReturnEvent);
        window.chrome.webview.postMessage({ type, args, callbackId });
        // Removing the Event Listener after a certain threshold time for cases
        // where we do not recieve back any event so that the impact on the
        // memory comsumption is minimum. (default threshold -> 10 mins)
        timeoutId = setTimeout(() => {
          timeoutEventListner();
        }, 600000);
      }
    });
  }, []);

    return [fetchData as Function, loading, data, error];
};
